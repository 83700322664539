<template>
  <div>
    <vs-row
      v-if="
        (camposObjeto || []).length > 0 &&
        (camposObjeto || []).filter((x) => x.Type != 'Automatic').length > 0
      "
      id="tags"
      class="pt-5 flex"
      vs-align="flex-end"
    >
      <template v-for="(tag, index) in camposObjeto || []">
        <vs-col
          class="tag-col pt-10"
          vs-lg="3"
          vs-sm="12"
          :key="index"
          v-if="tag.Type != 'Automatic'"
        >
          <vx-tooltip
            v-if="tag.Label === 'Conforme' || tag.Label === 'Referente'"
            position="center top"
            :text="tag.Label === 'Conforme' ? tooltip_conforme : tooltip_referente"
            class="enable-tag">
            <i class="material-icons">info</i>
          </vx-tooltip>
          <onpoint-input
            :input="tag"
            :validatorRuleObject="{ required: true }"
            :disabledTagFieldsEndosso="disabledTagFieldsEndosso"
            @input="handleInput(tag, $event)"
          />
        </vs-col>
      </template>
    </vs-row>

    <vs-row>
      <div class="w-full mt-8">
        <p class="font-semibold">Objeto</p>
        <p class="text-objeto p-5" v-html="currentTab == '1' && endosso.IsCompleteChangeObject ? endosso.CompleteObject : textoObjetoHtml"></p>
      </div>
    </vs-row>
  </div>
</template>

<script>
import TagHelpers from "@/helpers/TagHelpers";
import { mapGetters, mapMutations  } from "vuex";
import compareCompanyUtils from "@/utils/compareCompanyUtils.js";

export default {
  props: {
    isEndosso: { type: Boolean, default: false },
    cleanTags: {
      type: Boolean,
      default: false,
    },
    defaultTags: {
      type: Array,
      default() {
        return [];
      },
    },
    modalidadeObjeto: {
      type: Object,
      default() {
        return {};
      },
    },
    modalidadeComplementarObjeto: {
      type: Object,
      default() {
        return {};
      },
    },
    coberturasAdicionaisObjeto: {
      type: Array,
      default() {
        return [];
      },
    },
    endossoObjeto: {
      type: Array,
      default() {
        return [];
      },
    },
    tagsSeguradora: {
      type: Array,
      default() {
        return [];
      },
    },
    disabledTagFieldsEndosso: {
      type: Boolean,
      default: false
    },
    currentTab: {
      type: String,
    }
  },
  data() {
    return {
      textoObjetoHtml: "",
      camposObjeto: [],
      objeto: {},
      Excelsior: false,
      tooltip_conforme: "Documento vinculado ao Contrato Principal que gerou a necessidade da alteração da apólice.",
      tooltip_referente: "Contrato/Pedido de Compra/Documento gerador da apólice, objeto da presente garantia."
    };
  },
  computed: {
    ...mapGetters("endosso-module", ["endosso"]),
  },
  watch: {
    cleanTags() {
      this.cleanTag();
    },
    tagsSeguradora(newVal, oldVal) {
      if (newVal != oldVal) this.formatarTextoObjeto(false);
    },

    modalidadeObjeto(value) {
      this.montarCamposObjeto();
      this.formatarTextoObjeto(true);
    },

    modalidadeComplementarObjeto(value) {      
      this.montarCamposObjeto();
      this.formatarTextoObjeto(true);
    },

    coberturasAdicionaisObjeto(value) {
      this.montarCamposObjeto();
      this.formatarTextoObjeto(false);
    },

    endossoObjeto(value) {
      this.montarCamposObjeto();
      this.formatarTextoObjeto(true);
    },

    defaultTags(newVal, oldVal) {
      if (oldVal.length && this.$utils.validChanged(newVal, oldVal)) {
        this.formatarTextoObjeto(false);
      } else this.formatarTextoObjeto(true);

      this.montarCamposObjeto();
    },
  },
  methods: {
    ...mapMutations("endosso-module", ["updateChangedObjectEndorsement"]),
    
    setObject(value) {
      this.objeto = { ...this.objeto, ...value };
    },

    formatarTextoObjeto(initValue) {
      this.textoObjetoHtml = this.formatTextDefaultTags();
      this.atualizarPropostaTermos(initValue);
      this.$emit("textoObjetoCompleto", {
        objetoCompleto: this.textoObjetoHtml,
      });
      this.$forceUpdate();
    },

    formatTextDefaultTags() {
      let allTags = [];
      let textHtml = "";

      if (this.modalidadeObjeto || {}) {
        if ((this.modalidadeObjeto || {}).JSONTag)
          allTags = allTags.concat(eval(this.modalidadeObjeto.JSONTag) || []);

        if (this.modalidadeObjeto.Value)
          textHtml += this.modalidadeObjeto.Value;
      }

      if (this.modalidadeComplementarObjeto || {}) {
        if ((this.modalidadeComplementarObjeto || {}).JSONTag)
          allTags = allTags.concat(
            eval(this.modalidadeComplementarObjeto.JSONTag) || []
          );

        if (this.modalidadeComplementarObjeto.Value)
          textHtml += `\n${this.modalidadeComplementarObjeto.Value}\n`;
      }

      if ((this.endossoObjeto || []).length > 0)
        for (var i in this.endossoObjeto) {
          if (typeof i == "string") i = parseInt(i);
          if (i) textHtml += "</br></br>";

          allTags = allTags.concat(JSON.parse(this.endossoObjeto[i].JSONTag));
          textHtml += this.endossoObjeto[i].Value;
        }

      if ((this.coberturasAdicionaisObjeto || []).length > 0) {
        if (this.Excelsior) {
          textHtml += `<br/>A Modalidade e a Importância Segurada dispostas na Descrição da Garantia contemplam as <b>COBERTURAS ADICIONAIS</b> descritas abaixo.
        <br/>Não obstante a previsão de Limite Máximo de Indenização por cobertura contratada, em caso de sinistro, fica certo e ajustado que a soma de todas as Indenizações não poderá ultrapassar o Limite Máximo de Garantia, que para os fins desta apólice coincide com a IMPORTÂNCIA SEGURADA descrita no frontispício da apólice.
        <br/>A(s) cobertura(a) adicional(is) garante(m) o prazo prescricional nos termos do art. 7º, XXIX da Constituição da República, conforme condições especiais de cada cobertura, disposta(s) na presente apólice.<br/>`;
        }
        this.coberturasAdicionaisObjeto.forEach((coberturaAdicionalObjeto) => {
          if ((this.coberturaAdicionalObjeto || {}).JSONTag)
            allTags = allTags.concat(
              eval(this.coberturaAdicionalObjeto.JSONTag) || []
            );

          if ((coberturaAdicionalObjeto || {}).Value) {
            textHtml += `\n${coberturaAdicionalObjeto.Value}\n`;
          }
        });
      }

      if (!this.isEndosso)
        textHtml +=
          "<br/>Esta apólice é emitida de acordo com as condições da Circular da Susep n.º 662/22.";

      this.defaultTags.forEach((tag) => {
        const defaultTagVal = tag.Value;
        delete tag["value"];
        allTags.push(tag);

        this.objeto[tag.Name] = defaultTagVal;
      });

      if (this.tagsSeguradora && this.tagsSeguradora.length > 0) {
        this.tagsSeguradora.forEach((tag) => {
          allTags.push(tag);
          this.objeto[tag.Name] = tag.Value;
        });
      }

      if (this.camposObjeto && this.camposObjeto.length > 0) {
        this.camposObjeto.forEach((tag) => {
          allTags.push(tag);
        });
      }
      textHtml = TagHelpers.replaceTags(allTags, textHtml, this.objeto);

      textHtml = textHtml.replaceAll(/(?:\r\n|\r|\n)/g, "<br>");

      return textHtml;
    },

    atualizarPropostaTermos(initValue) {
      let propostaTermos = [];
      let tags = null;

      let auxObjeto = {};
      let auxValue = "";

      if ((this.modalidadeObjeto || {}).JSONTag) {
        tags = eval(this.modalidadeObjeto.JSONTag || []);
        auxValue = this.modalidadeObjeto.Value;

        if ((tags || []).length > 0) {
          Object.getOwnPropertyNames(this.objeto).forEach((objetoTag) => {
            if (auxValue.indexOf(`[${objetoTag}]`) > -1) {
              auxValue = auxValue.replaceAll(
                `[${objetoTag}]`,
                this.objeto[objetoTag]
              );
              auxObjeto[objetoTag] = this.objeto[objetoTag];
            }

            tags.forEach((tag) => {
              if (objetoTag == tag.Name)
                auxObjeto[objetoTag] = this.objeto[objetoTag];
            });
          });
        }

        propostaTermos.push({
          ContractualTermId: this.modalidadeObjeto.Id,
          Value: auxValue,
          JSONTagValue: JSON.stringify(auxObjeto),
        });
      }

      if ((this.modalidadeComplementarObjeto || {}).Value) {
        auxObjeto = {};
        auxValue = this.modalidadeComplementarObjeto.Value;

        tags = null;
        if ((this.modalidadeComplementarObjeto || {}).JSONTag)
          tags = eval(this.modalidadeComplementarObjeto.JSONTag || []);

        if ((tags || []).length > 0) {
          Object.getOwnPropertyNames(this.objeto).forEach((objetoTag) => {
            if (auxValue.indexOf(`[${objetoTag}]`) > -1) {
              auxValue = auxValue.replaceAll(
                `[${objetoTag}]`,
                this.objeto[objetoTag]
              );

              auxObjeto[objetoTag] = this.objeto[objetoTag];
            }

            tags.forEach((tag) => {
              if (objetoTag == tag.Name)
                auxObjeto[objetoTag] = this.objeto[objetoTag];
            });
          });
        }

        const idx = propostaTermos.findIndex(
          (item) =>
            item.ContractualTermId == this.modalidadeComplementarObjeto.Id
        );
        if (idx > -1) {
          propostaTermos[idx] = {
            ...propostaTermos[idx],
            Value: auxValue,
            JSONTagValue: JSON.stringify(auxObjeto),
          };
        } else {
          propostaTermos.push({
            ContractualTermId: this.modalidadeComplementarObjeto.Id,
            Value: auxValue,
            JSONTagValue: JSON.stringify(auxObjeto),
          });
        }
      }

      if ((this.coberturasAdicionaisObjeto || []).length > 0) {
        this.coberturasAdicionaisObjeto.forEach((coberturaAdicionalObjeto) => {
          auxObjeto = null;
          auxValue = coberturaAdicionalObjeto.Value;
          tags = null;

          if ((coberturaAdicionalObjeto || {}).JSONTag)
            tags = eval(coberturaAdicionalObjeto.JSONTag || []);

          if ((tags || []).length > 0) {
            auxObjeto = {};

            Object.getOwnPropertyNames(this.objeto).forEach((objetoTag) => {
              if (this.objeto[objetoTag]) {
                if (auxValue.indexOf(`[${objetoTag}]`) > -1) {
                  auxValue = auxValue.replaceAll(
                    `[${objetoTag}]`,
                    this.objeto[objetoTag]
                  );

                  auxObjeto[objetoTag] = this.objeto[objetoTag];
                }

                tags.forEach((tag) => {
                  if (objetoTag == tag.Name)
                    auxObjeto[objetoTag] = this.objeto[objetoTag];
                });
              }
            });
          }

          const idx = propostaTermos.findIndex(
            (item) => item.ContractualTermId == coberturaAdicionalObjeto.Id
          );

          if (idx > -1) {
            propostaTermos[idx] = {
              ...propostaTermos[idx],
              Value: auxValue,
              JSONTagValue: !!auxObjeto ? JSON.stringify(auxObjeto) : null,
            };
          } else {
            propostaTermos.push({
              ContractualTermId: coberturaAdicionalObjeto.Id,
              Value: auxValue,
              JSONTagValue: !!auxObjeto ? JSON.stringify(auxObjeto) : null,
            });
          }
        });
      }

      if ((this.endossoObjeto || []).length > 0) {
        this.endossoObjeto.forEach((term) => {
          const auxObjeto = {};
          let auxValue = term.Value;

          Object.getOwnPropertyNames(this.objeto).forEach((objetoTag) => {
            if (this.objeto[objetoTag])
              if (auxValue.indexOf(`[${objetoTag}]`) > -1) {
                auxValue = auxValue.replaceAll(
                  `[${objetoTag}]`,
                  this.objeto[objetoTag]
                );

                auxObjeto[objetoTag] = this.objeto[objetoTag];
              }
          });

          const idx = propostaTermos.findIndex(
            (item) => item.ContractualTermId == term.ContractualTermId
          );

          if (idx > -1) {
            propostaTermos[idx] = {
              ...propostaTermos[idx],
              Value: auxValue,
              JSONTagValue: JSON.stringify(auxObjeto),
            };
          } else {
            propostaTermos.push({
              ContractualTermId: term.ContractualTermId,
              Value: auxValue,
              JSONTagValue: JSON.stringify(auxObjeto),
            });
          }
        });
      }

      this.$emit("updatePropostaTermos", {
        termos: propostaTermos,
        initValue: initValue,
        object: JSON.stringify(this.objeto),
      });
    },

    montarCamposObjeto() {     
      (eval((this.modalidadeObjeto || {}).JSONTag) || []).forEach(
        (modalidadeComplementarObjetoTag) => {
          const idxFound = this.camposObjeto.findIndex((campo) => {
            return campo.Name == modalidadeComplementarObjetoTag.Name;
          });
          if (idxFound === -1)
            this.camposObjeto.push(modalidadeComplementarObjetoTag);
        }
      );

      if ((this.modalidadeObjeto || {}).JSONTagValue)
        this.objeto = {
          ...this.objeto,
          ...JSON.parse((this.modalidadeObjeto || {}).JSONTagValue || ""),
        };

      if ((this.modalidadeComplementarObjeto || {}).JSONTag) {
        if ((this.modalidadeComplementarObjeto || {}).JSONTagValue)
          this.objeto = {
            ...this.objeto,
            ...JSON.parse(this.modalidadeComplementarObjeto.JSONTagValue || ""),
          };

        (eval(this.modalidadeComplementarObjeto.JSONTag) || []).forEach(
          (modalidadeComplementarObjetoTag) => {
            const idxFound = this.camposObjeto.findIndex((campo) => {
              return campo.Name == modalidadeComplementarObjetoTag.Name;
            });
            if (idxFound === -1)
              this.camposObjeto.push(modalidadeComplementarObjetoTag);
          }
        );
      }

      if ((this.coberturasAdicionaisObjeto || []).length > 0) {
        this.coberturasAdicionaisObjeto.forEach((coberturaAdicionalObjeto) => {
          if ((coberturaAdicionalObjeto || {}).JSONTag) {
            if ((coberturaAdicionalObjeto || {}).JSONTagValue)
              this.objeto = {
                ...this.objeto,
                ...JSON.parse(coberturaAdicionalObjeto.JSONTagValue || ""),
              };

            (eval(coberturaAdicionalObjeto.JSONTag) || []).forEach(
              (objetoTag) => {
                const idxFound = this.camposObjeto.findIndex((campo) => {
                  return campo.Name == objetoTag.Name;
                });
                if (idxFound === -1) this.camposObjeto.push(objetoTag);
              }
            );
          }
        });
      }

      if (this.endossoObjeto && this.endossoObjeto.length) {
        this.endossoObjeto.forEach((termEndosso) => {
          this.objeto = { ...this.objeto, ...termEndosso.JSONTagValue };
          if ((termEndosso || {}).JSONTag) {
            (eval(termEndosso.JSONTag) || []).forEach((objetoTag) => {
              const idxFound = this.camposObjeto.findIndex((campo) => {
                return campo.Name == objetoTag.Name;
              });
              if (idxFound === -1) this.camposObjeto.push(objetoTag);
            });
          }
        });
      }

      this.$forceUpdate();
    },
    cleanTag() {
      this.camposObjeto = [];
    },

    handleInput(tag, value) {
      console.log("Input triggered:", tag, value);
      this.setObject({ [tag.Name]: value });
      tag.Value = value;

      this.updateChangedObjectEndorsement(true);
      this.formatarTextoObjeto(false);
    },
  },
  mounted() {
    this.montarCamposObjeto();
    this.formatarTextoObjeto(true);
  },
  created() {
    this.Excelsior = compareCompanyUtils("companyName", "excelsior");
  },
};
</script>

<style lang="scss" scoped>
.text-objeto {
  border: solid 1px rgba(0, 0, 0, 0.2);
  max-height: 300px;
  overflow-y: auto;
  border-radius: 8px !important;
}

.label {
  word-break: break-all;
}

.tag-col{
  position: relative;
}

.enable-tag .material-icons {
  font-size: 0.85rem;
  color: rgba(var(--vs-warning), 1);
  cursor: pointer;
  position: relative;
  top: -30%;
  left: 30%;
}
</style>
