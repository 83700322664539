var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [_c("Breadcrumb", { attrs: { pages: _vm.pages } })],
      1
    ),
    _vm._m(0),
    _c("div", [_c("Steps", { staticClass: "mt-5 mb-5 steps" })], 1),
    _vm.premio && _vm.isDadosRisco
      ? _c("div", { staticClass: "flex justify-center mt-16" }, [
          _c("div", { staticClass: "premio-card" }, [
            _vm._m(1),
            _c("div", { staticClass: "value" }, [
              _c("p", { staticClass: "title-value" }, [
                _vm._v("Apólice atual"),
              ]),
              _vm._v(
                "  \r\n        " +
                  _vm._s(
                    _vm.premio.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  ) +
                  "\r\n      "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-5" }, [
      _c("p", { staticClass: "title" }, [_vm._v("Nova propostas")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text" }, [
      _c("i", { staticClass: "onpoint-currency-circle-dollar icon" }),
      _vm._v("\r\n        Prêmio Estimado\r\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }