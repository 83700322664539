<template>
  <vs-popup
    title=""
    :active.sync="enviarParaSeguradoraModal"
    id="step-rules"
    :button-close-hidden="true"
  >
    <div class="px-6 pb-6">
      <h3 class="text-primary">Processando {{ labelModal }}</h3>
      <h6 class="mb-10">Aguarde, estamos processando sua {{ labelModal }}.</h6>
      <div v-for="(step, index) in stepList" :key="index">
        <p :class="['flex', 'font-semibold']">
          <span
            :class="[`loading-step-${step.status}`]"
            class="mr-2 loading-step-block vs-con-loading__container"
            :id="[`loading-step-${step.name}`]"
          >
          </span>
          <span
            v-if="step.status == 'error'"
            class="mr-2 material-icons text-danger"
          >
            cancel
          </span>
          <span
            v-if="step.status == 'success'"
            class="mr-2 material-icons text-success"
          >
            check_circle
          </span>
          <span
            v-if="step.status == 'warning'"
            class="mr-2 material-icons text-warning"
          >
            warning
          </span>
          <span class="pt-2 pl-5">{{ step.message }}</span>
        </p>
        <vs-alert
          color="warning"
          title=""
          :active="step.status == 'warning'"
          class="mt-4"
        >
          <p
            class="text-warning flex font-semibold mt-2"
            v-for="(message, index) in step.alerts"
            :key="index"
          >
            <span class="mr-2 material-icons"> warning </span>
            <span class="mt-1">{{ message }}</span>
          </p>
        </vs-alert>
        <vs-alert
          color="danger"
          title=""
          :active="step.status == 'error'"
          class="mt-4"
        >
          <p
            class="text-danger flex font-semibold mt-2"
            v-for="(message, index) in step.error"
            :key="index"
          >
            <span class="mr-2 material-icons"> cancel </span>
            <span class="mt-1">{{ message }}</span>
          </p>
        </vs-alert>
      </div>

      <div v-if="showButtons" class="text-right">
        <vs-button
          color="primary"
          class="mt-8 px-20 button-default"
          type="filled"
          @click="
            showLeaveButton
              ? $emit('onClickCloseButton')
              : $emit('onClickLeaveButton')
          "
          >Sair
        </vs-button>
  
        <vs-button
          v-if="showSuccessButton && showExeption != true"
          color="primary"
          class="mt-8 ml-3"
          type="filled"
          @click="$emit('onClickSuccessButton')"
          >{{ labelSuccessButton }}    
        </vs-button>
        <vs-button
          v-if="showErrorButton"
          color="primary"
          class="mt-8 ml-3"
          type="filled"
          @click="$emit('onClickErrorButton')"
          >{{ labelErrorButton }} 
        </vs-button>

        <vs-button
          v-if="showPendingButton"
          color="primary"
          class="mt-8 ml-3"
          type="filled"
          @click="$emit('onClickPendingButton')"
          >Inserir documentos obrigatórios
        </vs-button>
      </div>
    </div>
  </vs-popup>
</template>

<script>
export default {
  props: {
    showModal: { type: Boolean, default: false },
    stepList: { type: Array, default: () => [] },
    showErrorButton: { type: Boolean, default: false },
    showSuccessButton: { type: Boolean, default: false },
    showExeption: { type: Boolean, default: false },
    showLeaveButton: { type: Boolean, default: false },
    showPendingButton: { type: Boolean, default: false },
    showButtons: { type: Boolean, default: false },
    labelSuccessButton: { type: String, default: "Seguir para emissão" },
    labelErrorButton: { type: String, default: "Seguir para emissão" },
    labelModal: { type: String }
  },
  computed: {
    enviarParaSeguradoraModal: {
      get() {
        return this.showModal;
      },

      set(value) {
        this.$emit("setActionModal", value);
      }
    }
  }
};
</script>

<style lang="scss">
#step-rules {
  .loading-step-error {
    display: none !important;
  }
  .loading-step-success {
    display: none !important;
  }
  .loading-step-warning {
    display: none !important;
  }
  .loading-step-wait {
    display: "block";
  }
  .loading-step-block {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: #fff;
  }

  .material-icons {
    align-self: center;
  }

  .vs-popup {
    width: 600px !important;
  }
}
</style>
