<template>
  <div class="dados-corretora">
    <div class="vx-row">
      <h4 class="vx-col p-0 w-full flex items-center font-semibold">
        <span class="block">Dados da Corretora</span>
        <vx-tooltip :text="text.textCorretora" position="right">
          <i class="material-icons pl-2">info</i>
        </vx-tooltip>
      </h4>
      <div class="vx-col w-full mt-5 p-0">
        <vs-input
          label="Razão Social"
          size="large"
          :value="cadastro.Name"
          disabled
          class="w-full"
        />
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5 md:pl-0 md:pr-2 p-0">
        <vs-input
          label="CNPJ"
          size="large"
          :value="cadastro.CpfCnpj | maskCnpj"
          class="w-full"
          disabled="true"
        />
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5 md:pr-0 md:pl-2 p-0">
        <vs-input
          label="Nroº Susep"
          size="large"
          :value="cadastro.SUSEPProtocol"
          class="w-full"
          disabled
        />
      </div>
      <div class="vx-col w-full mt-5 p-0" v-if="!Sombrero">
        <vs-input
          label-placeholder="Inscrição Municipal"
          type="text"
          size="large"
          v-model="cadastro.InscricaoMunicipal"
          class="w-full"
        />
      </div>
      <div class="vx-col w-full mt-5 p-0" v-else>
        <ValidationProvider
          name="Inscrição Municipal"
          rules="required"
          v-slot="{ errors }"
        >
          <vs-input
            label-placeholder="Inscrição Municipal"
            size="large"
            v-model="(cadastro || {}).InscricaoMunicipal"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
    </div>

    <div class="vx-row" v-if="(cadastro.SUSEPProtocol || '').length == 0">
      <div class="vx-col w-full mt-12 p-0">
        <vs-alert
          title="Recadastramento SUSEP"
          color="rgb(231, 154, 23)"
          active="true"
        >
          Atenção, identificamos que você não está recadastrado na SUSEP.
          Verifique seu cadastro no site da Superintendência de Seguros
          Privados.
          <a href="http://susep.gov.br" target="_blank">http://susep.gov.br</a>
        </vs-alert>
      </div>
    </div>

    <div class="vx-row mt-12">
      <h4 class="vx-col w-full flex items-center font-semibold p-0">
        <span class="block">Dados do administrador do Portal</span>
        <vx-tooltip :text="text.textAdministrador" position="right">
          <i class="material-icons pl-2">info</i>
        </vx-tooltip>
      </h4>
      <div class="vx-col w-full mt-5 p-0">
        <ValidationProvider
          name="Nome do administrador"
          rules="required"
          v-slot="{ errors }"
        >
          <vs-input
            label-placeholder="Nome do administrador"
            size="large"
            v-model="(cadastro.Administrator || {}).Name"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5 p-0">
        <ValidationProvider
          name="E-mail"
          rules="required|email"
          v-slot="{ errors }"
        >
          <vs-input
            label-placeholder="E-mail"
            type="email"
            size="large"
            v-model="(cadastro.Administrator || {}).Email"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5 md:pr-0 md:pl-2 p-0">
        <ValidationProvider
          name="Celular"
          :rules="{
            regex:
              /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/,
          }"
          v-slot="{ errors }"
        >
          <vs-input
            label-placeholder="Celular para autenticação"
            size="large"
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="(cadastro.Administrator || {}).PhoneNumber"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>
<script>
import { mask } from "vue-the-mask";
import { mapMutations } from "vuex";
import compareCompanyUtils from "@/utils/compareCompanyUtils.js";

export default {
  name: "dados-corretora",
  props: ["broker"],
  directives: { mask },
  data() {
    return {
      Sombrero: false,
      text: {
        textCorretora:
          "Dados de cadastro da Corretora obtidos automaticamente da Receita Federal!",
        textAdministrador:
          "Dados da pessoa física que terá autonomia para definir novos usuários na Plataforma Digital",
      },
    };
  },
  computed: {
    cadastro: {
      get() {
        return this.broker;
      },

      set(value) {
        this.updateCorretora(value);
      },
    },
  },
  methods: {
    ...mapMutations("cadastro-corretora", ["updateCorretora"]),
  },
  created(){
    this.Sombrero = compareCompanyUtils("companyName", "sombrero");
  }
};
</script>
<style lang="scss">
.dados-corretora {
  h4 {
    color: rgba(var(--vs-primary), 1);

    i {
      color: rgba(var(--vs-warning), 1);
      display: block;
      font-size: 0.8em !important;
    }
  }
}
</style>
