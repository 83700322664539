<template>
  <Dialog :visible.sync="visible" :closable="false" class="dialog">
    <template #header>
      <div class="header">
        <div class="title-content">
          <p class="title">Solicitar revisão de limites</p>
          <Divider class="divider" />
        </div>
        <div class="subtitle-content">
          <p class="mt-8 subtitle">
            Descreva os motivos e anexe os documentos necessários para solicitar
            a revisão de limites
          </p>
        </div>
      </div>
    </template>
    <div class="body">
      <div class="text-area-content">
        <div>
          <p class="title-text-area mb-2">
            Motivo da solicitação de revisão <i class="requiredItem">*</i>
          </p>
          <Textarea
            v-model="observation"            
            rows="5"
            cols="30"
            class="text-area"
            label="teste"
          />         
        </div>
      </div>
      <div class="field-inputs-container">
        <div class="mt-10" v-for="(detalhe,index) in detalhamento" :key="index">
          <div v-if="detalhe.StatusName !== 'Conforme'">
            <p class="title-upload">
            {{ detalhe.Name }} <i class="requiredItem">*</i>
          </p>
          <OnpointUpload width="585px" @callBackUpload="callBackUpload($event,index)"></OnpointUpload>     
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="actions">
        <div class="buttons-actions">
          <Button
            class="cancelButton"
            label="Cancelar"
            icon="pi pi-check"
            autofocus
            @click="$emit('cancel')"
          />
          <Button
            label="Enviar para a seguradora"
            icon="pi pi-check"
            autofocus
            @click="finalizarPendencia()"
            :disabled="validaDocumentosObrigatorios"
          />
        </div>
      </div>
    </template>
  </Dialog>
</template>
<script>
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Button from "primevue/button";
import Textarea from "primevue/textarea";
import OnpointUpload from "/src/components/onpoint-upload/index.vue"

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  props: {
    visible: Boolean,
    value: String,
    detalhamento: Array,
    tomadorUniqueId:String
  },
  components: {
    Dialog,
    Divider,
    Button,
    Textarea,   
    OnpointUpload     
  },
  data() {
    return {
      observation: '',
      documents: [],
      uploaded: [],
      base64:[],      
    };
  },
  computed: {
    validaDocumentosObrigatorios() {
      let disabled = false;
      this.detalhamento.forEach((doc) => {
        if (doc.IsRequired && doc.StatusName != "Conforme") {
          disabled = true;
        }
      });

      this.observation.length === 0 ? disabled = true : null
      return disabled;
    },
  },
  methods: {
    ...mapActions("tomador-module", [
      "uploadDocumentoByRequestLimit",
      "sendRequestToAnalysis",
    ]),

    async finalizarPendencia() {     
      await this.$onpoint.loadingModal(
        "Estamos processando sua solicitação",
        async () => {
          return await this.sendRequestToAnalysis({
            PolicyHolderUniqueId: this.tomadorUniqueId,
            Observation: this.observation,
            Files: this.detalhamento,
            brokerId: this.$store.state["auth"].userInfo.BrokerId,
          })
            .then(() => {
              this.$emit('cancel')
              this.$onpoint.successModal(
                "Solicitação de revisão de limite recebida! Brevemente iniciaremos a análise, fique atento, pois poderemos solicitar mais informações via e-mail. Após a análise você receberá e-mail com o resultado de sua solicitação. "
              );              
            })
            .catch((ex) => {
              this.$onpoint.errorModal(ex.response.data.Errors);
            });
        }
      );
    },

    async callBackUpload(callback,index) {   
     
      const request = {
        RequestDocumentName: this.detalhamento[index].Name,
        PolicyHolderUniqueId: this.tomadorUniqueId,//verificar
        Files: callback,
      };
      return await this.uploadDocumentoByRequestLimit(request)
        .then((response) => {
          this.$onpoint.successModal("Documento enviado com sucesso.");
          this.detalhamento[index].StatusDescription =
            "Enviado";
          this.detalhamento[index].StatusName =
            "Conforme";
          this.document = {};
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$onpoint.errorModal(error.response.data.Errors);
          this.$vs.loading.close();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.dialog) {
  width: 50%;
  min-width: 697px;
  border-radius: 12px;
  .p-dialog-header {
    display: flex;
    justify-content: center;
  }
  .header {
    width: 100%;
  }
  .title {
    margin-top: 10px;
    font-weight: 700;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 26px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .subtitle-content {
    display: flex;
    justify-content: center;
    .subtitle {
      margin-top: 10px;
      width: 449px;
      font-weight: 400;
      font-family: "Montserrat", Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      color: rgba(107, 114, 128, 1);
      text-align: center;
    }
  }
  .p-dialog.p-dialog-footer button {
    margin: 0px;
  }
}
.cancelButton {
  background: white;
  border: solid 1px rgba(209, 213, 219, 1);
  color: black;
}
button {
  border-radius: 8px;
  margin: 0px;
}
.body {
  background: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .text-area-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .requiredItem {
    color: rgba(180, 23, 58, 1);
  }
  .title-text-area {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
  }
  .text-area {
    width: 585px;
    height: 165px;
  }
}

.title-upload {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.field-inputs-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.actions {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 7px;
}
.buttons-actions {
  width: 585px;
}
.subtitle-content {
  display: flex;
  justify-content: center;
  .subtitle {
    margin-top: 10px;
    width: 449px;
    font-weight: 400;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    color: rgba(107, 114, 128, 1);
    text-align: center;
  }
}
</style>