<template>
  <div>
    <!-- Atualização de dados das partes (segurado e tomador (nome e endereço)) -->   
    <vs-popup :title="titleModalUpdate" :active.sync="popupEditData">
      <form-endereco
        v-if="typeUpdate == 'address'"
        :dadosEndereco="novoEndereco"
        :showCancelButton="true"
        :showOkButton="true"
        textCancelButton="Cancelar"
        @cancel="closeEditModal"
        @save="salvarEndereco"
      ></form-endereco>

      <div v-if="typeUpdate == 'name'">
        <vs-row>
          <vs-col vs-w="12">
            <vs-input
              label="Razão social"
              size="large"
              class="w-full"
              v-model="nomeEdicao"
            />
          </vs-col>
        </vs-row>

        <vs-row class="mt-5">
          <vs-col vs-type="flex" vs-justify="end" vs-align="center">
            <vs-button :disabled="!nomeEdicao" @click="updateRazaoSocial()">
              Salvar
            </vs-button>
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
    <vs-popup :title="titleModalUpdate" :active.sync="popupNomeSocial">
      <vs-row>
        <vs-col vs-w="12">
          <vs-input
            :label="
              'Novo ' + apolice.Insured.Document.length > 11
                ? 'Nome Fantasia'
                : 'Nome Social'
            "
            size="large"
            class="w-full"
            v-model="newNomeSocial"
          />
        </vs-col>
      </vs-row>

      <vs-row class="mt-5">
        <vs-col vs-type="flex" vs-justify="end" vs-align="center">
          <vs-button :disabled="!newNomeSocial" @click="updateNomeSocial()">
            Salvar
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
    <vs-row vs-type="flex">
      <vs-col vs-lg="6" vs-sm="12" class="container-btn-changes">
        <p class="font-bold pb-3 w-full">Nº Apólice</p>
        <p class="font-bold w-full text-sm text-grey-700 word-wrap">
          {{ apolice.PolicyNumber || "-" }}
        </p>
      </vs-col>

      <slot></slot>
    </vs-row>
    <vs-row class="colum-persons">
      <vs-col vs-lg="6" vs-sm="12" class="tomador">
        <p class="font-bold pb-3 w-full">
          Tomador
        </p>

        <p class="font-bold w-full text-sm text-grey-700">
          {{ policyHolderName || "-" }}
          <i
           v-if="changeTypesIds.includes(4)"
           class="onpoint-pencil-simple-line icon-font float-right text-lg cursor-pointer text-primary-orig"
            role="button"
            @click="editarRazaoSocial(false)"
          ></i>
        </p>
        <p class="w-full text-xs">
          {{ $utils.maskDocument(apolice.PolicyHolder.Document) || "-" }}
        </p>

        <div
          vs-align="flex-end"
          class="pt-8 pb-5"
          @click="changeTypesIds.includes(5)?editarEndereco(false):null"
        >
          <p class="font-bold w-full">
            Endereço do Tomador
            <i
              v-if="changeTypesIds.includes(5)"
              class="onpoint-pencil-simple-line icon-font text-primary-orig float-right text-lg cursor-pointer"
            ></i>
          </p>
          <p class="w-full text-sm text-grey-700">
            {{ policyHolderAddress || "-" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-lg="6" vs-sm="12" class="segurado">
        <p class="font-bold pb-3 w-full">Segurado</p>
        <p class="font-bold w-full text-sm text-grey-700">
          {{ insuredName || "-" }}

          <i
          v-if="changeTypesIds.includes(6)"
            class="onpoint-pencil-simple-line icon-font text-primary-orig float-right text-lg cursor-pointer"
            role="button"
            @click="editarRazaoSocial(true)"
          ></i>
        </p>
        <p class="w-full text-xs">
          {{ $utils.maskDocument(apolice.Insured.Document) || "-" }}
        </p>
        <div v-if="enableChangeNomeSocial">
          <p class="font-bold pb-3 mt-2 w-full nome-social" v-if="NomeSocial">
            <b>
              {{
                apolice.Insured.Document.length > 11
                  ? "Nome Fantasia"
                  : "Nome Social "
              }}:
              <strong>{{ NomeSocial }}</strong>
            </b>
            <i
              class="onpoint-pencil-simple-line icon-font text-primary-orig float-right text-lg cursor-pointer"
              role="button"
              @click="editarNomeSocial(true)"
            ></i>
          </p>
          <p class="font-bold pb-3 mt-2 w-full nome-social" v-else>
            <vs-button
              color="primary"
              type="border"
              class="btn-view-changes"
              @click="editarNomeSocial(false)"
            >
              Adicionar Nome Social
            </vs-button>
          </p>
        </div>
        <div
          :class="'mb-0  pb-5 ' + NomeSocial ? ' pt-2' : ' pt-8'"
          @click="changeTypesIds.includes(7)?editarEndereco(true):null"
        >
          <p class="font-bold w-full">
            Endereço do Segurado  
            <i
              v-if="changeTypesIds.includes(7)"
              class="onpoint-pencil-simple-line icon-font text-primary-orig float-right text-lg cursor-pointer"
            ></i>
          </p>
          <p class="w-full text-sm text-grey-700">
            {{ insuredAddress || "-" }}
          </p>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import FormEndereco from "@/components/enderecos/FormEndereco";

import { mapGetters, mapMutations } from "vuex";

export default {
  components: { FormEndereco },
  props: {
    enableChangeNomeSocial: { type: Boolean, default: false }   
  },
  data() {
    return {
      typeUpdate: null,
      titleModalUpdate: null,
      novoEndereco: {},
      nomeEdicao: null,
      popupEditData: false,
      isInsured: false,
      popupNomeSocial: false,
      newNomeSocial: null,   
    };
  },
  computed: {
    ...mapGetters("apolice-module", ["apolice","changeTypes"]),
    ...mapGetters("endosso-module", ["endosso"]),

    insuredAddress() {
      if (((this.endosso || {}).Insured || {}).Location)
        return `${this.endosso.Insured.Location.AddressName}, ${this.endosso.Insured.Location.Number} - ${this.endosso.Insured.Location.Neighborhood}, ${this.endosso.Insured.Location.ZipCode}`;
      return `${this.apolice.Insured.Location.AddressName}, ${this.apolice.Insured.Location.Number} - ${this.apolice.Insured.Location.Neighborhood}, ${this.apolice.Insured.Location.ZipCode}`;
    },

    policyHolderAddress() {
      if (((this.endosso || {}).PolicyHolder || {}).Location)
        return `${this.endosso.PolicyHolder.Location.AddressName}, ${this.endosso.PolicyHolder.Location.Number} - ${this.endosso.PolicyHolder.Location.Neighborhood}, ${this.endosso.PolicyHolder.Location.ZipCode}`;
      return `${this.apolice.PolicyHolder.Location.AddressName}, ${this.apolice.PolicyHolder.Location.Number} - ${this.apolice.PolicyHolder.Location.Neighborhood}, ${this.apolice.PolicyHolder.Location.ZipCode}`;
    },

    insuredName() {
      return (
        ((this.endosso || {}).Insured || {}).Name ||
        (this.apolice.Insured || {}).Name
      );
    },
    NomeSocial() {
      return (
        ((this.endosso || {}).Insured || {}).NomeSocial ||
        (this.apolice.Insured || {}).NomeSocial
      );
    },

    policyHolderName() {
      return (
        ((this.endosso || {}).PolicyHolder || {}).Name ||
        this.apolice.PolicyHolder.Name
      );
    },

    changeTypesIds() {
      const data = (this.changeTypes || {}).data;
      return data && data.Response ? data.Response.map(element => element.PolicyEndorsementChangeTypeDescriptionId) : [];
    }
  },
  methods: {
    ...mapMutations("endosso-module", ["updateEndosso"]),

    editarEndereco(isInsured) {
      this.titleModalUpdate = `Atualizar endereço - ${
        isInsured ? "Segurado" : "Tomador"
      }`;

      this.typeUpdate = "address";
      this.popupEditData = true;
      this.isInsured = isInsured;
      this.novoEndereco = {
        StateProvinceId: "",
        CityId: "",
        ZipCode: "",
        AddressName: "",
        Number: "",
        Complement: "",
        Neighborhood: "",
        Id: ""
      };
    },

    editarRazaoSocial(isInsured) {
      this.titleModalUpdate = `Atualizar nome - ${
        isInsured ? "Segurado" : "Tomador"
      }`;

      this.typeUpdate = "name";
      this.popupEditData = true;
      this.isInsured = isInsured;
      this.nomeEdicao = isInsured ? this.insuredName : this.policyHolderName;
    },

    editarNomeSocial(isEdit) {
      this.titleModalUpdate = `${isEdit ? "Atualizar" : "Adicionar"}       
      ${
        this.apolice.Insured.Document.length > 11
          ? "Nome Fantasia"
          : "Nome Social"
      }   
      `;
      this.popupNomeSocial = true;
    },
    updateNomeSocial() {
      let item = Object.assign({}, this.endosso.Insured || {});
      item.NomeSocial = this.newNomeSocial;
      this.updateEndosso({ Insured: item });
      this.popupNomeSocial = false;
      this.newNomeSocial = null;
      this.$forceUpdate();
    },

    updateRazaoSocial() {
      if (this.isInsured) {
        let item = Object.assign({}, this.endosso.Insured || {});
        item.Name = this.nomeEdicao;

        this.updateEndosso({ Insured: item });
      } else {
        let item = Object.assign({}, this.endosso.PolicyHolder || {});
        item.Name = this.nomeEdicao;

        this.updateEndosso({ PolicyHolder: item });
      }
      this.closeEditModal();
      this.$forceUpdate();
    },

    salvarEndereco(value) {
      let result = value;
      if (result.Complement.length <= 0) {
        result.Complement = " ";
      }
      this.popupEditData = false;
   
      if (this.isInsured) {
        let dados = Object.assign({}, this.endosso.Insured || {});
        dados.Location = result;
        this.updateEndosso({ Insured: dados });
         this.$forceUpdate();
      } else {
        let item = Object.assign({}, this.endosso.PolicyHolder || {});
        item.Location = result;

        this.updateEndosso({ PolicyHolder: item });
      }

      this.closeEditModal();
      this.$forceUpdate();
    },

    closeEditModal() {
      this.popupEditData = false;
      this.typeUpdate = null;
      this.isInsured = null;

      setTimeout(() => {
        this.novoEndereco = {};
        this.nomeEdicao = "";
      }, 400);
    },
  }
};
</script>

<style lang="scss" scoped>
.tomador {
  border-right: 1px solid #d3d2d2;
}
.colum-persons {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.nome-social strong {
  cursor: pointer;
  border-bottom: 1px solid rgba(var(--vs-primary), 0.8);
}
.nome-social strong:hover {
  border-bottom: 1px solid rgb(255, 196, 0);
}

.btn-view-changes {
  background: rgba(var(--vs-primary), 0.08) !important;
  height: fit-content;
  padding: 5px 10px !important;
  font-size: 12px;

  &:hover {
    background: rgba(var(--vs-primary), 0.2) !important;
  }
}

.icon-font::before {
  font-size: 16px !important;
  margin: 10px;
  position: relative;
  right: 1px;
  top: 1px;
}
</style>


<style>
.vs-select--options{
  z-index: 530000000!important
}
</style>