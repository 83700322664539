<template>
  <section>
    <div>
      <Breadcrumb :pages="pages"/>
    </div>
    <!-- <template v-if="!podeCriarProposta"> -->
    <template>
      <form-wizard
        color="rgba(var(--vs-warning), 1)"
        :title="null"
        :subtitle="null"
        finishButtonText="Submit"
        @on-complete="formSubmitted"
        @on-change="handleChange"
        class="proposta"
      >
        <TagCanalCorretorMapfre
          v-if="podeExibirTagCanalCorretorMapfre"
          class="mb-6"
          :tipoCanalCorretora="tipoCanalCorretora"
          view="proposta"
        />

        <tab-content title="Dados do tomador" class="mb-8 mt-8">
          <!-- tab 1 content -->
          <dados-tomador
            :canRequestLimitsAndRatesReview="tomadorDaProposta.CanRequestLimitsAndRatesReview"
            :podeHabilitarAfiancadoHolding="podeHabilitarAfiancadoHolding"
          />
        </tab-content>

        <!-- tab 2 content -->
        <tab-content title="Dados do segurado" class="mb-6 mt-6">
          <dados-segurado
            v-if="tomadorDaProposta.CpfCnpj"
            :cnpjTomador="tomadorDaProposta.CpfCnpj"
            :uniqueId="tomadorDaProposta.UniqueId"
            :idTomador="tomadorDaProposta.Id"
            :podeHabilitarCadastroNomeSocial="podeHabilitarCadastroNomeSocial"
          />
        </tab-content>

        <!-- tab 3 content -->
        <tab-content title="Dados do risco" class="mb-6 mt-6">
          <dados-risco
            v-if="seguradoDaProposta.CpfCnpj"
            :seguradoUniqueId="seguradoDaProposta.UniqueId"
            :seguradoId="seguradoDaProposta.Id"
            :idTomador="tomadorDaProposta.Id"
            :tomadorUniqueId="tomadorDaProposta.UniqueId"
            :tipoCanalCorretora="tipoCanalCorretora"
            :permissoesCocorretagemMapfre="permissoesCocorretagemMapfre"
            :permissoesCosseguroMapfre="permissoesCosseguroMapfre"
            :podeHabilitarCadastroNomeSocialBeneficiario="podeHabilitarCadastroNomeSocialBeneficiario"
          />
          <div v-else>
            <h3 class="pl-10">Selecione um segurado.</h3>
          </div>
        </tab-content>

        <!-- tab 4 content -->
        <tab-content title="Dados financeiros" class="mb-6 mt-6">
          <!--<dados-financeiros />-->
        </tab-content>

        <template slot="footer" slot-scope="props">
          <div>
            <div class="wizard-footer-left">
              <vs-button
                v-if="props.activeTabIndex > 0"
                @click.native="props.prevTab(), enviarEventoVoltarAppInsights(props)"
                color="primary"
              >
                <span class="font-semibold pr-2 pl-2">Voltar</span>
              </vs-button>
            </div>
            <div class="wizard-footer-right">
              <vs-button
                v-if="
                  !props.isLastStep &&
                    !tomadorDaProposta.IsNominatedToOtherBroker &&
                    props.activeTabIndex !== 2
                "
                @click.native="props.nextTab(), enviarEventoProsseguirAppInsights(props)"
                :disabled="disableProsseguirButton(props)"
                color="primary"
              >
                <span class="font-semibold pr-2 pl-2">
                  Prosseguir
                </span>
              </vs-button>
            </div>
          </div>
        </template>
      </form-wizard>
    </template>
    <!-- <template v-if="podeCriarProposta">
      <div class="text-center mt-6">
        <h3 class="text-danger mb-6">Atenção!</h3>
        <p class="mb-6 font-semibold">Você não pode cadastrar uma proposta pois você possui documentos pendentes para enviar para a seguradora. Anexe sua documentação no seu perfil.</p>
        <router-link :to="{path: '/corretor', query: { activeTabIndex: '4' }}">
          <vs-button color="primary">Incluir documentos</vs-button>
        </router-link>
      </div>
    </template> -->
  </section>
</template>

<script>
import DadosTomador from "./components/criacao/DadosTomador.vue";
import DadosSegurado from "./components/criacao/DadosSegurado.vue";
import DadosRisco from "./components/criacao/DadosRisco.vue";
import DadosFinanceiros from "./components/emissao/DadosFinanceiros.vue";
import Breadcrumb from "@/components/onpoint-breadcrumb/";

import * as Ultils from "@/utils/utils";

import { mapGetters, mapMutations, mapActions } from "vuex";
import axiosInstance from "@/axios";

import * as Permissoes from "@/utils/permissions.js";
import mapfreProvider from "@/providers/mapfre-provider.js";
import commonProvider from "@/providers/common-provider.js";
import TagCanalCorretorMapfre from "@/components/tag-canal-corretor-mapfre/index.vue";

export default {
  components: {
    DadosTomador,
    DadosSegurado,
    DadosRisco,
    DadosFinanceiros,
    Breadcrumb,
    TagCanalCorretorMapfre
  },
  data() {
    return {
      currentTabIndex: 0,
      dadosTomador: {},
      changeButton: false,
      enviarCarta: false,
      podeCriarProposta: false,
      tipoCanalCorretora: {},
      permissoesProposta: [],
      permissoesCocorretagemMapfre: [],
      permissoesCosseguroMapfre: [],
      pages: [
        { label: "Propostas", to: "/propostas" },
        { label: "Nova proposta" },
      ], 
    };
  },
  computed: {
    ...mapGetters("proposta-module", [
      "proposta",
      "tomadorDaProposta",
      "limitesETaxas",
      "seguradoDaProposta"
    ]),

    podeExibirTagCanalCorretorMapfre() {
      return this.permissoesProposta.includes("ExibirCanal");
    },
    podeHabilitarCadastroNomeSocialBeneficiario() {
      return this.permissoesProposta.includes(
        "HabilitarCadastroNomeSocialBeneficiario"
      );
    },
    podeHabilitarCadastroNomeSocial() {
      return this.permissoesProposta.includes(
        "HabilitarCadastroNomeSocial"
      );
    },
    podeHabilitarAfiancadoHolding() {
      return this.permissoesProposta.includes(
        "HabilitarAfiancadoHolding"
      );
    }
  },
  created() {
    this.getTomador();
    this.$appInsights.trackPageView({
      name: "nova-proposta",
      url: window.location.href
    });
  },
  async mounted() {
    await this.$onpoint.loading(async () => {
      await this.validarDocumentos();
      await this.validarCorretoraAtivaFn();
      await this.pegarPermissoesMapfre();
    });
  },
  beforeMount() {
    if (!this.$route.params.propostaUniqueId) {
      this.limparEstado();
    }
  },
  methods: {
    ...mapActions("cadastro-corretora", ["validarCorretoraAtiva"]),
    ...mapMutations("proposta-module", ["limparEstado"]),
    handleChange(prevIndex, nextIndex) {
      this.currentTabIndex = nextIndex;
    },
    disableProsseguirButton(props) {
      //((this.limitesETaxas || {}).PolicyHolderLimitsAndRates || []).length > 0
      if (props.activeTabIndex === 0) {
        return (
          this.tomadorDaProposta.Id === 0 ||
          !this.tomadorDaProposta.CpfCnpj ||
          ((this.limitesETaxas || {}).LimitAndRates || [])
            .length == 0
        );
      } else if (props.activeTabIndex === 1) {
        return (
          this.seguradoDaProposta.Id === 0 || !this.seguradoDaProposta.CpfCnpj
        );
      }
    },

    async validarDocumentos() {
      this.$onpoint.loading(async () => {
        return await axiosInstance
          .get("api/Broker/ValidatePendingBrokerRequestDocuments")
          .then(response => {
            this.podeCriarProposta = response.data.Response;
          });
      });
    },

    async validarCorretoraAtivaFn() {
      return await this.validarCorretoraAtiva().catch(err => {
        this.$onpoint.errorModal(err.response.data.Errors);
        this.$router.push("/redirect-to-home");
      });
    },

    goToPerfil() {
      setTimeout(() => {
        this.$router.push("/corretor");
      }, 200);
    },

    formSubmitted() {
      alert("Form submitted!");
    },

    getTomador() {
      this.dadosTomador = Ultils.deepClone(this.tomadorDaProposta);
    },

    async pegarPermissoesMapfre() {
      this.permissoesProposta = [];
      this.permissoesCocorretagemMapfre = [];
      this.permissoesCosseguroMapfre = [];
      this.tipoCanalCorretora = {};
      try {
        this.permissoesProposta = await commonProvider.obterPermissoesModulo(Permissoes.Modulo.PROPOSTA)
        this.permissoesCocorretagemMapfre = await mapfreProvider.obterPermissoesModulo(Permissoes.Modulo.COCORRETAGEM);
        this.permissoesCosseguroMapfre = await mapfreProvider.obterPermissoesModulo(Permissoes.Modulo.COSSEGURO);
        this.tipoCanalCorretora = await mapfreProvider.buscarTipoCanalPorCorretora();
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    },

    enviarEventoVoltarAppInsights(props){
      if(props.activeTabIndex === 1) {
        this.$appInsights.trackEvent({ name: "voltar-proposta", properties: { place: "proposta-passo-2", action: "button-click", slug: "voltar-proposta-passo-2" }});
      }

      if(props.activeTabIndex === 2) {
        this.$appInsights.trackEvent({ name: "voltar-proposta", properties: { place: "proposta-passo-3", action: "button-click", slug: "voltar-proposta-passo-3" }});
      }

      if(props.activeTabIndex === 3 || props.isLastStep) {
        this.$appInsights.trackEvent({ name: "voltar-proposta", properties: { place: "proposta-passo-4", action: "button-click", slug: "voltar-proposta-passo-4" }});
      }
    },

    enviarEventoProsseguirAppInsights(props){
      if(props.activeTabIndex === 0) {
        this.$appInsights.trackEvent({ name: "prosseguir-proposta", properties: { place: "proposta-passo-1", action: "button-click", slug: "prosseguir-proposta-passo-1" }});
      }

      if(props.activeTabIndex === 1) {
        this.$appInsights.trackEvent({ name: "prosseguir-proposta", properties: { place: "proposta-passo-2", action: "button-click", slug: "prosseguir-proposta-passo-2" }});
      }
    }
  }
};
</script>
<style lang="scss">
.proposta {
  .wizard-tab-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .wizard-tab-content {
    background: white !important;
    margin: 2em 0 auto;
  }

  .wizard-card-footer {
    background-color: white !important;
    min-height: 70px;
    padding: 0 27px !important;
  }

  .wizard-footer-left .vs-button {
    padding: 0.75rem 1.2rem !important;
  }
}
</style>

<style lang="scss" scoped>
h4 {
  color: rgba(var(--sv-dark), 1);

  i {
    color: rgba(var(--vs-warning), 1);
    display: block;
    font-size: 0.8em;
  }
}

.link {
  float: right;
}



</style>
