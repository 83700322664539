<template>
  <section>
    <Card class="card">
      <template #content>
        <div class="card-premio">
          <p class="text-premio">Valor do prêmio estimado</p>
          <p class="value-premio mt-3" v-if="premio">
            {{
              premio.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </p>
          <div class="premio-alert">
            <i class="onpoint-warning-octagon icon-alert"></i>
            <p class="premio-alert-text">
              Este valor poderá sofrer alterações durante a avaliação de risco.
            </p>
          </div>
        </div>
        <div class="mt-8">
          <Accordion class="accordion">
            <AccordionTab header="Objeto da proposta completo">
              <div v-html="fullTerms"></div>
            </AccordionTab>
          </Accordion>
        </div>
        <div class="mt-8">
          <Accordion class="accordion">
            <AccordionTab header="Dados tomador">
              <div class="grid grid-rows-2 grid-cols-2 gap-5">
                <div class="tab-item-container">
                  <p class="title-data">Tomador</p>
                  <p class="value-data">{{ tomador.Name }}</p>
                </div>
                <div class="tab-item-container">
                  <p class="title-data">CNPJ</p>
                  <p class="value-data">{{ tomador.CpfCnpj }}</p>
                </div>

                <div class="col-span-2">
                  <template v-for="(local, i) in tomador.Locations">
                    <div
                      :key="i"
                      class="tab-item-container"
                      v-if="local.IsMain"
                    >
                      <p class="title-data">Endereço</p>
                      <p class="value-data">
                        {{ local.IsMain ? local.Address : null }}
                      </p>
                    </div>
                  </template>
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>
        <div class="mt-8">
          <Accordion class="accordion">
            <AccordionTab header="Dados segurado">
              <div class="grid grid-rows-2 grid-cols-2 gap-5">
                <div class="tab-item-container">
                  <p class="title-data">Segurado</p>
                  <p class="value-data">{{ segurado.Name }}</p>
                </div>
                <div class="tab-item-container">
                  <p class="title-data">CNPJ</p>
                  <p class="value-data">{{ segurado.CpfCnpj }}</p>
                </div>

                <div class="col-span-2">
                  <template v-for="(local, i) in segurado.Locations">
                    <div
                      :key="i"
                      class="tab-item-container"
                      v-if="local.IsMain"
                    >
                      <p class="title-data">Endereço</p>
                      <p class="value-data">
                        {{ local.IsMain ? local.Address : null }}
                      </p>
                    </div>
                  </template>
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>
        <div class="mt-8">
          <Accordion class="accordion">
            <AccordionTab header="Dados de risco">
              <div class="grid grid-cols-4 gap-5 mb-5">
                <div class="tab-item-container col-span-2">
                  <p class="title-data">Grupo Modalidade</p>
                  <p class="value-data">{{ proposta.ModalityGroup.Name }}</p>
                </div>
                <div class="tab-item-container col-span-2">
                  <p class="title-data">Modalidade principal</p>
                  <p class="value-data">{{ proposta.Modality.Name }}</p>
                </div>
                <div class="tab-item-container">
                  <p class="title-data">Taxa aplicada (%)</p>
                  <p class="value-data">
                    {{ proposta.ModalityGroup.RiskRate+ " %" }}
                  </p>
                </div>
                <div class="tab-item-container">
                  <p class="title-data">Valor IS</p>
                  <p class="value-data">
                    {{
                      proposta.InsuredAmountValue.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    }}
                  </p>
                </div>
                <div class="tab-item-container">
                  <p class="title-data">Inicio / Final da vigencia</p>
                  <p class="value-data">
                    {{
                      new Date(proposta.StartDate) | dateFormat("DD/MM/YYYY")
                    }}
                    -
                    {{ new Date(proposta.EndDate) | dateFormat("DD/MM/YYYY") }}
                  </p>
                </div>
                <div class="tab-item-container">
                  <p class="title-data">Prazo</p>
                  <p class="value-data">{{ proposta.DeadlineDays }}</p>
                </div>
                <div
                  class="col-span-4 tab-item-container"
                  v-if="
                    proposta.ProposalCoverages && proposta.ProposalCoverages[0]
                  "
                >
                  <p class="title-data">Coberturas adicionais</p>
                  <div class="flex">
                    <template
                      v-for="(cobertura, i) in proposta.ProposalCoverages"
                    >
                      <div :key="i" class="flex">
                        <p class="value-data">
                          {{ cobertura.Name }}
                        </p>
                        <p
                          class="divider-margin-value-data"
                          v-if="i < proposta.ProposalCoverages.length - 1"
                        >
                          /
                        </p>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div v-if="proposta.ComplementaryModality">
                <div class="title-divider mb-5">
                  Dados modalidade complementar
                </div>
                <div class="flex gap-5">
                  <div class="tab-item-container w-1/4">
                    <p class="title-data">Taxa aplicada (%)</p>
                    <p class="value-data">
                      {{
                        proposta.ComplementaryModality.ModalityTaxValue + " %"
                      }}
                    </p>
                  </div>
                  <div class="tab-item-container w-1/4">
                    <p class="title-data">Valor IS</p>
                    <p class="value-data">
                      {{
                        Number(
                          proposta.ComplementaryInsuredAmountValue
                        ).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </p>
                  </div>
                  <div class="tab-item-container w-1/4">
                    <p class="title-data">Inicio / Final da vigencia</p>
                    <p class="value-data">
                      {{
                        new Date(proposta.ComplementaryStartDate)
                          | dateFormat("DD/MM/YYYY")
                      }}
                      -
                      {{
                        new Date(proposta.ComplementaryEndDate)
                          | dateFormat("DD/MM/YYYY")
                      }}
                    </p>
                  </div>
                  <div class="tab-item-container w-1/4">
                    <p class="title-data">Prazo</p>
                    <p class="value-data">
                      {{ proposta.ComplementaryDeadlineDays }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                v-if="particularClauses && Object.keys(particularClauses[0])[0]"
                class="mb-0"
              >
                <div class="title-divider mt-5 mb-5">
                  Dados clausulas particulares
                </div>
                <div :key="i" v-for="(clausula, i) in particularClauses">
                  <div
                    :class="
                      clausula.Campos && clausula.Campos.length > 3
                        ? 'grid grid-cols-3 gap-5'
                        : 'flex gap-5 mb-5'
                    "
                  >
                    <div
                      :class="
                        clausula.Campos && clausula.Campos.length > 3
                          ? 'tab-item-container'
                          : 'tab-item-container w-1/3'
                      "
                    >
                      <p class="title-data">condições particulares</p>
                      <p class="value-data">{{ clausula.Name }}</p>
                    </div>
                    <div
                      :class="
                        clausula.Campos &&
                        clausula.Campos.length > 3 &&
                        index === clausula.Campos.length - 1
                          ? 'tab-item-container col-span-3 mb-0'
                          : clausula.Campos &&
                            clausula.Campos.length > 3 &&
                            index !== clausula.Campos.length - 1
                          ? 'tab-item-container'
                          : 'tab-item-container w-1/3'
                      "
                      :key="index"
                      v-for="(campo, index) in clausula.Campos"
                    >
                      <p class="title-data">
                        {{ campo.Label ? campo.Label : null }}
                      </p>
                      <p class="value-data">
                        {{ campo.Value ? campo.Value : null }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>
      </template>
      <template #footer>
        <div class="flex justify-end">
          <Button
            label="Cancelar"
            class="p-button-rounded cancel-button"
            @click="cancelCreateProposal()"
          />
          <Button
            label="Voltar"
            class="p-button-rounded back-button"
            @click="prevPage()"
          />
          <Button
            label="Enviar para a seguradora"
            class="p-button-rounded"
            @click="salvarProposta()"
          />
        </div>
      </template>
    </Card>
    <poposal-step-rules-modal
      :showModal="enviarParaSeguradoraModal"
      :stepList="getSteps"
      :showErrorButton="showTimelineErrorButton"
      :showSuccessButton="
        !showTimelineErrorButton &&
        !showRequiredDocumentsButton &&
        !!proposta.UniqueId
      "
      :showPendingButton="showRequiredDocumentsButton"
      :showLeaveButton="!(showTimelineButton && proposta.UniqueId)"
      :showButtons="showActionButtons"
      :showExeption="showExeptionButton"
      :labelModal="'Proposta'"
      @onClickSuccessButton="() => nextPage()"
      @onClickErrorButton="() => goToTimeline()"
      @onClickCloseButton="() => goToDashboard()"
      @onClickLeaveButton="() => closeModal()"
      @onClickPendingButton="() => sendDocuments()"
      @setActionModal="enviarParaSeguradoraModal"
      labelErrorButton="Seguir para timeline"
      labelSuccessButton="Seguir para emissão"
    />
    <template v-if="((detalhamento || {}).RequestDocuments || []).length">
      <required-documents
        :proposal="proposta"
        :detalhamento="detalhamento"
        :propostaUniqueId="proposta.UniqueId"
        :showModal="sendDocumentsDialog"
        :showFollowUp="true"
        @closeModal="closeModalDocuments"
        @dismissModal="closeModalDocuments"
        @updateDocs="updateDocs"
      />
    </template>
    <vs-popup
      class="modalCancelation"
      :active.sync="showModalCancelationProposal"
      title=""
      :button-close-hidden="true"
    >
      <div>
        <h3>O valor da proposta excede o limite aprovado.</h3>
        <br />
        <h6>
          Deseja revisar limite deste Tomador para continuar com a proposta?
        </h6>
      </div>
      <div>
        <vs-button
          color="primary"
          class="mt-8 px-20 button-default"
          type="filled"
          @click="continuarProposta()"
        >
          Continuar com a proposta
        </vs-button>

        <vs-button
          style="background-color: #cb2020 !important; float: inline-end"
          class="mt-8 ml-3"
          type="filled"
          @click="cancelarProposta()"
        >
          Cancelar proposta
        </vs-button>
      </div>
    </vs-popup>
  </section>
</template>

<script>
import Card from "primevue/card";
import Button from "primevue/button";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import PoposalStepRulesModal from "@/components/propostas/PoposalStepRulesModal.vue";
import RequiredDocuments from "./components/RequiredDocuments.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";
import axiosInstance from "@/axios";

export default {
  components: {
    Card,
    Button,
    Accordion,
    AccordionTab,
    PoposalStepRulesModal,
    RequiredDocuments,
  },
  data() {
    return {
      fullTerms: null,
      showTimelineErrorButton: false,
      enviarParaSeguradoraModal: false,
      showRequiredDocumentsButton: false,
      showTimelineButton: false,
      showActionButtons: false,
      showExeptionButton: false,
      enviarParaSeguradoraSteps: [],
      detalhamento: {
        RequiredDocuments: [],
      },
      sendDocumentsDialog: false,
      showModalCancelationProposal: false,
    };
  },
  computed: {
    ...mapGetters("proposta-module", ["proposta", "particularClauses"]),
    ...mapGetters("auth", ["advisorInfo"]),
    premio: {
      get() {
        return this.proposta.InsurancePremium;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          InsurancePremium: val,
        });
      },
    },
    tomador: {
      get() {
        return this.proposta.PolicyHolder;
      },
    },
    segurado: {
      get() {
        return this.proposta.Insured;
      },
    },
    getSteps: {
      get() {
        return this.enviarParaSeguradoraSteps || [];
      },
    },
  },
  mounted() {
    this.getAllterms();
  },
  watch: {
    proposta() {
      this.getAllterms();
    },
  },
  methods: {
    ...mapMutations("proposta-module", [
      "updateProposta",
      "EmissionProposalType",
    ]),
    ...mapActions("propostas-module", [
      "propostaCredito",
      "propostaAnalisePEP",
      "propostaResseguro",
      "propostaAnaliseCadastro",
      "propostaSubscricao",
      "propostaResseguro",
    ]),
    nextPage() {
      this.enviarParaSeguradoraModal = false;
      this.$router.push(`/propostas/nova-proposta-new/dados-financeiros/${this.proposta.UniqueId}`)
    },
    prevPage() {
      this.$emit("prevPage", { pageIndex: 3 });
    },
    getAllterms() {
      const proposalContractualTerms = this.proposta.ProposalContractualTerms;
      const complementaryContractualTerms = this.proposta
        .ProposalContractualTermsModalityComplementary
        ? this.proposta.ProposalContractualTermsModalityComplementary
        : null;

      let terms = [];

      if (proposalContractualTerms) {
        proposalContractualTerms.forEach((element) => {
          if (element.Value) {
            terms.push(element.Value);
          }
        });
      }

      if (complementaryContractualTerms) {
        complementaryContractualTerms.forEach((element) => {
          if (element.Value) {
            terms.push(element.Value);
          }
        });
      }

      if (this.particularClauses) {
        this.particularClauses.forEach((element) => {
          if (element.HtmlFormatted) {
            terms.push(element.HtmlFormatted);
          }
        });
      }

      this.fullTerms = terms.join("<br/>");
    },
    cancelCreateProposal() {
      this.$router.push("/");
    },
    async salvarProposta(somenteSalvaProposta) {
      this.$appInsights.trackEvent({
        name: "salvar-proposta",
        properties: {
          place: "proposta-passo-3",
          action: "button-click",
          slug: "salvar-proposta-passo-3",
        },
      });
      this.EmissionProposalType(0);
      this.concatAllTerms();
      this.enviarParaSeguradoraSteps = [];
      const step = {
        name: "salvar_cotacao",
        status: "wait",
        message: !somenteSalvaProposta
          ? "Salvando Proposta"
          : "Salvando Cotação.",
      };
      this.enviarParaSeguradoraModal = true;
      this.updateStep(step);

      if ((this.advisorInfo || {}).PersonId > 0) {
        this.proposta.AdvisorId = this.advisorInfo.PersonId;
      }

      let payload = {
        ...this.proposta,
        Beneficiaries: (this.proposta.Beneficiaries || []).map((x) => {
          return {
            NomeSocial: x.NomeSocial,
            Id: x.Id,
            LocationId: x.LocationId || ((x.Locations || [])[0] || {}).Id,
            PercentageAmountValue: x.PercentageAmountValue,
          };
        }),
      };

      if (this.isMapfre) {
        payload = {
          ...payload,
          TipoCanalId: this.tipoCanalCorretora.Id,
          HasCoBrokers: this.possuiCocorretagemMapfre,
          CodigoQuadroDistribuicao: this.codigoQuadroDistribuicao,
          CoInsurancers:
            this.tipoCosseguro === 0
              ? null
              : [{ CoInsuranceType: this.tipoCosseguro }],
        };
      }      
      return await axiosInstance({
        method: "post",
        url: "api/Proposal/SaveProposal",
        data: payload,
      })
        .then(async (response) => {
          this.updateProposta({
            ...this.proposta,
            Id: response.data.Response.Id,
            UniqueId: response.data.Response.UniqueId,
            Number: response.data.Response.Number,
          });
          this.updateStep({
            ...step,
            status: "success",
            message: !somenteSalvaProposta
              ? "Proposta salva com sucesso!"
              : `Cotação n° ${this.proposta.Number} salva com sucesso.`,
          });
          if (!somenteSalvaProposta)
            return await this.enviarPropostaAnaliseCadastro(
              this.proposta.UniqueId
            );         
        })
        .catch((error) => {
          this.showActionButtons = true;
          this.showTimelineButton = true;
          this.updateStep({
            ...step,
            status: "error",
            message:
              "Não foi possível salvar a cotação. Corrija os pontos abaixo e tente novamente.",
            error: error.response.data.Errors,
          });
        });
    },
    concatAllTerms() {
      let terms = [];

      const formatObjectObserverParticularClauses = JSON.parse(
        JSON.stringify(this.particularClauses)
      );

      terms.push(this.proposta.ProposalContractualTerms);

      if (this.proposta.ProposalContractualTermsModalityComplementary) {
        terms.push(this.proposta.ProposalContractualTermsModalityComplementary);
      }

      if (
        this.particularClauses &&
        Object.keys(formatObjectObserverParticularClauses[0]).length > 0
      ) {
        formatObjectObserverParticularClauses.forEach((element) => {
          let objeto = {
            ContractualTermId: element.ContractualTermId,
            JSONTagValue: element.ContractualTerm.JSONTagValue,
            Value: element.Value,
          };
          terms.push(objeto);
        });
      }

      let termsConcat = terms.flat();
      this.$store.commit("proposta-module/updateProposta", {
        ...this.proposta,
        ProposalContractualTerms: termsConcat,
      });
    },
    updateStep(data) {
      if (!this.enviarParaSeguradoraSteps) this.enviarParaSeguradoraSteps = [];

      const idx = this.enviarParaSeguradoraSteps.findIndex((item) => {
        return item.name === data.name;
      });
      if (idx > -1) {
        this.enviarParaSeguradoraSteps.splice(idx, 1);
      }

      this.enviarParaSeguradoraSteps.push(data);

      if (data.status === "wait") {
        setTimeout(() => {
          this.$vs.loading({
            container: `#loading-step-${data.name}`,
            scale: 0.6,
          });
        }, 300);
      }
    },
    async enviarPropostaAnaliseCadastro(propostaUniqueId) {
      this.showActionButtons = false;
      this.showTimelineErrorButton = false;
      this.showRequiredDocumentsButton = false;

      const step = {
        name: "aprovacao_cadastro",
        status: "wait",
        message: "Executando regras de cadastro.",
      };

      this.enviarParaSeguradoraModal = true;
      this.updateStep(step);
      return await this.propostaAnaliseCadastro(propostaUniqueId)
        .then((response) => {
          if (!response || !response.Success) {
            this.updateStep({
              ...step,
              status: "error",
              message: "Regras de aprovação de cadastro.",
              error: response.Errors,
            });

            this.validShowBottonsModalRules(
              response.RequiredDocuments,
              true,
              propostaUniqueId,
              response
            );
            return;
          }

          if (response.Success && (response.Alerts || []).length > 0) {
            this.updateStep({
              ...step,
              status: "success",
              message: "Regras de aprovação de cadastro executada com sucesso.",
              alerts: response.Alerts || [],
            });
          } else {
            this.updateStep({
              ...step,
              status: "success",
              message: "Regras de aprovação de cadastro executada com sucesso.",
            });
          }

          this.enviarPropostaPEP(propostaUniqueId);
        })
        .catch((error) => {
          this.validShowBottonsModalRules([], true, propostaUniqueId, response);
          this.updateStep({
            ...step,
            status: "error",
            message: "Regras de aprovação de cadastro.",
            error: error.response.data.Errors,
          });
        });
    },
    validShowBottonsModalRules(documents, hasError, propostaUniqueId, row) {
      this.showActionButtons = true;

      if (hasError) {
        if (documents.length > 0) {
          this.showRequiredDocumentsButton = true;
          this.proposalUniqueId = propostaUniqueId;
          this.detalhamento.RequestDocuments = documents;
        } else {
          this.showTimelineErrorButton = true;
        }
        if (
          row.Errors[row.Errors.length - 1] ===
          "Ocorreu um erro inesperado que impossibilitou o cadastro da proposta, gentileza tentar novamente ou entrar em contato com a seguradora."
        ) {
          this.showTimelineErrorButton = false;
          this.showExeptionButton = true;
        }
      }
    },
    async enviarPropostaPEP(propostaUniqueId) {
      const step = {
        name: "aprovacao_pep",
        status: "wait",
        message: "Executando regras de aprovação automática de PEP.",
      };
      this.enviarParaSeguradoraModal = true;
      this.updateStep(step);
      return await this.propostaAnalisePEP(propostaUniqueId)
        .then(async (response) => {
          if (!response || !response.Success) {
            this.updateStep({
              ...step,
              status: "error",
              message: "Regras de aprovação automática de PEP.",
              error: response.Errors,
            });

            this.validShowBottonsModalRules(
              response.RequiredDocuments,
              true,
              propostaUniqueId,
              response
            );
            return;
          }
          if (response.Success && (response.Alerts || []).length > 0) {
            this.updateStep({
              ...step,
              status: "success",
              message:
                "Regras de aprovação automática de PEP executada com sucesso.",
              alerts: response.Alerts || [],
            });
          } else {
            this.updateStep({
              ...step,
              status: "success",
              message:
                "Regras de aprovação automática de PEP executada com sucesso.",
            });
          }
          await this.enviarPropostaCredito(propostaUniqueId);
        })
        .catch((error) => {
          this.validShowBottonsModalRules([], true, propostaUniqueId, response);

          this.updateStep({
            ...step,
            status: "error",
            message: "Regras de aprovação automática de PEP.",
            error: error.response.data.Errors,
          });
        });
    },
    async enviarPropostaCredito(propostaUniqueId) {
      const step = {
        name: "aprovacao_credito",
        status: "wait",
        message: "Executando regras de aprovação automática de crédito.",
      };
      this.enviarParaSeguradoraModal = true;
      this.updateStep(step);
      return await this.propostaCredito(propostaUniqueId)
        .then(async (response) => {
          if (!response || !response.Success) {
            this.updateStep({
              ...step,
              status: "error",
              message: "Regras de aprovação automática de crédito.",
              error: response.Errors,
            });

            this.validShowBottonsModalRules(
              response.RequiredDocuments,
              true,
              propostaUniqueId,
              response
            );

            if (response.ExibirOpcaoUsuario) {
              this.enviarParaSeguradoraModal = false;
              this.showModalCancelationProposal = true;
            }

            return;
          }
          if (response.Success && (response.Alerts || []).length > 0) {
            this.updateStep({
              ...step,
              status: "success",
              message:
                "Regras de aprovação automática de crédito executada com sucesso.",
              alerts: response.Alerts || [],
            });
          } else {
            this.updateStep({
              ...step,
              status: "success",
              message:
                "Regras de aprovação automática de crédito executada com sucesso.",
            });
          }

          await this.enviarPropostaSubscricao(propostaUniqueId);
        })
        .catch((error) => {
          this.validShowBottonsModalRules([], true, propostaUniqueId, response);

          this.updateStep({
            ...step,
            status: "error",
            message: "Regras de aprovação automática de crédito.",
            error: error.response.data.Errors,
          });
        });
    },
    async enviarPropostaSubscricao(propostaUniqueId) {
      const step = {
        name: "aprovacao_subscricao",
        status: "wait",
        message: "Executando regras de aprovação automática de subscrição.",
      };
      this.updateStep(step);

      return await this.propostaSubscricao(propostaUniqueId)
        .then(async (response) => {
          if (!response || !response.Success) {
            this.updateStep({
              ...step,
              status: "error",
              message:
                "Regras de aprovação automática de subscrição executada.",
              error: response.Errors,
            });

            this.validShowBottonsModalRules(
              response.RequiredDocuments,
              true,
              propostaUniqueId,
              response
            );
            return;
          }

          if (response.Success && (response.Alerts || []).length > 0) {
            this.updateStep({
              ...step,
              status: "success",
              message:
                "Regras de aprovação automática de subscrição executada com sucesso.",
              alerts: response.Alerts || [],
            });
          } else {
            this.updateStep({
              ...step,
              status: "success",
              message:
                "Regras de aprovação automática de subscrição executada com sucesso.",
            });
          }

          await this.enviarPropostaResseguro(propostaUniqueId);
        })
        .catch((error) => {
          this.validShowBottonsModalRules([], true, propostaUniqueId, response);

          this.updateStep({
            ...step,
            status: "error",
            message: "Regras de aprovação automática de subscrição executada.",
            error: error.response.data.Errors,
          });
        });
    },
    async enviarPropostaResseguro(propostaUniqueId) {
      const step = {
        name: "aprovacao_resseguro",
        status: "wait",
        message: "Executando regras de aprovação automática de resseguro.",
      };
      this.updateStep(step);
      return await this.propostaResseguro(propostaUniqueId)
        .then((response) => {
          if (!response || !response.Success) {
            this.updateStep({
              ...step,
              status: "error",
              message:
                "Regras de aprovação automática de resseguro executada com sucesso.",
              error: response.Errors,
            });

            this.validShowBottonsModalRules(
              response.RequiredDocuments,
              true,
              propostaUniqueId,
              response
            );
            return;
          }

          if (response.Success && (response.Alerts || []).length > 0) {
            this.updateStep({
              ...step,
              status: "success",
              message:
                "Regras de aprovação automática de resseguro executada com sucesso.",
              alerts: response.Alerts || [],
            });
          } else {
            this.updateStep({
              ...step,
              status: "success",
              message:
                "Regras de aprovação automática de resseguro executada com sucesso.",
            });
          }
          this.showActionButtons = true;
        })
        .catch((error) => {
          this.validShowBottonsModalRules([], true, propostaUniqueId, response);

          this.updateStep({
            ...step,
            status: "error",
            message:
              "Regras de aprovação automática de resseguro executada com sucesso.",
            error: error.response.data.Errors,
          });
        });
    },
    closeModal() {
      this.$appInsights.trackEvent({
        name: "fechar-modal-enviar-seguradora-proposta",
        properties: {
          place: "proposta-passo-3",
          action: "button-click",
          slug: "fechar-modal-enviar-seguradora-proposta-passo-3",
        },
      });
      this.enviarParaSeguradoraModal = false;
    },
    goToTimeline() {
      this.$appInsights.trackEvent({
        name: "go-to-timeline-proposta",
        properties: {
          place: "proposta-passo-3",
          action: "button-click",
          slug: "go-to-timeline-proposta-passo-3",
        },
      });
      return this.$router.push({
        name: "timeline-proposta",
        params: { propostaUniqueId: this.proposta.UniqueId },
      });
    },
    goToDashboard() {
      this.$appInsights.trackEvent({
        name: "sair-proposta",
        properties: {
          place: "proposta-passo-3",
          action: "button-click",
          slug: "sair-proposta-passo-3",
        },
      });
      this.enviarParaSeguradoraModal = false;
      setTimeout(() => {
        this.$router.push("/propostas");
      }, 500);
    },
    sendDocuments() {
      this.enviarParaSeguradoraModal = false;
      this.sendDocumentsDialog = true;
    },
    updateDocs(docs) {
      this.detalhamento.RequestDocuments = docs;
    },
    closeModalDocuments() {
      this.goToTimeline();
      this.popupDetalhamento = false;
    },
    continuarProposta() {
      this.showModalCancelationProposal = false;
      this.sendDocumentsDialog = true;
    },
    cancelarProposta() {
      this.enviarParaSeguradoraModal = false;
      this.showModalCancelationProposal = false;

      this.$onpoint.loadingModal("Estamos cancelando a proposta", () => {
        return axiosInstance
          .post(
            `api/Proposal/CancelProposal?ProposalUniqueId=${this.proposta.UniqueId}`
          )
          .then(() => {
            this.$router.push({ name: "propostas" });
          })
          .catch((errors) =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.card {
  padding: 24px;
  border-radius: 16px;
  width: 100%;
}
.back-button {
  background: white;
  color: black;
  border: solid 1px rgba(209, 213, 219, 1);
  margin-right: 5px;
}
.cancel-button {
  background: white;
  color: black;
  border: solid 1px rgba(209, 213, 219, 1);
  margin-right: 5px;
  border: none;
}
.accordion {
  :deep(.p-accordion-header-text) {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #6b7280;
  }
  :deep(.p-accordion-tab .p-accordion-header .p-accordion-header-link) {
    background: #f9fafb;
  }
  :deep(.p-accordion-tab .p-toggleable-content .p-accordion-content) {
    background: #f9fafb;
  }
}

.card-premio {
  background: linear-gradient(90deg, #005a97, #052e58);
  height: 148px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .text-premio {
    color: white;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
  }
  .value-premio {
    color: white;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
  }
  .premio-alert {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    background: #fdecce;
    color: #623f04;
    border-radius: 5px;
    padding-inline: 10px;
    .icon-alert {
      position: relative;
      top: 1px;
      padding: 1px;
    }
    .premio-alert-text {
      padding: 1px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      font-family: "Montserrat", Helvetica, Arial, sans-serif;
    }
  }
}
.tab-item-container {
  background: white;
  min-height: 82px;
  border-radius: 8px;
  padding: 17px 24px;
  .title-data {
    height: 22px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    color: #4b5563;
  }
  .value-data {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    color: #4b5563;
  }
  .divider-margin-value-data {
    margin-inline: 10px;
  }
}
.title-divider {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  color: #6b7280;
  padding: 5px 17px;
}
</style>