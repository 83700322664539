import instance from "@/axios";
import TagHelpers from "@/helpers/TagHelpers";
import {
  dateToDDMMYYYY,
  diffDates,
  formatStrMoney,
  stripHtml
} from "../../utils/utils";

export default {
  namespaced: true,
  state: {
    endosso: {
      RequestInsurancePremiumReview: 0.0,
      RestoreInsurancePremium: false,
      DeadlineDays: 0,
      IsCompleteChangeObject: false,
      CompleteObject: "",
      EndorsementTypeId: null,
      PolicyUniqueId: null,
      ContractualTerms: {
        Object: [{}],
        IsCompleteChangeObject: null
      }
    },
    endossoList: [],
    objectDiff: [],
    seguradoraTags: {},
    hasFixedStartDate: false,
    hasVigencyStartDate: false,
    hasReason: false,
    changedObjectEndorsement: false
  },
  mutations: {
    updatehasReason(state, data) {
      state.hasReason = data;
    },
    updatehasFixedStartDate(state, data) {
      state.hasFixedStartDate = data;
    },
    updateVigencyStartDate(state, data) {
      state.hasVigencyStartDate = data;
    },
    updateDeadlineDays(state, data) {
      state.endosso.DeadlineDays = data;
    },
    updateSeguradoraTags(state, data) {
      state.seguradoraTags = data;
    },

    updateDays(state, data) {
      if (data.PrincipalModalityDate) {
        if (data.PrincipalModalityDate.EndDate) {
          state.endosso.EndDate = data.PrincipalModalityDate.EndDate;
        }
        if (data.PrincipalModalityDate.DeadLineDays) {
          state.endosso.DeadlineDays = data.PrincipalModalityDate.DeadLineDays;
        }
        if (data.PrincipalModalityDate.StartDate) {
          state.endosso.StartDate = data.PrincipalModalityDate.StartDate;
        }
      }
      if (data.ComplementaryModalityDate) {
        if (data.ComplementaryModalityDate.DeadLineDays) {
          state.endosso.ComplementaryDeadlineDays =
            data.ComplementaryModalityDate.DeadLineDays;
        }
        if (data.ComplementaryModalityDate.ComplementaryStartDate) {
          state.endosso.ComplementaryStartDate =
            data.ComplementaryModalityDate.StartDate;
        }
        if (data.ComplementaryModalityDate.EndDate) {
          state.endosso.ComplementaryEndDate =
            data.ComplementaryModalityDate.EndDate;
        }
      }
    },
    updateDeadlineDays(state, data) {
      state.endosso.DeadlineDays = data;
    },

    updateEndosso(state, data) {
      state.endosso = {
        ...state.endosso,
        ...data
      };
      if (data.Documents) {
        data.Documents.forEach(doc => {
          if (doc.files && doc.files.length)
            state.endosso.Documents.forEach(item => {
              if (item.Id == doc.Id)
                item.files = {
                  DocumentName: doc.Name,
                  EndorsementRequestDocumentId: doc.Id,
                  files: doc.files
                };
            });
        });
      }
    },

    updateEndorsementList(state, data) {
      state.endossoList = data.sort(function(a, b) {
        return a.EndorsementId > b.EndorsementId
          ? -1
          : a.EndorsementId < b.EndorsementId
          ? 1
          : 0;
      });
    },

    resetEndosso(state) {
      state.endosso = {
        EndorsementTypeId: null,
        RequestInsurancePremiumReview: 0.0,
        PolicyUniqueId: null,
        RestoreInsurancePremium: false,
        DeadlineDays: 0,
        IsCompleteChangeObject: false,
        CompleteObject: "",
        ContractualTerms: {
          Object: []
        }
      };
    },

    setEndosso(state, data) {
      state.endosso = data;
    },

    setObjectDiff(state, data) {
      state.objectDiff = data;
    },

    updateContractualTermsObject(state, data) {
      state.endosso.ContractualTerms.Object = data;
    },

    updateContractualTermsIsCompleteChangeObject(state, data) {
      state.endosso.ContractualTerms.IsCompleteChangeObject = data;
    },

    initializeObjectValue(state) {
      let data = [{
        Value: ""
      }]
      state.endosso.ContractualTerms.Object = data;
    },

    updateContractualTermsObjectValue(state, data) {
      state.endosso.ContractualTerms.Object[0].Value = data;
    },

    updateChangedObjectEndorsement(state, data){
      state.changedObjectEndorsement = data;
    }
  },
  actions: {
    async getEndorsementType({ commit }) {
      return await instance
        .get("/api/Endorsement/GetEndorsementType")
        .then(response => response.data.Response);
    },

    async getListEndorsementPolicy({ commit }, policyUniqueId) {
      return await instance
        .get(
          `/api/Endorsement/ListEndorsementsByPolicy?policyUniqueId=${policyUniqueId}`
        )
        .then(response =>
          commit("updateEndorsementList", response.data.Response)
        );
    },

    async getEndorsementAttachmentsList({ commit }, endorsementId) {
      return await instance
        .get(
          `api/Endorsement/ListEndorsementAttachments?endorsementId=${endorsementId}`
        )
        .then(response => response.data.Response);
    },

    async downloadEndorsementDocument({ commit }, payload) {
      return await instance
        .get(
          `api/Endorsement/DownloadEndorsementDocument?proposalUniqueId=${payload}`
        )
        .then(response => response.data.Response);
    },

    async getEndorsementRequestDocumentArchives({ commit }, requestDocumentUniqueId) {
      return await instance
        .get(
          `api/Endorsement/GetEndorsementRequestDocumentArchives?requestDocumentUniqueId=${requestDocumentUniqueId}`
        )
        .then(response => response.data.Response);
    },

    async downloadEndorsementArchive({}, archiveUniqueId) {
      return await instance
        .get(
          `api/Endorsement/DownloadEndorsementArchive?archiveUniqueId=${archiveUniqueId}`
        )
        .then(response => response.data.Response);
    },

    async resetEndosso({ commit }, uniqueId) {
      return await instance
        .post(
          `api/Endorsement/CancelRequestEndorsement?PolicyUniqueId=${uniqueId}`
        )
        .then(response => response.data.Response);
    },

    async getReasonEndorsementRequest({}, payload) {
      return await instance
        .get(
          `/api/ReasonEndorsementRequest/GetPolicyReasonsEndorsementRequest?policyUniqueId=${payload.policyUniqueId}&PolicyEndorsementTypeId=${payload.typeId}`
        )
        .then(responses => responses.data.Response);
    },

    async postValidateEndorsement({ getters, state, commit }, data) {
      let payload = getters.endossoPayload;

      if (state.endosso.RequestInsurancePremiumReview) {
        payload.InsurancePremium = state.endosso.RequestInsurancePremiumReview;
      }

      if (payload.IsCompleteChangeObject) {
        commit("updateContractualTermsIsCompleteChangeObject", payload.IsCompleteChangeObject);
        payload.ContractualTerms.IsCompleteChangeObject = true;
        commit("initializeObjectValue")
        commit("updateContractualTermsObjectValue", payload.CompleteObject);
        payload.ContractualTerms.Object[0].Value = payload.CompleteObject;
      }     

      if (payload.EndorsementTypeId == 1) {
        if (payload.EndDate == null) {  
          let date = new Date()           
          payload.EndDate = date.toJSON();
        }
      }     

      if (payload.EndorsementTypeId != 1) {  
        if (payload.EndDate == null) {
          payload.EndDate = data.apolice.EndDate;
        }
        if (payload.ComplementaryEndDate == null) {
          payload.ComplementaryEndDate = data.apolice.ComplementaryEndDate;
        }
      }

      if (payload.DeadlineDays == null) {
        payload.DeadlineDays = data.apolice.DeadlineDays;
      }    

      return await instance
        .post(`/api/Endorsement/ValidateEndorsement`, payload)
        .then(responses => responses.data.Response);
    },

    async loadEndorsementDocuments({}, params) {
      return await instance
        .get(
          `/api/Endorsement/GetPolicyEndorsementRequestDocument?endorsementTypeId=${params.endorsementId}&endorsementReasonId=${params.EndorsementReasonId}&modalityId=${params.modalityId}&endorsementChangeTypeIds=${params.endorsementChangeTypeIds}`
        )
        .then(response => response.data.Response);
    },

    async validateCanRequestEndorsementType({}, params) {
      return await instance({
        method: "post",
        url: `api/Endorsement/ValidateCanRequestEndorsementType?policyUniqueId=${params.policyUniqueId}&type=${params.type}`
      }).then(response => response.data.Response);
    },
    async createEndorsementPolicy({}, payload) {
      let dataPayload = payload;
      if (dataPayload.PolicyHolder) {
        if (dataPayload.PolicyHolder.BankAccount == null) {
          delete dataPayload.PolicyHolder.BankAccount;
        }
      }
      return await instance
        .post(`api/Endorsement/CreateEndorsement`, dataPayload)
        .then(response => response.data.Response);
    },
    async endossoCadastro({}, proposalUniqueId) {
      return await instance
        .post(
          `api/Endorsement/ExecuteAutomaticRegistrationEndorsementPolicy?proposalUniqueId=${proposalUniqueId}`
        )
        .then(response => response.data.Response);
    },

    async endossoAprovacaoCredito({}, proposalUniqueId) {
      return await instance
        .post(
          `api/Endorsement/ExecuteAutomaticCreditApprovalEndorsementPolicy?proposalUniqueId=${proposalUniqueId}`
        )
        .then(response => response.data.Response);
    },

    async endossoResseguro({}, proposalUniqueId) {
      return await instance
        .post(
          `api/Endorsement/ExecuteAutomaticReinsuranceEndorsementPolicy?proposalUniqueId=${proposalUniqueId}`
        )
        .then(response => response.data.Response);
    },

    async endossoSubscricao({}, payload) {
      return await instance
        .post(
          `api/Endorsement/ExecuteAutomaticSubscriptionEndorsementPolicy`,
          payload
        )
        .then(response => response.data.Response);
    },

    async approveEndorsement({}, payload) {
      return await instance
        .post(`api/Endorsement/ApproveEndorsement`, payload)
        .then(response => response.data.Response);
    },

    async restoreInsurancePremium({ getters, state }, payload) {
      let data = getters.endossoPayload;
      return await instance
        .post(`api/RestoreInsurancePremium/ValidateRestore`, data)
        .then(response => response.data.Response)
        .catch(data => {
          return Promise.reject(data);
        });
    },
    async GetCalculationProposalDate({ getters, state }, payload) {
      let data = {};
      if (payload.apolice.ComplementaryEndDate) {
        data = {
          PolicyUniqueId: payload.apolice.PolicyUniqueId,
          PrincipalModalityDate: {
            DeadlineDays: getters.endossoPayload.DeadlineDays,
            EndDate: getters.endossoPayload.EndDate,
            StartDate: getters.endossoPayload.StartDate
          },
          ComplementaryModalityDate: {
            DeadlineDays: getters.endossoPayload.ComplementaryDeadlineDays,
            EndDate: getters.endossoPayload.ComplementaryEndDate
          }
        };
      } else {
        data = {
          PolicyUniqueId: payload.apolice.PolicyUniqueId,
          PrincipalModalityDate: {
            DeadlineDays: getters.endossoPayload.DeadlineDays,
            EndDate: getters.endossoPayload.EndDate,
            StartDate: getters.endossoPayload.StartDate
          }
        };
      }
      data.PrincipalModalityDate.EndDate = data.PrincipalModalityDate.EndDate
        ? data.PrincipalModalityDate.EndDate
        : payload.apolice.EndDate;

      data.PrincipalModalityDate.DeadlineDays = null;
      if (payload.apolice.ComplementaryEndDate) {
        data.ComplementaryModalityDate.EndDate = data.ComplementaryModalityDate
          .EndDate
          ? data.ComplementaryModalityDate.EndDate
          : payload.apolice.ComplementaryEndDate;
      }
      if (payload.apolice.ComplementaryEndDate) {
        data.ComplementaryModalityDate.StartDate = data.PrincipalModalityDate
          .EndDate
          ? data.PrincipalModalityDate.EndDate
          : payload.apolice.ComplementaryStartDate;
      }

      if (payload.type === 6) {
        data.PrincipalModalityDate.StartDate = data.PrincipalModalityDate
          .StartDate
          ? data.PrincipalModalityDate.StartDate
          : state.hasVigencyStartDate;
      } else {
        data.PrincipalModalityDate.StartDate = data.PrincipalModalityDate
          .StartDate
          ? data.PrincipalModalityDate.StartDate
          : payload.apolice.StartDate;
      }

      if (payload.type === 3 || 4) {
        if (payload.apolice.ComplementaryEndDate) {
          data.ComplementaryModalityDate.DeadlineDays = data
            .ComplementaryModalityDate.DeadlineDays
            ? data.ComplementaryModalityDate.DeadlineDays
            : payload.apolice.ComplementaryDeadlineDays;
          data.ComplementaryModalityDate.EndDate = null;
        }
      }
      if (payload.type === 5) {
        if (payload.apolice.ComplementaryEndDate) {
          data.ComplementaryModalityDate.DeadlineDays = null;
          data.ComplementaryModalityDate.EndDate = state.endosso
            .ComplementaryEndDate
            ? state.endosso.ComplementaryEndDate
            : payload.apolice.ComplementaryEndDate;
        }
      }
      if (state.hasFixedStartDate) {
        if (payload.type === 9) {
          data.PrincipalModalityDate.EndDate = null;
          data.PrincipalModalityDate.DeadlineDays = state.endosso.DeadLineDays
            ? state.endosso.DeadLineDays
            : payload.apolice.DeadlineDays;
        }
      }
      return await instance
        .post(`api/Proposal/GetCalculationProposalDate  `, data)
        .then(response => response.data.Response)
        .catch(data => {
          return Promise.reject(data);
        });
    },

    async getPercentageLimitPremiumRelationInsuredAmount({}) {
      return await instance
        .get(`/api/Core/GetPercentageLimitPremiumRelationIssuredAmount`)
        .then(response => response.data.Response);
    },

    async getNumberOfDaysToCancelPolicy({}) {
      return await instance
        .get(`/api/policy/GetNumberOfDaysToCancelPolicy`)
        .then(response => response.data.Response);
    },

    async getCompareChanges({}, payload) {
      return await instance
        .get(
          `/api/endorsement/GetCompareChanges?proposalUniqueId=${payload.uniqueId}`
        )
        .then(response => response.data.Response);
    },

    async generateEndorsementDocument({ commit }, propostaUniqueId) {
      return await instance
        .get(
          `api/endorsement/GenerateEndorsementDocument?ProposalUniqueId=${propostaUniqueId}`
        )
        .then(response => response.data.Response.UrlToDownload);
    },

    async validChangedObject({ commit, getters }, payload) {
      let changed = false;
      let objectDiff = [];    

      if (payload.value.IsCompleteChangeObject != false) {
        changed = true;
        objectDiff.push({
          current: "Objeto customizado",
          origin: "Objeto da Apólice",
          label: "Objeto do Endosso",
          key: "InsuredAmountValue"
        });
      }

      if (payload.value.ComplementaryDeadlineDays) {
        if (
          payload.value.ComplementaryDeadlineDays !=
          payload.valueCompare.ComplementaryDeadlineDays
        ) {
          changed = true;
        }
        // else delete payload.value.ComplementaryDeadlineDays;

        if (payload.value.ComplementaryDeadlineDays)
          objectDiff.push({
            current: payload.value.ComplementaryDeadlineDays,
            origin: payload.valueCompare.ComplementaryDeadlineDays,
            label: "Prazo dias complementar",
            key: "ComplementaryDeadlineDays"
          });
      }

      if (payload.value.ComplementaryEndDate) {
        if (
          diffDates(
            payload.value.ComplementaryEndDate,
            payload.valueCompare.ComplementaryEndDate
          )
        )
          changed = true;
        else delete payload.value.ComplementaryEndDate;

        if (payload.value.ComplementaryEndDate)
          objectDiff.push({
            current: dateToDDMMYYYY(payload.value.ComplementaryEndDate),
            origin: dateToDDMMYYYY(payload.valueCompare.ComplementaryEndDate),
            label: "Fim vigência da nova IS complementar",
            key: "ComplementaryEndDate"
          });
      }

      if (payload.value.ComplementaryInsuredAmountValue) {
        if (
          payload.value.ComplementaryInsuredAmountValue !=
          payload.valueCompare.ComplementaryInsuredAmountValue
        )
          changed = true;
        else delete payload.value.ComplementaryInsuredAmountValue;

        if (payload.value.ComplementaryInsuredAmountValue)
          objectDiff.push({
            current: formatStrMoney(
              payload.value.ComplementaryInsuredAmountValue,
              2,
              ",",
              "."
            ),
            origin: formatStrMoney(
              payload.valueCompare.ComplementaryInsuredAmountValue,
              2,
              ",",
              "."
            ),
            label: "Importância segurada complementar",
            key: "ComplementaryInsuredAmountValue"
          });
      }

      if (payload.value.DeadlineDays) {
        if (payload.value.DeadlineDays != payload.valueCompare.DeadlineDays)
          changed = true;
        else delete payload.value.DeadlineDays;

        if (payload.value.DeadlineDays)
          objectDiff.push({
            current: payload.value.DeadlineDays,
            origin: payload.valueCompare.DeadlineDays,
            label: "Prazo dias principal",
            key: "DeadlineDays"
          });
      }

      if (payload.value.EndDate) {
        if (diffDates(payload.value.EndDate, payload.valueCompare.EndDate))
          changed = true;
        else delete payload.value.EndDate;

        if (payload.value.EndDate)
          objectDiff.push({
            current: dateToDDMMYYYY(payload.value.EndDate),
            origin: dateToDDMMYYYY(payload.valueCompare.EndDate),
            label: "Fim vigência da nova IS principal",
            key: "EndDate"
          });
      }

      if (payload.value.StartDate) {
        if (diffDates(payload.value.StartDate, payload.valueCompare.StartDate))
          changed = true;
        else delete payload.value.StartDate;

        if (payload.value.StartDate)
          objectDiff.push({
            current: dateToDDMMYYYY(payload.value.StartDate),
            origin: dateToDDMMYYYY(payload.valueCompare.StartDate),
            label: "Início vigência da nova IS principal",
            key: "StartDate"
          });
      }

      if (payload.value.InsuredAmountValue) {
        if (
          payload.value.InsuredAmountValue !=
          payload.valueCompare.InsuredAmountValue
        )
          changed = true;
        else delete payload.value.InsuredAmountValue;

        if (payload.value.InsuredAmountValue)
          objectDiff.push({
            current: formatStrMoney(
              payload.value.InsuredAmountValue,
              2,
              ",",
              "."
            ),
            origin: formatStrMoney(
              payload.valueCompare.InsuredAmountValue,
              2,
              ",",
              "."
            ),
            label: "Importância segurada principal",
            key: "InsuredAmountValue"
          });
      }

      if (payload.value.PolicyHolder) {
        let diffObjects = false;

        if (
          payload.value.PolicyHolder.Name &&
          payload.value.PolicyHolder.Name !=
            payload.valueCompare.PolicyHolder.Name
        ) {
          diffObjects = true;

          objectDiff.push({
            current: payload.value.PolicyHolder.Name,
            origin: payload.valueCompare.PolicyHolder.Name,
            label: "Nome do tomador",
            object: "PolicyHolder",
            key: "Name"
          });
        } else delete payload.value.PolicyHolder.Name;

        if (payload.value.PolicyHolder.Location) {
          let locationChanged = false;

          if (
            payload.value.PolicyHolder.Location.ZipCode !=
            payload.valueCompare.PolicyHolder.Location.ZipCode
          ) {
            diffObjects = true;
            locationChanged = true;
          }
          if (
            payload.value.PolicyHolder.Location.AddressName !=
            payload.valueCompare.PolicyHolder.Location.AddressName
          ) {
            diffObjects = true;
            locationChanged = true;
          }
          if (
            payload.value.PolicyHolder.Location.Number !=
            payload.valueCompare.PolicyHolder.Location.Number
          ) {
            diffObjects = true;
            locationChanged = true;
          }
          if (
            payload.value.PolicyHolder.Location.Complement !=
            payload.valueCompare.PolicyHolder.Location.Complement
          ) {
            diffObjects = true;
            locationChanged = true;
          }
          if (
            payload.value.PolicyHolder.Location.Neighborhood !=
            payload.valueCompare.PolicyHolder.Location.Neighborhood
          ) {
            diffObjects = true;
            locationChanged = true;
          }
          if (
            payload.value.PolicyHolder.Location.StateProvinceId !=
            payload.valueCompare.PolicyHolder.Location.StateProvinceId
          ) {
            diffObjects = true;
            locationChanged = true;
          }
          if (
            payload.value.PolicyHolder.Location.CityId !=
            payload.valueCompare.PolicyHolder.Location.CityId
          ) {
            diffObjects = true;
            locationChanged = true;
          }

          if (locationChanged) {
            objectDiff.push({
              current: `${payload.value.PolicyHolder.Location.AddressName}, ${
                payload.value.PolicyHolder.Location.Number
              }
              ${
                payload.value.PolicyHolder.Location.Complement
                  ? ", " + payload.value.PolicyHolder.Location.Complement
                  : ""
              } - ${payload.value.PolicyHolder.Location.Neighborhood}, ${
                payload.value.PolicyHolder.Location.ZipCode
              }`,
              origin: `${
                payload.valueCompare.PolicyHolder.Location.AddressName
              }, ${payload.valueCompare.PolicyHolder.Location.Number}
              ${
                payload.valueCompare.PolicyHolder.Location.Complement
                  ? ", " + payload.valueCompare.PolicyHolder.Location.Complement
                  : ""
              } - ${payload.valueCompare.PolicyHolder.Location.Neighborhood}, ${
                payload.valueCompare.PolicyHolder.Location.ZipCode
              }`,
              label: "Endereço do tomador",
              object: "PolicyHolder",
              key: "Location"
            });
          }
        }

        if (diffObjects) {
          changed = true;
        }
      }

      if (payload.value.Insured) {
        let diffObjects = false;

        if (payload.value.Insured.Name != payload.valueCompare.Insured.Name) {
          diffObjects = true;

          objectDiff.push({
            current: payload.value.Insured.Name,
            origin: payload.valueCompare.Insured.Name,
            label: "Nome do segurado",
            object: "Insured",
            key: "Name"
          });
        } else delete payload.value.Insured.Name;

        if (payload.value.Insured.Location) {
          let locationChanged = false;

          if (
            payload.value.Insured.Location.ZipCode !=
            payload.valueCompare.Insured.Location.ZipCode
          ) {
            diffObjects = true;
            locationChanged = true;
          }
          if (
            payload.value.Insured.Location.AddressName !=
            payload.valueCompare.Insured.Location.AddressName
          ) {
            diffObjects = true;
            locationChanged = true;
          }
          if (
            payload.value.Insured.Location.Number !=
            payload.valueCompare.Insured.Location.Number
          ) {
            diffObjects = true;
            locationChanged = true;
          }
          if (
            payload.value.Insured.Location.Complement !=
            payload.valueCompare.Insured.Location.Complement
          ) {
            diffObjects = true;
            locationChanged = true;
          }
          if (
            payload.value.Insured.Location.Neighborhood !=
            payload.valueCompare.Insured.Location.Neighborhood
          ) {
            diffObjects = true;
            locationChanged = true;
          }
          if (
            payload.value.Insured.Location.StateProvinceId !=
            payload.valueCompare.Insured.Location.StateProvinceId
          ) {
            diffObjects = true;
            locationChanged = true;
          }
          if (
            payload.value.Insured.Location.CityId !=
            payload.valueCompare.Insured.Location.CityId
          ) {
            diffObjects = true;
            locationChanged = true;
          }

          if (locationChanged) {
            objectDiff.push({
              current: `${payload.value.Insured.Location.AddressName}, ${
                payload.value.Insured.Location.Number
              }
              ${
                payload.value.Insured.Location.Complement
                  ? ", " + payload.value.Insured.Location.Complement
                  : ""
              }  - ${payload.value.Insured.Location.Neighborhood}, ${
                payload.value.Insured.Location.ZipCode
              }`,
              origin: `${payload.valueCompare.Insured.Location.AddressName}, ${
                payload.valueCompare.Insured.Location.Number
              }
              ${
                payload.valueCompare.Insured.Location.Complement
                  ? ", " + payload.valueCompare.Insured.Location.Complement
                  : ""
              } - ${payload.valueCompare.Insured.Location.Neighborhood}, ${
                payload.valueCompare.Insured.Location.ZipCode
              }`,
              label: "Endereço do segurado",
              object: "Insured",
              key: "Location"
            });
          }
        }

        if (diffObjects) {
          changed = true;
        } else delete payload.value.Insured;
      }

      if (payload.value.ContractualTerms) {
        if (
          payload.value.ContractualTerms.Object &&
          payload.value.ContractualTerms.Object.length
        ) {
          let diffObjects = false;

          payload.value.ContractualTerms.Object.forEach(term => {
            let proposalTerm = payload.valueCompare.ContractualTerms.Object.find(
              x => x.ContractualTermId == term.ContractualTermId
            );

            let termOrigValue = TagHelpers.replaceTagsTextCompare(
              proposalTerm
                ? eval(proposalTerm.JSONTag || []).concat(
                    TagHelpers.defaultTags(
                      payload.valueCompare,
                      getters.seguradoraTags
                    )
                  )
                : [],
              (proposalTerm || {}).Value,
              JSON.parse(proposalTerm.JSONTagValue)
            );

            if (proposalTerm && term.Value != termOrigValue) {
              diffObjects = true;

              objectDiff.push({
                current: term.Value,
                origin: termOrigValue,
                label: "Objeto",
                object: "ContractualTerms",
                key: "Object"
              });
            }
          });

          if (diffObjects) {
            changed = true;
          }
        }

        if (
          payload.value.ContractualTerms &&
          payload.value.ContractualTerms.ParticularClausule
        ) {
          let diffObjects = false;

          payload.value.ContractualTerms.ParticularClausule.forEach(term => {
            let termOrigValue = "Nova";
            let proposalTerm = payload.valueCompare.ContractualTerms.ParticularClausule.find(
              x => x.ContractualTermId == term.ContractualTermId
            );

            let termOrigJSONTags = proposalTerm
              ? eval(proposalTerm.JSONTag || []).concat(
                  TagHelpers.defaultTags(
                    payload.valueCompare,
                    getters.seguradoraTags
                  )
                )
              : [];
            if ((proposalTerm || {}).Value) {
              let objectOrig = JSON.parse(proposalTerm.JSONTagValue);

              if (
                termOrigJSONTags &&
                termOrigJSONTags.length &&
                termOrigJSONTags instanceof Array
              )
                termOrigJSONTags.forEach(x => {
                  if (!x.Value && objectOrig[x.Name])
                    x.Value = objectOrig[x.Name];

                  objectOrig[x.Name] = x.Value;
                });

              termOrigValue = TagHelpers.replaceTagsTextCompare(
                termOrigJSONTags,
                proposalTerm.Value,
                objectOrig
              );
            }

            let objectTerm = JSON.parse(term.JSONTagValue);
            let termJSONTags = term
              ? eval(term.JSONTag || []).concat(
                  TagHelpers.defaultTags(
                    {
                      ...payload.valueCompare,
                      ...payload.value
                    },
                    getters.seguradoraTags
                  )
                )
              : [];

            if (
              termJSONTags &&
              termJSONTags.length &&
              termJSONTags instanceof Array
            )
              termJSONTags.forEach(x => {
                if (!x.Value && objectTerm[x.Name])
                  x.Value = objectTerm[x.Name];

                objectTerm[x.Name] = x.Value;
              });

            let termValue = TagHelpers.replaceTagsTextCompare(
              termJSONTags,
              (proposalTerm || {}).Value || (term || {}).Value,
              objectTerm
            );

            termValue = stripHtml(termValue);
            termOrigValue = stripHtml(termOrigValue);

            if (!proposalTerm || termValue != termOrigValue) {
              diffObjects = true;

              objectDiff.push({
                current: termValue,
                origin: termOrigValue,
                label: "Condições particulares",
                object: "ContractualTerms",
                key: "ParticularClausule"
              });
            }
          });

          if (diffObjects) {
            changed = true;
          }
        }

        if (
          payload.valueCompare.ContractualTerms &&
          payload.valueCompare.ContractualTerms.ParticularClausule
        ) {
          let diffObjects = false;

          payload.valueCompare.ContractualTerms.ParticularClausule.forEach(
            term => {
              let endossoTerm = (
                (payload.value.ContractualTerms || {}).ParticularClausule || []
              ).find(x => x.ContractualTermId == term.ContractualTermId);

              if (!endossoTerm) {
                diffObjects = true;

                objectDiff.push({
                  current: "Removido",
                  origin: (term || {}).Value,
                  label: "Condições particulares",
                  object: "ContractualTerms",
                  key: "ParticularClausule"
                });
              }
            }
          );

          if (diffObjects) {
            changed = true;
          }
        }
      }

      if (changed) commit("setObjectDiff", objectDiff);

      commit("setEndosso", payload.value);
      return await Promise.resolve(changed);
    },

    resetEndossoModule({ commit }) {
      commit("resetEndosso");
      commit("setObjectDiff", []);
      commit("updateSeguradoraTags", {});
      commit("updatehasReason", false);
      commit("updatehasFixedStartDate", false);
      commit("updateVigencyStartDate", false);
      commit("updateContractualTermsObject", []);
      commit("updateChangedObjectEndorsement", false);
    }
  },
  getters: {
    endosso(state) {
      return state.endosso || {};
    },

    endossoList(state) {
      return state.endossoList || [];
    },

    endossoPayload(state) {
      return {
        IsCompleteChangeObject: state.endosso.IsCompleteChangeObject,
        ProposalUniqueId: state.endosso.ProposalUniqueId,
        EndorsementTypeId: state.endosso.EndorsementTypeId,
        PolicyUniqueId: state.endosso.PolicyUniqueId,
        ComplementaryDeadlineDays:
          state.endosso.ComplementaryDeadlineDays || null,
        ComplementaryEndDate: state.endosso.ComplementaryEndDate || null,
        ComplementaryInsuredAmountValue:
          state.endosso.ComplementaryInsuredAmountValue || null,
        DeadlineDays: state.endosso.DeadlineDays || null,
        EndDate: state.endosso.EndDate || null,
        StartDate: state.endosso.StartDate || null,
        InsuredAmountValue: state.endosso.InsuredAmountValue || null,
        Insured: state.endosso.Insured || null,
        PolicyHolder: state.endosso.PolicyHolder || null,
        ContractualTerms: state.endosso.ContractualTerms || {},
        CompleteObject: state.endosso.CompleteObject || ""
      };
    },

    endossoCreatePayload(state) {
      return {
        Documents:
          ((state.endosso.Documents || []).filter(doc => doc.files) || []).map(
            doc => doc.files
          ) || [],
        EndorsementObject: state.endosso.EndorsementObject || "",
        RestoreInsurancePremium: state.endosso.RestoreInsurancePremium,
        RequestInsurancePremiumReview:
          state.endosso.RequestInsurancePremiumReview,
        JustificationInsurancePremiumReview:
          state.endosso.JustificationInsurancePremiumReview || "",
        ReasonEndorsementRequestId: state.endosso.EndorsementReasonId
      };
    },
    seguradoraTags(state, data) {
      return state.seguradoraTags;
    },
    objectDiff(state) {
      return state.objectDiff;
    },

    changedObjectEndorsement(state) {
      return state.changedObjectEndorsement;
    }
  }
};
