<template>
  <div>
    <!-- NOTIFICATIONS -->
    <vs-dropdown
      vs-custom-content
      vs-trigger-click
      class="cursor-pointer"
    >
      <button>
        <!-- <img v-if="userInfo.photoURL" key="onlineImg" :src="userInfo.photoURL" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" /> -->
        <i class="onpoint-bell notification-button"></i>
      </button>

      <vs-dropdown-menu
        class="notification-dropdown dropdown-custom vx-navbar-dropdown"
      >
        <div class="notification-top text-start p-5 bg-primary text-white">
          <h4 class="text-white">{{ title }}</h4>
        </div>
        <template v-if="unreadNotifications.length > 0">
          <component
            :is="scrollbarTag"
            ref="mainSidebarPs"
            class="scroll-area--nofications-dropdown p-0"
            :settings="settings"
            :key="$vs.rtl"
          >
            <ul class="bordered-items p-0">
              <li
                v-for="ntf in unreadNotifications"
                :key="ntf.index"
                class="notification cursor-pointer w-full"
              >
                <div class="flex justify-between px-4 py-4" v-if="ntf.Message">
                  <div class="flex flex-col items-start">
                    {{
                      new Date(ntf.CreateDate)
                        | dateFormat("DD/MM/YYYY - HH:mm")
                    }}
                    <p class="font-semibold text-sm">
                      {{ ntf.Message }}
                    </p>
                  </div>
                  <div class="botoes">
                    <el-button
                      v-if="perfilHelpers.isInsurance() && ntf.CardUniqueId"
                      type="primary"
                      icon="el-icon-share"
                      @click="openCard(ntf)"
                      circle
                    ></el-button>
                    <el-button
                      v-if="perfilHelpers.isBroker() && ntf.ProposalUniqueId"
                      type="primary"
                      icon="el-icon-share"
                      @click="openProposal(ntf)"
                      circle
                    ></el-button>
                  </div>
                </div>
                <div class="flex justify-between px-4 py-4" v-else>
                  <div class="flex flex-col items-start">
                    {{
                      new Date(ntf.id.creationTime)
                        | dateFormat("DD/MM/YYYY - HH:mm")
                    }}
                    <p class="font-semibold text-sm">
                      {{ ntf.message }}
                    </p>
                  </div>
                  <div class="botoes">
                    <el-button
                      v-if="perfilHelpers.isInsurance() && ntf.cardUniqueId"
                      type="primary"
                      icon="el-icon-share"
                      @click="openCard2(ntf)"
                      circle
                    ></el-button>
                    <el-button
                      v-if="perfilHelpers.isBroker() && ntf.proposalUniqueId"
                      type="primary"
                      icon="el-icon-share"
                      @click="openProposal2(ntf)"
                      circle
                    ></el-button>
                  </div>
                </div>
              </li>
            </ul>
          </component>
        </template>
        <template v-else>
          <component
            :is="scrollbarTag"
            ref="mainSidebarPs"
            class="scroll-area--nofications-dropdown p-0"
            :settings="settings"
            :key="$vs.rtl"
          >
            <ul class="bordered-items p-0">
              <li class=" cursor-pointer w-full">
                <div class="flex justify-between px-4 py-4">
                  <div class="flex flex-col items-start">Sem mensagens</div>
                </div>
              </li>
            </ul>
          </component>
        </template>
        <div class="outras-notificacoes" @click.prevent="showNotificacoes()">
          Ver lidas
        </div>
      </vs-dropdown-menu>
    </vs-dropdown>
    <vs-popup :active.sync="modal" class="termos-modal" title="Mensagens lidas">
      <div class="flex flex-col items-center pt-4">
        <template v-if="OldNotifications.length > 0">
          <component
            :is="scrollbarTag"
            ref="mainSidebarPs"
            class="scroll-area--nofications-dropdown p-0"
            :settings="settings"
            :key="$vs.rtl"
          >
            <ul class="bordered-items p-0">
              <li
                v-for="ntf in OldNotifications"
                :key="ntf.index"
                class="notification cursor-pointer w-full"
              >
                <div class="flex justify-between px-4 py-4">
                  <div class="flex flex-col items-start">
                    {{
                      new Date(ntf.id.creationTime)
                        | dateFormat("DD/MM/YYYY - HH:mm")
                    }}
                    <p class="font-semibold text-sm">
                      {{ ntf.message }}
                    </p>

                    <p class="font-semibold text-sm">
                      Status: {{ ntf.status }}
                    </p>
                  </div>
                  <div class="botoes">
                    <el-button
                      v-if="perfilHelpers.isInsurance() && ntf.cardUniqueId"
                      type="primary"
                      icon="el-icon-share"
                      @click="openCard2(ntf)"
                      circle
                    ></el-button>
                    <el-button
                      v-if="perfilHelpers.isBroker() && ntf.proposalUniqueId"
                      type="primary"
                      icon="el-icon-share"
                      @click="openProposal2(ntf)"
                      circle
                    ></el-button>
                  </div>
                </div>
              </li>
            </ul>
          </component>
        </template>
        <template v-else>
          <component
            :is="scrollbarTag"
            ref="mainSidebarPs"
            class="scroll-area--nofications-dropdown p-0"
            :settings="settings"
            :key="$vs.rtl"
          >
            <ul class="bordered-items p-0">
              <li class="notification cursor-pointer w-full">
                <div class="flex justify-between px-4 py-4">
                  <div class="flex flex-col items-start">Sem mensagens</div>
                </div>
              </li>
            </ul>
          </component>
        </template>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { TheMask } from "vue-the-mask";
import axiosInstance from "@/axios";
import environment from "@/environment";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import Button from 'primevue/button';
export default {
  components: {
    VuePerfectScrollbar,
    TheMask,
    Button
  },
  props: ["title", "unreadNotifications", "OldNotifications", "message"],
  data() {
    return {
      modal: false,
      perfilHelpers: PerfilHelpers,
      openDiv: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
        isOpen: true,
      },
    };
  },
  computed: {
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
  },
  methods: {
    openCard(row) {
      if (
        this.$route.params.pipeid == row.ProposalUniqueId &&
        this.$route.params.token == row.PipeUniqueId
      ) {
        window.location.reload();
      } else {
        this.$router.push(
          `/kanban/pipe/${row.PipeUniqueId}/${row.ProposalUniqueId}`
        );
      }

      this.modal = false;
    },
    openProposal(row) {
      if (this.$route.params.propostaUniqueId == row.ProposalUniqueId) {
        window.location.reload();
      } else {
        this.$router.push(`/propostas/timeline/${row.ProposalUniqueId}`);
      }

      this.modal = false;
    },
    openCard2(row) {
      if (
        this.$route.params.pipeid == row.proposalUniqueId &&
        this.$route.params.token == row.pipeUniqueId
      ) {
        window.location.reload();
      } else {
        this.$router.push(
          `/kanban/pipe/${row.pipeUniqueId}/${row.proposalUniqueId}`
        );
      }

      this.modal = false;
    },
    openProposal2(row) {
      if (this.$route.params.propostaUniqueId == row.proposalUniqueId) {
        window.location.reload();
      } else {
        this.$router.push(`/propostas/timeline/${row.proposalUniqueId}`);
      }

      this.modal = false;
    },
    showNotificacoes() {
      this.modal = true;
    },
    goToDetalheProposta(proposta) {
      if (proposta.StatusId === 1) {
        //COTAÇÃO
        this.$router.push({
          name: "editar-proposta",
          params: { propostaUniqueId: proposta.UniqueId },
        });
      } else {
        this.$router.push({
          name: "timeline-proposta",
          params: { propostaUniqueId: proposta.UniqueId },
        });
      }
    },
    /*  elapsedTime (startTime) {
      const x        = new Date(startTime)
      const now      = new Date()
      let timeDiff = now - x
      timeDiff    /= 1000

      const seconds = Math.round(timeDiff)
      timeDiff    = Math.floor(timeDiff / 60)

      const minutes = Math.round(timeDiff % 60)
      timeDiff    = Math.floor(timeDiff / 60)

      const hours   = Math.round(timeDiff % 24)
      timeDiff    = Math.floor(timeDiff / 24)

      const days    = Math.round(timeDiff % 365)
      timeDiff    = Math.floor(timeDiff / 365)

      const years   = timeDiff

      if (years > 0) {
        return `${years + (years > 1 ? ' Years ' : ' Year ')}ago`
      } else if (days > 0) {
        return `${days + (days > 1 ? ' Days ' : ' Day ')}ago`
      } else if (hours > 0) {
        return `${hours + (hours > 1 ? ' Hrs ' : ' Hour ')}ago`
      } else if (minutes > 0) {
        return `${minutes + (minutes > 1 ? ' Mins ' : ' Min ')}ago`
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? ' sec ago' : 'just now')
      }

      return 'Just Now'
    },
    // Method for creating dummy notification time
    randomDate ({ hr, min, sec }) {
      const date = new Date()

      if (hr) date.setHours(date.getHours() - hr)
      if (min) date.setMinutes(date.getMinutes() - min)
      if (sec) date.setSeconds(date.getSeconds() - sec)

      return date
    }, */
  },
};
</script>
<style lang="scss" >
button .notification-button{
  border: none !important;
  background-color: #ebebeb;
  color: #626262;
  font-size: 25px;
  border-radius: 50px;
  padding: 4px; 
}

button button{
  position: relative;
  top:3px;
  border: none !important;  
  border-radius: 50px !important;
}
</style>