export default function compareCompanyUtils(key, valueToCompare) {
  try {
    const data = localStorage.getItem("lsApplicationConfig");
    if (!data) {
      console.warn("Nenhuma configuração encontrada no localStorage.");
      return false;
    }

    const applicationConfig = JSON.parse(data);

    if (applicationConfig.hasOwnProperty(key)) {
      return applicationConfig[key] === valueToCompare;
    }

    console.warn(`A chave "${key}" não existe na configuração.`);
    return false;
  } catch (error) {
    console.error("Erro ao comparar o valor:", error);
    return false;
  }
}
