import instance from "@/axios";

class EndossoProvider {
  async obterPolicyUniqueIdPorNumeroApolice(numeroApolice) {
    return await instance
      .get(`api/Policy/ObterPolicyUniqueIdPorNumeroApolice?numeroApolice=${numeroApolice}`)
      .then((response) => response.data.Response);
  }

  async validarEndossoCancelamento(policyUniqueId) {
    return await instance
      .post(`api/Endosso/ValidarEndossoCancelamento?policyUniqueId=${policyUniqueId}`)
      .then((response) => response.data.Response);
  }

  async obterDadosEndossoCancelamento(payload) {
    return await instance
      .post("/api/Endosso/ObterDadosEndossoCancelamento", payload)
      .then(response => response.data.Response);
  }
}

export default new EndossoProvider();
