<template>
  <div>
    <horizontal-tabs-components :tabs="tabs">
      <modality-details
        :details="detalhes || {}"
        :complementary="isComplementaryModality"
        :mainModalityId="isComplementaryModality ? mainModalityId : null"
        @modalitySaved="$emit('modalitySaved', $event)"
        @update-details="updateDetails"
        slot="tab-content-0"
        :permissoesModalidade="permissoesModalidade"
      ></modality-details>

      <template v-if="!!detalhes.Id">
        <div slot="tab-content-1">
          <vs-row>
            <vs-col vs-w="12" vs-sm="12">
              <vs-input
                class="w-full p-1"
                label="Referência da Seguradora"
                v-model="objeto.RefIntegration"
              /><br />
            </vs-col>
          </vs-row>
          <contractual-term
            :allowedRepeater="false"
            :allowedTextArea="allowedTextArea"
            :jsonTagsAll="allTags"
            :valueTerm="objeto.Value"
            :contractualTerm="objeto"
            @setJSONTagsAll="setJSONTagsAll"
            @setValueTerm="setValueTerm"
          />

          <div class="pt-4 mt-5 flex flex-row-reverse w-full">
            <el-button
              type="primary"
              @click="saveObject(2)"
              :loading="loadingButton"
              >Salvar objeto</el-button
            >
          </div>
        </div>
        <div slot="tab-content-2">
          <vs-row>
            <vs-col vs-w="12" vs-sm="12">
              <div class="flex">
                <vs-switch
                  class="mr-3"
                  v-model="CondicoesGerais.EnableToProposal"
                />
                <label>Habilitar na Proposta</label>
              </div>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-w="12" vs-sm="12">
              <vs-input
                class="w-full p-1"
                label="Referência da Seguradora"
                v-model="CondicoesGerais.RefIntegration"
              /><br />
            </vs-col>
          </vs-row>

          <contractual-term
            :allowedRepeater="false"
            :allowedTextArea="allowedTextArea"
            :jsonTagsAll="allTagsCondicoesGerais"
            :valueTerm="CondicoesGerais.Value"
            :contractualTerm="CondicoesGerais"
            @setJSONTagsAll="setJSONTagsAllCondicoesGerais"
            @setValueTerm="setValueTermCondicoesGerais"
          />

          <div class="pt-4 mt-5 flex flex-row-reverse w-full">
            <el-button
              type="primary"
              @click="saveObject(1)"
              :loading="loadingButton"
              >Salvar objeto</el-button
            >
          </div>
        </div>
        <div slot="tab-content-3">
          <template v-if="condicoesEspeciais">
            <vs-row>
              <vs-col vs-w="12" vs-sm="12">
                <div class="flex">
                  <vs-switch
                    class="mr-3"
                    v-model="condicoesEspeciais.EnableToProposal"
                  />
                  <label>Habilitar na Proposta</label>
                </div>
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col vs-w="12" vs-sm="12">
                <vs-input
                  class="w-full"
                  label="Referência da Seguradora"
                  v-model="condicoesEspeciais.RefIntegration"
                />
                <br />
              </vs-col>
            </vs-row>
          </template>
          <contractual-term
            :allowedRepeater="false"
            :allowedTextArea="allowedTextArea"
            :jsonTagsAll="allTagsCondicoesEspeciais"
            :valueTerm="condicoesEspeciais.Value"
            :contractualTerm="condicoesEspeciais"
            @setJSONTagsAll="setJSONTagsAllCondicoesEspeciais"
            @setValueTerm="setValueTermCondicoesEspeciais"
          />

          <vs-row>
            <vs-col vs-type="flex" vs-justify="end">
              <el-button
                type="primary"
                @click="saveObject(3)"
                :loading="loadingButton"
                >Salvar condições especiais</el-button
              >
            </vs-col>
          </vs-row>
        </div>

        <condicoes-particulares slot="tab-content-4" />

        <documentos-obrigatorios slot="tab-content-5" />
      </template>
    </horizontal-tabs-components>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DetalhesModalidade from "./DetalhesModalidade";
import CondicoesParticulares from "./CondicoesParticulares.vue";
import DocumentosObrigatorios from "./Documentos.vue";
import HorizontalTabsComponents from "../../../../components/horizontal-tabs/HorizontalTabsComponents.vue";
import ContractualTerm from "../ContractualTerm.vue";
import "jodit/build/jodit.min.css";
import { JoditEditor } from "jodit-vue";
import compareCompanyUtils from "@/utils/compareCompanyUtils.js";

export default {
  data() {
    return {
      tabActive: "param-modalidade-detalhes",
      EnableToProposalGeral: false,
      EnableToProposalEspecial: false,
      EnableToProposalParticular: false,
      loadingButton: false,
      allowedTextArea: false
    };
  },
  computed: {
    allTags: {
      get() {
        return eval(this.objeto.JSONTag || []);
      },

      set(value) {
        this.objeto.Tags = value;
        this.objeto.JSONTag = JSON.stringify(value);
      }
    },

    allTagsCondicoesGerais: {
      get() {
        return eval(this.CondicoesGerais.JSONTag || []);
      },

      set(value) {
        this.CondicoesGerais.Tags = value;
        this.CondicoesGerais.JSONTag = JSON.stringify(value);
      }
    },

    allTagsCondicoesEspeciais: {
      get() {
        return eval(this.condicoesEspeciais.JSONTag || []);
      },

      set(value) {
        this.condicoesEspeciais.Tags = value;
        this.condicoesEspeciais.JSONTag = JSON.stringify(value);
      }
    },

    modalityId: {
      get() {
        return this.isComplementaryModality
          ? this.detalhes.Id
          : this.$route.params.modalidadeId;
      }
    },

    tabs() {
      if (this.detalhes.Id)
        return [
          {
            name: "Detalhes da modalidade",
            index: 0
          },
          {
            name: "Objeto",
            index: 1
          },
          {
            name: "Condição Geral",
            index: 2
          },

          {
            name: "Condições Especiais",
            index: 3
          },
          {
            name: "Condições Particulares",
            index: 4
          },
          {
            name: "Documentos",
            index: 5
          }
        ];
      else
        return [
          {
            name: "Detalhes da modalidade",
            index: 0
          }
        ];
    }
  },
  props: {
    objeto: Object,
    condicoesEspeciais: Object,
    CondicoesGerais: Object,
    detalhes: Object,
    coberturas: Array,
    isComplementaryModality: Boolean,
    mainModalityId: {
      type: Number,
      required: false
    },
    permissoesModalidade: {
      type: Array,
      default: () => []
    }
  },
  components: {
    JoditEditor,
    HorizontalTabsComponents,
    "modality-details": DetalhesModalidade,
    DocumentosObrigatorios,
    "condicoes-particulares": CondicoesParticulares,
    ContractualTerm
  },
  methods: {
    ...mapActions("seguradora-module", [
      "saveModalityObject",
      "saveDetailModality"
    ]),

    setJSONTagsAll(value) {
      this.allTags = value;
    },
    setValueTerm(value) {
      this.objeto.Value = value;
    },
    setJSONTagsAllCondicoesGerais(value) {
      this.allTagsCondicoesGerais = value;
    },
    setValueTermCondicoesGerais(value) {
      this.CondicoesGerais.Value = value;
    },

    setJSONTagsAllCondicoesEspeciais(value) {
      this.allTagsCondicoesEspeciais = value;
    },
    setValueTermCondicoesEspeciais(value) {
      this.condicoesEspeciais.Value = value;
    },

    async saveObject(isCondicoes) {
      let data = {};
      if (isCondicoes === 3) {
        data = {
          RefIntegration: this.condicoesEspeciais.RefIntegration,
          Value: this.condicoesEspeciais.Value,
          JSONTag:
            this.condicoesEspeciais.Tags && this.condicoesEspeciais.Tags.length
              ? JSON.stringify(this.condicoesEspeciais.Tags)
              : "",
          ContractualTermTypeId: 2,
          EnableToProposal: this.condicoesEspeciais.EnableToProposal
        };
      } else if (isCondicoes === 1) {
        data = {
          RefIntegration: this.CondicoesGerais.RefIntegration,
          Value: this.CondicoesGerais.Value,
          JSONTag: this.CondicoesGerais.JSONTag,
          ContractualTermTypeId: 1,
          EnableToProposal: this.CondicoesGerais.EnableToProposal
        };
      } else {
        data = {
          RefIntegration: this.objeto.RefIntegration,
          Value: this.objeto.Value,
          JSONTag: this.objeto.JSONTag,
          ContractualTermTypeId: 3,
          EnableToProposal: true
        };
      }
      this.loadingButton = true;
      this.$vs.loading();
      await this.$onpoint.loading(async () => {
        return await this.saveModalityObject({ ...data, Ref1Id: this.modalityId })
          .then(res => {
            this.loadingButton = false;
            this.$vs.loading.close();
            this.$emit("modalitySaved", res);
            this.$onpoint.successModal(
              `Dados ${
                data.UniqueId ? "atualizados" : "inseridos"
              } com sucesso!`
            );
          })
          .catch(ex => {
            this.loadingButton = false;
            this.$vs.loading.close();
            this.$onpoint.errorModal(
              ex.response.data.Errors.length
                ? ex.response.data.Errors
                : ex.response.data.Response
            );
          });
      });
    },

    updateDetails(details) {
      this.$emit("atualizar-Detalhes", details);
    }
  },
  mounted() {
    this.allowedTextArea = compareCompanyUtils("companyName", "sombrero");
  },
};
</script>

<style lang="scss">
.vs-popup {
  width: 600px !important;
}

.add-tag i {
  font-size: 2rem;
}

.label-input {
  padding-left: 5px;
  font-size: 0.85rem;
  color: rgba(0, 0, 0, 0.7);
}
</style>
