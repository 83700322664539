<template>
  <section v-loading="onCardLoad">
    <div class="m-1 mr-4 mb-10 px-0 esteiras">
      <breadcrumb
        :title="currentPipe.name || 'Pipes'"
        :actualPage="currentPipe.name || 'Pipes'"
        previousPage="kanban"
        previousPageTitle="Pipes"
      />
      <div
        class="flex mt-8 mb-6 gap-8 "
      >
        <filtro ref="filtro" :isFilter="filtro">
          <div class="pt-2 pb-6 px-4">
            <div class="flex flex-col md:flex-row">
              <vs-input
                size="large"
                label-placeholder="Filtro por palava chave"
                class="w-full md:w-5/5 md:pr-3"
                v-model="filterValue.meta"
              />
            </div>
            <div class="flex flex-row-reverse mt-4">
              <vs-button
                color="primary"
                type="filled"
                class="ml-4"
                @click.native="loadSteps(1)"
                >Aplicar filtro</vs-button
              >
              <vs-button
                color="primary"
                type="filled"
                icon="clear"
                @click.native="clearFilter(1)"
                >Limpar filtro</vs-button
              >
            </div>
          </div>
        </filtro>

        <FiltroPrioridades ref="priorityFilter" :isPriorityFilter="priorityFilter">
          <div class="pt-2 pb-6 px-4">
            <vs-row class="center p-0" vs-type="flex" vs-justify="space-around">
              <div v-for="(priority, index) of cardPriorityList" :key="index" class="m-4">
                <vs-checkbox v-model="priorityIds" :vs-value="priority.Id">{{ priority.Name }}</vs-checkbox>
              </div>
            </vs-row>

            <div class="flex flex-row-reverse mt-4">
              <vs-button
                color="primary"
                type="filled"
                class="ml-4"
                @click.native="loadSteps(2)"
                >Aplicar filtro</vs-button
              >
              <vs-button
                color="primary"
                type="filled"
                icon="clear"
                @click.native="clearFilter(1)"
                >Limpar filtro</vs-button
              >
            </div>
          </div>
        </FiltroPrioridades>
      </div>
    </div>

    <div class="container-kamban">
      <div class="flex">
        <card-data-view
          v-if="renderComponent"
          @reload="reloadCardDataView"
          :data="cardData"
          :title="cardDataViewTitle"
          :type="cardDataViewType"
          :stepName="(itemSelected || {}).stepName"
          :currentPipe="currentPipe"
          :cardId="cardId"
          :cardMovementUser="cardMovementUser"
          :metasValidationClickedCard="metasValidationClickedCard"
          :ownerCard="ownerCard"
          :ownerList="ownerList"
          :cardPriorityList="cardPriorityList"
          :priorityDataClickedCard="priorityDataClickedCard"
          @closeCardDataView="hideCardDataView"
          @refreshPipe="refreshPipe"
          :permissoesCocorretagemMapfre="permissoesCocorretagemMapfre"
          :permissoesResseguroMapfre="permissoesResseguroMapfre"
          :permissoesCosseguroMapfre="permissoesCosseguroMapfre"
          :cardInfos="itemSelected"
          :justificationParameters="justificationParameters"
        />
        <div
          class="w-full max-w-sm px-3 step"
          v-for="step in objecSteps"
          :key="step.id"
        >
          <p
            class="step-name mb-3 text-lg text-gray-700 flex font-semibold tracking-wide"
          >
            {{ step.name }}
            <vx-tooltip :text="step.description" position="right">
              <i class="onpoint-info px-2 py-2" style="color:rgba(var(--vs-warning),1);"></i>
            </vx-tooltip>
          </p>
          <draggable
            tag="ul"
            group="all-users"
            style="min-height: 90%"
            class="draggable-list"
            ghost-class="moving-card"
            filter=".action-button"
            :move="checkCanMove"
            :data-stepid="step.id"
            :data-stepType="step.stepType"
            :draggable="hasPermission ? '.pipe-card' : false"
            @change="onDragChange"
            @end="onDragEnd"
            :list="step.cards"
            :animation="200"
          >
            <template v-for="stepCard in step.cards">
              <card
                v-if="!stepCard.hasOwnProperty('filtered') || stepCard.filtered"
                @onCardClick="onCardClick"
                class="pipe-card"
                :data="stepCard.cardData"
                :object="stepCard"
                :stepName="step.name"
                :key="stepCard.id"
              ></card>
            </template>
            <a class="btn-see-more">
              <b>
                <p
                  v-on:click="
                    seeMoreCards(
                      currentPipe.id,
                      step.id,
                      step.cards.length,
                      filterValue.meta
                    )
                  "
                  v-if="step.cards.length < step.countCards"
                >
                  Ver mais
                </p>
                {{ step.cards.length }} / {{ step.countCards }}</b
              >
            </a>
          </draggable>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { kanbanProvider } from "@/providers/kanban.provider";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

import Draggable from "vuedraggable";
import Card from "./Card.vue";
import CardDataView from "./CardDataView.vue";
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import Filtro from "@/components/Filtro/Filtro";
import FiltroPrioridades from "@/components/filtro-prioridades/index.vue";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import * as Utils from "@/utils/utils";

import compareCompanyUtils from "@/utils/compareCompanyUtils.js";

import mapfreProvider from "@/providers/mapfre-provider.js";
import * as Permissoes from "@/utils/permissions.js"

export default {
  components: {
    Draggable,
    CardDataView,
    Card,
    Breadcrumb,
    Filtro,
    FiltroPrioridades
  },
  data() {
    return {
      itemSelected: null,
      cardTypes: [],
      cardData: {},
      cardDataViewTitle: "",
      filtro: false,
      cardDataViewType: "",
      cardId: "",
      cardDraggedData: undefined,
      renderComponent: true,
      filterValue: {
        meta: "",
        cardType: "",
        startDate: "",
        endDate: ""
      },
      objecSteps: {},
      objecSteps2: {},

      perfilHelpers: PerfilHelpers,
      onCardLoad: false,
      isEssor: false,
      metasValidationClickedCard: [],
      permissoesCocorretagemMapfre: [],
      permissoesResseguroMapfre: [],
      permissoesCosseguroMapfre: [],
      cardMovementUser: {},
      ownerCard: null,
      ownerList: [],
      cardPriorityList: [],
      priorityDataClickedCard: {},
      priorityFilter: false,
      priorityIds: [],
      justificationParameters: {},
    };
  },
  computed: {
    ...mapGetters("pipe-module", {
      currentPipe: "pipe",
      pipeSteps: "steps",
      pipeSteps2: "steps2",
      pipes: "pipes"
    }),

    hasPermission() {
      return this.perfilHelpers.checkPermissionKanban(this.currentPipe.id);
    },

    stepSolicitacaoInformacoes: {
      get() {
        if (this.pipeSteps) {
          const stepSolicitacaoInformacoesContent = this.pipeSteps.find(
            step => step.name === "Solicitação de Informações"
          );
          return stepSolicitacaoInformacoesContent || null;
        } else return [];
      }
    },

    pipeCadastroInfos: {
      get() {
        if (this.pipes) {
          const pipeCadastro = this.pipes.find(
            pipe => pipe.name === "Cadastro"
          );
          return pipeCadastro || null;
        } else {
          return null;
        }
      }
    },

    pipeCreditoInfos: {
      get() {
        if (this.pipes) {
          const pipeCadastro = this.pipes.find(pipe => pipe.name == "Crédito");
          return pipeCadastro || null;
        } else {
          return null;
        }
      }
    },

    pipeNomeacaoInfos: {
      get() {
        if (this.pipes) {
          const pipeCadastro = this.pipes.find(
            pipe => pipe.name === "Nomeação"
          );
          return pipeCadastro || null;
        } else {
          return null;
        }
      }
    },

    pipePEPInfos: {
      get() {
        if (this.pipes) {
          const pipeCadastro = this.pipes.find(pipe => pipe.name === "PEP");
          return pipeCadastro || null;
        } else {
          return null;
        }
      }
    },

    pipeResseguroInfos: {
      get() {
        if (this.pipes) {
          const pipeCadastro = this.pipes.find(
            pipe => pipe.name === "Resseguro"
          );
          return pipeCadastro || null;
        } else {
          return null;
        }
      }
    },

    pipeSubscricaoInfos: {
      get() {
        if (this.pipes) {
          const pipeCadastro = this.pipes.find(
            pipe => pipe.name === "Subscrição"
          );
          return pipeCadastro || null;
        } else {
          return null;
        }
      }
    }
  },
  methods: {
    ...mapActions("pipe-module", ["getPipeSteps", "getStepCards", "getPipes", "getPipeStepsByPriority"]),
    ...mapMutations("pipe-module", [
      "updateShowCardDataView",
      "updateSteps",
      "updateCards"
    ]),

    clearFilter(filterType) {
      this.filterValue = {};
      this.priorityIds = [];
      this.loadSteps(filterType);
    },

    hideCardDataView() {
      this.updateShowCardDataView(false);
      this.renderComponent = false;
      this.refreshPipe(1);
    },

    refreshPipe(filterType) {
      this.filterValue = {};
      this.priorityIds = [];
      this.loadSteps(filterType);
    },

    reloadCardDataView() {
      !this.itemSelected;
      this.onCardClick(this.itemSelected);
    },

    async onCardClick(card) {
      this.itemSelected = card;

      await this.$onpoint.loadingMultipleRequests([
          () => this.getDataForCardDataView(this.itemSelected),
          () => this.getDataForResponsibleUser(this.itemSelected.cardId),
          () => this.obterResponsavel(this.itemSelected.cardId),
          () => this.obterListaResponsavel(this.itemSelected.pipeId),
          () => this.obterParametrosJustificativa(this.itemSelected.cardId)
      ])
      .catch (error => {
        // console.log("Error handling in onCardClick", error);
        this.$onpoint.errorModal(error);
      })
    },

    async onCardLink() {
      if (this.$route.params.pipeid) {
        this.onCardLoad = true;
        let card = {
          cardId: this.$route.params.pipeid,
          cardRef: this.$route.params.pipeid,
          cardType: "PROPOSTA"
        };
        this.itemSelected = card;
        await this.$onpoint.loading(async () => {
          return await kanbanProvider
            .getCardDataView(card)
            .then(response => {
              this.cardData = response.data;
              this.cardDataViewTitle = response.title;
              this.cardDataViewType = response.type;
              this.renderComponent = true;
              this.updateShowCardDataView(true);
              this.onCardLoad = false;
            })
            .catch(ex => {
              this.$onpoint.errorModal(ex);
            });
        });
      }
    },

    checkCanMove(args) {
      let canMove = false;
      if (
        (((args.from || {}).dataset || {}).steptype || 100) < 90 &&
        this.hasPermission
      )
        canMove = true;
      return canMove;
    },

    async onDragChange(evt) {
      if (evt.added) {
        await this.$vs.dialog({
          type: "confirm",
          title: "Atenção",
          text: "Realmente deseja movimentar este card?",
          acceptText: "Confirmar",
          accept: () => {
            this.$onpoint.loading(async () => {
              return await kanbanProvider
                .moveCard(
                  evt.added.element.cardId,
                  this.cardDraggedData.fromStepId,
                  this.cardDraggedData.toStepId
                )
                .then(async data => {
                  this.$vs.notify({
                    title: "Sucesso",
                    text: "Card movido com sucesso",
                    color: "success"
                  });

                  if (
                    this.currentPipe.id === this.pipeCreditoInfos.id ||
                    this.currentPipe.id === this.pipeResseguroInfos ||
                    this.currentPipe.id === this.pipeSubscricaoInfos.id
                  ) {
                    if (
                      this.cardDraggedData.toStepId ===
                      this.stepSolicitacaoInformacoes.id
                    ) {
                      let docs = {
                        ProposalUniqueId: this.getProposalNumber(data.cardId),
                      };
                      await kanbanProvider.KanbanSendEmailRequestDocumentToBroker(
                        docs
                      );
                    }
                  }
                })
                .catch(() => {
                  this.$vs.notify({
                    title: "Erro",
                    text: "Não foi possível mover esse card",
                    color: "danger"
                  });

                  this.objecSteps = Object.assign([], this.pipeSteps);
                })
                .then(() => {
                  if (!this.filterValue || !this.filterValue.meta)
                    this.filterValue = {};

                  this.loadSteps(1);
                });
            });
          },
          cancelText: "Cancelar",
          cancel: () => {
            this.filterValue = {};
            this.loadSteps(1);
          }
        });
      }
    },

    onDragEnd(args) {
      this.cardDraggedData = {
        fromStepId: args.from.dataset.stepid,
        toStepId: args.to.dataset.stepid
      };
    },

    removeMaskFromCpfCnpj(param) {
      if (param == undefined) {
        param = null;
        return param;
      }
      let valoresDoFiltroTratado = param.replace(".", "");
      valoresDoFiltroTratado = valoresDoFiltroTratado.replace(".", "");
      valoresDoFiltroTratado = valoresDoFiltroTratado.replace("/", "");
      valoresDoFiltroTratado = valoresDoFiltroTratado.replace("-", "");
      return valoresDoFiltroTratado;
    },

    async seeMoreCards(pipeId, stepId, skip, filterCardsValues) {
      if (filterCardsValues == "" || filterCardsValues == undefined) {
        await this.$onpoint.loading(async () => {
          return await this.getStepCards({
            pipeId,
            stepId,
            skip,
            filterCardsValues: null
          }).then(resp => {});
        });
      } else {
        await this.$onpoint.loading(async () => {
          filterCardsValues = this.removeMaskFromCpfCnpj(filterCardsValues);
          return await this.getStepCards({
            pipeId,
            stepId,
            skip,
            filterCardsValues
          }).then(resp => {});
        });
      }
    },

    async loadSteps(filterType) {
      let filterCardsValues = this.filterValue.meta
          ? this.removeMaskFromCpfCnpj(this.filterValue.meta)
          : null;
      const pipeId = this.$route.params.token;
      const ids = this.priorityIds;

      if (filterType === 2 && ids.length === 0) {
        this.$onpoint.errorModal("Escolha ao menos uma prioridade!");
        return
      }

      this.filtro = !this.filtro;
      this.priorityFilter = !this.priorityFilter;

      await this.$onpoint.loading(async () => {
        if(filterType === 1) {

          return await this.getPipeSteps({ pipeId, filterCardsValues })
            .then(() => {
              let option = [];
              this.pipeSteps.map(steps =>
                steps.cards.map(card => {
                  option.push({
                    value: card.cardType,
                    name: (((card.cardData.body || {}).metas || [])[0] || {})
                      .description
                  });
                })
              );
              this.cardTypes = Object.values(
                option.reduce((c, e) => {
                  if (!c[e.value]) c[e.value] = e;
                  return c;
                }, {})
              );

              this.objecSteps = Object.assign([], this.pipeSteps);
              this.$vs.loading.close();
            })
            .catch(error => {
              this.$vs.loading.close();
            });

        } else if (filterType === 2) {

          return await this.getPipeStepsByPriority({pipeId, ids})
            .then(() => {
              let option = [];
              this.pipeSteps.map(steps =>
                steps.cards.map(card => {
                  option.push({
                    value: card.cardType,
                    name: (((card.cardData.body || {}).metas || [])[0] || {})
                      .description
                  });
                })
              );
              this.cardTypes = Object.values(
                option.reduce((c, e) => {
                  if (!c[e.value]) c[e.value] = e;
                  return c;
                }, {})
              );

              this.objecSteps = Object.assign([], this.pipeSteps);
              this.$vs.loading.close();
            })
            .catch(error => {
              this.$vs.loading.close();
            });
        }
      });
    },

    getProposalNumber(currentCardId) {
      const currentCardInfos = this.stepSolicitacaoInformacoes.cards.find(
        card => card.cardId === currentCardId
      );

      let proposalUniqueId = currentCardInfos.cardRef;

      return proposalUniqueId;
    },

    getCardClickedMetaData(cardID) {
      let currentCard = {};

      for (let step of this.pipeSteps) {
        let card = step.cards.find(card => card.cardId === cardID)
        if (card) {
          currentCard = card;
          break
        }
      };

      this.metasValidationClickedCard = currentCard.cardData.body.metasValidation;
    },

    async obterPermissoes() {
      this.permissoesCocorretagemMapfre = [];
      this.permissoesResseguroMapfre = [];
      this.permissoesCosseguroMapfre = [];
      try {
        this.permissoesCocorretagemMapfre = await mapfreProvider.obterPermissoesModulo(Permissoes.Modulo.COCORRETAGEM);
        this.permissoesResseguroMapfre = await mapfreProvider.obterPermissoesModulo(Permissoes.Modulo.RESSEGURO);
        this.permissoesCosseguroMapfre = await mapfreProvider.obterPermissoesModulo(Permissoes.Modulo.COSSEGURO);
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    },

    async getDataForCardDataView(card){
      try {
        let response = await kanbanProvider.getCardDataView(card)
        this.cardData = response.data;
        this.cardDataViewTitle = response.title;
        this.cardDataViewType = response.type;
        this.renderComponent = true;
        this.cardId = card.cardId;
        this.updateShowCardDataView(true);
        this.getPriorityDataFromClickedCard(this.cardId)

        if(this.isEssor) {
          this.getCardClickedMetaData(this.cardId)
        }
      } catch (error) {
        this.$onpoint.errorModal(error);
      }
    },

    async getDataForResponsibleUser(cardId) {
      try {
        const response = await kanbanProvider.getResponsibleUserForCardMovement(cardId)

        this.cardMovementUser = response
      } catch (error) {
        this.$onpoint.errorModal(error);
      }
    },

    getPriorityDataFromClickedCard(cardID) {
      let currentCardPriorityData = {
        Id: null,
        Name: "",
        Level: null,
        PipeId: "",
        CardId: "",
      };

      for (let step of this.pipeSteps) {
        let card = step.cards.find(card => card.cardId === cardID)
        if (card) {
          currentCardPriorityData.Id = card.priorityId;
          currentCardPriorityData.Name = card.priorityName;
          currentCardPriorityData.Level = card.priorityLevel;
          currentCardPriorityData.PipeId = card.pipeId;
          currentCardPriorityData.CardId = card.cardId;
          break
        }
      };

      this.priorityDataClickedCard = currentCardPriorityData;
    },

    async getCardPriorityList() {
      try {
        const response = await kanbanProvider.getPrioritiesKanbanCard()

        this.cardPriorityList = response
      } catch (error) {
        this.$onpoint.errorModal(error);
      }
    },

    async obterResponsavel(cardId) {
      await kanbanProvider.kanbanGetCardOwner(cardId).then(data => {
        if (data) {
          this.ownerCard = data;
        } else {
          this.ownerCard = "";
        }
      });
    },

    async obterListaResponsavel(pipeId) {
      await kanbanProvider
        .kanbanGetCardOwnerList(pipeId)
        .then(data => {
          this.ownerList = data;
        });
    },

    async obterParametrosJustificativa(cardId){
      try {
        const response = await kanbanProvider.getCardJustificationParameters(cardId)
        this.justificationParameters = response
      } catch (error) {
        this.$onpoint.errorModal(error);
      }
    }
  },
  async created() {
    this.objecSteps = {};

    await this.$onpoint.loadingMultipleRequests([
          () => this.onCardLink(),
          () => this.loadSteps(1),
          () => this.getCardPriorityList(),
      ])
      .catch (error => {
        this.$onpoint.errorModal(error);
      })
  },
  beforeCreate() {
    let pipe = this.$store.getters["pipe-module/pipe"];
    if (!pipe || !pipe.id || pipe.id != this.$route.params.token)
      this.$onpoint.loading(() => {
        return this.$store.dispatch(
          "pipe-module/getPipe",
          this.$route.params.token
        );
      });
  },
  async mounted() {
    this.$appInsights.trackPageView({
      name: "kanban-pipe",
      url: window.location.href
    });

    await this.$onpoint.loading(async () => {
      await this.getPipes().catch(err => {
        this.$vs.notify({
          title: "Erro",
          text: "Não foi possível autenticar no serviço de Kanban",
          color: "danger"
        });
      });

      await this.obterPermissoes()
    });

    this.isEssor = compareCompanyUtils("companyName", "essor");
  }
};
</script>

<style lang="scss">
.container-kamban {
  display: block;
  position: relative;
  overflow-x: auto;
}

.step-name {
  cursor: pointer;

  .material-icons {
    color: rgba(var(--vs-warning), 1);
    display: block;
    font-size: 0.8em;
  }
}

.vs-card--content {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.vs-card--header {
  padding-bottom: 5px;
}
.card-data-view .vs-sidebar--items {
  background-color: #fff !important;
  overflow: auto !important;
}
.card-data-view .vs-sidebar--header {
  background-color: #fff !important;
}
.card-data-view .vs-sidebar--footer {
  background-color: #fff !important;
}

.btn-see-more {
  display: block;
  text-align: center;
  text-decoration: none;
  p {
    cursor: pointer;
  }
}

.step {
  background-color: #f0f0f0;
  margin-left: 20px;
  padding-top: 10px;
  border-radius: 10px;
  min-width: 240px !important;
  overflow: auto;

  @media (min-height: 800px) {
    height: 75vh;
  }

  @media (max-height: 799px) and (min-height: 700px) {
    height: 72vh;
  }

  @media (max-height: 699px) and (min-height: 600px) {
    height: 65vh;
  }

  @media (max-height: 599px) and (min-height: 480px) {
    height: 60vh;
  }

  @media (max-height: 479px) and (min-height: 380px) {
    height: 50vh;
  }

  @media (max-height: 379px) {
    height: 40vh;
  }
}

.step:first-of-type {
  margin-left: 0px !important;
}

.step::-webkit-scrollbar {
  width: 10px !important;
}

.step::-webkit-scrollbar-thumb {
  background: #c1c1c1;
}
</style>
