<template>
  <div class="px-4 pt-5 pb-4 ccg">
    <template v-if="loadCompleted">
      <!-- Upload manual -->
      <div class="upload_ccg ccg-alert" v-if="ccg.TemDocumentoCustomizado">
        <el-alert class="ccg-alert" type="warning" :closable="false">
          Este tomador possui template customizado no CCG.
        </el-alert>
      </div>
      <base64Upload
        v-if="uploadModal"
        :modalUpload="'CCG'"
        :documentoName="'CCG'"
        :uniqueId="tomador.UniqueId"
        @callBackUpload="callbackUploadManual"
        @closeModal="fecharPopupUploadManual"
      />

      <!-- Alertas -->
      <div class="ccg-alert">
        <el-alert class="ccg-alert" :type="tipoAvisoStatus" :closable="false">
          {{ ccg.Contrato ? ccg.Contrato.DescricaoStatus : "" }}
        </el-alert>
      </div>

      <!-- Botão histórico de alterações -->
      <VisualizarAlteracoes class="view-changes" :contrato="ccg.Contrato" />

      <!-- Representantes Legais -->
      <div
        class="representatives-alerts"
        v-if="
          tomador.Id > 0 &&
            (representantesLegais || []).length == 0 &&
            deveExibirAdicionarParteQuandoParteVazia(1)
        "
      >
        <h4 class="representatives-alerts-title font-bold text-primary">
          Representantes legais do tomador
        </h4>

        <div class="ccg-alert representatives-alerts-content">
          <el-alert type="warning" :closable="false">
            Esse tomador não possui representantes legais. Para adicionar um
            representante, clique no botão de "Adicionar representante".
          </el-alert>
        </div>

        <div class="representatives-alerts-add">
          <el-button
            type="primary"
            class="representatives-alerts-add-btn"
            @click="exibirAdcPopupQuandoRepFiadorVazio(1)"
            :disabled="ccg.Contrato.StatusId !== 1"
          >
            <i class="el-icon-user"></i>
            Adicionar representante
          </el-button>
        </div>
      </div>

      <TabelaPartes
        v-else
        :tipoParteId="1"
        :partes="representantesLegais"
        :permissoes="permissoes"
        :contratoEspecificacoes="ccg.Contrato"
        @adicionarParteSelecionado="exibirAdcPopupRepFiadorPF"
        @edicaoDadosSelecionado="exibirEdicaoDados"
        @reenviarEmailSelecionado="lidarComReenviarEmailIndividual"
        @copiarLinkAssinaturaSelecionado="lidarComCopiarLinkAssinatura"
        @excluirRepresentanteSelecionado="exibirExcluirPartePopup"
      >
        <template v-slot:header>
          Representantes legais do tomador
        </template>
        <template v-slot:alert>
          Erro
        </template>
        <template v-slot:button>
          Adicionar representante
        </template>
      </TabelaPartes>

      <!-- Fiadores PF -->
      <div
        class="representatives-alerts"
        v-if="
          tomador.Id > 0 &&
            (fiadoresPF || []).length === 0 &&
            deveExibirAdicionarParteQuandoParteVazia(3)
        "
      >
        <h4 class="representatives-alerts-title font-bold text-primary">
          Fiadores do tomador (Pessoas Físicas)
        </h4>

        <div class="ccg-alert representatives-alerts-content">
          <el-alert type="warning" :closable="false">
            Esse tomador não possui fiadores PF. Para adicionar um fiador,
            clique no botão de "Adicionar Fiador PF".
          </el-alert>
        </div>

        <div class="representatives-alerts-add">
          <el-button
            type="primary"
            class="representatives-alerts-add-btn"
            @click="exibirAdcPopupQuandoRepFiadorVazio(3)"
            :disabled="ccg.Contrato.StatusId !== 1"
          >
            <i class="el-icon-user"></i>
            Adicionar fiador PF
          </el-button>
        </div>
      </div>

      <TabelaPartes
        v-else
        :tipoParteId="3"
        :partes="fiadoresPF"
        :permissoes="permissoes"
        :contratoEspecificacoes="ccg.Contrato"
        :conjugeHabilitado="ccg.UsaConjuge"
        @adicionarParteSelecionado="exibirAdcPopupRepFiadorPF"
        @edicaoDadosSelecionado="exibirEdicaoDados"
        @adicionarConjugeSelecionado="exibirAdcPopupConjuge"
        @reenviarEmailSelecionado="lidarComReenviarEmailIndividual"
        @copiarLinkAssinaturaSelecionado="lidarComCopiarLinkAssinatura"
        @excluirRepresentanteSelecionado="exibirExcluirPartePopup"
      >
        <template v-slot:header>
          Fiadores do tomador (Pessoas Físicas)
        </template>
        <template v-slot:alert>
          Erro
        </template>
        <template v-slot:button>
          Adicionar fiador
        </template>
      </TabelaPartes>

      <!-- Fiadores PJ -->
      <div
        class="representatives-alerts"
        v-if="
          tomador.Id > 0 &&
            (fiadoresPJ || []).length == 0 &&
            deveExibirAdicionarParteQuandoParteVazia(2)
        "
      >
        <h4 class="representatives-alerts-title font-bold text-primary">
          Fiadores do tomador (Pessoas Jurídicas)
        </h4>

        <div class="ccg-alert representatives-alerts-content">
          <el-alert type="warning" :closable="false">
            Esse tomador não possui fiadores PJ. Para adicionar um fiador,
            clique no botão de "Adicionar Fiador PJ".
          </el-alert>
        </div>

        <div class="representatives-alerts-add">
          <el-button
            type="primary"
            class="representatives-alerts-add-btn"
            @click="exibirAdcPopupQuandoRepFiadorVazio(2)"
            :disabled="ccg.Contrato.StatusId !== 1"
          >
            <i class="el-icon-user"></i>
            Adicionar fiador PJ
          </el-button>
        </div>
      </div>

      <TabelaPartes
        v-else
        :tipoParteId="2"
        :partes="fiadoresPJ"
        :permissoes="permissoes"
        :contratoEspecificacoes="ccg.Contrato"
        @adicionarParteSelecionado="exibirAdcPopupFiadorPJ"
        @edicaoDadosSelecionado="exibirEdicaoDados"
        @reenviarEmailSelecionado="lidarComReenviarEmailIndividual"
        @copiarLinkAssinaturaSelecionado="lidarComCopiarLinkAssinatura"
        @excluirRepresentanteSelecionado="exibirExcluirPartePopup"
      >
        <template v-slot:header>
          Fiadores do tomador (Pessoas Jurídicas)
        </template>
        <template v-slot:alert>
          Esse tomador não possui fiadores PJ. Para adicionar um fiador, clique
          no botão de "Adicionar Fiador PJ".
        </template>
        <template v-slot:button>
          Adicionar fiador
        </template>
      </TabelaPartes>

      <!-- Testemunhas - desabilitado até segunda ordem -->
      <div v-if="ccg.UsaTestemunha">
        <div
          class="representatives-alerts"
          v-if="
            tomador.Id > 0 &&
              (testemunhas || []).length == 0 &&
              deveExibirAdicionarParteQuandoParteVazia(4)
          "
        >
          <h4 class="representatives-alerts-title font-bold text-primary">
            Testemunhas
          </h4>

          <div class="ccg-alert representatives-alerts-content">
            <el-alert type="warning" :closable="false">
              Esse tomador não possui testemunhas. Para adicionar uma
              testemunha, clique no botão de "Adicionar testemunha".
            </el-alert>
          </div>

          <div class="representatives-alerts-add">
            <el-button
              type="primary"
              class="representatives-alerts-add-btn"
              @click="exibirAdcPopupQuandoRepFiadorVazio(4)"
              :disabled="ccg.Contrato.StatusId !== 1"
            >
              <i class="el-icon-user"></i>
              Adicionar testemunha
            </el-button>
          </div>
        </div>

        <TabelaPartes
          v-else
          :tipoParteId="4"
          :partes="testemunhas"
          :permissoes="permissoes"
          :contratoEspecificacoes="ccg.Contrato"
          @adicionarParteSelecionado="exibirAdcPopupRepFiadorPF"
          @edicaoDadosSelecionado="exibirEdicaoDados"
          @reenviarEmailSelecionado="lidarComReenviarEmailIndividual"
          @copiarLinkAssinaturaSelecionado="lidarComCopiarLinkAssinatura"
          @excluirRepresentanteSelecionado="exibirExcluirPartePopup"
        >
          <template v-slot:header>
            Testemunhas
          </template>
          <template v-slot:alert>
            Erro
          </template>
          <template v-slot:button>
            Adicionar testemunha
          </template>
        </TabelaPartes>
      </div>

      <div
        class="representatives-alerts"
        v-if="this.ccg.RegraBypass"
      >
        <h4 class="representatives-alerts-title font-bold text-primary">
          Assinatura da seguradora
        </h4>

        <div class="ccg-alert mt-5" >
          <el-alert class="ccg-alert" :type="tipoAvisoStatus" :closable="false">
            {{  this.ccg.MsgBypass || "Esta seguradora adota uma política de bypass, o que significa que a regularização do CCG é concluída assim que 100% das partes do Tomador e Fiadores assinam na plataforma. Nesse caso, não é necessário a apresentação dos representantes da seguradora."}}
          </el-alert>
        </div>
      </div>

      <TabelaPartes
        v-else
        :tipoParteId="6"
        :partes="Seguradoras"
        :contratoEspecificacoes="ccg.Contrato"

      >
        <template v-slot:header>
          Assinatura da seguradora
        </template>
        <template v-slot:alert>
          Erro
        </template>
      </TabelaPartes>

      <!-- Pop ups -->

      <!-- Alerta quando parte já cadastrada -->
      <el-dialog
        :visible.sync="popupAlertaParteCadastrada"
        width="32%"
        :before-close="fecharPopupAlertaParteCadastrada"
        class="registered-part-popup"
      >
        <div class="registered-part-popup-content">
          <div class="registered-part-popup-content-header">
            <img
              src="@/assets/images/icons/ccg-icons/add-icon-ccg.svg"
              alt="Ícone de link individual de assinatura"
              width="24"
              height="24"
            />
            <h3>
              {{
                `Adicionar ${representanteOuFiador.TipoParteName.toLowerCase()}`
              }}
            </h3>
          </div>

          <div class="registered-part-popup-content-body">
            <el-alert
              type="warning"
              center
              :closable="false"
              class="registered-part-popup-content-body-alert"
            >
              CPF já está cadastrado para este tomador.
              <br />
              <br />
              Caso os novos dados divergirem, <br />essa ação irá alterar os
              dados já cadastrados!
              <br />
              <br />
              Deseja continuar?</el-alert
            >
          </div>
        </div>

        <span slot="footer" class="dialog-footer registered-part-popup-footer">
          <el-button type="primary" @click="lidarComVariacoesAdicionarParte"
            >Sim, continuar</el-button
          >
          <el-button @click="fecharPopupAlertaParteCadastrada"
            >Cancelar</el-button
          >
        </span>
      </el-dialog>

      <!-- Representante e Fiador PF e Testemunha -->
      <!-- Adicionar | Editar -->
      <el-dialog
        width="28%"
        :visible.sync="popupAdcEditRepresentanteFiadorPF"
        class="add-edit-rep-guarantor-pf-popup"
      >
        <ValidationObserver ref="observerRepresentante" v-slot="{ invalid }">
          <div class="add-edit-rep-guarantor-pf-popup-content">
            <div class="add-edit-rep-guarantor-pf-popup-content-header">
              <img
                src="@/assets/images/icons/ccg-icons/add-icon-ccg.svg"
                alt="Ícone de adição"
                width="24"
                height="24"
                v-if="!editarRepresentanteFiadorPF"
              />
              <img
                src="@/assets/images/icons/ccg-icons/edit-icon-ccg.svg"
                alt="Ícone de edição"
                width="24"
                height="24"
                v-else
              />
              <h3>
                {{
                  editarRepresentanteFiadorPF
                    ? `Editar ${representanteOuFiador.TipoParteName.toLowerCase()}`
                    : `Adicionar ${representanteOuFiador.TipoParteName.toLowerCase()}`
                }}
              </h3>
            </div>

            <div class="add-edit-rep-guarantor-pf-popup-content-body">
              <div class="add-edit-rep-guarantor-pf-popup-content-body-input">
                <ValidationProvider
                  name="CPF"
                  :rules="editarRepresentanteFiadorPF ? 'required' : 'required|cpfValidator'"
                  v-slot="{ errors }"
                >
                  <label for="representative-cpf">CPF DO(A) {{representanteOuFiador.TipoParteName.toUpperCase()}}</label>
                  <el-input
                    v-if='editarRepresentanteFiadorPF'
                    id="representative-cpf"
                    size="large"
                    class="w-full"
                    label="CPF"
                    :disabled="editarRepresentanteFiadorPF ? true : false"
                    v-model="representanteOuFiador.CPFMask "
                  ></el-input>
                  <el-input
                    v-else
                    id="representative-cpf"
                    size="large"
                    class="w-full"
                    label="CPF"
                    :disabled="editarRepresentanteFiadorPF ? true : false"
                    v-model="representanteOuFiador.CPF"
                    v-mask="'###.###.###-##'"
                  ></el-input>

                  <span class="text-danger pl-3" v-show="errors[0]">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>

              <div class="add-edit-rep-guarantor-pf-popup-content-body-input">
                <ValidationProvider
                  name="Nome"
                  rules="required|validatorName"
                  v-slot="{ errors }"
                >
                  <label for="representative-name">NOME DO(A) {{representanteOuFiador.TipoParteName.toUpperCase()}}</label>
                  <el-input
                    id="representative-name"
                    size="large"
                    class="w-full"
                    label="Nome"
                    v-model="representanteOuFiador.Nome"
                  ></el-input>

                  <span class="text-danger pl-3" v-show="errors[0]">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>

              <div class="add-edit-rep-guarantor-pf-popup-content-body-input">
                <ValidationProvider
                  name="Email"
                  rules="email"
                  v-slot="{ errors }"
                >
                  <label for="representative-email"
                    >E-MAIL DO(A) {{representanteOuFiador.TipoParteName.toUpperCase()}}</label
                  >
                  <el-input
                    type="email"
                    size="large"
                    class="w-full"
                    label="Email"
                    v-model="representanteOuFiador.Email"
                  ></el-input>

                  <span class="text-danger pl-3" v-show="errors[0]">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <span
            slot="footer"
            class="dialog-footer add-edit-rep-guarantor-pf-popup-footer"
          >
            <el-button
              type="primary"
              class="add-edit-rep-guarantor-pf-popup-footer-btn"
              @click="deveEditarOuAdicionarParte()"
              :disabled="desabilitarAdcEdtRepresentanteFiadorPF || invalid"
              >{{
                editarRepresentanteFiadorPF
                  ? `Salvar ${representanteOuFiador.TipoParteName.toLowerCase()}`
                  : `Adicionar ${representanteOuFiador.TipoParteName.toLowerCase()}`
              }}</el-button
            >
          </span>
        </ValidationObserver>
      </el-dialog>

      <!-- Feedback - Adicionar | Editar -->
      <el-dialog
        width="28%"
        :visible.sync="popupFeedbackAdcEdtRepEFiadorPF"
        :before-close="fecharAdcEdtFeedbackPopupRepFiadorPF"
        class="add-edit-rep-guarantor-pf-feedback-popup"
      >
        <div class="add-edit-rep-guarantor-pf-feedback-popup-content">
          <div class="add-edit-rep-guarantor-pf-feedback-popup-content-header">
            <img
              src="@/assets/images/icons/ccg-icons/add-icon-ccg.svg"
              alt="Ícone de adição"
              width="24"
              height="24"
              v-if="!editarRepresentanteFiadorPF"
            />
            <img
              src="@/assets/images/icons/ccg-icons/edit-icon-ccg.svg"
              alt="Ícone de edição"
              width="24"
              height="24"
              v-else
            />
            <h3>
              {{
                editarRepresentanteFiadorPF
                  ? `Editar ${representanteOuFiador.TipoParteName.toLowerCase()}`
                  : `Adicionar ${representanteOuFiador.TipoParteName.toLowerCase()}`
              }}
            </h3>
          </div>

          <div class="add-edit-rep-guarantor-pf-feedback-popup-content-body">
            <div
              class="add-edit-rep-guarantor-pf-feedback-popup-content-body-title"
            >
              <img
                src="@/assets/images/icons/ccg-icons/success-icon.svg"
                alt="Ícone de sucesso para edição de dados"
                width="48"
                height="48"
              />
              <p>
                {{
                  editarRepresentanteFiadorPF
                    ? `${representanteOuFiador.TipoParteName} Editado(a)`
                    : `${representanteOuFiador.TipoParteName} Adicionado(a)`
                }}
              </p>
            </div>

            <div
              class="add-edit-rep-guarantor-pf-feedback-popup-content-body-infos"
            >
              <div
                class="add-edit-rep-guarantor-pf-feedback-popup-content-body-infos-list"
              >
                <div
                  class="add-edit-rep-guarantor-pf-feedback-popup-content-body-infos-divider"
                >
                  <p
                    class="add-edit-rep-guarantor-pf-feedback-popup-content-body-infos-title"
                  >
                    {{
                      `CPF DO(A) ${representanteOuFiador.TipoParteName.toUpperCase()}`
                    }}
                  </p>
                  <p
                    class="add-edit-rep-guarantor-pf-feedback-popup-content-body-infos-content"
                  >
                    {{ representanteOuFiador.CPF }}
                  </p>
                </div>

                <div
                  class="add-edit-rep-guarantor-pf-feedback-popup-content-body-infos-divider"
                >
                  <p
                    class="add-edit-rep-guarantor-pf-feedback-popup-content-body-infos-title"
                  >
                    {{
                      `NOME DO(A) ${representanteOuFiador.TipoParteName.toUpperCase()}`
                    }}
                  </p>
                  <p
                    class="add-edit-rep-guarantor-pf-feedback-popup-content-body-infos-content"
                  >
                    {{ representanteOuFiador.Nome }}
                  </p>
                </div>

                <div
                  class="add-edit-rep-guarantor-pf-feedback-popup-content-body-infos-divider"
                >
                  <p
                    class="add-edit-rep-guarantor-pf-feedback-popup-content-body-infos-title"
                  >
                    {{
                      `E-MAIL DO(A) ${representanteOuFiador.TipoParteName.toUpperCase()}`
                    }}
                  </p>
                  <p
                    class="add-edit-rep-guarantor-pf-feedback-popup-content-body-infos-content"
                  >
                    {{ representanteOuFiador.Email }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <span
          slot="footer"
          class="dialog-footer add-edit-rep-guarantor-pf-feedback-popup-footer"
        >
          <el-button
            type="primary"
            @click="fecharAdcEdtFeedbackPopupRepFiadorPF"
            >Fechar
          </el-button>
        </span>
      </el-dialog>

      <!-- Cônjuge -->
      <!-- Adicionar | Editar -->
      <el-dialog
        width="28%"
        :visible.sync="popupAdcEdtConjuge"
        class="add-edit-spouse-pf-popup"
      >
        <ValidationObserver ref="observerConjuge" v-slot="{ invalid }">
          <div class="add-edit-spouse-pf-popup-content">
            <div class="add-edit-spouse-pf-popup-content-header">
              <img
                src="@/assets/images/icons/ccg-icons/spouse-icon-ccg.svg"
                alt="Ícone de adição"
                width="24"
                height="24"
                v-if="!editarConjuge"
              />
              <img
                src="@/assets/images/icons/ccg-icons/edit-icon-ccg.svg"
                alt="Ícone de edição"
                width="24"
                height="24"
                v-else
              />
              <h3>
                {{ editarConjuge ? "Editar cônjuge" : "Adicionar cônjuge" }}
              </h3>
            </div>

            <div class="add-edit-spouse-pf-popup-content-body">
              <div class="add-edit-spouse-pf-popup-content-body-input">
                <ValidationProvider
                  name="CPF do cônjuge"
                  rules="required|cpfValidator"
                  v-slot="{ errors }"
                >
                  <label for="spouse-cpf">CPF DO CÔNJUGE</label>
                  <el-input
                    id="spouse-cpf"
                    size="large"
                    class="w-full"
                    label="CPF do cônjuge"
                    v-mask="['###.###.###-##']"
                    v-model="representanteOuFiador.CPF"
                  ></el-input>

                  <span class="text-danger pl-3" v-show="errors[0]">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>

              <div class="add-edit-spouse-pf-popup-content-body-input">
                <ValidationProvider
                  name="nome do cônjuge"
                  rules="required|validatorName"
                  v-slot="{ errors }"
                >
                  <label for="spouse-name">NOME DO CÔNJUGE</label>
                  <el-input
                    id="spouse-name"
                    size="large"
                    class="w-full"
                    label="Nome do cônjuge"
                    v-model="representanteOuFiador.Nome"
                  ></el-input>

                  <span class="text-danger pl-3" v-show="errors[0]">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>

              <div class="add-edit-spouse-pf-popup-content-body-input">
                <ValidationProvider
                  name="e-mail do cônjuge"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <label for="spouse-email">E-MAIL DO CÔNJUGE</label>
                  <el-input
                    id="spouse-email"
                    size="large"
                    class="w-full"
                    label="E-mail do cônjuge"
                    v-model="representanteOuFiador.Email"
                  ></el-input>

                  <span class="text-danger pl-3" v-show="errors[0]">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <span
            slot="footer"
            class="dialog-footer add-edit-spouse-pf-popup-footer"
          >
            <el-button
              type="primary"
              class="add-edit-spouse-pf-popup-footer-btn"
              @click="verficarSeParteJaCadastrada"
              :disabled="desabilitarAdicionarConjuge || invalid"
              >{{
                editarConjuge ? "Salvar cônjuge" : "Adicionar cônjuge"
              }}</el-button
            >
          </span>
        </ValidationObserver>
      </el-dialog>

      <!-- Feedback Adicionar | Editar -->
      <el-dialog
        width="28%"
        :visible.sync="popupFeedbackAdcEdtConjuge"
        :before-close="fecharAdcEdtFeedbackPopupRepConjuge"
        class="add-edit-spouse-pf-feedback-popup"
      >
        <div class="add-edit-spouse-pf-feedback-popup-content">
          <div class="add-edit-spouse-pf-feedback-popup-content-header">
            <img
              src="@/assets/images/icons/ccg-icons/spouse-icon-ccg.svg"
              alt="Ícone de adição"
              width="24"
              height="24"
              v-if="!editarConjuge"
            />
            <img
              src="@/assets/images/icons/ccg-icons/edit-icon-ccg.svg"
              alt="Ícone de edição"
              width="24"
              height="24"
              v-else
            />
            <h3>
              {{ editarConjuge ? "Editar cônjuge" : "Adicionar cônjuge" }}
            </h3>
          </div>

          <div class="add-edit-spouse-pf-feedback-popup-content-body">
            <div class="add-edit-spouse-pf-feedback-popup-content-body-title">
              <img
                src="@/assets/images/icons/ccg-icons/success-icon.svg"
                alt="Ícone de sucesso para edição de dados"
                width="48"
                height="48"
              />
              <p>
                {{ editarConjuge ? "Cônjuge editado" : "Cônjuge adicionado" }}
              </p>
            </div>

            <div class="add-edit-spouse-pf-feedback-popup-content-body-infos">
              <div
                class="add-edit-spouse-pf-feedback-popup-content-body-infos-list"
              >
                <div
                  class="add-edit-spouse-pf-feedback-popup-content-body-infos-divider"
                >
                  <p
                    class="add-edit-spouse-pf-feedback-popup-content-body-infos-title"
                  >
                    CPF DO CÔNJUGE
                  </p>
                  <p
                    class="add-edit-spouse-pf-feedback-popup-content-body-infos-content"
                  >
                    {{ representanteOuFiador.CPF }}
                  </p>
                </div>

                <div
                  class="add-edit-spouse-pf-feedback-popup-content-body-infos-divider"
                >
                  <p
                    class="add-edit-spouse-pf-feedback-popup-content-body-infos-title"
                  >
                    NOME DO CÔNJUGE
                  </p>
                  <p
                    class="add-edit-spouse-pf-feedback-popup-content-body-infos-content"
                  >
                    {{ representanteOuFiador.Nome }}
                  </p>
                </div>

                <div
                  class="add-edit-spouse-pf-feedback-popup-content-body-infos-divider"
                >
                  <p
                    class="add-edit-spouse-pf-feedback-popup-content-body-infos-title"
                  >
                    E-MAIL DO CÔNJUGE
                  </p>
                  <p
                    class="add-edit-spouse-pf-feedback-popup-content-body-infos-content"
                  >
                    {{ representanteOuFiador.Email }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <span
          slot="footer"
          class="dialog-footer add-edit-spouse-pf-feedback-popup-footer"
        >
          <el-button type="primary" @click="fecharAdcEdtFeedbackPopupRepConjuge"
            >Fechar
          </el-button>
        </span>
      </el-dialog>

      <!-- Fiador PJ -->
      <!-- Adicionar Fiador PJ -->
      <el-dialog
        width="28%"
        :visible.sync="popupAdcFiadorPJ"
        class="add-guarantor-pj-popup"
      >
        <div class="add-guarantor-pj-popup-content">
          <div class="add-guarantor-pj-popup-content-header">
            <img
              src="@/assets/images/icons/ccg-icons/add-icon-ccg.svg"
              alt="Ícone de adição"
              width="24"
              height="24"
              v-if="!editarFiadorPJ"
            />
            <img
              src="@/assets/images/icons/ccg-icons/edit-icon-ccg.svg"
              alt="Ícone de edição"
              width="24"
              height="24"
              v-else
            />
            <h3>
              {{
                editarFiadorPJ
                  ? `Editar ${representanteOuFiador.TipoParteName.toLowerCase()}`
                  : `Adicionar ${representanteOuFiador.TipoParteName.toLowerCase()}`
              }}
            </h3>
          </div>

          <div class="add-guarantor-pj-popup-content-body">
            <div class="add-guarantor-pj-popup-content-body-input">
              <ValidationProvider
                name="CNPJ"
                rules="required|cnpj"
                v-slot="{ errors }"
              >
                <label for="guarantor-cnpj">CNPJ DO FIADOR PESSOA JURÍDICA</label>
                <el-input
                  id="guarantor-cnpj"
                  size="large"
                  class="w-full"
                  label="CNPJ"
                  v-mask="['##.###.###/####-##']"
                  v-model="representanteOuFiador.CNPJ"
                ></el-input>

                <span class="text-danger pl-3" v-show="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>

            <div class="add-guarantor-pj-popup-content-body-input">
              <ValidationProvider
                name="Razão social"
                rules="required"
                v-slot="{ errors }"
              >
                <label for="guarantor-corporate-name"
                  >RAZÃO SOCIAL DO FIADOR PESSOA JURÍDICA</label
                >
                <el-input
                  id="guarantor-corporate-name"
                  size="large"
                  class="w-full"
                  label="Razão social"
                  v-model="representanteOuFiador.NomeEmpresa"
                ></el-input>

                <span class="text-danger pl-3" v-show="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>

            <el-button
              type="primary"
              class="add-guarantor-pj-popup-content-body-add-rep"
              @click="exibirAdcRepParaFiadorPJ"
              >Adicionar representante</el-button
            >
          </div>
        </div>

        <el-dialog
          width="28%"
          :visible.sync="popupAdcRepParaFiadorPJ"
          :before-close="voltarParaPopupAdcFiadorPJ"
          append-to-body
          class="add-guarantor-pj-add-rep-popup"
        >
          <div class="add-guarantor-pj-add-rep-popup-content">
            <div class="add-guarantor-pj-add-rep-popup-content-header">
              <img
                src="@/assets/images/icons/ccg-icons/add-icon-ccg.svg"
                alt="Ícone de adição"
                width="24"
                height="24"
              />
              <h3>
                {{
                  `Adicionar ${representanteOuFiador.TipoParteName.toLowerCase()}`
                }}
              </h3>
            </div>

              <div class="add-guarantor-pj-add-rep-popup-content-body">
                <div class="add-guarantor-pj-add-rep-popup-content-body-input">
                  <ValidationProvider
                    name="CPF do representante"
                    rules="required|cpfValidator"
                    v-slot="{ errors }"
                  >
                    <label for="guarantor-representative-cpf"
                        >CPF DO REPRESENTANTE DO FIADOR PESSOA JURÍDICA</label
                    >
                    <el-input
                      id="guarantor-representative-cpf"
                      size="large"
                      class="w-full"
                      label="CPF do representante"
                      v-mask="['###.###.###-##']"
                      v-model="representanteOuFiador.CPF"
                    ></el-input>

                    <span class="text-danger pl-3" v-show="errors[0]">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>

                <div class="add-guarantor-pj-add-rep-popup-content-body-input">
                  <ValidationProvider
                    name="Nome do representante"
                    rules="required|validatorName"
                    v-slot="{ errors }"
                  >
                    <label for="guarantor-representative-name"
                      >NOME DO REPRESENTANTE DO FIADOR PESSOA JURÍDICA</label
                    >
                    <el-input
                      id="guarantor-representative-name"
                      size="large"
                      class="w-full"
                      label="Nome do representante"
                      v-model="representanteOuFiador.Nome"
                    ></el-input>

                    <span class="text-danger pl-3" v-show="errors[0]">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>

                <div class="add-guarantor-pj-add-rep-popup-content-body-input">
                  <ValidationProvider
                    name="E-mail do representante"
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <label for="representative-email"
                      >E-MAIL DO REPRESENTANTE DO FIADOR PESSOA JURÍDICA</label
                    >
                    <el-input
                      size="large"
                      class="w-full"
                      label="E-mail do representante"
                      v-model="representanteOuFiador.Email"
                    ></el-input>

                    <span class="text-danger pl-3" v-show="errors[0]">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="add-guarantor-pj-add-rep-popup-footer">
                <el-button
                  type="primary"
                  class="add-guarantor-pj-add-rep-popup-footer-btn-pj-guarantor"
                  @click="voltarParaPopupAdcFiadorPJ"
                  >Adicionar representante</el-button
                >
              </div>
            </div>
          </el-dialog>

        <span slot="footer" class="dialog-footer add-guarantor-pj-popup-footer">
          <el-button
            type="primary"
            @click="verficarSeParteJaCadastrada"
            :disabled="desabilitarAdicionarFiadorPJ"
          >
            {{
              `Adicionar ${representanteOuFiador.TipoParteName.toLowerCase()}`
            }}
          </el-button>
        </span>
      </el-dialog>

      <!-- Editar Fiador PJ -->
      <el-dialog
        width="28%"
        :visible.sync="popupEdtFiadorPj"
        class="edit-guarantor-pj-popup"
      >
        <div class="edit-guarantor-pj-popup-content">
          <div class="edit-guarantor-pj-popup-content-header">
            <img
              src="@/assets/images/icons/ccg-icons/edit-icon-ccg.svg"
              alt="Ícone de edição"
              width="24"
              height="24"
            />
            <h3>
              {{
                `Editar ${representanteOuFiador.TipoParteName.toLowerCase()}`
              }}
            </h3>
          </div>

          <div class="edit-guarantor-pj-popup-content-body">
            <div class="edit-guarantor-pj-popup-content-body-input">
              <ValidationProvider
                name="CNPJ do fiador pessoa jurídica"
                v-slot="{ errors }"
              >
                <label for="guarantor-cnpj">CNPJ DO FIADOR PESSOA JURÍDICA</label>
                <el-input
                  id="guarantor-cnpj"
                  size="large"
                  class="w-full"
                  label="CNPJ do fiador pessoa jurídica"
                  :disabled="editarFiadorPJ ? true : false"
                  v-mask="['XX.XXX.XXX/XXXX-XX']"
                  v-model="representanteOuFiador.CNPJMask"
                ></el-input>

                <span class="text-danger pl-3" v-show="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>

            <div class="edit-guarantor-pj-popup-content-body-input">
              <ValidationProvider
                name="Razão social do fiador pessoa jurídica"
                rules="required"
                v-slot="{ errors }"
              >
                <label for="guarantor-corporate-name"
                  >RAZÃO SOCIAL DO FIADOR PESSOA JURÍDICA</label
                >
                <el-input
                  id="guarantor-corporate-name"
                  size="large"
                  class="w-full"
                  label="Razão social do fiador pessoa jurídica"
                  v-model="representanteOuFiador.NomeEmpresa"
                ></el-input>

                <span class="text-danger pl-3" v-show="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>

            <div class="edit-guarantor-pj-popup-content-body-input">
              <ValidationProvider
                name="CPF do representante do fiador pessoa jurídica"
                rules="required|cpfValidator"
                v-slot="{ errors }"
              >
                <label for="guarantor-representative-cpf"
                  >CPF DO REPRESENTANTE DO FIADOR PESSOA JURÍDICA</label
                >
                <el-input
                  id="guarantor-representative-cpf"
                  size="large"
                  class="w-full"
                  label="CPF do representante do fiador pessoa jurídica"                  
                  v-mask="['###.###.###-##']"
                  v-model="representanteOuFiador.CPF"
                ></el-input>
                <span class="text-danger pl-3" v-show="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>

            <div class="edit-guarantor-pj-popup-content-body-input">
              <ValidationProvider
                name="Nome do representante do fiador pessoa jurídica"
                rules="required|validatorName"
                v-slot="{ errors }"
              >
                <label for="guarantor-representative-name"
                  >NOME DO REPRESENTANTE DO FIADOR PESSOA JURÍDICA</label
                >
                <el-input
                  id="guarantor-representative-name"
                  size="large"
                  class="w-full"
                  label="Nome do representante do fiador pessoa jurídica"
                  v-model="representanteOuFiador.Nome"
                ></el-input>

                <span class="text-danger pl-3" v-show="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>

            <div class="edit-guarantor-pj-popup-content-body-input">
              <ValidationProvider
                name="E-mail do representante do fiador pessoa jurídica"
                rules="required|email"
                v-slot="{ errors }"
              >
                <label for="representative-email"
                  >E-MAIL DO REPRESENTANTE DO FIADOR PESSOA JURÍDICA</label
                >
                <el-input
                  size="large"
                  class="w-full"
                  label="E-mail do representante do fiador pessoa jurídica"
                  v-model="representanteOuFiador.Email"
                ></el-input>

                <span class="text-danger pl-3" v-show="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <span
          slot="footer"
          class="dialog-footer edit-guarantor-pj-popup-footer"
        >
          <el-button
            type="primary"
            @click="editarRepresentanteFiadorPJ = true , lidarEditarParte()"
            :disabled="desabilitarEditarFiadorPJ"
            >Salvar representante
          </el-button>
        </span>
      </el-dialog>

      <!-- Feedback Adicionar | Editar -->
      <el-dialog
        width="28%"
        :visible.sync="popupFeedbackAdcEdtFiadorPJ"
        :before-close="fecharAdcEdtFeedbackPopupFiadorPj"
        class="add-guarantor-pj-feedback-popup"
      >
        <div class="add-guarantor-pj-feedback-popup-content">
          <div class="add-guarantor-pj-feedback-popup-content-header">
            <img
              src="@/assets/images/icons/ccg-icons/add-icon-ccg.svg"
              alt="Ícone de adição"
              width="24"
              height="24"
              v-if="!editarFiadorPJ"
            />
            <img
              src="@/assets/images/icons/ccg-icons/edit-icon-ccg.svg"
              alt="Ícone de edição"
              width="24"
              height="24"
              v-else
            />
            <h3>
              {{
                editarFiadorPJ
                  ? `Editar ${representanteOuFiador.TipoParteName.toLowerCase()}`
                  : `Adicionar ${representanteOuFiador.TipoParteName.toLowerCase()}`
              }}
            </h3>
          </div>

          <div class="add-guarantor-pj-feedback-popup-content-body">
            <div class="add-guarantor-pj-feedback-popup-content-body-title">
              <img
                src="@/assets/images/icons/ccg-icons/success-icon.svg"
                alt="Ícone de sucesso para edição de dados"
                width="48"
                height="48"
              />
              <p>
                {{
                  editarFiadorPJ
                    ? `${representanteOuFiador.TipoParteName.toLowerCase()} editado`
                    : `${representanteOuFiador.TipoParteName.toLowerCase()} adicionado`
                }}
              </p>
            </div>

            <div class="add-guarantor-pj-feedback-popup-content-body-infos">
              <div
                class="add-guarantor-pj-feedback-popup-content-body-infos-list"
              >
                <div
                  class="add-guarantor-pj-feedback-popup-content-body-infos-divider"
                >
                  <p
                    class="add-guarantor-pj-feedback-popup-content-body-infos-title"
                  >
                    CNPJ DO FIADOR
                  </p>
                  <p
                    class="add-guarantor-pj-feedback-popup-content-body-infos-content"
                  >
                    {{ representanteOuFiador.CNPJ }}
                  </p>
                </div>

                <div
                  class="add-guarantor-pj-feedback-popup-content-body-infos-divider"
                >
                  <p
                    class="add-guarantor-pj-feedback-popup-content-body-infos-title"
                  >
                    RAZÃO SOCIAL DO FIADOR
                  </p>
                  <p
                    class="add-guarantor-pj-feedback-popup-content-body-infos-content"
                  >
                    {{ representanteOuFiador.NomeEmpresa }}
                  </p>
                </div>

                <div
                  class="add-guarantor-pj-feedback-popup-content-body-infos-divider"
                >
                  <p
                    class="add-guarantor-pj-feedback-popup-content-body-infos-title"
                  >
                    CPF DO REPRESENTANTE DO FIADOR
                  </p>
                  <p
                    class="add-guarantor-pj-feedback-popup-content-body-infos-content"
                  >
                    {{ representanteOuFiador.CPF }}
                  </p>
                </div>

                <div
                  class="add-guarantor-pj-feedback-popup-content-body-infos-divider"
                >
                  <p
                    class="add-guarantor-pj-feedback-popup-content-body-infos-title"
                  >
                    NOME DO REPRESENTANTE DO FIADOR
                  </p>
                  <p
                    class="add-guarantor-pj-feedback-popup-content-body-infos-content"
                  >
                    {{ representanteOuFiador.Nome }}
                  </p>
                </div>

                <div
                  class="add-guarantor-pj-feedback-popup-content-body-infos-divider"
                >
                  <p
                    class="add-guarantor-pj-feedback-popup-content-body-infos-title"
                  >
                    EMAIL DO REPRESENTANTE DO FIADOR
                  </p>
                  <p
                    class="add-guarantor-pj-feedback-popup-content-body-infos-content"
                  >
                    {{ representanteOuFiador.Email }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <span
          slot="footer"
          class="dialog-footer add-guarantor-pj-feedback-popup-footer"
        >
          <el-button type="primary" @click="fecharAdcEdtFeedbackPopupFiadorPj"
            >Fechar
          </el-button>
        </span>
      </el-dialog>

      <!-- Excluir Partes -->
      <el-dialog
        :visible.sync="popupExcluirParte"
        width="28%"
        :before-close="fecharPopupExcluirParte"
        class="delete-rep-popup"
      >
        <div class="delete-rep-popup-content">
          <div class="delete-rep-popup-content-header">
            <img
              src="@/assets/images/icons/ccg-icons/trash-ccg.svg"
              alt="Ícone de excluir representante"
              width="24"
              height="24"
            />
            <h3>
              {{ `Excluir ${parteASerExcluida.TipoParteName.toLowerCase()}` }}
            </h3>
          </div>

          <div class="delete-rep-popup-content-body">
            <p class="delete-rep-popup-content-body-title">
              Você tem certeza que deseja
              <strong
                >excluir este
                {{
                  `${parteASerExcluida.TipoParteName.toLowerCase()}`
                }}?</strong
              >
            </p>
          </div>
        </div>
        <span slot="footer" class="dialog-footer delete-rep-popup-footer">
          <el-button @click="fecharPopupExcluirParte">Cancelar</el-button>
          <el-button
            type="primary"
            @click="lidarExcluirParte"
            :disabled="desabilitarExcluirParte"
            >Excluir</el-button
          >
        </span>
      </el-dialog>

      <!-- Feedback Excluir Partes -->
      <el-dialog
        :visible.sync="popupFeedbackExcluirParte"
        width="28%"
        :before-close="fecharFeedbackPopupExcluirParte"
        class="delete-rep-feedback-popup"
      >
        <div class="delete-rep-feedback-popup-content">
          <div class="delete-rep-feedback-popup-content-header">
            <img
              src="@/assets/images/icons/ccg-icons/trash-ccg.svg"
              alt="Ícone de excluir representante"
              width="24"
              height="24"
            />
            <h3>
              {{ `Excluir ${parteASerExcluida.TipoParteName.toLowerCase()}` }}
            </h3>
          </div>

          <div class="delete-rep-feedback-popup-content-body">
            <p class="delete-rep-feedback-popup-content-body-title">
              {{ `${parteASerExcluida.TipoParteName} excluido(a) com sucesso` }}
            </p>
          </div>
        </div>
        <span
          slot="footer"
          class="dialog-footer delete-rep-feedback-popup-footer"
        >
          <el-button @click="fecharFeedbackPopupExcluirParte">Fechar</el-button>
        </span>
      </el-dialog>

      <!-- Confirmação do link de assinatura -->
      <el-dialog
        :visible.sync="popupLinkAssinatura"
        width="32%"
        :before-close="fecharPopupLinkAssinatura"
        class="sign-link-popup"
      >
        <div class="sign-link-popup-content">
          <div class="sign-link-popup-content-header">
            <img
              src="@/assets/images/icons/ccg-icons/link-icon-ccg.svg"
              alt="Ícone de link individual de assinatura"
              width="24"
              height="24"
            />
            <h3>
              Link de assinatura copiado
            </h3>
          </div>

          <div class="sign-link-popup-content-body">
            <el-alert
              type="warning"
              center
              :closable="false"
              class="sign-link-popup-content-body-alert"
            >
              O link único de assinatura pode ser usado para acessar e assinar o
              documento.
              <br />
              <br />
              Não compartilhe este link com mais de um signatário!</el-alert
            >
          </div>
        </div>
        <span slot="footer" class="dialog-footer sign-link-popup-footer">
          <el-button type="primary" @click="fecharPopupLinkAssinatura"
            >Fechar</el-button
          >
        </span>
      </el-dialog>

      <!-- Feedback Reenvio de e-mail individual -->
      <el-dialog
        :visible.sync="popupFeedbackReenvioEmailIndividual"
        width="27%"
        :before-close="fecharPopupReenvioEmailIndividual"
        class="resend-email-popup"
      >
        <div class="resend-email-popup-content">
          <div class="resend-email-popup-content-header">
            <img
              src="@/assets/images/icons/ccg-icons/forward-to-inbox-ccg.svg"
              alt="Ícone de reenviar de email"
              width="24"
              height="24"
            />
            <h3>
              Reenviar e-mail
            </h3>
          </div>

          <div class="resend-email-popup-content-body">
            <div class="resend-email-popup-content-body-title">
              <img
                src="@/assets/images/icons/ccg-icons/success-icon.svg"
                alt="Ícone de sucesso para edição de dados"
                width="48"
                height="48"
              />
              <p>
                O e-mail foi reenviado.
              </p>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer resend-email-popup-footer">
          <el-button type="primary" @click="fecharPopupReenvioEmailIndividual"
            >Fechar</el-button
          >
        </span>
      </el-dialog>

      <!-- Feedback Reenvio de e-mail todos -->
      <el-dialog
        :visible.sync="popupFeedbackReenvioEmailTodos"
        width="27%"
        :before-close="fecharPopupReenvioEmailTodos"
        class="resend-email-popup"
      >
        <div class="resend-email-popup-content">
          <div class="resend-email-popup-content-header">
            <img
              src="@/assets/images/icons/ccg-icons/forward-to-inbox-ccg.svg"
              alt="Ícone de reenviar de email"
              width="24"
              height="24"
            />
            <h3>
              Reenviar e-mails
            </h3>
          </div>

          <div class="resend-email-popup-content-body">
            <div class="resend-email-popup-content-body-title">
              <img
                src="@/assets/images/icons/ccg-icons/success-icon.svg"
                alt="Ícone de sucesso para edição de dados"
                width="48"
                height="48"
              />
              <p>
                Todos os emails foram reenviados.
              </p>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer resend-email-popup-footer">
          <el-button type="primary" @click="fecharPopupReenvioEmailTodos"
            >Fechar</el-button
          >
        </span>
      </el-dialog>

      <!-- Termo de Responsabilidade -->
      <el-dialog
        :visible.sync="popupTermoResponsabilidade"
        width="35%"
        :before-close="fecharTermoResponsabilidade"
        class="resp-term-popup"
      >
        <div class="resp-term-popup-content">
          <div class="resp-term-popup-content-header">
            <h3>
              Termo de responsabilidade
            </h3>
          </div>

          <div class="resp-term-popup-content-body">
            <div
              class="resp-term-popup-content-body-title"
              v-html="termoDeResponsabilidade.Texto"
            ></div>
          </div>

          <el-checkbox
            class="resp-term-popup-content-body-checkbox"
            v-model="aceiteTermoResponsabilidade"
          >
            Declaro que li e estou ciente das condições acima.
          </el-checkbox>
        </div>
        <span slot="footer" class="dialog-footer resp-term-popup-footer">
          <el-button @click="fecharTermoResponsabilidade">Cancelar</el-button>
          <el-button
            type="primary"
            :disabled="!aceiteTermoResponsabilidade"
            @click="aceitarTermoResponsabilidade"
            >Assinar CCG</el-button
          >
        </span>
      </el-dialog>

      <!-- Confirmação Cancelamento envio para assinatura  -->
      <el-dialog
        :visible.sync="popupConfirmacaoCancelamentoEnvioAss"
        width="32%"
        :before-close="fecharPopupConfirmacaoCancelamentoEnvioAss"
        class="cancellation-submission-signature-popup"
      >
        <div class="cancellation-submission-signature-popup-content">
          <div class="cancellation-submission-signature-popup-content-header">
            <img
              src="@/assets/images/icons/ccg-icons/trash-ccg.svg"
              alt="Ícone destinado ao cancelamento de envio para assinatura"
              width="24"
              height="24"
            />
            <h3>
              Cancelamento de envio para assinatura
            </h3>
          </div>

          <div class="cancellation-submission-signature-popup-content-body">
            <el-alert
              type="warning"
              center
              :closable="false"
              class="cancellation-submission-signature-popup-content-body-alert"
            >
              O cancelamento do envio para assinatura irá alterar o status do
              contrato e das partes nele existente.
              <br />
              <br />
              Deseja continuar?</el-alert
            >
          </div>
        </div>

        <span
          slot="footer"
          class="dialog-footer cancellation-submission-signature-popup-footer"
        >
          <el-button type="primary" @click="cancelarEnvioParaAssinatura"
            >Sim, continuar</el-button
          >
          <el-button @click="fecharPopupConfirmacaoCancelamentoEnvioAss"
            >Cancelar</el-button
          >
        </span>
      </el-dialog>

      <!-- Feedback Cancelamento envio para assinatura  -->
      <el-dialog
        :visible.sync="popupFeedbackConfirmacaoCancelamentoEnvioAss"
        width="27%"
        :before-close="fecharPopupFeedbackConfirmacaoCancelamentoEnvioAss"
        class="cancellation-submission-signature-feedback-popup"
      >
        <div class="cancellation-submission-signature-feedback-popup-content">
          <div
            class="cancellation-submission-signature-feedback-popup-content-header"
          >
            <img
              src="@/assets/images/icons/ccg-icons/trash-ccg.svg"
              alt="Ícone destinado ao cancelamento de envio para assinatura"
              width="24"
              height="24"
            />
            <h3>
              Cancelamento de envio para assinatura
            </h3>
          </div>

          <div
            class="cancellation-submission-signature-feedback-popup-content-body"
          >
            <div
              class="cancellation-submission-signature-feedback-popup-content-body-title"
            >
              <img
                src="@/assets/images/icons/ccg-icons/success-icon.svg"
                alt="Ícone de sucesso para edição de dados"
                width="48"
                height="48"
              />
              <p>
                O envio do CCG para Assinatura foi cancelado com sucesso!
              </p>
            </div>
          </div>
        </div>
        <span
          slot="footer"
          class="dialog-footer cancellation-submission-signature-feedback-popup-footer"
        >
          <el-button
            type="primary"
            @click="fecharPopupFeedbackConfirmacaoCancelamentoEnvioAss"
            >Fechar</el-button
          >
        </span>
      </el-dialog>

      <!-- Confirmação Cancelamento do CCG Assinado  -->
      <el-dialog
        :visible.sync="popupConfirmacaoCancelamentoCCGAssinado"
        width="32%"
        :before-close="fecharPopupConfirmacaoCancelamentoCCGAssinado"
        class="cancellation-signed-ccg-popup"
      >
        <div class="cancellation-signed-ccg-popup-content">
          <div class="cancellation-signed-ccg-popup-content-header">
            <img
              src="@/assets/images/icons/ccg-icons/trash-ccg.svg"
              alt="Ícone destinado ao cancelamento de envio para assinatura"
              width="24"
              height="24"
            />
            <h3>
              Cancelamento de CCG assinado
            </h3>
          </div>

          <div class="cancellation-signed-ccg-popup-content-body">
            <el-alert
              type="warning"
              center
              :closable="false"
              class="cancellation-signed-ccg-popup-content-body-alert"
            >
              O cancelamento do CCG assinado irá alterar o status do
              contrato e das partes nele existente.
              <br />
              <br />
              Deseja continuar?</el-alert
            >
          </div>
        </div>

        <span
          slot="footer"
          class="dialog-footer cancellation-signed-ccg-popup-footer"
        >
          <el-button type="primary" @click="cancelarCCGAssinado"
            >Sim, continuar</el-button
          >
          <el-button @click="fecharPopupConfirmacaoCancelamentoCCGAssinado"
            >Cancelar</el-button
          >
        </span>
      </el-dialog>

      <!-- Feedback Cancelamento do CCG Assinado  -->
      <el-dialog
        :visible.sync="popupFeedbackConfirmacaoCancelamentoCCGAssinado"
        width="27%"
        :before-close="fecharPopupFeedbackConfirmacaoCancelamentoCCGAssinado"
        class="cancellation-signed-ccg-feedback-popup"
      >
        <div class="cancellation-signed-ccg-feedback-popup-content">
          <div
            class="cancellation-signed-ccg-feedback-popup-content-header"
          >
            <img
              src="@/assets/images/icons/ccg-icons/trash-ccg.svg"
              alt="Ícone destinado ao cancelamento de envio para assinatura"
              width="24"
              height="24"
            />
            <h3>
              Cancelamento de CCG assinado
            </h3>
          </div>

          <div
            class="cancellation-signed-ccg-feedback-popup-content-body"
          >
            <div
              class="cancellation-signed-ccg-feedback-popup-content-body-title"
            >
              <img
                src="@/assets/images/icons/ccg-icons/success-icon.svg"
                alt="Ícone de sucesso para edição de dados"
                width="48"
                height="48"
              />
              <p>
                O CCG assinado foi cancelado com sucesso!
              </p>
            </div>
          </div>
        </div>
        <span
          slot="footer"
          class="dialog-footer cancellation-signed-ccg-feedback-popup-footer"
        >
          <el-button
            type="primary"
            @click="fecharPopupFeedbackConfirmacaoCancelamentoCCGAssinado"
            >Fechar</el-button
          >
        </span>
      </el-dialog>

      <!-- Região de ações -->
      <div class="actions text-right mt-10 w-full">
        <el-button
          type="primary"
          v-if="podeReenviarEmailTodos && ccg.Contrato.StatusId == 2 && !isMapfre"
          @click="lidarComReenviarEmailTodos"
        >
          Reenviar e-mails
        </el-button>

        <el-button
          type="primary"
          v-if="
            ccg.Contrato.StatusId == 1 &&
              profileHelpers.isInsurance() &&
              podeFazerUploadTemplate
          "
          @click="uploadModal = true"
        >
          Upload Manual CCG <i class="el-icon-upload"></i>
        </el-button>

        <el-button
          type="primary"
          v-if="podeExibirRascunhoDoContrato"
          @click="lidarDownloadDocumento"
        >
          {{
            ccg.Contrato.StatusId === 3
              ? "Download do documento assinado"
              : "Download do documento para conferência"
          }}
        </el-button>

        <el-button
          type="primary"
          v-if="podeCancelarAssinaturaCCG && ccg.Contrato.StatusId == 2"
          @click="popupConfirmacaoCancelamentoEnvioAss = true"
        >
          Cancelar envio
        </el-button>

        <el-button
          type="primary"
          v-if="podeCancelarCcgAssinado && ccg.Contrato.StatusId == 3"
          @click="popupConfirmacaoCancelamentoCCGAssinado = true"
        >
          Cancelar CCG
        </el-button>

        <el-button
          type="primary"
          v-if="ccg.Contrato.StatusId == 1"
          :disabled="!podeEnviarCcgParaAssinatura"
          @click="lidarComAssinarCCG"
        >
          Assinar CCG
        </el-button>
      </div>
    </template>

    <template v-else>
      <div class="flex flex-col w-full md:w-1/2 mx-auto items-center mt-32">
        <span class="material-icons text-6xl">description</span>
        <p>Contra garantia não cadastrada para esse tomador.</p>
      </div>
    </template>
  </div>
</template>

<script>
import { mask, TheMask } from "vue-the-mask";
import * as Utils from "@/utils/utils";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import ccgController from "@/components/CCG/providers/ccg-provider.js";

import VisualizarAlteracoes from "@/components/CCG/components/VisualizarAlteracoes.vue";
import TabelaPartes from "@/components/CCG/components/TabelaPartes.vue";
import base64Upload from "@/components/base64-Upload/ccg_upload.vue";

import compareCompanyUtils from "@/utils/compareCompanyUtils.js";

export default {
  name: "nova-contra-garantia",
  directives: { mask },
  components: {
    TheMask,
    VisualizarAlteracoes,
    TabelaPartes,
    base64Upload
  },
  props: {
    tomador: {
      type: Object,
      required: true
    },
    permissoesCCG: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      profileHelpers: PerfilHelpers,
      ccg: {},
      permissoes: [],
      loadCompleted: false,
      representanteOuFiador: {
        CPF: "",
        CNPJ: "",
        Nome: "",
        TipoParteId: "",
        ContratoId: "",
        NomeEmpresa: "",
        Email: "",
        TipoParteName: "",
        ParteId: ""
      },
      parteASerExcluida: {
        ParteId: "",
        ContratoId: "",
        TipoParteName: ""
      },
      editarRepresentanteFiadorPF: false,
      desabilitarAdcEdtRepresentanteFiadorPF: false,
      popupAdcEditRepresentanteFiadorPF: false,
      popupFeedbackAdcEdtRepEFiadorPF: false,
      editarFiadorPJ: false,
      popupAdcFiadorPJ: false,
      popupEdtFiadorPj: false,
      desabilitarAdicionarFiadorPJ: false,
      desabilitarEditarFiadorPJ: false,
      popupAdcRepParaFiadorPJ: false,
      popupFeedbackAdcEdtFiadorPJ: false,
      popupExcluirParte: false,
      popupFeedbackExcluirParte: false,
      desabilitarExcluirParte: false,
      popupAdcEdtConjuge: false,
      popupFeedbackAdcEdtConjuge: false,
      desabilitarAdicionarConjuge: false,
      editarConjuge: false,
      popupLinkAssinatura: false,
      popupFeedbackReenvioEmailIndividual: false,
      popupFeedbackReenvioEmailTodos: false,
      uploadModal: false,
      podeEnviarCcgParaAssinatura: true,
      aceiteTermoResponsabilidade: false,
      termoDeResponsabilidade: {
        Id: "",
        Texto: ""
      },
      popupTermoResponsabilidade: false,
      popupAlertaParteCadastrada: false,
      parteJaCadstrada: false,
      popupConfirmacaoCancelamentoEnvioAss: false,
      popupFeedbackConfirmacaoCancelamentoEnvioAss: false,
      popupConfirmacaoCancelamentoCCGAssinado: false,
      popupFeedbackConfirmacaoCancelamentoCCGAssinado: false,
      editarRepresentanteFiadorPJ:false,
      isMapfre: false
    };
  },
  computed: {
    representantesLegais: {
      get() {
        if (this.ccg && this.ccg.RepresentantesLegais)
          return this.ccg.RepresentantesLegais.filter(representanteLegal => {
            return (
              representanteLegal.ParteId &&
              representanteLegal.TipoParteName === "Representante Legal"
            );
          });
        else return [];
      }
    },
    fiadoresPF: {
      get() {
        if (this.ccg && this.ccg.FiadoresPessoaFisica)
          return this.ccg.FiadoresPessoaFisica.filter(fiadorPF => {
            return (
              fiadorPF.ParteId &&
              fiadorPF.TipoParteName === "Fiador Pessoa Física"
            );
          });
        else return [];
      }
    },
    fiadoresPJ: {
      get() {
        if (this.ccg && this.ccg.FiadoresPessoaJuridica)
          return this.ccg.FiadoresPessoaJuridica.filter(fiadorPJ => {
            return (
              fiadorPJ.ParteId &&
              fiadorPJ.TipoParteName === "Fiador Pessoa Jurídica"
            );
          });
        else return [];
      }
    },
    testemunhas: {
      get() {
        if (this.ccg && this.ccg.Testemunhas)
          return this.ccg.Testemunhas.filter(testemunha => {
            return (
              testemunha.ParteId && testemunha.TipoParteName === "Testemunha"
            );
          });
        else return [];
      }
    },
    Seguradoras: {
      get() {
        if (this.ccg && this.ccg.Seguradora)
          return this.ccg.Seguradora
        else return [];
      }
    },
    tipoAvisoStatus() {
      return this.ccg.Contrato ? this.ccg.Contrato.TipoAvisoStatus.toLowerCase() : "";
    },
    cpfRepresentanteVinculado() {
      return this.representantesLegais.filter(representanteLegal => {
        return (
          !this.representanteOuFiador.ParteId &&
          this.representanteOuFiador.CPF &&
          (this.representanteOuFiador.CPF || "").replace(/[^\d]+/g, "") ==
            (representanteLegal.CPF || "").replace(/[^\d]+/g, "")
        );
      }).length >= 1
        ? "CPF já está vinculado a outro representante legal"
        : "";
    },
    cpfFiadorPFVinculado() {
      return this.fiadoresPF.filter(fiadorPF => {
        return (
          !this.representanteOuFiador.ParteId &&
          this.representanteOuFiador.CPF &&
          (this.representanteOuFiador.CPF || "").replace(/[^\d]+/g, "") ==
            (fiadorPF.CPF || "").replace(/[^\d]+/g, "")
        );
      }).length >= 1
        ? "CPF já está vinculado a outro fiador PF"
        : "";
    },
    cpfRepresentanteFiadorPjVinculado() {
      return this.fiadoresPJ.filter(fiadorPJ => {
        return (
          !this.representanteOuFiador.ParteId &&
          this.representanteOuFiador.CPF &&
          (this.representanteOuFiador.CPF || "").replace(/[^\d]+/g, "") ==
            (fiadorPJ.CPF || "").replace(/[^\d]+/g, "")
        );
      }).length >= 1
        ? "CPF do representante já está vinculado a outro CNPJ"
        : "";
    },
    cpfTestemunhaVinculado() {
      return this.testemunhas.filter(testemunha => {
        return (
          !this.representanteOuFiador.ParteId &&
          this.representanteOuFiador.CPF &&
          (this.representanteOuFiador.CPF || "").replace(/[^\d]+/g, "") ==
            (testemunha.CPF || "").replace(/[^\d]+/g, "")
        );
      }).length >= 1
        ? "CPF já está vinculado a outra testemunha"
        : "";
    },
    emailRepresentanteVinculado() {
      return this.representantesLegais.filter(representanteLegal => {
        return (
          !this.representanteOuFiador.ParteId &&
          this.representanteOuFiador.Email &&
          (this.representanteOuFiador.Email || "") ==
            (representanteLegal.Email || "")
        );
      }).length >= 1
        ? "E-mail já está vinculado a outro representante legal"
        : "";
    },
    emailFiadorPFVinculado() {
      return this.fiadoresPF.filter(fiadorPF => {
        return (
          !this.representanteOuFiador.ParteId &&
          this.representanteOuFiador.Email &&
          (this.representanteOuFiador.Email || "") == (fiadorPF.Email || "")
        );
      }).length >= 1
        ? "E-mail já está vinculado a outro fiador PF"
        : "";
    },
    emailRepresentanteFiadorPjVinculado() {
      return this.fiadoresPJ.filter(fiadorPJ => {
        return (
          (this.representanteOuFiador.Email || "") == (fiadorPJ.Email || "")
        );
      }).length >= 1
        ? "E-mail do representante já está vinculado a outro CNPJ"
        : "";
    },
    emailTestemunhaVinculado() {
      return this.testemunhas.filter(testemunha => {
        return (
          !this.representanteOuFiador.ParteId &&
          this.representanteOuFiador.Email &&
          (this.representanteOuFiador.Email || "") == (testemunha.Email || "")
        );
      }).length >= 1
        ? "E-mail já está vinculado a outra testemunha"
        : "";
    },
    podeAdicionarRepresentante() {
      return this.permissoes.includes("PodeIncluirRepresentante");
    },
    podeAdicionarFiadorPF() {
      return this.permissoes.includes("PodeIncluirFiadorPessoaFisica");
    },
    podeAdicionarFiadorPJ() {
      return this.permissoes.includes("PodeEditarFiadorPessoaJuridica");
    },
    podeReenviarEmailTodos() {
      return this.permissoes.includes("PodeReenviarEmailTodos");
    },
    podeExibirRascunhoDoContrato() {
      return this.permissoes.includes("PodeExibirRascunhoDoContrato");
    },
    podeFazerUploadTemplate() {
      return this.permissoes.includes("PodeFazerUploadTemplate");
    },
    deveExibirTermoDeResponsabilidade() {
      return this.permissoes.includes("DeveExibirTermoDeResponsabilidade");
    },
    podeCancelarAssinaturaCCG() {
      return this.permissoes.includes("PodeCancelarAssinaturaCcg");
    },
    podeCancelarCcgAssinado() {
      return this.permissoes.includes("PodeCancelarCcgAssinado");
    },
  },
  methods: {
    async obterCCG() {
      try {
        this.ccg = await ccgController.obterDocumentoCCG(this.tomador.Id);
      } catch (err) {
        this.$onpoint.errorModal(err.response.data.Errors);
      } 
    },

    async ccgPodeSerEnviadoParaAssinatura() {
      let contratoId = this.ccg.Contrato.ContratoId;

      await this.$onpoint.loading(async () => {
        return await ccgController
          .obterPermissaoAssinatura(contratoId)
          .then(data => {
            this.podeEnviarCcgParaAssinatura =
              data == "PodeEnviarCcgParaAssinatura" ? true : false;
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },

    removerMascaraCPFouCNPJ(cpfOuCnj) {
      if (cpfOuCnj) {
        return cpfOuCnj.replace(/[^\d]/g, "");
      } else {
        return "";
      }
    },

    validarDocumentosParaRequisicao(tipoParteId) {
      let isPJ = tipoParteId === 2;
      let CPF
      let  CNPJ
      let  email = this.representanteOuFiador.Email ? Utils.validEmail(this.representanteOuFiador.Email): true
      let name = Utils.validName(this.representanteOuFiador.Nome)

      if(this.editarRepresentanteFiadorPF){
        CPF = this.representanteOuFiador.CPFHash
        CNPJ = isPJ ? this.representanteOuFiador.CNPJHash : ''
      }
      else if(this.editarRepresentanteFiadorPJ){        
        
        if(!!this.representanteOuFiador.CPF || this.representanteOuFiador.CPF===""){
          CPF = true
        }
        else{
          CPF = Utils.isValidCpf(this.representanteOuFiador.CPF);
        }

        CNPJ = true
      }
      else{
        CPF = Utils.isValidCpf(this.representanteOuFiador.CPF);
        CNPJ = isPJ ? Utils.isValidCnpj(this.representanteOuFiador.CNPJ) : "";
      }

      let erro = {
        CPF: CPF
          ? ""
          : isPJ
          ? "CPF representante fiador PJ inválido"
          : "CPF inválido",
        CNPJ: CNPJ ? "" : "CNPJ inválido",
        Email: email
          ? ""
          : isPJ
          ? "E-mail representante fiador PJ inválido"
          : "E-mail inválido",
        Nome: name ? "" : "Não são permitidos números e caracteres especiais no campo nome! Por favor, verifique."
      };

      if (
        this.editarRepresentanteFiadorPF ||
        this.editarFiadorPJ ||
        this.editarConjuge
      ) {
        if (isPJ) {
          if (!CNPJ || !CPF || !email || !name) {
            this.$onpoint.errorModal([erro.CNPJ, erro.CPF, erro.Email, erro.Nome]);
            return false;
          }
        } else if (!CPF || !email || !name) {
          this.$onpoint.errorModal([erro.CPF, erro.Email, erro.Nome]);
          return false;
        }
      } else {
        if (isPJ) {
          if (!CNPJ || !CPF || !email || !name) {
            this.$onpoint.errorModal([erro.CNPJ, erro.CPF, erro.Email, erro.Nome]);
            return false;
          }
        } else if (!CPF || !email || !name) {
          this.$onpoint.errorModal([erro.CPF, erro.Email, erro.Nome]);
          return false;
        }
      }

      return true;
    },

    checarSeParteExiste(tipoParteId) {
      if (tipoParteId === 1) {
        if (
          this.cpfRepresentanteVinculado ||
          this.emailRepresentanteVinculado
        ) {
          this.$onpoint.errorModal([
            this.cpfRepresentanteVinculado,
            this.emailRepresentanteVinculado
          ]);
          return true;
        }
      }

      if (tipoParteId === 2) {
        if (
          this.cpfRepresentanteFiadorPjVinculado ||
          this.emailRepresentanteFiadorPjVinculado
        ) {
          this.$onpoint.errorModal([
            this.cpfRepresentanteFiadorPjVinculado,
            this.emailRepresentanteFiadorPjVinculado
          ]);
          return true;
        }
      }

      if (tipoParteId === 3) {
        if (this.cpfFiadorPFVinculado || this.emailFiadorPFVinculado) {
          this.$onpoint.errorModal([
            this.cpfFiadorPFVinculado,
            this.emailFiadorPFVinculado
          ]);
          return true;
        }
      }

      if (tipoParteId === 4) {
        if (this.cpfTestemunhaVinculado || this.emailTestemunhaVinculado) {
          this.$onpoint.errorModal([
            this.cpfTestemunhaVinculado,
            this.emailTestemunhaVinculado
          ]);
          return true;
        }
      }

      return false;
    },

    limparParte() {
      this.representanteOuFiador = Object.fromEntries(
        Object.keys(this.representanteOuFiador).map(chave => [chave, ""])
      );
      this.$nextTick(() => {
        this.limparFormularios();
      });
    },

    limparFormularios() {
      if (this.popupAdcEditRepresentanteFiadorPF) this.$refs.observerRepresentante.reset();
      if (this.popupAdcEdtConjuge) this.$refs.observerConjuge.reset();
    },

    limparParteASerExcluida() {
      this.parteASerExcluida = Object.fromEntries(
        Object.keys(this.parteASerExcluida).map(chave => [chave, ""])
      );
    },

    limparTermoResponsabilidade() {
      this.termoDeResponsabilidade = Object.fromEntries(
        Object.keys(this.termoDeResponsabilidade).map(chave => [chave, ""])
      );
    },

    deveEditarOuAdicionarParte() {
      if (this.editarRepresentanteFiadorPF) {
        this.lidarEditarParte();
      } else {
        this.verficarSeParteJaCadastrada();
      }
    },

    async lidarAdicionarParte() {
      if (
        !this.validarDocumentosParaRequisicao(
          this.representanteOuFiador.TipoParteId
        )
      )
        return;

      let parteCPF = this.removerMascaraCPFouCNPJ(
        this.representanteOuFiador.CPF
      );
      let parteCNPJ = this.removerMascaraCPFouCNPJ(
        this.representanteOuFiador.CNPJ
      );
      this.desabilitarAdcEdtRepresentanteFiadorPF = true;
      this.desabilitarAdicionarFiadorPJ = true;

      let data = {
        CPF: parteCPF,
        CNPJ: parteCNPJ,
        Nome: this.representanteOuFiador.Nome,
        NomeEmpresa: this.representanteOuFiador.NomeEmpresa,
        TipoParteId: this.representanteOuFiador.TipoParteId,
        ContratoId: this.ccg.Contrato.ContratoId,
        Email: this.representanteOuFiador.Email
      };

      await this.$onpoint.loading(async () => {
        return await ccgController
          .adicionarParte(data)
          .then(async () => {
            await this.obterCCG();
          })
          .then(async () => {
            await this.ccgPodeSerEnviadoParaAssinatura();
          })
          .then(() => {
            if (this.representanteOuFiador.TipoParteId === 2) {
              this.exibirAdcEdtFeedbackPopupFiadorPJ();
            } else {
              this.exibirAdcEdtFeedbackPopupRepFiadorPF();
            }
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          })
          .finally(() => {
            this.desabilitarAdcEdtRepresentanteFiadorPF = false;
            this.desabilitarAdicionarFiadorPJ = false;
          });
      });
    },

    deveExibirAdicionarParteQuandoParteVazia(podeAdicionar) {
      switch (podeAdicionar) {
        case "Representante Legal":
          return this.podeAdicionarRepresentante;
        case "Fiador Pessoa Física":
          return this.podeAdicionarFiadorPF;
        case "Fiador Pessoa Jurídica":
          return this.podeAdicionarFiadorPJ;
        default:
          return true;
      }
    },

    exibirAdcPopupQuandoRepFiadorVazio(tipoParteId) {
      if (this.ccg.Contrato.StatusId !== 1) return;

      let data = {
        tipoParteId: tipoParteId,
        tipoParteName: ""
      };

      switch (tipoParteId) {
        case 1:
          data.tipoParteName = "Representante Legal";
          break;
        case 2:
          data.tipoParteName = "Fiador Pessoa Jurídica";
          break;
        case 3:
          data.tipoParteName = "Fiador Pessoa Física";
          break;
        case 4:
          data.tipoParteName = "Testemunha";
          break;
        default:
          break;
      }

      if (data.tipoParteId === 2) {
        this.exibirAdcPopupFiadorPJ(data);
      } else {
        this.exibirAdcPopupRepFiadorPF(data);
      }
    },

    exibirAdcPopupRepFiadorPF(event) {
      if (this.ccg.Contrato.StatusId !== 1) return;
      this.editarRepresentanteFiadorPF = false;
      this.limparParte();
      this.representanteOuFiador.TipoParteId = event.tipoParteId;
      this.representanteOuFiador.TipoParteName = event.tipoParteName;
      this.popupAdcEditRepresentanteFiadorPF = true;
    },

    exibirAdcEdtFeedbackPopupRepFiadorPF() {
      this.popupAdcEditRepresentanteFiadorPF = false;
      this.popupAlertaParteCadastrada = false;
      this.popupFeedbackAdcEdtRepEFiadorPF = true;
    },

    fecharAdcEdtFeedbackPopupRepFiadorPF() {
      this.popupFeedbackAdcEdtRepEFiadorPF = false;
    },

    exibirAdcPopupFiadorPJ(event) {
      this.editarFiadorPJ = false;
      this.limparParte();
      this.representanteOuFiador.TipoParteId = event.tipoParteId;
      this.representanteOuFiador.TipoParteName = event.tipoParteName;
      this.popupAdcFiadorPJ = true;
    },

    fecharAdcPopupFiadorPJ() {
      this.popupAdcFiadorPJ = false;
    },

    exibirAdcRepParaFiadorPJ() {
      this.popupAdcFiadorPJ = false;
      this.popupAdcRepParaFiadorPJ = true;
    },

    voltarParaPopupAdcFiadorPJ() {
      this.popupAdcFiadorPJ = true;
      this.popupAdcRepParaFiadorPJ = false;
    },

    exibirAdcEdtFeedbackPopupFiadorPJ() {
      this.popupEdtFiadorPj = false;
      this.popupAdcFiadorPJ = false;
      this.popupAlertaParteCadastrada = false;
      this.popupFeedbackAdcEdtFiadorPJ = true;
    },

    fecharAdcEdtFeedbackPopupFiadorPj() {
      this.popupFeedbackAdcEdtFiadorPJ = false;
    },

    async lidarAdicionarConjuge() {
      if (
        !this.validarDocumentosParaRequisicao(
          this.representanteOuFiador.TipoParteId
        )
      )
        return;

      let parteCPF = this.removerMascaraCPFouCNPJ(
        this.representanteOuFiador.CPF
      );
      this.desabilitarAdicionarConjuge = true;

      let data = {
        CPF: parteCPF,
        Nome: this.representanteOuFiador.Nome,
        TipoParteId: this.representanteOuFiador.TipoParteId,
        ContratoId: this.ccg.Contrato.ContratoId,
        Email: this.representanteOuFiador.Email,
        ParteId: this.representanteOuFiador.ParteId
      };

      await this.$onpoint.loading(async () => {
        return await ccgController
          .adicionarConjuge(data)
          .then(async () => {
            await this.obterCCG();
          })
          .then(async () => {
            await this.ccgPodeSerEnviadoParaAssinatura();
          })
          .then(() => {
            this.exibirAdcEdtFeedbackPopupConjuge();
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          })
          .finally(() => {
            this.desabilitarAdicionarConjuge = false;
          });
      });
    },

    exibirAdcPopupConjuge(evento) {
      this.editarConjuge = false;
      this.limparParte();
      this.representanteOuFiador.ParteId = evento.parteId;
      this.representanteOuFiador.ContratoId = evento.contratoId;
      this.representanteOuFiador.TipoParteId = evento.tipoParteId;
      this.popupAdcEdtConjuge = true;
    },

    exibirAdcEdtFeedbackPopupConjuge() {
      this.popupAdcEdtConjuge = false;
      this.popupFeedbackAdcEdtConjuge = true;
    },

    fecharAdcEdtFeedbackPopupRepConjuge() {
      this.popupFeedbackAdcEdtConjuge = false;
    },

    async verficarSeParteJaCadastrada() {
      this.parteJaCadstrada = false;
      if (
        !this.validarDocumentosParaRequisicao(
          this.representanteOuFiador.TipoParteId
        )
      )
        return;

      let contratoId = this.ccg.Contrato.ContratoId;
      let parteCPF = this.removerMascaraCPFouCNPJ(
        this.representanteOuFiador.CPF
      );

      await this.$onpoint.loading(async () => {
        return await ccgController
          .verificarParteJaFoiCadastradaTomador(contratoId, parteCPF)
          .then(data => {
            this.parteJaCadstrada = data;
          })
          .then(() => {
            this.lidarComParteJaCadastrada();
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },

    lidarComParteJaCadastrada() {
      if (this.parteJaCadstrada) {
        this.exibirPopupAlertaParteCadastrada();
      } else {
        this.lidarComVariacoesAdicionarParte();
      }
    },

    lidarComVariacoesAdicionarParte() {
      if (this.representanteOuFiador.TipoParteId == 5) {
        this.lidarAdicionarConjuge();
      } else {
        this.lidarAdicionarParte();
      }
    },

    exibirPopupAlertaParteCadastrada() {
      this.popupAdcEditRepresentanteFiadorPF = false;
      this.popupAdcFiadorPJ = false;
      this.popupAlertaParteCadastrada = true;
    },

    fecharPopupAlertaParteCadastrada() {
      this.popupAlertaParteCadastrada = false;
    },

    async lidarEditarParte() {
      if (
        !this.validarDocumentosParaRequisicao(
          this.representanteOuFiador.TipoParteId
        )
      )
        return;
      this.desabilitarAdcEdtRepresentanteFiadorPF = true;
      this.desabilitarEditarFiadorPJ = true;

      let parteCPF = this.removerMascaraCPFouCNPJ(
        this.representanteOuFiador.CPF
      );
      let parteCNPJ = this.removerMascaraCPFouCNPJ(
        this.representanteOuFiador.CNPJ
      );

      let data = {
        ParteId: this.representanteOuFiador.ParteId,
        Email: this.representanteOuFiador.Email,
        Nome: this.representanteOuFiador.Nome,
        TipoParteId: this.representanteOuFiador.TipoParteId,
        NomeEmpresa: this.representanteOuFiador.NomeEmpresa,
        CPF: parteCPF,
        CNPJ: parteCNPJ
      };

      await this.$onpoint.loading(async () => {
        return await ccgController
          .editarParte(data)
          .then(async () => {
            await this.obterCCG();
          })
          .then(async () => {
            await this.ccgPodeSerEnviadoParaAssinatura();
          })
          .then(() => {
            if (this.representanteOuFiador.TipoParteId === 2) {
              this.exibirAdcEdtFeedbackPopupFiadorPJ();
            } else {
              this.exibirAdcEdtFeedbackPopupRepFiadorPF();
            }
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          })
          .finally(() => {
            this.desabilitarAdcEdtRepresentanteFiadorPF = false;
            this.desabilitarEditarFiadorPJ = false;
            this.editarRepresentanteFiadorPJ =false
          });
      });
    },

    exibirEdicaoDados(evento) {
      this.limparParte();
      this.representanteOuFiador = Utils.deepClone(evento.parte);

      if (this.representanteOuFiador.TipoParteId === 2) {
        this.editarFiadorPJ = true;
        this.popupEdtFiadorPj = true;
      } else {
        this.editarRepresentanteFiadorPF = true;
        this.popupAdcEditRepresentanteFiadorPF = true;
      }
    },

    async lidarExcluirParte() {
      this.desabilitarExcluirParte = true;
      let data = {
        ParteId: this.parteASerExcluida.ParteId,
        ContratoId: this.parteASerExcluida.ContratoId
      };

      await this.$onpoint.loading(async () => {
        return await ccgController
          .excluirParte({ data })
          .then(async () => {
            await this.obterCCG();
          })
          .then(async () => {
            await this.ccgPodeSerEnviadoParaAssinatura();
          })
          .then(() => {
            this.exibirExcluirParteFeedbackPopup();
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          })
          .finally(() => {
            this.desabilitarExcluirParte = false;
          });
      });
    },

    exibirExcluirPartePopup(evento) {
      this.popupExcluirParte = true;
      this.limparParteASerExcluida();
      this.parteASerExcluida.ParteId = evento.parteId;
      this.parteASerExcluida.ContratoId = evento.contratoId;
      this.parteASerExcluida.TipoParteName = evento.tipoParteName;
    },

    fecharPopupExcluirParte() {
      this.popupExcluirParte = false;
    },

    exibirExcluirParteFeedbackPopup() {
      this.popupExcluirParte = false;
      this.popupFeedbackExcluirParte = true;
    },

    fecharFeedbackPopupExcluirParte() {
      this.popupFeedbackExcluirParte = false;
    },

    lidarComCopiarLinkAssinatura(evento) {
      try {
        let linkAssinatura = evento.parte.LinkAssinatura;
        if (!linkAssinatura)
          throw new Error("Parte não possui link de assinatura individual");
        this.popupLinkAssinatura = true;
        this.copyToClipboard(linkAssinatura);
      } catch (error) {
        this.$notify({
          title: "Erro",
          message: "Parte não possui link de assinatura individual",
          type: "error",
          position: "bottom-right"
        });
      }
    },

    fecharPopupLinkAssinatura() {
      this.popupLinkAssinatura = false;
    },

    async copyToClipboard(myLink) {
      try {
        await navigator.clipboard.writeText(myLink);
        this.$notify({
          title: "Sucesso",
          message: "Link de assinatura copiado!",
          type: "success",
          position: "bottom-right"
        });
      } catch ($e) {}
    },

    async lidarComReenviarEmailIndividual(evento) {
      let parteId = evento.parteId;
      let contratoId = evento.contratoId;

      await this.$onpoint.loading(async () => {
        return await ccgController
          .reenviarEmailIndividual(contratoId, parteId)
          .then(() => {
            this.popupFeedbackReenvioEmailIndividual = true;
          })
          .then(async () => {
            await this.obterCCG();
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },

    fecharPopupReenvioEmailIndividual() {
      this.popupFeedbackReenvioEmailIndividual = false;
    },

    async lidarComReenviarEmailTodos() {
      let contratoId = this.ccg.Contrato.ContratoId;

      await this.$onpoint.loading(async () => {
        return await ccgController
          .reenviarEmailTodos(contratoId)
          .then(async () => {
            await this.obterCCG();
          })
          .then(() => {
            this.popupFeedbackReenvioEmailTodos = true;
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },

    fecharPopupReenvioEmailTodos() {
      this.popupFeedbackReenvioEmailTodos = false;
    },

    async fecharPopupUploadManual() {
      await this.obterCCG();
      this.uploadModal = false;
    },

    async callbackUploadManual(callback) {
      this.uploadModal = false;

      await this.$onpoint.loading(async () => {
        return await this.obterCCG().then(async () => {
          await this.ccgPodeSerEnviadoParaAssinatura();
        });
      });
    },

    lidarDownloadDocumento() {
      if (this.ccg.Contrato.StatusId === 3) {
        if (this.ccg.Contrato.EnderecoDocumentoAssinado) {
          let urlDocumentoAssinado = this.ccg.Contrato
            .EnderecoDocumentoAssinado;
          window.open(urlDocumentoAssinado, "_self");
        } else {
          this.$onpoint.successModal("Documento não encontrado");
        }
      } else {
        this.downloadDocumentoConferenciaOuAssinado();
      }
    },

    async downloadDocumentoConferenciaOuAssinado() {
      let contratoId = this.ccg.Contrato.ContratoId;

      await this.$onpoint.loading(async () => {
        return await ccgController
          .gerarDocumento(contratoId)
          .then(data => {
            window.open(data, "_self");
          })
          .then(async () => {
            await this.ccgPodeSerEnviadoParaAssinatura();
          })
          .then(() => {
            this.$onpoint.successModal("Download concluído com sucesso");
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },

    async lidarComAssinarCCG() {
      if (this.deveExibirTermoDeResponsabilidade) {
        let tomadorId = this.tomador.Id;
        this.limparTermoResponsabilidade();

        await this.$onpoint.loading(async () => {
          return await ccgController
            .buscarTermoResponsabilidade(tomadorId)
            .then(data => {
              this.termoDeResponsabilidade.Texto = data.Texto;
              this.termoDeResponsabilidade.Id = data.Id;
            })
            .then(() => {
              this.aceiteTermoResponsabilidade = false;
              this.popupTermoResponsabilidade = true;
            })
            .catch(error => {
              this.$onpoint.errorModal(error.response.data.Errors);
            });
        });
      } else {
        this.assinarCCG();
      }
    },

    async aceitarTermoResponsabilidade() {
      let data = {
        Id: this.termoDeResponsabilidade.Id,
        Texto: this.termoDeResponsabilidade.Texto,
        TomadorId: this.tomador.Id
      };

      await this.$onpoint.loading(async () => {
        return await ccgController
          .aceitarTermoResponsabilidade(data)
          .then(() => {
            this.assinarCCG();
          })
          .then(async () => {
            await this.obterCCG();
            await this.ccgPodeSerEnviadoParaAssinatura();
          })
          .then(() => {
            this.fecharTermoResponsabilidade();
          })
          .catch(error => {
            this.fecharTermoResponsabilidade();
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },

    fecharTermoResponsabilidade() {
      this.popupTermoResponsabilidade = false;
    },

    async assinarCCG() {
      let contratoId = this.ccg.Contrato.ContratoId;

      await this.$onpoint.loading(async () => {
        return await ccgController
          .enviarParaAssinatura(contratoId)
          .then(async () => {
            await this.$onpoint.successModal(
              "Solicitação de assinatura realizada com sucesso! Aguarde o retorno das partes assinadas."
            );
          })
          .then(async () => {
            await this.obterCCG();
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },

    async cancelarEnvioParaAssinatura() {
      let contratoId = this.ccg.Contrato.ContratoId;

      await this.$onpoint.loading(async () => {
        return await ccgController
          .cancelarEnvioParaAssinatura(contratoId)
          .then(() => {
            this.fecharPopupConfirmacaoCancelamentoEnvioAss();
          })
          .then(() => {
            this.popupFeedbackConfirmacaoCancelamentoEnvioAss = true;
          })
          .then(async () => {
            await this.obterCCG();
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },

    fecharPopupConfirmacaoCancelamentoEnvioAss() {
      this.popupConfirmacaoCancelamentoEnvioAss = false;
    },

    fecharPopupFeedbackConfirmacaoCancelamentoEnvioAss() {
      this.popupFeedbackConfirmacaoCancelamentoEnvioAss = false;
    },

    async cancelarCCGAssinado(){
      let contratoId = this.ccg.Contrato.ContratoId;

      await this.$onpoint.loading(async () => {
        return await ccgController
          .cancelarCCGAssinado(contratoId)
          .then(() => {
            this.fecharPopupConfirmacaoCancelamentoCCGAssinado();
          })
          .then(() => {
            this.popupFeedbackConfirmacaoCancelamentoCCGAssinado = true;
          })
          .then(async () => {
            await this.obterCCG();
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },

    fecharPopupConfirmacaoCancelamentoCCGAssinado() {
      this.popupConfirmacaoCancelamentoCCGAssinado = false;
    },

    fecharPopupFeedbackConfirmacaoCancelamentoCCGAssinado() {
      this.popupFeedbackConfirmacaoCancelamentoCCGAssinado = false;
    }
  },
  async created() {
    this.permissoes = this.permissoesCCG;

    await this.$onpoint.loading(async () => {
      return await this.obterCCG()
        .then(async () => await this.ccgPodeSerEnviadoParaAssinatura())
        .finally(() => this.loadCompleted = true);
    });
  },
  mounted() {
    this.isMapfre = compareCompanyUtils("companyName", "mapfre");
  },
};
</script>

<style lang="scss" scoped>
.hide {
  display: none;
}

.ccg {
  display: flex;
  flex-direction: column;
}

.view-changes {
  margin-top: 36px;
  align-self: flex-end;
}

.representatives-alerts {
  margin: 36px 0 0;

  &-content {
    margin-top: 28px;
  }

  &-add {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 28px;
    cursor: pointer;

    &-btn {
      height: 40px;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      padding: 12px 18px;

      i {
        margin-right: 8px;
      }
    }

    &-btn:hover {
      background: rgba(var(--vs-primary), 0.8) !important;
    }
  }
}

.add-edit-rep-guarantor-pf-popup,
.add-guarantor-pj-popup,
.add-guarantor-pj-add-rep-popup,
.edit-guarantor-pj-popup,
.add-edit-spouse-pf-popup {
  &-content {
    &-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 16px;
      border-bottom: 1px solid #747474;

      & h3 {
        text-align: center;
        margin-top: 8px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #2c2c2c;
        word-break: break-word;
      }
    }

    &-body {
      padding: 36px 0;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #747474;

      & label {
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        color: #79747e;
      }

      &-input {
        margin-bottom: 36px;
      }

      &-input:last-child {
        margin-bottom: 0;
      }

      &-add-rep {
        align-self: flex-end;
        width: fit-content;
      }
    }
  }

  &-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 26px 0 16px;

    &-btn-pj-guarantor {
      margin: 10px 0 20px;
    }
  }
}

.add-edit-rep-guarantor-pf-feedback-popup,
.add-guarantor-pj-feedback-popup,
.add-edit-spouse-pf-feedback-popup {
  &-content {
    &-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 16px;
      border-bottom: 1px solid #747474;

      & h3 {
        text-align: center;
        margin-top: 8px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #2c2c2c;
        word-break: break-word;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-bottom: 1px solid #747474;

      &-title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 36px auto;
        word-break: break-word;

        & p {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #009045;
        }
      }

      &-infos {
        padding: 0 24px 36px;

        &-list {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        &-divider {
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &-title {
          font-size: 12px;
          line-height: 14px;
          font-weight: 700;
          color: #79747e;
        }

        &-content {
          font-size: 16px;
          line-height: 18px;
          font-weight: 400;
          color: #000000;
        }
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 26px 0 16px;
  }
}

.resend-email-popup {
  &-content {
    &-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 36px;
      border-bottom: 1px solid #747474;

      & h3 {
        margin-top: 8px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #2c2c2c;
        text-align: center;
        word-break: break-word;
      }
    }

    &-body {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #747474;
      padding: 36px;

      &-title {
        display: flex;
        justify-content: center;
        align-items: center;

        & p {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #009045;
        }
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 26px 0 16px;
  }
}

.sign-link-popup {
  &-content {
    &-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 36px;
      border-bottom: 1px solid #747474;

      & h3 {
        margin-top: 8px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #2c2c2c;
        text-align: center;
        word-break: break-word;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #747474;
      padding: 36px;

      &-alert {
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 26px 0 16px;
  }
}

.registered-part-popup {
  &-content {
    &-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 36px;
      border-bottom: 1px solid #747474;

      & h3 {
        margin-top: 8px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #2c2c2c;
        word-break: break-word;
        text-align: center;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #747474;
      padding: 36px;

      &-alert {
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
        word-break: break-word;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 26px 0 16px;
  }
}

.cancellation-submission-signature-popup,
.cancellation-signed-ccg-popup {
  &-content {
    &-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 36px;
      border-bottom: 1px solid #747474;

      & h3 {
        margin-top: 8px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #2c2c2c;
        word-break: break-word;
        text-align: center;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #747474;
      padding: 36px;

      &-alert {
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
        word-break: break-word;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 26px 0 16px;
  }
}

.cancellation-submission-signature-feedback-popup,
.cancellation-signed-ccg-feedback-popup {
  &-content {
    &-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 36px;
      border-bottom: 1px solid #747474;

      & h3 {
        margin-top: 8px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #2c2c2c;
        text-align: center;
        word-break: break-word;
      }
    }

    &-body {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #747474;
      padding: 36px;

      &-title {
        display: flex;
        justify-content: center;
        align-items: center;

        & p {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #009045;
          margin-left: 16px;
          word-break: break-word;
        }
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 26px 0 16px;
  }
}

.delete-rep-popup,
.delete-rep-feedback-popup {
  &-content {
    &-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 36px;
      border-bottom: 1px solid #747474;

      & h3 {
        text-align: center;
        margin-top: 8px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #2c2c2c;
        word-break: break-word;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #747474;
      padding: 36px;

      &-title {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        word-break: keep-all;
      }

      &-alert {
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
        word-break: break-word;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 26px 0 16px;
  }
}

.resp-term-popup {
  &-content {
    &-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 36px;
      border-bottom: 1px solid #747474;

      & h3 {
        margin-top: 8px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #2c2c2c;
        text-align: center;
        word-break: break-word;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #747474;
      padding: 36px;

      &-title {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 36px;
        word-break: break-word;
      }

      &-checkbox {
        margin: 32px 16px 16px;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 26px 0 16px;
  }
}

.edit-ccg-popup {
  &-content {
    &-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 36px;
      border-bottom: 1px solid #747474;

      & h3 {
        margin-top: 8px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #2c2c2c;
        text-align: center;
        word-break: break-word;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #747474;
      padding: 36px;

      &-title {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 36px;
        word-break: keep-all;
      }

      &-alert {
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 26px 0 16px;
  }
}

.upload_ccg {
  display: flex;
  margin-bottom: 16px;
}
</style>
