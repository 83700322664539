var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("Card", {
        staticClass: "card",
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "card-content-data-cancelamento" },
                  [
                    _c(
                      "h3",
                      { staticClass: "card-content-data-cancelamento-title" },
                      [_vm._v("Endosso")]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "card-content-data-cancelamento-subtitle",
                      },
                      [_vm._v("Informações para cancelamento")]
                    ),
                    _c("OnpointInput", {
                      staticClass: "w-1/4",
                      attrs: {
                        label: "Data de cancelamento",
                        required: true,
                        calendar: true,
                      },
                      model: {
                        value: _vm.dataCancelamento,
                        callback: function ($$v) {
                          _vm.dataCancelamento = $$v
                        },
                        expression: "dataCancelamento",
                      },
                    }),
                  ],
                  1
                ),
                _c("Divider", { staticClass: "card-content-divisor" }),
                _c(
                  "div",
                  { staticClass: "card-content-dados-bancarios-tomador" },
                  [_c("DadosBancariosTomador")],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "flex justify-end card-footer" },
                  [
                    _c("Button", {
                      staticClass: "p-button-rounded cancel-button",
                      attrs: { label: "Cancelar" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({ path: "/" })
                        },
                      },
                    }),
                    _c("Button", {
                      staticClass: "p-button-rounded",
                      attrs: { label: "Próximo" },
                      on: {
                        click: function ($event) {
                          return _vm.nextPage()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }