var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      (_vm.camposObjeto || []).length > 0 &&
      (_vm.camposObjeto || []).filter((x) => x.Type != "Automatic").length > 0
        ? _c(
            "vs-row",
            {
              staticClass: "pt-5 flex",
              attrs: { id: "tags", "vs-align": "flex-end" },
            },
            [
              _vm._l(_vm.camposObjeto || [], function (tag, index) {
                return [
                  tag.Type != "Automatic"
                    ? _c(
                        "vs-col",
                        {
                          key: index,
                          staticClass: "tag-col pt-10",
                          attrs: { "vs-lg": "3", "vs-sm": "12" },
                        },
                        [
                          tag.Label === "Conforme" || tag.Label === "Referente"
                            ? _c(
                                "vx-tooltip",
                                {
                                  staticClass: "enable-tag",
                                  attrs: {
                                    position: "center top",
                                    text:
                                      tag.Label === "Conforme"
                                        ? _vm.tooltip_conforme
                                        : _vm.tooltip_referente,
                                  },
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("info"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _c("onpoint-input", {
                            attrs: {
                              input: tag,
                              validatorRuleObject: { required: true },
                              disabledTagFieldsEndosso:
                                _vm.disabledTagFieldsEndosso,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.handleInput(tag, $event)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _c("vs-row", [
        _c("div", { staticClass: "w-full mt-8" }, [
          _c("p", { staticClass: "font-semibold" }, [_vm._v("Objeto")]),
          _c("p", {
            staticClass: "text-objeto p-5",
            domProps: {
              innerHTML: _vm._s(
                _vm.currentTab == "1" && _vm.endosso.IsCompleteChangeObject
                  ? _vm.endosso.CompleteObject
                  : _vm.textoObjetoHtml
              ),
            },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }