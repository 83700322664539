var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [_c("Breadcrumb", { attrs: { pages: _vm.pages } })],
      1
    ),
    _c(
      "div",
      [
        _c("EndossoSteps", {
          staticClass: "mt-5 mb-5 steps",
          attrs: { steps: _vm.steps },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }