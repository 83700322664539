<template>
  <div>
    <h3>Dados bancários do Tomador</h3>
    <div class="flex gap-4">
      <OnpointInput
        class="mt-8 w-1/2"
        label="Data de inicio da vigencia"
        v-model="teste"
        :required="true"
        :calendar="true"
      />
      <OnpointSelect
        class="mt-8 w-1/2"
        label="Modalidade principal"
        placeholder="Selecione"
        :required="true"
        :items="[0,1,2]"
        optionLabel="Name"
        v-model="teste"
        :disabled="false"
      ></OnpointSelect>
    </div>
    <div class="flex gap-4">
      <OnpointInput
        class="mt-8 w-1/4"
        label="Data de inicio da vigencia"
        v-model="teste"
        :required="true"
        :calendar="true"
      />
      <OnpointInput
        class="mt-8 w-1/4"
        label="Data de inicio da vigencia"
        v-model="teste"
        :required="true"
        :calendar="true"
      />
      <OnpointInput
        class="mt-8 w-1/4"
        label="Prazo em dias"
        v-model="teste"
        :required="true"
      />
      <OnpointInput
        class="mt-8 w-1/4"
        label="Data de final da vigencia"
        v-model="teste"
        :required="true"
        :calendar="true"
        :calendarDisabled="true"
      />
    </div>
  </div>
</template>

<script>
import OnpointInput from "@/components/onpoint-input";
import OnpointSelect from "@/components/onpoint-select/index.vue";

export default {
  name: "dados-bancarios-do-tomador",
  components: {
    OnpointSelect,
    OnpointInput,
  },
  data() {
    return {
        teste: null,
    };
  },
};
</script>

<style lang="scss"></style>
