<template>
  <section class="modality-detail" v-if="details && objectDetails">
    <vs-row
      v-if="
        $route.params.modalidadeId &&
          !complementary &&
          objectDetails.hasOwnProperty('Id')
      "
    >
      <vs-col js-justify="flex-end">
        <h5 class="update-date">
          Atualizado em
          {{ $utils.dateToDDMMYYYY((objectDetails || {}).UpdateDate) }}
        </h5>
      </vs-col>
    </vs-row>

    <!-- v-if="
      ($route.params.modalidadeId && complementary) ||
        !details.hasOwnProperty('Id')
    " -->

    <vs-row>
      <vs-col vs-w="12" vs-sm="12">
        <vs-input
          class="w-full"
          label="Nome da modalidade"
          v-model="objectDetails.Name"
        />
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-w="4" vs-sm="12">
        <p class="label-input">Valor máximo segurado</p>
        <currency-input
          class="
            w-full
            vs-inputx
            vs-input--input
            hasValue
            vs-input--input
            normal
          "
          v-model="objectDetails.MaxInsuredAmountValue"
        />
      </vs-col>

      <vs-col vs-w="4" vs-sm="12">
        <p class="label-input">Valor máximo aprovado automaticamente</p>
        <currency-input
          class="
            w-full
            vs-inputx
            vs-input--input
            hasValue
            vs-input--input
            normal
          "
          v-model="objectDetails.MaxInsuredAmountAutomaticValue"
        />
      </vs-col>

      <vs-col vs-w="4" vs-sm="12">
        <p class="label-input">Prêmio mínimo</p>
        <currency-input
          class="
            w-full
            vs-inputx
            vs-input--input
            hasValue
            vs-input--input
            normal
          "
          v-model="objectDetails.MinInsurancePremium"
        />
      </vs-col>

      <vs-col vs-w="3" vs-sm="12" v-if="false">
        <p class="label-input">Importância Segurada mínima</p>
        <currency-input
          class="
            w-full
            vs-inputx
            vs-input--input
            hasValue
            vs-input--input
            normal
          "
          :value="0"
          :readonly="true"
        />
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-w="6" vs-sm="12" vs-xs="12">
        <vs-input
          class="w-full"
          label="Max. dias retroativos"
          v-model="objectDetails.MaxRetroactivityDays"
        />
      </vs-col>

      <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
        <vs-input
          class="w-full"
          label="Max. dias futuros"
          v-model="objectDetails.MaxFutureDays"
        />
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-w="4" vs-sm="12" vs-xs="12">
        <vs-input
          class="w-full"
          label="Vigência mínima (dias)"
          v-model="objectDetails.MinPeriodInDays"
        />
      </vs-col>

      <vs-col vs-w="4" vs-sm="12" vs-xs="12">
        <vs-input
          class="w-full"
          label="Vigência máxima (dias)"
          v-model="objectDetails.MaxPeriodInDays"
        />
      </vs-col>
      <vs-col vs-w="4" vs-sm="12" vs-xs="12">
        <vs-input
          class="w-full"
          label="Código de referência da seguradora"
          v-model="objectDetails.Ref"
        />
      </vs-col>
    </vs-row>

    <vs-row v-if="objectDetails.CanUseModalityTax">
      <vs-col vs-w="4" vs-sm="12" vs-xs="12">
        <label class="vs-input--label">Selecione o tipo</label>
        <v-select
          label="name"
          size="large"
          placeholder="Tipo"
          class="w-full"
          :options="options"
          :value="objectDetails.CalculationType"
          :reduce="option => option.value"
          v-model="objectDetails.CalculationType"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />
      </vs-col>

      <vs-col vs-w="4" vs-sm="12" vs-xs="12">
        <vs-input
          class="w-full"
          label="Valor %"
          v-model="objectDetails.ModalityTaxValue"
        />
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-w="12" vs-sm="12" class="mt-8 md:pb-3">
        <div class="flex">
          <vs-switch class="mr-3" v-model="objectDetails.IsDeleted" />
          <label>Inativar modalidade</label>
        </div>
        <div v-if="objectDetails.IsDeleted" style="margin: 10px;">
          <label style="font-weight:bold"> Observação:</label><br />
          <el-input
            type="textarea"
            :rows="2"
            placeholder="Observação"
            v-model="objectDetails.Observation"
          >
          </el-input>
        </div>

        <div class="flex pt-3">
          <vs-switch
            class="mr-3"
            v-model="objectDetails.EnabledForAutomaticEmission"
          />
          <label>Modalidade habilitada para emissão automática</label>
        </div>

        <div class="flex pt-3">
          <vs-switch
            class="mr-3"
            v-model="objectDetails.IsComplementaryModality"
            disabled
          />
          <label
            >Esta modalidade está atuando como modalidade complementar</label
          >
        </div>

        <div class="flex pt-3" v-if="true">
          <vs-switch
            class="mr-3"
            v-model="objectDetails.HasComplementaryModality"
            :disabled="objectDetails.IsComplementaryModality"
          />
          <label
            >Esta modalidade permite a inclusão de modalidade
            complementar</label
          >
        </div>

        <div class="flex pt-3">
          <vs-switch
            class="mr-3"
            v-model="objectDetails.DisabledForRestrictiveBranchActivity"
          />
          <label
            >Esta modalidade é restrita para os tomadores com ramos
            restritos</label
          >
        </div>

        <div class="flex pt-3">
          <vs-switch
            class="mr-3"
            v-model="objectDetails.EqualizeValidityDate"
          />
          <label
            >As vigências entre as modalidades e cobertura devem ser
            iguais</label
          >
        </div>

        <div class="flex pt-3">
          <vs-switch
            class="mr-3"
            v-model="objectDetails.IgnoreBranchWhenInsuredIsPF"
          />
          <label>Permite emissão com segurado Pessoa Física</label>
        </div>

        <div class="flex pt-3">
          <vs-switch
            class="mr-3"
            v-model="objectDetails.CanHaveBeneficiary"
            @change="objectDetails.CanHaveBeneficiaryPF = false"
          />
          <label>Permite inclusão de beneficiário</label>
        </div>

        <div class="flex pt-3" v-if="!!objectDetails.CanHaveBeneficiary">
          <vs-switch
            class="mr-3"
            :value="!!objectDetails.CanHaveBeneficiaryPF"
            @input="
              objectDetails.CanHaveBeneficiaryPF = $event;
              $forceUpdate();
            "
          />
          <label>Permite inclusão de beneficiário Pessoa Física</label>
        </div>

        <div class="flex pt-3">
          <vs-switch class="mr-3" v-model="details.EnableToMonitoring" />
          <label>Esta modalidade é monitorada</label>
        </div>

        <div class="flex pt-3">
          <vs-switch class="mr-3" v-model="details.EnableToSteppin" />
          <label
            >Esta modalidade pode realizar a retomada do objeto (Step-in)</label
          >
        </div>

        <div class="flex pt-3">
          <vs-switch class="mr-3" v-model="details.EnableToBigRisk" />
          <label>Esta modalidade é de grande risco</label>
        </div>

        <div class="flex pt-3">
          <vs-switch
            class="mr-3"
            v-model="objectDetails.EnableAutomaticCancel"
            @change="$forceUpdate()"
          />
          <label>Cancelamento automático de apólices vencidas</label>
        </div>

        <div v-if="Newe" class="flex pt-3">
          <vs-switch
            class="mr-3"
            v-model="objectDetails.CanUseModalityTax"
            @change="$forceUpdate()"
          />
          <label>Permite de uso de taxa por modalidade</label>
        </div>

        
        <div class="flex pt-3" v-if="exibirHabilitarEsteiraDataRetroativa">
          <vs-switch
            class="mr-3"
            v-model="objectDetails.HabilitarEsteiraDataRetroativa"
            @change="$forceUpdate()"
          />
          <label>Habilitar esteira de data retroativa</label>
        </div>
      </vs-col>
    </vs-row>

    <vs-row v-if="objectDetails.EnableAutomaticCancel">
      <vs-col class="md:w-1/2 w-full">
        <vs-input
          label="Nº dias após vencimento"
          v-model="objectDetails.DaysForAutomaticCancel"
        />
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="end">
        <el-button
          type="primary"
          @click="saveDetails(objectDetails)"
          :loading="loadingButton"
          >Salvar detalhes</el-button
        >
      </vs-col>
    </vs-row>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import compareCompanyUtils from "@/utils/compareCompanyUtils.js";

export default {
  name: "modality-details",
  props: {
    details: Object,
    complementary: Boolean,
    mainModalityId: {
      type: Number,
      required: false
    },
    permissoesModalidade: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      Newe: false,
      loadingButton: false
    };
  },
  created() {
    if (this.details.Id) {
      this.$emit("update-details", {
        ...this.details,
        CanHaveBeneficiary: !!this.details.CanHaveBeneficiary,
        CanHaveBeneficiaryPF: !!this.details.CanHaveBeneficiaryPF
      });
    }
  },
  mounted() {
    this.Newe = compareCompanyUtils("companyName", "newe");
  },
  computed: {
    options() {
      let options = [
        {
          value: "Agravation",
          name: "Agravamento"
        },
        {
          value: "Reduce",
          name: "Redução"
        }
      ];

      return options;
    },
    objectDetails: {
      get() {
        if (!!this.details) return this.details;
        return {
          DisabledForRestrictiveBranchActivity: false,
          EnabledForAutomaticEmission: false,
          EqualizeValidityDate: false,
          HasComplementaryModality: false,
          IgnoreBranchWhenInsuredIsPF: false,
          IsComplementaryModality: false,
          MaxFutureDays: null,
          MaxInsuredAmountAutomaticValue: null,
          MaxInsuredAmountValue: null,
          MaxPeriodInDays: null,
          MaxRetroactivityDays: null,
          MinInsurancePremium: null,
          MinPeriodInDays: null,
          EnableAutomaticCancel: false,
          CanHaveBeneficiary: false,
          CanHaveBeneficiaryPF: false,
          DaysForAutomaticCancel: 0,
          Name: null,
          Ref: null,
          IsDeleted: false,
          CanUseModalityTax: false,
          CalculationType: null,
          ModalityTaxValue: 0,
          HabilitarEsteiraDataRetroativa: false,
        };
      },

      set(value) {
        this.$emit("update-details", value);
      }
    },
    exibirHabilitarEsteiraDataRetroativa() {    
      return this.permissoesModalidade.includes("ExibirEsteiraDataRetroativa");
    },
  },
  methods: {
    ...mapActions("seguradora-module", [
      "updateModality",
      "saveModality",
      "insertComplementaryModality"
    ]),
    async saveDetails(detailsModality) {
      let error = !detailsModality ? true : false;
      Object.entries(detailsModality).forEach(([key, value]) => {
        if ((value === "" || value === null) && key !== "Ref") {
          error = true;
          console.log(key);
          if (
            key === "CalculationType" &&
            detailsModality.CanUseModalityTax == false
          ) {
            error = false;
          }
          if (
            key === "DaysForAutomaticCancel" &&
            detailsModality.EnableAutomaticCancel == false
          ) {
            error = false;
          }
        }
      });
      if (error) {
        this.$onpoint.errorModal("Todos os valores são obrigatórios");
        return;
      }

      this.loadingButton = true;
      this.$vs.loading();

      if (this.$route.params.modalidadeId) {
        if (this.complementary && !detailsModality.Id) {
          // Seta a modalidade como complementar
          detailsModality.IsComplementaryModality = true;
          // Cria a modalidade complementar
          return await this.saveModality({
            ...detailsModality,
            ModalityGroupId: this.$route.params.modalityGroupId,
            BranchId: this.$route.params.branchId
          })
            .then(async modality => {
              // Adiciona a ligação entre a modalide principal e a modalidade complementar
              return await this.insertComplementaryModality({
                modalityId: this.mainModalityId,
                complementaryModalityId: modality.Id
              })
                .then(resp => {
                  // Recarrega a pagina para pegar os novos dados da modalidade principal
                  this.$onpoint.successModal(
                    "Modalidade complementar criada com sucesso."
                  );
                  this.$router.go();
                  this.$forceUpdate();
                  this.$vs.loading.close();
                  this.loadingButton = false;
                })
                .catch(err => {
                  this.loadingButton = false;
                  this.$vs.loading.close();
                  this.$onpoint.errorModal(
                    ex.response.data.Errors.length
                      ? ex.response.data.Errors
                      : ex.response.data.Response
                  );
                });
            })
            .catch(ex => {
              this.loadingButton = false;
              this.$vs.loading.close();
              this.$onpoint.errorModal(
                ex.response.data.Errors.length
                  ? ex.response.data.Errors
                  : ex.response.data.Response
              );
            });
        } else {
          // Update na modalidade ou modalidade complementar
          return await this.updateModality(detailsModality)
            .then(modality => {
              this.$vs.loading.close();
              this.loadingButton = false;
              this.$onpoint.successModal("Modalidade atualizada com sucesso.");
              this.$emit("modalitySaved", detailsModality.Id);
            })
            .catch(ex => {
              this.$vs.loading.close();
              this.loadingButton = false;
              this.$onpoint.errorModal(
                ex.response.data.Errors.length
                  ? ex.response.data.Errors
                  : ex.response.data.Response
              );
            });
        }
      } else {
        // Cria a modalidade
        return await this.saveModality({
          ...detailsModality,
          ModalityGroupId: this.$route.params.modalityGroupId,
          BranchId: this.$route.params.branchId
        })
          .then(modality => {
            this.$onpoint.successModal("Modalidade adicionada com sucesso.");
            // Mudar para a rota de edição de modalidade
            this.$router.push({
              name: "param-configurar-modalidade",
              params: {
                modalidadeId: modality.Id,
                modalidadeUniqueId: modality.UniqueId
              }
            });
            this.$vs.loading.close();
            this.loadingButton = false;
            setTimeout(() => {
              this.$router.go(this.$router.currentRoute);
            }, 500);
          })
          .catch(ex => {
            this.$vs.loading.close();
            this.loadingButton = false;
            this.$onpoint.errorModal(
              ex.response.data.Errors.length
                ? ex.response.data.Errors
                : ex.response.data.Response
            );
          });
      }
    }
  }
};
</script>

<style lang="scss">
.vs-popup {
  width: 600px !important;
}
.modality-detail {
  .update-date {
    color: #8e8e8e;
    font-size: 12px;
    float: right;
  }
}
</style>
