var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("Card", {
        staticClass: "card",
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("div", { staticClass: "title" }, [
                  _c("p", [_vm._v("Informações de risco")]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "subtitle",
            fn: function () {
              return [
                _c("div", { staticClass: "subtitle" }, [
                  _c("p", [_vm._v("Informe dos dados de risco do segurado")]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "grid grid-cols-2 gap-6" },
                    [
                      _c("OnpointSelect", {
                        attrs: {
                          label: "Grupo de modalidade",
                          placeholder: "Selecione",
                          required: true,
                          items: _vm.modalidadesList,
                          optionLabel: "Name",
                        },
                        model: {
                          value: _vm.modalidade,
                          callback: function ($$v) {
                            _vm.modalidade = $$v
                          },
                          expression: "modalidade",
                        },
                      }),
                      _c("OnpointSelect", {
                        attrs: {
                          label: "Modalidade principal",
                          placeholder: "Selecione",
                          required: true,
                          items: _vm.modalidadePrincipalList,
                          optionLabel: "Name",
                          disabled: _vm.modalidade ? false : true,
                        },
                        model: {
                          value: _vm.modalidadePrincipal,
                          callback: function ($$v) {
                            _vm.modalidadePrincipal = $$v
                          },
                          expression: "modalidadePrincipal",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm.modalidadePrincipal
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "mt-8" },
                        [
                          _c("CoberturasAdicionais", {
                            attrs: {
                              modalidadePrincipalUniqueId:
                                _vm.modalidadePrincipal.UniqueId,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-8" },
                        [
                          _c("DadosDaProposta", {
                            attrs: {
                              modalidadePrincipal: _vm.modalidadePrincipal,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.proposta.InsuredAmountValue &&
                      _vm.proposta.DeadlineDays
                        ? _c("div", { staticClass: "mt-8" }, [
                            _c("div", [_c("DadosDoObjeto")], 1),
                            _c("div", [_c("DadosModalidadeComplementar")], 1),
                            _c("div", [_c("CondicoesParticulares")], 1),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "flex justify-end" },
                  [
                    _c("Button", {
                      staticClass: "p-button-rounded cancel-button",
                      attrs: { label: "Voltar" },
                      on: {
                        click: function ($event) {
                          return _vm.prevPage()
                        },
                      },
                    }),
                    _c("Button", {
                      staticClass: "p-button-rounded",
                      attrs: { label: "Próximo" },
                      on: {
                        click: function ($event) {
                          return _vm.nextPage()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }