var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grid" }, [
    _c("label", { staticClass: "label" }, [
      _vm._v(_vm._s(_vm.label)),
      _vm.required
        ? _c("i", { staticClass: "required" }, [_vm._v(" *")])
        : _vm._e(),
    ]),
    !_vm.calendar
      ? _c("div", [
          _c("input", {
            staticClass: "inputStyle",
            style: _vm.width ? `width:${_vm.width}` : "width:100%",
            attrs: {
              type: "text",
              id: _vm.currency ? "currencyInput" : "input",
              placeholder: _vm.currency ? "R$" : null,
              disabled: _vm.disabled,
            },
            domProps: { value: _vm.valueFormatted },
            on: {
              input: _vm.formatValue,
              change: function ($event) {
                return _vm.$emit("change", _vm.valueFormatted)
              },
            },
          }),
          _vm.alertRequiredItem
            ? _c("div", { staticClass: "alertMessage" }, [
                _vm._v(
                  _vm._s(
                    _vm.requiredMessage || "Este campo não pode estar vazío."
                  )
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.calendar
      ? _c(
          "div",
          { staticClass: "calendar" },
          [
            _c("Calendar", {
              attrs: { disabled: _vm.calendarDisabled, value: _vm.date },
              on: { input: _vm.formatValue },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }