<template>
  <div>
    <template v-if="dadoFinanceiro.InsurancePremium != null">
      <h4 class="mb-5">Prêmio e comissão</h4>

      <vs-row>
        <vs-col vs-type="flex" vs-justify="end">
          <dados-financeiros-visualizar-alteracoes
            :proposalUniqueId="proposta.UniqueId"
            :currentValues="propostaFinancialData"
            :showDesfazer="!(proposta.BlockChangesInProposal && blockInputs)"
            @reseteChanges="reseteChanges"
          />
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col class="w-4/12">
          <p>
            <b v-if="propostaFinancialData.IsPremiumFixed">Valor do prêmio</b>
            <b v-else
              >Valor do prêmio (Minímo R$ {{ proposta.MinInsurancePremium }})</b
            >
          </p>
          <currency-input
            disabled="true"
            class="w-full vs-inputx vs-input--input large hasValue"
            size="large"
            v-model="dadoFinanceiro.InsurancePremium"
          />
        </vs-col>

        <vs-col class="w-1/6">
          <p class="taxa-aplicada flex">
            <b>Taxa aplicada(%)</b>
            <vx-tooltip
              text="Taxa de risco aplicada sobre o calculo do prêmio."
              position="right"
            >
              <i class="material-icons pl-2">info</i>
            </vx-tooltip>
          </p>

          <vs-input
            class="w-full insurance"
            :disabled="
              propostaFinancialData.IsPremiumFixed ||
                proposta.BlockChangesInProposal ||
                blockInputs ||
                (proposta.ProposalTypeId == 2 && !perfilHelpers.isInsurance())
            "
            v-currency="{
              currency: { prefix: '' },
              locale: 'pt',
              precision: 6
            }"
            size="large"
            @keyup="alteracaoDeValor"
            @blur="atualizaDadosFinanceirosPelaTaxa"
            v-model="dadoFinanceiro.Tax"
          />
        </vs-col>

        <vs-col class="w-4/12">
          <p>
            <b>Valor da comissão</b>
          </p>
          <currency-input
            class="w-full vs-inputx vs-input--input large hasValue"
            disabled="true"
            size="large"
            :value="dadoFinanceiro.ComissionValue"
          />
        </vs-col>

        <vs-col v-if="deveExibirCodQuadroDistribuicaoMapfreEmissao" class="w-1/6">
          <p>
            <b>Código de quadro de distribuição </b>
          </p>
          <vs-input
            class="w-full insurance"
            disabled
            size="large"
            v-model="codigoQuadroDistribuicaoMapfreEmissao"
          />
        </vs-col>

        <vs-col v-else class="w-1/6">
          <p>
            <b>% de comissão</b>
          </p>
          <vs-input
            class="w-full insurance"
            :disabled="
              proposta.BlockChangesInProposal ||
                blockInputs ||
                !perfilHelpers.isInsurance()
            "
            size="large"
            v-model="dadoFinanceiro.ComissionPercentage"
            @keyup="alteracaoDeValor"
            @blur="atualizaDadosFinanceirosPelaComissao"
            v-currency="{
              currency: { prefix: '' },
              locale: 'pt'
            }"
          />
        </vs-col>

        <vs-col vs-w="12" v-if="mostraCorretagem">
          <!-- <vs-checkbox :disabled="blockInputs" v-model="openCocorretagem" class="mt-8"> -->
          <vs-checkbox v-model="openCocorretagem" class="mt-8">
            Adicionar cocorretagem
          </vs-checkbox>
          <transition name="fade">
            <cocorretagem v-if="openCocorretagem"></cocorretagem>
          </transition>
        </vs-col>

        <vs-col  vs-w="12" v-if="mostrarCocorretagemMapfre">
          <vs-checkbox v-model="hasCobrokers" class="mt-8">
            Possui cocorretagem
          </vs-checkbox>

          <transition name="fade">
            <CocorretagemMapfre v-if="hasCobrokers" />
          </transition>
        </vs-col>
      </vs-row>
      <div class="text-right">
        <vs-button
          v-if="
            StringObservations.CanToggleManualReimbursementPremium == 'true'
          "
          @click="abrirPopupMotivoRestituicao"
          color="primary"
          class="mr-2"
        >
          <span class="font-semibold pr-2 pl-2">
            Restituição Total
          </span>
        </vs-button>
        <vs-button
          v-if="
            StringObservations.CanToggleZeroOutReimbursementPremium == 'true'
          "
          @click="zerarPremio(2)"
          color="primary"
          class="mr-2"
        >
          <span class="font-semibold pr-2 pl-2">
            Zerar Prêmio e Comissão
          </span>
        </vs-button>
      </div>

      <div v-if="compareChanges.RequestInsurancePremiumReview">
        <span style="margin-bottom:10px">Motivo da Revisão do Prêmio</span>
        <el-input
          style="    color: #3a3e47!important"
          disabled
          type="textarea"
          :rows="2"
          placeholder="Please input"
          v-model="compareChanges.JustificationInsurancePremiumReview"
        >
        </el-input>
      </div>

      <hr class="my-12 opacity-25" />
    </template>
    <div
      v-if="StringObservations.CanToggleZeroOutReimbursementPremium != 'true'"
    >
      <h4 class="mb-5">Forma de pagamento</h4>
      <vs-row>
        <vs-col class="w-1/4">
          <p>
            <b>Nº de Parcelas</b>
          </p>
          <v-select
            class="w-full insurance"
            label="Description"
            :show-no-options="false"
            v-model="selectedOpcaoDeParcelamento"
            @input="setOpcaoDeParcelamento(selectedOpcaoDeParcelamento)"
            :options="opcoesParcelamento"
            placeholder="Selecione um opção"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            :searchable="false"
          >
            <div slot="no-options">Não há opções de parcelamento</div>
          </v-select>
        </vs-col>

        <vs-col class="w-1/4">
          <p>
            <b>Adicional de fracionamento na parcela (R$)</b>
          </p>
          <currency-input
            class="w-full vs-inputx vs-input--input large hasValue"
            size="large"
            disabled="true"
            :value="
              (selectedOpcaoDeParcelamento || {}).InterestValuePerInstallment ||
                0
            "
          />
        </vs-col>

        <vs-col class="w-1/4">
          <p>
            <b>1ª parcela</b>
          </p>
          <currency-input
            class="w-full vs-inputx vs-input--input large hasValue"
            disabled="true"
            size="large"
            :value="(selectedOpcaoDeParcelamento || {}).Value || 0"
          />
          <small
            v-if="(opcoesParcelamento || []).length > 1"
            class="text-primary cursor-pointer"
            @click="popupParcelas = true"
            >Exibir outras parcelas</small
          >
        </vs-col>

        <vs-col class="w-1/4">
          <p>
            <b>Dias</b>
          </p>
          <v-select
            class="w-full insurance"
            :disabled="blockInputs"
            :show-no-options="false"
            v-model="selectedDiasParaPagamento"
            @input="setOpcaoDiasParaPagamento(selectedDiasParaPagamento)"
            :options="opcoesDiasParaPagamento"
            placeholder="Selecione um opção"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            :searchable="false"
          >
            <div slot="no-options">Não há opções de dias pagamento</div>
          </v-select>
        </vs-col>
      </vs-row>
      <!-- <hr class="my-12 opacity-25" /> -->
    </div>
    <div v-if="proposta.PolicyHolder.BankAccounts.length > 0">
      <h4 class="mb-5">Dados Bancários do Beneficiário:</h4>
      <p class="text-sm font-semibold"></p>
      <br />
      <div
        class="vx-row"
        v-for="(dados, index) in proposta.PolicyHolder.BankAccounts.slice(0, 1)"
        :key="index"
        style=" padding-bottom: 5px; margin-bottom: 10px;"
      >
        <div class="vx-col w-1/3">
          <p class="text-sm font-semibold">Tipo de conta</p>
          <p class="text-lg">{{ dados.BankAccountType.Name }}</p>
        </div>
        <div class="vx-col w-1/3">
          <p class="text-sm font-semibold">Agencia</p>
          <p class="text-lg">
            {{ dados.Branch }}
            <strong v-if="dados.DigitBranch"
              >( {{ dados.DigitBranch }} )</strong
            >
          </p>
        </div>
        <div class="vx-col w-1/3">
          <p class="text-sm font-semibold">Conta</p>
          <p class="text-lg">
            {{ dados.Number }}
            <strong v-if="dados.DigitNumber"
              >( {{ dados.DigitNumber }} )</strong
            >
          </p>
        </div>

        <div class="vx-col w-2/3">
          <br />
          <p class="text-sm font-semibold">Banco</p>
          <p class="text-lg">{{ dados.Bank.Name }}</p>
        </div>
      </div>

      <!-- <hr class="my-12 opacity-25" /> -->
    </div>

    <vs-popup :active.sync="popupParcelas" title="">
      <h3 class="text-primary ml-4">Parcelas</h3>
      <vs-list
        class="p-4"
        v-if="
          selectedOpcaoDeParcelamento &&
            selectedOpcaoDeParcelamento.Installments
        "
      >
        <vs-list-item
          v-for="parcela in selectedOpcaoDeParcelamento.Installments"
          :key="parcela.Id"
          :title="`${parcela.Number}ª de ${parcela.Value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL'})} (${periodoParcela(parcela.PeriodType)})`"
        >
        </vs-list-item>
      </vs-list>
      <p class="pt-3 pl-3" v-else>Selecione uma opção de parcelamento.</p>
    </vs-popup>

    <!-- Popup motivo restituição total -->
    <el-dialog
      width="36%"
      :visible.sync="popupMotivoRestituicao"
      class="refund-reason-popup"
    >
      <div class="refund-reason-popup-content">
        <div class="refund-reason-popup-content-header">
          <img
            src="@/assets/images/icons/ccg-icons/edit-pencil-ccg.svg"
            alt="Ícone de edição"
            width="24"
            height="24"
          />
          <h3>
            Restituição total
          </h3>
        </div>
        <div class="refund-reason-popup-content-body">
          <div class="refund-reason-popup-content-body-input">
            <label for="cpf-cnpj">Motivo</label>
            <el-input
              type="textarea"
              :rows="8"
              placeholder="Por favor, descreva qual o motivo para a restituição total..."
              v-model="motivoRestituicao">
            </el-input>
          </div>
        </div>
      </div>

      <div
        slot="footer"
        class="dialog-footer refund-reason-popup-footer"
      >
        <el-button type="primary" class="refund-reason-popup-footer-btn" :disabled="motivoRestituicao.length < 10" @click="restituirPremio(1)"
          >Restituição Total</el-button
        >

        <el-button class="refund-reason-popup-footer-btn" @click="fecharPopupMotivoRestituicao"
          >Cancelar</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Cocorretagem from "./Cocorretagem.vue";
import DadosFinanceirosVisualizarAlteracoes from "@/components/propostas/DadosFinanceirosVisualizarAlteracoes.vue";
import instance from "@/axios";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import CocorretagemMapfre from "@/views/propostas/components/emissao/mapfre/CocorretagemMapfre.vue";
import compareCompanyUtils from "@/utils/compareCompanyUtils.js";


export default {
  name: "dados-financeiros",
  components: {
    vSelect,
    Cocorretagem,
    DadosFinanceirosVisualizarAlteracoes,
    CocorretagemMapfre
  },
  props: {
    StringObservations: {
      default: false
    },
    compareChanges: {
      default: false
    },
    blockInputs: {
      type: Boolean,
      default: false
    },
    blockValueInputs: {
      type: Boolean,
      default: false
    },
    permissoesCocorretagemMapfre: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    ...mapGetters("proposta-module", [
      "proposta",
      "propostaFinancialData",
      "edicaoDeProposta"
    ]),

    mostrarCocorretagemMapfre() {
      return (this.permissoesCocorretagemMapfre.includes("ExibirCoCorretagem") && this.$route.name != "endosso-emissao")
    },

    mostraCorretagem() {
      return (
        !this.isMapfre &&
        this.$route.name != "endosso-emissao"
      );
    },

    hasCobrokers: {
      get() {
        return this.proposta.HasCoBrokers;
      },

      set(newValue){
        this.updateHasCobrokers(newValue)
      }
    },

    deveExibirCodQuadroDistribuicaoMapfreEmissao(){
      return (this.hasCobrokers && this.isMapfre && this.$route.name === "editar-proposta")
    },

    codigoQuadroDistribuicaoMapfreEmissao() {
      return this.proposta.CoBrokers[0].CodigoQuadroDistribuicao ? this.proposta.CoBrokers[0].CodigoQuadroDistribuicao : "";
    },
  },
  data() {
    return {
      PreviousProposal: {},
      teveAlteracaoDeValor: false,
      popupParcelas: false,
      dadoFinanceiro: {},
      opcoesParcelamento: [],
      opcoesDiasParaPagamento: [],
      selectedOpcaoDeParcelamento: null,
      selectedDiasParaPagamento: undefined,
      _refreshDelayPromise: undefined,
      openCocorretagem: false,
      dadosEndosso: {},
      perfilHelpers: PerfilHelpers,
      updatedFinancialData: {},
      mockupInstallments: [
        {
          Number: 1,
          Description: "mockup",
          Value: 0,
          HasInterest: false,
          TypeOfInterest: 0,
          InterestPercentage: 0,
          InterestValue: 0
        }
      ],
      motivoRestituicao: "",
      popupMotivoRestituicao: false,
      isMapfre: false,
      IsAxa: false
    };
  },
  mounted() {
    this.IsAxa = compareCompanyUtils("companyName", "axa");
    this.isMapfre = compareCompanyUtils("companyName", "mapfre");
  },
  destroyed() {
    this.updatePropostaFinancialData({});
    this.updatePropostaFinancialDataRollback({});
  },
  watch: {
    proposta(val) {
      this.checkCoBroker((val.CoBrokers || []).length);
      /**this.$onpoint.loading(() => {
        return this.calcularDadosFinanceirosProposta()
      })**/
    },

    edicaoDeProposta: {
      immediate: true,
      handler(val, oldVal) {
        if (val && this.proposta.StatusId > 1) {
          setTimeout(this.carregarDadosFinanceirosProposta, 400);
        }
      }
    },
    propostaFinancialData(val) {      
      this.dadoFinanceiro = {
        ...val,
        Tax: (val.Tax || 0.0).toLocaleString("pt-BR", {
          minimumFractionDigits: 6,
          currency: "BRL"
        }),
        ComissionPercentage: (val.ComissionPercentage || 0.0).toLocaleString(
          "pt-BR",
          {
            minimumFractionDigits: 2,
            currency: "BRL"
          }
        )
      };

      if (val.InstallmentResult && val.InstallmentResult.InstallmentOptions) {
        this.opcoesDiasParaPagamento =
          val.InstallmentResult.PossibleGracePeriodsInDays;
        this.opcoesParcelamento = this.FilterValue(
          val.InstallmentResult.InstallmentOptions
        );

        this.selectedOpcaoDeParcelamento = this.opcoesParcelamento.find(x => x.Number == this.proposta.NumberOfInstallment && x.PeriodType == this.proposta.PeriodType) || this.opcoesParcelamento[0];
        this.selectedDiasParaPagamento = this.opcoesDiasParaPagamento.find(x => x == this.proposta.GracePeriod) || this.opcoesDiasParaPagamento[0];

        this.updateProposta({
          ...this.proposta,
          Installments: this.selectedOpcaoDeParcelamento.Installments,
          NumberOfInstallment: this.selectedOpcaoDeParcelamento.Number,
          GracePeriod: this.selectedDiasParaPagamento
        });
      } else {
        this.updateProposta({
          ...this.proposta,
          Installments: this.mockupInstallments
        });
        this.opcoesParcelamento = [];
        this.opcoesDiasParaPagamento = [];
        this.selectedOpcaoDeParcelamento = null;
        this.selectedDiasParaPagamento = undefined;
      }
    }
  },
  methods: {
    ...mapMutations("proposta-module", [
      "updateProposta",
      "updatePropostaFinancialData",
      "updatePropostaFinancialDataRollback",
      "updateHasCobrokers"
    ]),

    ...mapActions("proposta-module", [
      "calcularDadosFinanceirosProposta",
      "calcularDadosFinanceirosPropostaSeguradora"
    ]),
    async zerarPremio(premiumValueType) {
      await this.$onpoint.loading(async () => {
        return await this.getUpdatedFinancialData(premiumValueType);
      });
      await this.$confirm(
        ' Tem certeza que deseja alterar essa proposta ? Após a confirmação da alteração não será possível a sua reversão."',
        "Atenção!",
        {
          confirmButtonText: "Continuar",
          cancelButtonText: "Voltar"
        }
      )
        .then(() => {
          this.dadoFinanceiro.InsurancePremium = this.updatedFinancialData.InsurancePremium;
          this.dadoFinanceiro.ComissionValue = this.updatedFinancialData.ComissionValue;
          this.dadoFinanceiro.ComissionPercentage = this.updatedFinancialData.ComissionPercentage;
          this.dadoFinanceiro.Tax = this.updatedFinancialData.Tax;

          this.proposta.InsurancePremiumSetManually = false;
          this.proposta.InsurancePremiumSetToZero = true;
          this.proposta.BlockChangesInProposal = true;
          this.$vs.notify({
            title: "Sucesso",
            text: "Valores atualizados!",
            color: "success"
          });
        })
        .then(() => {
          this.updateProposta({
            ...this.proposta,
            InsurancePremium: this.dadoFinanceiro.InsurancePremium,
            ComissionValue: this.dadoFinanceiro.ComissionValue,
            ComissionPercentage: this.dadoFinanceiro.ComissionPercentage,
            Tax: this.dadoFinanceiro.Tax
          });
          this.updatePropostaFinancialData({
            ...this.propostaFinancialData,
            InsurancePremium: this.dadoFinanceiro.InsurancePremium,
            ComissionValue: this.dadoFinanceiro.ComissionValue,
            ComissionPercentage: this.dadoFinanceiro.ComissionPercentage,
            Tax: this.dadoFinanceiro.Tax
          });
          this.updatePropostaFinancialDataRollback({
            ...this.propostaFinancialData,
            InsurancePremium: this.dadoFinanceiro.InsurancePremium,
            ComissionValue: this.dadoFinanceiro.ComissionValue,
            ComissionPercentage: this.dadoFinanceiro.ComissionPercentage,
            Tax: this.dadoFinanceiro.Tax
          });
        })
        .catch(() => {
          console.log("cancelou");
        });
    },

    abrirPopupMotivoRestituicao(){
      this.motivoRestituicao = "";
      this.popupMotivoRestituicao = true;
    },

    fecharPopupMotivoRestituicao(){
      this.popupMotivoRestituicao = false;
    },

    async restituirPremio(premiumValueType) {
      this.fecharPopupMotivoRestituicao()

      await this.$onpoint.loading(async () => {
        return await this.getUpdatedFinancialData(premiumValueType);
      });
      await this.$confirm(
        ' Tem certeza que deseja alterar essa proposta ? Após a confirmação da alteração não será possível a sua reversão."',
        "Atenção!",
        {
          confirmButtonText: "Continuar",
          cancelButtonText: "Voltar"
        }
      )
        .then(() => {
          this.dadoFinanceiro.InsurancePremium = this.updatedFinancialData.InsurancePremium;
          this.dadoFinanceiro.ComissionValue = this.updatedFinancialData.ComissionValue;
          this.dadoFinanceiro.ComissionPercentage = this.updatedFinancialData.ComissionPercentage;
          this.dadoFinanceiro.Tax = this.updatedFinancialData.Tax;
          this.dadoFinanceiro.InsurancePremiumChangeObservation = this.motivoRestituicao;

          this.proposta.InsurancePremiumSetManually = true;
          this.proposta.InsurancePremiumSetToZero = false;
          this.proposta.BlockChangesInProposal = true;
          this.$vs.notify({
            title: "Sucesso",
            text: "Valores atualizados!",
            color: "success"
          });
        })
        .then(() => {
          this.updateProposta({
            ...this.proposta,
            InsurancePremium: this.dadoFinanceiro.InsurancePremium,
            ComissionValue: this.dadoFinanceiro.ComissionValue,
            ComissionPercentage: this.dadoFinanceiro.ComissionPercentage,
            Tax: this.dadoFinanceiro.Tax,
            InsurancePremiumChangeObservation: this.dadoFinanceiro.InsurancePremiumChangeObservation
          });
          this.updatePropostaFinancialData({
            ...this.propostaFinancialData,
            InsurancePremium: this.dadoFinanceiro.InsurancePremium,
            ComissionValue: this.dadoFinanceiro.ComissionValue,
            ComissionPercentage: this.dadoFinanceiro.ComissionPercentage,
            Tax: this.dadoFinanceiro.Tax,
            InsurancePremiumChangeObservation: this.dadoFinanceiro.InsurancePremiumChangeObservation
          });
          this.updatePropostaFinancialDataRollback({
            ...this.propostaFinancialData,
            InsurancePremium: this.dadoFinanceiro.InsurancePremium,
            ComissionValue: this.dadoFinanceiro.ComissionValue,
            ComissionPercentage: this.dadoFinanceiro.ComissionPercentage,
            Tax: this.dadoFinanceiro.Tax,
            InsurancePremiumChangeObservation: this.dadoFinanceiro.InsurancePremiumChangeObservation
          });
        })
        .catch(() => {
          console.log("cancelou");
        });
    },
    GetPreviousProposalByUniqueId() {
      return instance
        .get(
          `/api/proposal/GetPreviousProposalByUniqueId?ProposalUniqueid=${
            this.$route.params.propostaUniqueId
              ? this.$route.params.propostaUniqueId
              : this.$route.params.proposalType
          }`
        )
        .then(response => {
          this.PreviousProposal = response.data.Response;
        });
    },
    async getUpdatedFinancialData(premiumValueType) {
      let data = {
        ProposalUniqueId: this.proposta.UniqueId,
        ForcePremiumValueType: premiumValueType
      };

      return await instance({
        method: "post",
        url: "/api/Proposal/ForceUpdateFinancialData",
        data
      }).then(response => {
        this.updatedFinancialData = response.data.Response;
      });
    },
    async reseteChanges(value) {
      this.dadoFinanceiro = {
        ...this.dadoFinanceiro,
        Tax: value.Tax.toLocaleString("pt-BR", {
          minimumFractionDigits: 6,
          currency: "BRL"
        }),
        ComissionValue: value.ComissionValue,
        ComissionPercentage: value.ComissionPercentage.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          currency: "BRL"
        }),
        InsurancePremium: value.InsurancePremium
      };

      this.teveAlteracaoDeValor = true;
      await this.refreshPropostaFinancialData(true, true);
    },

    FilterValue(value) {
      let dados = JSON.stringify(value);
      dados = dados.split("$").join("R$");
      return JSON.parse(dados);
    },
    checkCoBroker(CoBrokers) {
      if (CoBrokers > 0) {
        this.openCocorretagem = true;
      }
    },
    setOpcaoDeParcelamento(opcao) { 
      this.updateProposta({
        ...this.proposta,
        Installments: this.selectedOpcaoDeParcelamento.Installments,
        NumberOfInstallment: opcao.Number,
        PeriodType: opcao.PeriodType
      });      
    },
    setOpcaoDiasParaPagamento(opcao) {
      this.proposta.GracePeriod = opcao;
      this.updateProposta({
        ...this.proposta,
        GracePeriod: opcao
      });
    },
    alteracaoDeValor() {
      this.teveAlteracaoDeValor = true;
    },
    atualizaDadosFinanceirosPelaComissao() {
      this.refreshPropostaFinancialData(true);
    },
    atualizaDadosFinanceirosPelaTaxa() {
      this.refreshPropostaFinancialData(false);
    },
    async carregarDadosFinanceirosProposta() {
      return await this.$onpoint.loading(async() => {
        if (this.perfilHelpers.isInsurance()) {
          return await this.calcularDadosFinanceirosPropostaSeguradora()
            .catch(ex => {
              this.$onpoint.errorModal(ex.response.data.Errors);
            });
        } else {
          return await this.calcularDadosFinanceirosProposta()
            .then(response => console.log("calcularDadosFinanceirosProposta"))
            .catch(ex => {
              this.$onpoint.errorModal(ex.response.data.Errors);
            });
        }
      });
    },
    async refreshPropostaFinancialData(peloCampoComissao, loadUpdateProposal) {
      if (!this.teveAlteracaoDeValor) return;

      /**if (this._refreshDelayPromise) {
        clearTimeout(this._refreshDelayPromise)
      }**/

      /**this._refreshDelayPromise = setTimeout(function () {

      }.bind(this), 500)**/

      //testar
      this.updateProposta({
        ...this.proposta,
        NumberOfInstallment: 1
      });

      if (peloCampoComissao === undefined) peloCampoComissao = true;
      return await this.$onpoint.loading(async () => {
        this.teveAlteracaoDeValor = false;

        let calculate = this.perfilHelpers.isInsurance()
          ? this.calcularDadosFinanceirosPropostaSeguradora
          : this.calcularDadosFinanceirosProposta;

        return calculate({
          ProposalUniqueId: this.proposta.UniqueId,
          PolicyHolderUniqueId: this.proposta.PolicyHolder.UniqueId,
          ProposalStartDate: this.proposta.StartDate,
          ProposalEndDate: this.proposta.EndDate,
          DeadlineDays: this.proposta.DeadlineDays,
          InsuredAmountValue: this.proposta.InsuredAmountValue,
          ComissionValue: this.dadoFinanceiro.ComissionValue,
          ComissionPercentage: parseFloat(
            this.dadoFinanceiro.ComissionPercentage.replace(",", ".")
          ),
          Tax: parseFloat(this.dadoFinanceiro.Tax.replace(",", ".")),
          InsurancePremium: this.dadoFinanceiro.InsurancePremium,
          CalculatePerComissionPercentage: peloCampoComissao,
          CalculatePerRiskRate: !peloCampoComissao
        })
          .then(response => !loadUpdateProposal || this.$emit("saveProposal"))
          .catch(ex => {
            this.$onpoint.errorModal(ex.response.data.Errors);
          });
      });
    },
    periodoParcela(periodType){
      switch (periodType) {
        case 1:
          return "mensal";
        case 2:
          return "anual"
        default:
          return ""
      }
    }
  }
};
</script>

<style lang="scss">
input.vs__search:disabled {
  display: none !important;
}

.taxa-aplicada .material-icons {
  color: rgba(var(--vs-warning), 1);
  font-size: 0.8em;
}

.vs-con-dropdown,
.vs__dropdown-toggle {
  height: 47px;
  border-radius: 8px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.refund-reason-popup {
  & > div {
    border: none !important;
    border-radius: 8px !important;
    outline: none !important;
    box-shadow: none !important;
  }
  &-content {
    &-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 16px;
      border-bottom: 1px solid #747474;
      & h3 {
        text-align: center;
        margin-top: 8px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #2c2c2c;
        word-break: break-word;
      }
    }
    &-body {
      padding: 36px 0;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #747474;
      & label {
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        color: #79747e;
      }
    }
  }
  &-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 26px 0 16px;
    &-btn {
      font-weight: 600;
    }
  }
}
</style>
