// axios
import axios from "axios";
import environment from "./environment";
import router from "./router";
import store from "./store/index";
import * as authUtils from "./utils/auth";

const instance = axios.create({
  baseURL: environment.apiUrl,
  params: {}
});

instance.interceptors.request.use(function(config) {
  const currentRoute = router.history.current;
  const authRequired = currentRoute.meta.authRequired;

  const applicationConfig = environment.getApplicationConfig();
  const userInfo = store.getters["auth/userInfo"];  
  const companyKey = (applicationConfig || {}).companyKey;
  const brokerId = userInfo.BrokerId;
  const userId = userInfo.UserId;

  config.headers.CompanyKey = companyKey;
  config.headers.BrokerId = brokerId || "";
  config.headers.UserId = userId || "";

  if (authRequired === false) {
    return config;
  }
  const token = store.getters["auth/token"];

  config.headers.Authorization = `Bearer ${token}`;

  return config;

}, function(error) {
  return Promise.reject(error);
});

instance.interceptors.response.use(function(response) {
  return response;
}, function(error) {
  if (401 === ((error || {}).response || {}).status) {
    const authData = store.getters["auth/authData"];
    const appLoading = document.getElementById("loading-bg");
    if ((authData || {}).refresh_token) {
      //Foi preciso colocar essa verificação aqui,
      //Pois no caso de um F5 ou no momento que o usuário acessa
      //a aplicação ainda não subiu.
      if (!appLoading) store.dispatch("setNeedRefreshToken", true);
      return Promise.reject(error);
    } else {
      authUtils.logout();
      return Promise.reject(error);
    }
  } else {
    return Promise.reject(error);
  }
});

export default instance;