<template>
  <div class="grid">
    <label class="label"
      >{{ label }}<i class="required" v-if="required"> *</i></label
    >
    <input
      type="text"
      class="inputStyle"
      @input="formatValue"
      :placeholder="'R$'"
      :value="valueFormatted"
      @change="$emit('change')"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import Calendar from "primevue/calendar";
export default {
  props: {
    label: String,
    required: Boolean,
    disabled: Boolean,
    value: {
      type: [String, Number],
    },
  },
  components: { Calendar },
  data() {
    return {
      date: null,
      valueFormatted: null,
    };
  },
  created() {
    if (this.value || this.value == 0) {
      this.formatValue(this.value);
    }
  },
  watch: {
    value(value) {
      if (value || value == 0) {        
        this.formatValue(value);
      }
      if (!value && value !=0) {
        this.valueFormatted = null;
        if (this.calendar) {
          this.formatValue(Date.now());
        }
      }
    },
  },
  methods: {
    formatValue(event) {
      if (this.value || this.value == 0) {
        let value = Number(this.value).toFixed(2).toString();       
        this.valueFormatted = value.includes('.') ? 'R$ '+value.replace('.',',') : 'R$ ' + value + ',00'
      }
      if (event.target && event.target.value) {
        let valueFormatted = event.target.value;
        valueFormatted = valueFormatted.replace(/\D/g, "");
        valueFormatted = (parseInt(valueFormatted) / 100).toFixed(2);
        valueFormatted = valueFormatted.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });

        if (valueFormatted || valueFormatted == 0) {
          this.$emit("input", valueFormatted);
          return (this.valueFormatted = "R$ " + valueFormatted);
        } else {
          this.$emit("input", null);
        }
        return (this.valueFormatted = "R$");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.inputStyle {
  border-radius: 1px;
  height: 38px;
  border: solid 1px rgba(229, 231, 235, 1);
  border-radius: 12px;
  padding-left: 10px;
  color: #3f3f46;
  min-width: 50px;
}
.label {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(55, 65, 81, 1);
  margin-bottom: 9px;
}
.required {
  color: rgba(180, 23, 58, 1);
}
.p-calendar {
  width: 100%;
}
</style>