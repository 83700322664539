var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("Card", {
        staticClass: "card",
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [_c("h1", [_vm._v("Motivos e documentos")])]
            },
            proxy: true,
          },
          {
            key: "subtitle",
            fn: function () {
              return [_c("p", [_vm._v("Teste")])]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [_c("p", [_vm._v("conteúdo do card")])]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "flex justify-end" },
                  [
                    _c("Button", {
                      staticClass: "p-button-rounded cancel-button",
                      attrs: { label: "Voltar" },
                      on: {
                        click: function ($event) {
                          return _vm.prevPage()
                        },
                      },
                    }),
                    _c("Button", {
                      staticClass: "p-button-rounded",
                      attrs: { label: "Próximo" },
                      on: {
                        click: function ($event) {
                          return _vm.nextPage()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }