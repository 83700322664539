var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tomador-data" },
    [
      _c("div", { staticClass: "vx-row px-8 mb-5" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "vx-col w-full mt-5" },
          [
            _c(
              "multiselect",
              {
                staticClass: "w-full",
                attrs: {
                  id: "buscar-tomador",
                  label: "Name",
                  "open-direction": "bottom",
                  placeholder:
                    "Digite o CNPJ ou Razão Social para buscar um Tomador",
                  options: _vm.dadosBuscaTomador,
                  searchable: true,
                  loading: _vm.isLoading,
                  "internal-search": false,
                  "clear-on-select": false,
                  "options-limit": 300,
                  limit: 3,
                  "max-height": 600,
                  "show-no-options": false,
                  "show-no-results": true,
                  "hide-selected": true,
                  "select-label": "",
                },
                on: { "search-change": _vm.buscarTomador },
                scopedSlots: _vm._u([
                  {
                    key: "option",
                    fn: function (props) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "option__desc",
                            on: {
                              click: function ($event) {
                                return _vm.tomadorAfiancadoPorHolding(
                                  props.option.Cnpj
                                )
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "option__title" }, [
                              _c("b", [_vm._v(_vm._s(props.option.Name))]),
                            ]),
                            props.option.Cnpj
                              ? _c(
                                  "p",
                                  { staticClass: "mt-3" },
                                  [
                                    _c("the-mask", {
                                      staticClass: "w-full the-mask",
                                      attrs: {
                                        mask: ["##.###.###/####-##"],
                                        disabled: "true",
                                        masked: true,
                                        value: props.option.Cnpj,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            props.option.Description
                              ? _c("p", { staticClass: "mt-3" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(props.option.Description) +
                                      "\n            "
                                  ),
                                ])
                              : _vm._e(),
                            !props.option.IsNew &&
                            props.option.IsNominatedToOtherBroker
                              ? _c("p", { staticClass: "mt-3 text-danger" }, [
                                  _vm._v(
                                    "\n              Tomador nomeado para outra corretora\n            "
                                  ),
                                ])
                              : _vm._e(),
                            !props.option.IsNew &&
                            props.option.IsNominatedToThisBroker
                              ? _c("p", { staticClass: "mt-3 text-success" }, [
                                  _vm._v(
                                    "\n              Tomador nomeado para sua corretora\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "clear",
                    fn: function (props) {
                      return [
                        _c("div", {
                          staticClass: "multiselect__clear",
                          on: {
                            mousedown: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.clearAll(props.search)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.cnpjRazaoSocial,
                  callback: function ($$v) {
                    _vm.cnpjRazaoSocial = $$v
                  },
                  expression: "cnpjRazaoSocial",
                },
              },
              [
                _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
                  _vm._v("Não foi encontrado nenhum tomador na base"),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      !!_vm.dadosTomador.CpfCnpj
        ? [
            _c("div", { staticClass: "vx-row px-8" }, [
              _c(
                "div",
                { staticClass: "vx-col md:w-1/2 w-full mt-5" },
                [
                  _c("p", [_vm._v("Razão Social")]),
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      disabled: "",
                      value: _vm.dadosTomador.Name,
                      size: "large",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/2 w-full mt-5" },
                [
                  _c("p", [_vm._v("CNPJ")]),
                  _c("the-mask", {
                    staticClass: "w-full input-the-mask",
                    attrs: {
                      mask: ["##.###.###/####-##"],
                      disabled: "true",
                      masked: true,
                      value: _vm.dadosTomador.CpfCnpj,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-full mt-5" },
                [
                  _c("p", [_vm._v("Endereço completo")]),
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      disabled: "",
                      value:
                        (_vm.endereco || {}).Address ||
                        (_vm.dadosTomador.Locations || [])[0].Address,
                      size: "large",
                    },
                  }),
                ],
                1
              ),
            ]),
            !_vm.dadosTomador.IsNominatedToOtherBroker &&
            !_vm.dadosTomador.HasPendingNomeationProcess &&
            _vm.dadosTomador.Id > 0
              ? _c(
                  "div",
                  { staticClass: "text-right mt-5 px-8 w-full" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "detalhe-tomador",
                            params: {
                              tomadorUniqueId: _vm.dadosTomador.UniqueId,
                            },
                          },
                          target: "_blank",
                        },
                      },
                      [
                        _c(
                          "vs-button",
                          { attrs: { color: "primary", type: "flat" } },
                          [
                            _c("span", { staticClass: "font-semibold" }, [
                              _vm._v(" Visualizar cadastro completo "),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.podeSolicitarLimite
              ? _c(
                  "div",
                  { staticClass: "text-right mt-5 px-8 w-full" },
                  [
                    _c(
                      "vs-button",
                      {
                        attrs: { color: "primary", type: "flat" },
                        on: {
                          click: function ($event) {
                            _vm.popupDetalhamento = true
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "font-semibold" }, [
                          _vm._v(" Solicitar revisão de limite "),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            (_vm.filiais || []).length > 0
              ? _c(
                  "div",
                  { staticClass: "m-8" },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "mb-8 text-base md:text-lg lg:text-2x1 xl:text-3x1",
                      },
                      [
                        _vm._v(
                          "\n        Caso deseje utilizar uma filial, selecione abaixo ou adicione uma.\n      "
                        ),
                      ]
                    ),
                    _vm._l(_vm.filiais, function (filial, index) {
                      return _c("vs-card", { key: filial.Id }, [
                        _c(
                          "div",
                          { staticClass: "flex items-center p-5" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "w-full",
                                on: {
                                  change: function ($event) {
                                    return _vm.toggleFilial(filial)
                                  },
                                },
                                model: {
                                  value: _vm.filialSelecionada[filial.Id],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filialSelecionada,
                                      filial.Id,
                                      $$v
                                    )
                                  },
                                  expression: "filialSelecionada[filial.Id]",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "ml-2 mr-3 flex flex-col flex-grow",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "text-base font-semibold",
                                      },
                                      [_vm._v(_vm._s(filial.Name))]
                                    ),
                                    _c("the-mask", {
                                      staticClass:
                                        "text-base font-semibold the-mask",
                                      attrs: {
                                        mask: ["##.###.###/####-##"],
                                        readonly: "",
                                        masked: true,
                                        value: filial.CpfCnpj,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c("vs-button", {
                              attrs: {
                                radius: "",
                                color: "primary",
                                type: "flat",
                                size: "large",
                                icon: "delete",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.excluirFilial(
                                    filial.UniqueId,
                                    index
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                  ],
                  2
                )
              : _vm._e(),
            !_vm.dadosTomador.IsNominatedToOtherBroker
              ? _c(
                  "div",
                  { staticClass: "text-right mt-5 px-8 w-full" },
                  [
                    _c("adicionar-filial", {
                      attrs: { uniqueIdTomador: _vm.dadosTomador.UniqueId },
                      on: { modal_close: _vm.onModalClose },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showLimites
              ? _c(
                  "div",
                  { staticClass: "limites" },
                  [
                    _c(
                      "vs-table",
                      {
                        attrs: {
                          data: (_vm.limitesETaxas || {}).LimitAndRates || [],
                          noDataText: "Nenhum Limite e Taxas encontrado.",
                          hoverFlat: true,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ data }) {
                                return [
                                  _vm._l(data, function (tr, indextr) {
                                    return _c(
                                      "vs-tr",
                                      { key: indextr },
                                      [
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr]
                                                .ModalityGroupName,
                                            },
                                          },
                                          [
                                            _c("div", { staticClass: "flex" }, [
                                              _c(
                                                "span",
                                                { staticClass: "ml-20" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      data[indextr]
                                                        .ModalityGroupName
                                                    ) + "\n                  "
                                                  ),
                                                  !data[indextr].CanViewLimit
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [_vm._v("*")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].LimitRevised,
                                            },
                                          },
                                          [
                                            data[indextr].CanViewLimit
                                              ? _c("currency-input", {
                                                  staticClass:
                                                    "input-currency text-primary font-semibold",
                                                  attrs: { disabled: "true" },
                                                  model: {
                                                    value:
                                                      data[indextr]
                                                        .LimitRevised,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        data[indextr],
                                                        "LimitRevised",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "data[indextr].LimitRevised",
                                                  },
                                                })
                                              : _vm._e(),
                                            _c("br"),
                                            data[indextr].ChangedManually &&
                                            !_vm.perfilHelpers.isInsurance()
                                              ? _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "* Limite alterado pela seguradora"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr]
                                                .AvailableLimit,
                                            },
                                          },
                                          [
                                            data[indextr].CanViewLimit
                                              ? _c("currency-input", {
                                                  staticClass:
                                                    "input-currency text-primary font-semibold",
                                                  attrs: { disabled: "true" },
                                                  model: {
                                                    value:
                                                      data[indextr]
                                                        .AvailableLimit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        data[indextr],
                                                        "AvailableLimit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "data[indextr].AvailableLimit",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].RiskRate,
                                            },
                                          },
                                          [
                                            data[indextr].CanViewLimit
                                              ? _c("p", [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        data[indextr].RiskRate
                                                      ) +
                                                      "% / " +
                                                      _vm._s(
                                                        data[indextr].RiskRate
                                                      ) +
                                                      "%\n              "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    "vs-tr",
                                    { attrs: { state: "warning" } },
                                    [
                                      _c("vs-td", [
                                        _c("p", { staticClass: "font-bold" }, [
                                          _vm._v("Limite Total"),
                                        ]),
                                      ]),
                                      _c(
                                        "vs-td",
                                        [
                                          _c("currency-input", {
                                            staticClass:
                                              "input-currency font-bold",
                                            attrs: { disabled: "true" },
                                            model: {
                                              value:
                                                _vm.limitesETaxas
                                                  .MaxApprovedLimit,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.limitesETaxas,
                                                  "MaxApprovedLimit",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "limitesETaxas.MaxApprovedLimit",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "vs-td",
                                        [
                                          _c("currency-input", {
                                            staticClass:
                                              "input-currency font-bold",
                                            attrs: { disabled: "true" },
                                            model: {
                                              value:
                                                _vm.limitesETaxas
                                                  .MaxAvailableLimit,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.limitesETaxas,
                                                  "MaxAvailableLimit",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "limitesETaxas.MaxAvailableLimit",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("vs-td"),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4232747720
                        ),
                      },
                      [
                        _c("template", { slot: "header" }, [
                          _c("h4", { staticClass: "px-8 pb-4" }, [
                            _c("b", [_vm._v("Limites e Taxas")]),
                          ]),
                        ]),
                        _c(
                          "template",
                          { slot: "thead" },
                          [
                            _c("vs-th", [_vm._v(" Modalidades ")]),
                            _c("vs-th", [
                              _vm._v(" Limite Aprovado "),
                              _c("span", { staticClass: "text-danger" }, [
                                _vm._v("*"),
                              ]),
                            ]),
                            _c("vs-th", [_vm._v(" Limite Disponível ")]),
                            _c("vs-th", [
                              _vm._v("\n            Taxa "),
                              _c("br"),
                              _vm._v(
                                "\n            Segurado privado/público\n          "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c("div", { staticClass: "px-8 pb-8" }, [
                      !_vm.Sombrero
                        ? _c("h5", [_vm._v("Informações adicionais")])
                        : _vm._e(),
                      _c(
                        "b",
                        [
                          _vm.Excelsior
                            ? _c("div", { staticClass: "Excelsior" }, [
                                _c("p", [
                                  _vm._v(
                                    "\n              * As aprovações acima são indicativas e podem sofrer alterações;\n            "
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "\n              ** À critério da Seguradora, pode ser solicitada documentação\n              adicional em qualquer modalidade\n            "
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "\n              *** Nas modalidades Judiciais: Não existe automaticidade, 100%\n              das operações passarão pela análise julgamental para confirmação\n              ou não do limite. Somente serão analisados os clientes que\n              atinjam os critérios mínimos de Faturamento Anual / PL abaixo: -\n              Judicial Fiscal: R$ 50 MM de Fat. Ano e PL ou R$ 200 MM de Fat.\n              Ano - Judicial Cível: R$ 200 MM de Fat. Ano ou R$ 200 MM de PL\n            "
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "\n              **** Nas modalidades Financeiras: Automaticidade até R$ 500 mil\n              de IS, acima deste valor, necessário envio dos balanços para\n              análise julgamental, para confirmação ou não do limite. -\n              Energia: Guideline mínimo de: Fat. Ano e PL de R$ 20 MM.\n            "
                                  ),
                                ]),
                                _c("p"),
                                _c("p"),
                              ])
                            : _vm.Sombrero
                            ? _c("div", { staticClass: "Excelsior" }, [
                                _vm._m(1),
                                _c("p", [
                                  _vm._v(
                                    "\n              *As aprovações acima são indicativas e podem sofrer alterações;\n            "
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "\n              **À critério da Seguradora, pode ser solicitada documentação\n              adicional em qualquer modalidade;\n            "
                                  ),
                                ]),
                                _vm._m(2),
                                _c("p"),
                                _c("p"),
                              ])
                            : _vm.Essor
                            ? _c("div", [
                                _c("p", [
                                  _vm._v(
                                    "* As aprovações acima são indicativas e podem sofrer alterações;"
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "\n              *Tomadores com limites zerados em algum grupo de modalidades podem solicitar revisão de limite diretamente na Plataforma,\n               neste primeiro passo, clicando em “Revisar Limite”.\n            "
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "\n              *Tomadores que possuam demandas que excedam o valor do limite aprovado,\n              e ainda não revisado nos últimos 6 meses, poderão solicitar revisão \n              diretamente pela Plataforma, seguindo o fluxo da proposta.\n            "
                                  ),
                                ]),
                                _c("p"),
                                _c("p"),
                              ])
                            : _vm._l(
                                _vm.informacoesAdicionaisLimites,
                                function (info, index) {
                                  return _c(
                                    "p",
                                    {
                                      key: index,
                                      staticStyle: {
                                        "font-size": "0.9em !important",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s("*".repeat(info.order)) +
                                          " " +
                                          _vm._s(info.value) +
                                          _vm._s(
                                            index ==
                                              _vm.informacoesAdicionaisLimites
                                                .length -
                                                1
                                              ? "."
                                              : ";"
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                }
                              ),
                          _c("br"),
                        ],
                        2
                      ),
                    ]),
                    _vm.canRequestLimitsAndRatesReview
                      ? _c("p", { staticClass: "px-32 pt-4 pb-12" }, [
                          _c("span", { staticClass: "text-warning" }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n        Limite pré-aprovado e provisorio, pode estar aquém da capacidade do\n        tomador. Para aprovação do limite completo e definitivo é preciso a\n        inclusão da demanda e o envio da documentação financeira\n      "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._m(3),
      [
        _vm.dadosTomador.Id > 0 &&
        _vm.dadosTomador.IsNominatedToOtherBroker &&
        !_vm.dadosTomador.HasPendingNomeationProcess
          ? _c("div", { staticClass: "vx-row px-8" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full mt-2" },
                [
                  _c("vs-alert", { attrs: { color: "danger" } }, [
                    _vm._v(
                      "\n          Esse tomador está nomeado para outra corretora. Para prosseguir com\n          uma proposta, anexe uma carta de nomeação para submeter ao processo\n          de nomeação.\n        "
                    ),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "vx-row px-8 mt-8" },
          [
            this.dadosTomador.CpfCnpj &&
            _vm.dadosTomador.Id > 0 &&
            !_vm.dadosTomador.HasPendingNomeationProcess &&
            !_vm.dadosTomador.IsNominatedToThisBroker
              ? _c("carta-nomeacao", {
                  attrs: {
                    tomador: this.dadosTomador,
                    nomeationRequestCallback: this.nomeationRequestCallback,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm.dadosTomador.Id > 0 && _vm.dadosTomador.HasPendingNomeationProcess
          ? _c("div", { staticClass: "vx-row px-8" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full mt-5" },
                [
                  _c(
                    "vs-alert",
                    { staticClass: "h-auto", attrs: { color: "warning" } },
                    [
                      _c("b", [_vm._v("Atenção!")]),
                      _vm._v(
                        " Você já possui uma solicitação de nomeação via carta\n          para esse tomador.\n          "
                      ),
                      _c("br"),
                      _vm._v("\n          Data da solicitação:\n          "),
                      _c("b", [
                        _vm._v(
                          _vm._s(
                            _vm._f("moment")(
                              _vm.dadosTomador.LastPolicyHolderNomeationRequest
                                .RequestDate,
                              "DD/MM/YYYY"
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.dadosTomador.Id > 0 && _vm.dadosTomador.IsNominatedToThisBroker
          ? _c("div", { staticClass: "vx-row px-8" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full mt-5" },
                [
                  _c("vs-alert", { attrs: { color: "success" } }, [
                    _vm._v(
                      "\n          Esse tomador está nomeado para sua corretora.\n        "
                    ),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
      ],
      _c(
        "vs-popup",
        {
          staticClass: "endereco",
          attrs: {
            buttonCloseHidden: true,
            title: "",
            active: _vm.popupAddEndereco,
          },
        },
        [
          _c("h3", { staticClass: "pl-4 pb-6" }, [_vm._v("Novo Endereço")]),
          _c(
            "vs-alert",
            {
              staticClass: "pl-4 h-auto mt-2 mb-3",
              attrs: { color: "warning" },
            },
            [
              _vm._v(
                "\n      Não encontramos nenhum endereço vinculado. Por favor, adicione para\n      continuar.\n    "
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "p-4 pt-0" },
            [
              _c(
                "div",
                { staticClass: "vx-col" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: "#####-###",
                        expression: "'#####-###'",
                      },
                      {
                        name: "debounce",
                        rawName: "v-debounce:1s",
                        value: _vm.buscarCep,
                        expression: "buscarCep",
                        arg: "1s",
                      },
                    ],
                    staticClass: "w-full",
                    attrs: { label: "CEP", size: "large" },
                    model: {
                      value: _vm.cep,
                      callback: function ($$v) {
                        _vm.cep = $$v
                      },
                      expression: "cep",
                    },
                  }),
                ],
                1
              ),
              _vm.novoEndereco.ZipCode
                ? [
                    _c(
                      "div",
                      { staticClass: "mt-5 flex flex-wrap justify-around" },
                      [
                        _c(
                          "div",
                          { staticClass: "vx-col pr-1 w-3/4 md:pr-4" },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: { label: "Logradouro", size: "large" },
                              model: {
                                value: _vm.novoEndereco.AddressName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.novoEndereco, "AddressName", $$v)
                                },
                                expression: "novoEndereco.AddressName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col w-1/4" },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: { label: "Número", size: "large" },
                              model: {
                                value: _vm.novoEndereco.Number,
                                callback: function ($$v) {
                                  _vm.$set(_vm.novoEndereco, "Number", $$v)
                                },
                                expression: "novoEndereco.Number",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "mt-5 flex flex-wrap justify-around" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "vx-col pr-1 w-full md:w-3/5 md:pr-4",
                          },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: { label: "Complemento", size: "large" },
                              model: {
                                value: _vm.novoEndereco.Complement,
                                callback: function ($$v) {
                                  _vm.$set(_vm.novoEndereco, "Complement", $$v)
                                },
                                expression: "novoEndereco.Complement",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "vx-col mt-5 w-full md:w-2/5 md:mt-0",
                          },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: { label: "Bairro", size: "large" },
                              model: {
                                value: _vm.novoEndereco.Neighborhood,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.novoEndereco,
                                    "Neighborhood",
                                    $$v
                                  )
                                },
                                expression: "novoEndereco.Neighborhood",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-5 flex flex-wrap justify-around md:w-3/5",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "vx-col pr-1 w-full md:w-1/2 md:pr-4",
                          },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: { label: "Cidade", size: "large" },
                              model: {
                                value: _vm.novoEndereco.CityName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.novoEndereco, "CityName", $$v)
                                },
                                expression: "novoEndereco.CityName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "vx-col mt-5 w-full md:w-1/2 md:mt-0",
                          },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: { label: "Estado", size: "large" },
                              model: {
                                value: _vm.novoEndereco.StateProvinceName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.novoEndereco,
                                    "StateProvinceName",
                                    $$v
                                  )
                                },
                                expression: "novoEndereco.StateProvinceName",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "p-4 mt-5 flex flex-row-reverse w-full" },
                      [
                        _c(
                          "vs-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.salvarendereco(_vm.novoEndereco)
                              },
                            },
                          },
                          [_vm._v("Salvar Endereço")]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("request-limit", {
        attrs: {
          showModal: _vm.popupDetalhamento,
          detalhamento: _vm.detalhamento,
          tomadorUniqueId: (_vm.dadosTomador || {}).UniqueId,
        },
        on: {
          closeModal: _vm.closeModalDocuments,
          dismissModal: _vm.dismissModalDocuments,
          updateDocs: _vm.updateDocs,
        },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "popup-afiancado-holding",
          attrs: {
            width: "32%",
            visible: _vm.popupAfiancadoHolding,
            "before-close": _vm.fecharPopupAfiancadoHolding,
          },
          on: {
            "update:visible": function ($event) {
              _vm.popupAfiancadoHolding = $event
            },
          },
        },
        [
          _c("div", { staticClass: "popup-afiancado-holding-content" }, [
            _c(
              "div",
              { staticClass: "popup-afiancado-holding-content-header" },
              [_c("h4", [_vm._v(_vm._s(_vm.tomadorSelecionado.Message))])]
            ),
            _c("div", { staticClass: "popup-afiancado-holding-content-body" }, [
              _c("p", [_vm._v("Este tomador é afiançado pela holding?")]),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer popup-afiancado-holding-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "vs-button",
                {
                  staticClass: "popup-afiancado-holding-footer-no-option",
                  on: {
                    click: function ($event) {
                      return _vm.enviarStatusAfiancadoPelaHolding(false)
                    },
                  },
                },
                [_vm._v("\n          Não\n        ")]
              ),
              _c(
                "vs-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.enviarStatusAfiancadoPelaHolding(true)
                    },
                  },
                },
                [_vm._v("\n          Sim\n        ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "h4",
      { staticClass: "vx-col w-full flex items-center font-semibold" },
      [
        _c("span", { staticClass: "block" }, [
          _vm._v("Digite o CNPJ ou Razão Social para buscar um Tomador."),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_vm._v("Informações adicionais: "), _c("br"), _c("br")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n              Nas modalidades Judiciais:"),
      _c("b", { staticStyle: { "text-decoration": "underline" } }, [
        _vm._v("\n                NÃO EXISTE AUTOMATICIDADE"),
      ]),
      _vm._v(
        ", 100% das operações passarão pela análise julgamental para\n              confirmação ou não do limite. Somente serão analisados os\n              clientes que atinjam aos critérios mínimos de Faturamento Anual/\n              PL: JUDICIAL FISCAL: R$ 200MM de Fat. Ano e PL 100MM – JUDICIAL\n              CÝVEL E TRABALHISTA: R$ 35MM Fat. Ano e PL R$ 35MM.\n            "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "hidden", attrs: { id: "loading-limites" } },
      [
        _c("div", { staticClass: "py-12 px-32 flex items-center" }, [
          _c("div", {
            staticClass: "vs-con-loading__container",
            attrs: { id: "proposta-loading" },
          }),
          _c("div", { staticClass: "pl-20" }, [
            _c("h4", [
              _vm._v(
                "Aguarde, estamos calculando o limite e a taxa para o tomador"
              ),
            ]),
            _c("p", [
              _vm._v(
                "\n          Esse processo pode levar alguns minutos. Caso queira, pode continuar\n          com o preenchimento da proposta, enquanto realizamos este\n          procedimento\n        "
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }