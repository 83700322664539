<template>
  <div class="mt-4 pl-4 pr-4">
    <vs-tabs color="warning">
      <vs-tab label="Resumo da Proposta">
        <div class="vx-row mt-6" v-if="endosso.EndorsementType.Id">
          <div class="vx-col text-right w-full mt-2">
            <visualizar-alteracoes
              :changed="diffEndosso.length > 0"
              :showDesfazer="false"
              :objectDiff="diffEndosso"
              :responsavel="responsavelDataUpdateEndosso"
            />
          </div>
        </div>

        <vs-card class="mt-4 p-8 dados-proposta">
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold mb-1">Responsável</p>
              <vs-select v-model="selectedOwnerCard" @change="atualizarResponsavel">
                <vs-select-item
                  v-for="(item, index) in ownerList"
                  :key="index"
                  :value="item"
                  :text="item"
                />
              </vs-select>
            </div>

            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold mb-1">Prioridade</p>
              <vs-select v-model="selectedPriorityId" @change="atualizarPrioridade">
                <vs-select-item
                  v-for="(item, index) in cardPriorityList"
                  :key="index"
                  :value="item.Id"
                  :text="item.Name"
                />
              </vs-select>
            </div>
          </div>

          <div class="vx-row mt-4" v-if="deveExibirResponsavelMovimentacao">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">{{ stepName }} por</p>
              <p class="text-lg">
                {{ (cardMovementUser || {}).Name ? (cardMovementUser || {}).Name : "-" }}
              </p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Data da movimentação</p>
              <p class="text-lg">
                {{ (cardMovementUser || {}).MovingDate ? (cardMovementUser || {}).MovingDate : "-" | moment("DD/MM/YYYY HH:mm") }}
              </p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-proposta">
          <div v-if="Newe" class="vx-row">
            <div class="vx-col md:w-6/12 w-full md:mt-0 mt-5">
              <p class="text-sm font-semibold">Nroº Proposta</p>
              <p class="text-lg">{{ proposta.Number || "Não informado" }}</p>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-6/12">
              <p class="text-sm font-semibold">Corretora</p>
              <p class="text-lg">{{ (proposta.Broker || {}).Name }}</p>
              <small>{{ (proposta.Broker || {}).CpfCnpj | maskCpfCnpj }}</small>
            </div>

            <div class="vx-col w-6/12">
              <p class="text-sm font-semibold">Data de solicitação</p>
              <p class="text-lg">
                {{ proposta.CreateDate | moment("DD/MM/YYYY HH:mm") }}
              </p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-proposta" v-if="proposta.Policy">
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Nroº Apólice</p>
              <p class="text-lg">
                {{ (proposta.Policy || {}).Number || "Não informado" }}
              </p>
            </div>

            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Data de emissão</p>
              <p class="text-lg">
                {{ (proposta.Policy || {}).CreateDate | moment("DD/MM/YYYY") }}
              </p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-proposta">
          <div class="vx-row">
            <div class="vx-col w-1/3">
              <p class="text-sm font-semibold">Nroº Proposta</p>
              <p class="text-lg">{{ proposta.Number }}</p>
            </div>
            <div class="vx-col w-1/3">
              <p class="text-sm font-semibold">Nroº Endosso</p>
              <p class="text-lg">{{ endosso.Number }}</p>
            </div>
            <div class="vx-col w-1/3">
              <p class="text-sm font-semibold">Motivo</p>
              <p class="text-lg" v-if="endosso.ReasonEndorsementRequest">
                {{ endosso.ReasonEndorsementRequest.Name }}
              </p>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-1/3">
              <p class="text-sm font-semibold pb-3">Tipo</p>
              <span
                class="text-lg inline-flex"
                v-for="(text, index) in tiposEndosso"
                :key="index"
              >
                <div class="inline-flex" v-if="verifyUndefined(text)">
                  <i class="onpoint-check-circle my-auto mr-5"></i>
                  <p v-html="text"></p>
                </div>
              </span>
            </div>
          </div>
        </vs-card>

        <vs-card
          class="mt-4 p-8 dados-proposta"
          v-if="proposta.PolicyHolder.BankAccounts.length > 0"
        >
          <p class="text-sm font-semibold">Dados Bancários do Beneficiário:</p>
          <br />
          <div
            class="vx-row"
            v-for="(dados, index) in proposta.PolicyHolder.BankAccounts.slice(
              0,
              1
            )"
            :key="index"
            style=" padding-bottom: 5px; margin-bottom: 10px;"
          >
            <div class="vx-col w-1/3">
              <p class="text-sm font-semibold">Tipo de conta</p>
              <p class="text-lg">{{ dados.BankAccountType.Name }}</p>
            </div>
            <div class="vx-col w-1/3">
              <p class="text-sm font-semibold">Agencia</p>
              <p class="text-lg">
                {{ dados.Branch }}
                <strong v-if="dados.DigitBranch"
                  >( {{ dados.DigitBranch }} )</strong
                >
              </p>
            </div>
            <div class="vx-col w-1/3">
              <p class="text-sm font-semibold">Conta</p>
              <p class="text-lg">
                {{ dados.Number }}
                <strong v-if="dados.DigitNumber"
                  >( {{ dados.DigitNumber }} )</strong
                >
              </p>
            </div>

            <div class="vx-col w-2/3">
              <br />
              <p class="text-sm font-semibold">Banco</p>
              <p class="text-lg">{{ dados.Bank.Name }}</p>
            </div>
          </div>
        </vs-card>
        
        <vs-card class="p-8 dados-proposta">
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Tomador</p>
         
              <p class="text-lg">
                {{ proposta.PolicyHolder.Name || "Não informado" }}
              </p>
            </div>

            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Cnpj</p>
              <p class="text-lg">
                {{
                  (proposta.PolicyHolder.CpfCnpj || "Não informado")
                    | maskCpfCnpj
                }}
              </p>
            </div>
          </div>

          <div class="vx-row mb-6 mt-2">
            <div class="vx-col w-1/2">
              <router-link
                :to="{
                  name: 'detalhe-tomador',
                  params: { tomadorUniqueId: proposta.PolicyHolder.UniqueId }
                }"
                target="_blank"
              >
                <span class="font-semibold">
                  Visualizar cadastro completo do tomador
                </span>
              </router-link>
            </div>
          </div>

          <div class="vx-row" v-if="proposta.PolicyHolderBranch">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Tomador Filial</p>
              <p class="text-lg">
                {{ proposta.PolicyHolderBranch.Name || "Não informado" }}
              </p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Cnpj Filial</p>
              <p class="text-lg">
                {{
                  (proposta.PolicyHolderBranch.CpfCnpj || "Não informado")
                    | maskCpfCnpj
                }}
              </p>
            </div>
          </div>
          
          <div class="vx-row" v-if="proposta.PolicyHolderBranch">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Tomador Filial</p>
              <p class="text-lg">
                {{ proposta.PolicyHolderBranch.Name || "Não informado" }}
                </p>
              </div>
              <div class="vx-col w-1/2">
                <p class="text-sm font-semibold">Cnpj Filial</p>
              <p class="text-lg">
                {{
                  (proposta.PolicyHolderBranch.CpfCnpj || "Não informado")
                    | maskCpfCnpj
                }}
              </p>
            </div>
          </div>

          <div class="vx-row mt-6">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Segurado</p>
              <p class="text-lg">
                {{ proposta.Insured.Name || "Não informado" }}
              </p>
            </div>

            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Cpf/Cnpj</p>
              <p class="text-lg">
                {{
                  (proposta.Insured.CpfCnpj || "Não informado") | maskCpfCnpj
                }}
              </p>
            </div>
          </div>
        </vs-card>

        <vs-card class="p-8 pt-4 pb-4 dados-proposta">
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Grupo Principal</p>
              <p class="text-lg">
                {{ proposta.ModalityGroup.Name || "Não informado" }}
              </p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Modalidade Principal</p>
              <p class="text-lg" v-if="proposta.Modality">
                {{ proposta.Modality.Name }}
                <b style="color:red">
                  {{
                    proposta.Modality.IsDeleted ? "(Modalidade Inativada)" : ""
                  }}</b
                >
              </p>
              <p class="text-lg" v-else>Não informado</p>
            </div>
          </div>

          <div class="vx-row mt-6">
            <div class="vx-col w-1/4">
              <p class="text-sm font-semibold">Importância Segurada</p>
              <p class="text-lg">
                {{ proposta.InsuredAmountValue | maskCurrency }}
              </p>
            </div>
            <div class="vx-col w-1/4">
              <p class="text-sm font-semibold">Inicio Vigência</p>
              <p class="text-lg">
                {{ proposta.StartDate | moment("DD/MM/YYYY") }}
              </p>
            </div>
            <div class="vx-col w-1/4">
              <p class="text-sm font-semibold">Fim Vigência</p>
              <p class="text-lg">
                {{ proposta.EndDate | moment("DD/MM/YYYY") }}
              </p>
            </div>
            <div class="vx-col w-1/4">
              <p class="text-sm font-semibold">Prazo</p>
              <p class="text-lg">{{ proposta.DeadlineDays }} dias</p>
            </div>
          </div>
        </vs-card>

        <vs-card
          class="p-8 pt-4 pb-4 dados-proposta"
          v-if="proposta.ComplementaryModalityId"
        >
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Modalidade Complementar</p>
              <p class="text-lg">
                {{ proposta.ComplementaryModality.Name || "Não informado" }}
              </p>
            </div>
          </div>

          <div class="vx-row mt-6">
            <div class="vx-col w-1/4">
              <p class="text-sm font-semibold">Importância Segurada</p>
              <p class="text-lg">
                {{ proposta.ComplementaryInsuredAmountValue | maskCurrency }}
              </p>
            </div>

            <div class="vx-col w-1/4">
              <p class="text-sm font-semibold">Inicio Vigência</p>
              <p class="text-lg">
                {{ proposta.ComplementaryStartDate | moment("DD/MM/YYYY") }}
              </p>
            </div>

            <div class="vx-col w-1/4">
              <p class="text-sm font-semibold">Fim Vigência</p>
              <p class="text-lg">
                {{ proposta.ComplementaryEndDate | moment("DD/MM/YYYY") }}
              </p>
            </div>

            <div class="vx-col w-1/4">
              <p class="text-sm font-semibold">Prazo</p>
              <p class="text-lg">
                {{ proposta.ComplementaryDeadlineDays }} dias
              </p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-proposta">
          <div class="vx-row">
            <div class="vx-col">
              <p class="text-sm font-semibold">Coberturas adicionais</p>
              <template v-if="(proposta.ProposalCoverages || []).length">
                <p
                  class="text-lg"
                  v-for="item in proposta.ProposalCoverages"
                  :key="item.Id"
                >
                  {{ item.Coverage.Name }}
                </p>
              </template>
              <p class="text-lg" v-else>
                Não consta
              </p>
            </div>
          </div>
        </vs-card>

        <div class="vx-row mt-6">
          <div class="vx-col text-right w-full mt-2">
            <router-link
              :to="{
                name: 'resumo-proposta-visao-seguradora',
                params: {
                  propostaUniqueId: this.proposta.UniqueId,
                  proposalType: 'Endosso'
                }
              }"
              target="_blank"
            >
              <vs-button
                color="primary"
                v-if="
                  perfilHelpers.checkPermission([
                    perfilHelpers.menuFuncionalitiesEnum
                      .GERENCIAR_PROPOSTAS_SEGURADORA,
                    perfilHelpers.menuFuncionalitiesEnum
                      .VISUALIZAR_PROPOSTAS_SEGURADORA
                  ])
                "
              >
                <span class="font-semibold pr-2 pl-2">
                  {{
                    `${
                      perfilHelpers.checkPermission([
                        perfilHelpers.menuFuncionalitiesEnum
                          .GERENCIAR_PROPOSTAS_SEGURADORA
                      ])
                        ? "Editar"
                        : "Visualizar"
                    } informações da Proposta`
                  }}
                </span>
              </vs-button>
            </router-link>
          </div>
        </div>
      </vs-tab>

      <vs-tab label="Histórico do Motor">
        <div
          class="vx-row"
          v-if="!regrasExecutadas || regrasExecutadas.length == 0"
        >
          <div class="vx-col w-full mt-2">
            <vs-alert color="warning" class="h-auto">
              Nenhuma regra foi executada nessa proposta.
            </vs-alert>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col pt-5 w-full mt-2">
            <vs-collapse
              type="margin"
              v-for="(regra, index) in regrasExecutadas"
              :key="index"
            >
              <vs-collapse-item>
                <div slot="header">
                  <p class="text-sm font-semibold"></p>
                  <p class="text-lg">
                    {{
                      (regra || {}).EngineExecutionType.Name || "Não informado"
                    }}
                  </p>
                  <p class="text-sm">
                    <b>Executada em </b
                    >{{ (regra || {}).CreateDate | moment("DD/MM/YYYY") }}
                  </p>
                  <!--<vs-button class="status" type="filled"
                          size="small"
                          :color="getLabelColor(regra)">
                          {{getLabelText(regra)}}
                        </vs-button>-->
                </div>

                <div class="vx-row flex">
                  <div class="vx-col flex-1">
                    <vs-table
                      :data="
                        ((regra || {}).RuleEngineResult || {}).RuleResults || []
                      "
                      noDataText="Nenhum histórico de regras executado."
                    >
                      <template slot="thead">
                        <vs-th>
                          Etapa
                        </vs-th>
                        <vs-th>
                          Regra
                        </vs-th>
                        <vs-th>
                          Executada com sucesso?
                        </vs-th>
                        <vs-th>
                          Mensagem
                        </vs-th>
                      </template>

                      <template slot-scope="{ data }">
                        <vs-tr
                          :key="indextr"
                          v-for="(tr, indextr) in data"
                          :style="getRowStyle(data[indextr])"
                        >
                          <vs-td :data="indextr">
                            <div class="flex">
                              <span>{{ indextr + 1 }}</span>
                            </div>
                          </vs-td>
                          <vs-td :data="data[indextr].RuleName">
                            <div class="flex">
                              <span>{{ data[indextr].RuleName }}</span>
                            </div>
                          </vs-td>
                          <vs-td :data="data[indextr].Success">
                            <div class="flex">
                              <span>{{
                                data[indextr].Success ? "Sim" : "Não"
                              }}</span>
                            </div>
                          </vs-td>
                          <vs-td :data="data[indextr].Messages">
                            <div class="flex">
                              <span
                                v-if="
                                  (data[indextr].Messages || []).length == 0
                                "
                              ></span>
                              <div
                                v-if="(data[indextr].Messages || []).length > 0"
                              >
                                <ul>
                                  <li
                                    v-for="(message, indexMsg) in data[indextr]
                                      .Messages || []"
                                    :key="indexMsg"
                                  >
                                    {{ message }}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </div>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </div>
      </vs-tab>

      <vs-tab label="Follow Up">
        <div class="vx-row" v-if="observacoes.length == 0">
          <div class="vx-col w-full mt-2">
            <vs-alert color="warning" class="h-auto">
              Nenhum follow up até o momento.
            </vs-alert>
          </div>
        </div>
        <div class="vx-row">
          <vs-card
            class="p-8 pt-4 pb-4"
            v-for="(mensagem, index) in observacoes"
            :key="index"
          >
            <h6 class="flex items-baseline">
              <span class="text-primary mr-2 mb-2">{{
                mensagem.CreateByPerson
              }}</span>
              <small
                >escreveu em
                {{
                  new Date(mensagem.CreateDate) | dateFormat("DD/MM/YYYY")
                }}</small
              >
            </h6>
            <p class="pl-4">{{ mensagem.Observation }}</p>
          </vs-card>
        </div>
        <div class="vx-row" v-if="hasPermission">
          <div class="vx-col pt-5 w-full mt-2">
            <label>Comentário</label>
            <vs-textarea v-model="observation" />
          </div>
        </div>
        <div class="vx-row" v-if="hasPermission">
          <div class="vx-col text-right w-full mt-2">
            <vs-button
              :disabled="(observation || '').length == 0"
              @click.native="enviarObservacao()"
              color="primary"
            >
              <span class="font-semibold pr-2 pl-2">
                Enviar para o corretor
              </span>
            </vs-button>
          </div>
        </div>
      </vs-tab>

      <vs-tab label="Documentos Solicitados">
        <div class="vx-row" v-if="documentos.length == 0">
          <div class="vx-col w-full mt-2">
            <vs-alert color="warning" class="h-auto">
              Nenhum documento solicitado para a corretora.
            </vs-alert>
          </div>
        </div>
        <div>
          <div>
            <vs-card
              class="mt-1 p-2"
              v-for="(documento, index) in documentos"
              :key="index"
            >
              <div class="vx-row flex">
                <div class="vx-col flex-1">
                  <p class="text-sm font-semibold"></p>
                  <p class="text-lg">{{ documento.Name || "Não informado" }}</p>
                  <p class="text-sm pt-4">
                    <b>Status </b>{{ documento.StatusDescription }}
                  </p>
                  <p class="text-sm pt-4">
                    <b>Total de anexos </b>{{ documento.TotalArchives }}
                  </p>
                  <p class="text-sm">
                    <b>Solicitado em </b
                    >{{ documento.CreateDate | moment("DD/MM/YYYY") }}
                  </p>
                </div>
                <div class="vx-col">
                  <div class="text-right w-full flex items-center">
                     <button
                      class="onpoint-download-simple download-button "
                      v-if="documento.TotalArchives > 0"
                      @click="downloadDocumento(documento)"                   
                    ></button>
                    <button
                      class="onpoint-thumbs-up aprove-button "
                      v-if="documento.StatusId == 3 && hasPermission"
                      @click="alterarStatusDocumento(documento, 1)"                  
                    ></button>
                   
                    <button
                      class="onpoint-thumbs-down reprove-button "
                      v-if="documento.StatusId == 3 && hasPermission"
                      @click="alterarStatusDocumento(documento, 4)"               
                    ></button>                    

                    <button
                      class="onpoint-trash trash-button "
                      v-if="hasPermission"
                      @click="deletarDocumento(documento)"                                  
                    ></button>
                  </div>
                </div>
                <!---<div class="vx-col w-1/4">
                          <p class="text-sm font-semibold">Data de Solicitação</p>
                          <p class="text-lg">{{ documento.CreateDate  | moment('DD/MM/YYYY') }}</p>
                        </div>
                        <div class="vx-col w-1/4">
                          <p class="text-sm font-semibold">Status</p>
                          <p class="text-lg">{{ documento.StatusDescription }}</p>
                        </div>--->
              </div>
            </vs-card>
          </div>
        </div>
        <div class="vx-row" v-if="hasPermission">
          <div class="vx-col text-right w-full mt-2">
            <vs-button
              @click.native="mostrarListaDeDocumentos()"
              color="primary"
            >
              <span class="font-semibold pr-2 pl-2">
                Adicionar documentos
              </span>
            </vs-button>
            <vs-button
              class="ml-5"
              :disabled="!possuiDocumentacaoPendente"
              @click.native="
                enviarParaCorretor('Solicitação de documentos para o corretor.')
              "
              color="primary"
            >
              <span class="font-semibold pr-2 pl-2">
                Enviar para o corretor
              </span>
            </vs-button>
          </div>
        </div>
      </vs-tab>
    </vs-tabs>

    <vs-popup
      class="popup-lista-documentos"
      title="Solicitar documentação"
      :active.sync="popupListaDocumentos"
    >
      <vs-tabs color="warning">
        <vs-tab label="Lista de Documentos">
          <div class="vx-row">
            <div class="vx-col w-full">
              <ul class="mt-8" v-if="(documentosPadrao || []).length > 0">
                <li
                  v-for="(documento, index) in documentosPadrao"
                  :key="documento.Id"
                  :class="{ 'pt-5': index > 0 }"
                >
                  <vs-checkbox
                    v-if="documentoJaFoiSolicitado(documento.Name)"
                    v-model="documentosParaSolicitar"
                    :vs-value="documento.Id"
                    >{{ documento.Name }}</vs-checkbox
                  >
                </li>
              </ul>
              <div v-if="(documentosPadrao || []).length == 0">
                <p>Nenhum tipo de documento encontrado!</p>
              </div>
            </div>
          </div>
          <div class="vx-row" v-if="(documentosPadrao || []).length > 0">
            <div class="vx-col pt-5 text-right w-full mt-2">
              <vs-button @click.native="solicitarDocumentos()" color="primary">
                <span class="font-semibold pr-2 pl-2">
                  Solicitar documentação
                </span>
              </vs-button>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Outros documentos">
          <div class="vx-row">
            <div class="vx-col w-full">
              <div
                class="vx-row"
                v-if="(documentosManuaisParaSolicitar || []).length == 0"
              >
                <div class="vx-col w-full mt-2">
                  <vs-alert color="warning" class="h-auto">
                    Nenhum documento adicionado. Clique no botão de adicionar
                    documentos;
                  </vs-alert>
                </div>
              </div>
              <div
                class="vx-row"
                v-for="(documentoManual,
                index) in documentosManuaisParaSolicitar || []"
                :key="index"
              >
                <div class="vx-col md:w-10/12 w-full mt-5 md:pr-2">
                  <vs-input
                    label-placeholder="Documento"
                    size="large"
                    v-model="documentoManual.Name"
                    class="w-full"
                  />
                </div>
                <div
                  class="vx-col md:w-2/12 w-full mt-5 md:pr-0 md:pl-2 p-0 flex items-center"
                >
                  <vs-button
                    class="delete mt-4 ml-2"
                    @click="removeDocumentoManual(index)"
                    color="primary"
                    type="flat"
                    size="large"
                    icon="delete"
                  ></vs-button>
                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col w-full">
                  <vs-button
                    icon-after
                    @click="adicionaDocumentoManual()"
                    class="font-semibold mt-2 float-right"
                    color="primary"
                    type="flat"
                    icon="add_circle"
                  >
                    Adicionar novo Documento
                  </vs-button>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col pt-5 text-right w-full mt-2">
                  <vs-button
                    @click.native="solicitarDocumentosManuais()"
                    color="primary"
                  >
                    <span class="font-semibold pr-2 pl-2">
                      Solicitar documentação
                    </span>
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </vs-popup>

    <vs-popup
      class="popup-download-arquivos"
      title="Anexos do documento"
      :active.sync="popupListaArquivos"
    >
      <div class="vx-row">
        <div class="vx-col w-full">
          <h5>{{ (documentoSelecionado || {}).Name }}</h5>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col pt-5 w-full mt-2">
          <vs-card
            class="mt-1 p-2"
            v-for="(archive, index) in arquivosParaDownload"
            :key="index"
          >
            <div class="vx-row flex">
              <div class="vx-col flex-1">
                <p class="text-sm font-semibold"></p>
                <p class="text-lg">
                  {{ archive.OriginalFilename || "Não informado" }}
                  {{ archive.Extension }}
                </p>
                <p class="text-sm">
                  <b>Anexado em </b
                  >{{ archive.CreateDate | moment("DD/MM/YYYY") }}
                </p>
              </div>
              <div class="vx-col">
                <div class="text-right w-full flex items-center">
                  <vs-button
                    class="delete"
                    @click="baixarArquivo(archive)"
                    color="primary"
                    type="flat"
                    size="large"
                    icon="get_app"
                  ></vs-button>
                </div>
              </div>
            </div>
          </vs-card>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { kanbanProvider } from "@/providers/kanban.provider";
import { mask, TheMask } from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";
import * as Enums from "@/utils/enums";
import VisualizarAlteracoes from "@/views/endosso/components/VisualizarAlteracoes.vue";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import compareCompanyUtils from "@/utils/compareCompanyUtils.js";

export default {
  name: "EndorsementDataViewContent",
  directives: { mask },
  components: { TheMask, VisualizarAlteracoes },
  props: {
    data: {
      type: Object
    },
    cardType: {
      type: String
    },
    cardId: {
      type: String
    },
    stepName: {
      type: String
    },
    cardMovementUser: {
      type: Object,
      default: () => {}
    },
    cardPriorityList: {
      type: Array,
      default: () => []
    },
    priorityDataClickedCard: {
      type: Object,
      default: () => {}
    },
    ownerCard: {
      type: String,
      default: null
    },
    ownerList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      grupoEconomico: {},
      popupListaDocumentos: false,
      popupListaArquivos: false,
      documentoSelecionado: undefined,
      //popupConcluirAcao:false,
      arquivosParaDownload: [],
      documentosManuaisParaSolicitar: [],
      documentosParaSolicitar: [],
      observation: "",
      documentosPadrao: [],
      responsavelDataUpdateEndosso: null,
      diffEndosso: [],
      perfilHelpers: PerfilHelpers,
      Newe: false,
      priorityCard: null,
      selectedPriorityId: null,
      selectedOwnerCard: null,
    };
  },
  computed: {
    ...mapGetters("pipe-module", { currentPipe: "pipe" }),

    hasPermission() {
      return this.perfilHelpers.checkPermissionKanban(this.currentPipe.id);
    },

    regrasExecutadas() {
      const result = (this.data || {}).ProposalEngineExecutionLogs || [];
      return result;
    },

    proposta() {
      const result = (this.data || {}).Proposal || {};
      return result;
    },

    endosso() {
      const result = (this.data || {}).Endorsement || {};
      return result;
    },

    documentos() {
      return (this.data || {}).RequestDocuments || [];
    },

    observacoes() {
      return (this.data || {}).Observations || [];
    },

    possuiDocumentacaoPendente() {
      const pendentes = this.documentos.filter(item => {
        return item.StatusId == 2 || item.StatusId == 4;
      });
      return pendentes.length > 0;
    },

    blockInputs() {
      return (
        this.proposta.StatusId == Enums.ProposalStatus.AguardandoPagamento ||
        this.proposta.StatusId == Enums.ProposalStatus.Recusada ||
        this.proposta.StatusId == Enums.ProposalStatus.Cancelada ||
        this.proposta.StatusId == Enums.ProposalStatus.Emitida
      );
    },

    tiposEndosso() {
      let countTypes = ((this.endosso || {}).ChangeTypes || []).length;
      if (countTypes)
        return this.endosso.ChangeTypes.map(x => {
          let text = x.Description;
          if (countTypes > 1) text += "<br />";
          return text;
        });
      return "";
    },
    carId: {
      get() {
        return this.cardId || {};
      },
      set(val) {
        if (!val) {
          this.data = val;
        }
      }
    },
    deveExibirResponsavelMovimentacao() {
      return ['Aprovado', 'Reprovado', 'Recusado', 'Cancelado'].includes(this.stepName)
    },
    deveExibirResponsavelPeloCard(){
      return ['Cadastro', 'Crédito', 'Resseguro', 'Subscrição'].includes(this.currentPipe.name)
    },
    deveExibirGerenciamentoPrioridade(){
      return ['Cadastro', 'Crédito', 'Resseguro', 'Subscrição'].includes(this.currentPipe.name)
    }
  },
  watch: {
    selectedPriorityId(newId) {
      this.priorityCard = this.cardPriorityList.find(option => option.Id === newId);
    },
    priorityCard(newPriority) {
      if (newPriority) {
        this.selectedPriorityId = newPriority.Id;
      }
    },
    ownerCard(newVal) {
      this.selectedOwnerCard = newVal;
    }
  },
  methods: {
    ...mapActions("pipe-module", ["getPipeSteps"]),
    ...mapActions("endosso-module", ["getCompareChanges"]),
    ...mapActions("seguradora-module", [
      "getCardProposalParameterRequestDocument"
    ]),
    verifyUndefined(text){
      return  !text.includes('undefined')
    },
    async loadGetCompareChanges() {
      if ((this.endosso || {}).EndorsementType.Id == 2)
        await this.$onpoint.loading(async() => {
          return await this.getCompareChanges({
            uniqueId: this.proposta.UniqueId
          }).then(result => {
            this.responsavelDataUpdateEndosso = {
              UserName: result.UserOwner.Name,
              ModifiedAt: result.ModifiedAt
            };

            ((result || {}).ChangeTypes || []).forEach(item => {
              item.ChangesValues.forEach(element => {
                this.diffEndosso.push({
                  current: element.Current ? element.Current : "--",
                  origin: element.Previous ? element.Previous : "--",
                  label: element.Name,
                  key: element.Name + element.Current
                });
              });
            });
          });
        });
    },

    async atualizarResponsavel() {
      if (this.selectedOwnerCard != "") {
        await this.$onpoint.loading(async () => {
          return await kanbanProvider.kanbanUpdateCardOwner({
            cardId: this.cardId,
            owner: this.selectedOwnerCard
          })
          .catch(error => {
              this.$onpoint.errorModal(error);
          });
        })
      }
    },

    getLabelText(regra) {
      if (((regra || {}).RuleEngineResult || {}).Success) return "APROVADO";
      else return "REPROVADO";
    },
    getLabelColor(regra) {
      if (((regra || {}).RuleEngineResult || {}).Success) return "success";
      else return "danger";
    },
    getRowStyle(data) {
      if (data.Success) return { backgroundColor: "#97db9e" };
      else return { backgroundColor: "#e69191" };
    },

    editarProposta() {
      let routeData = this.$router.resolve({
        name: "resumo-proposta-visao-seguradora",
        params: {
          propostaUniqueId: this.proposta.UniqueId,
          proposalType: "Endosso"
        }
      });
      window.open(routeData.href, "_blank");
    },

    async obterTipoDeDocumentos() {
      await this.$onpoint.loading(async() => {
        return await this.getCardProposalParameterRequestDocument()
          .then(data => {
            this.documentosPadrao = data;
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },
    downloadDocumento(documento) {
      this.documentoSelecionado = documento;
      this.arquivosParaDownload = [];
      this.popupListaArquivos = false;

      this.$onpoint.loading(() => {
        return kanbanProvider
          .getRequestDocumentArchives(documento.UniqueId)
          .then(data => {
            this.arquivosParaDownload = data;
            if (this.arquivosParaDownload.length == 1) {
              this.baixarArquivo(this.arquivosParaDownload[0]);
            } else {
              this.popupListaArquivos = true;
            }
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },
    baixarArquivo(arquivo) {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .getArchiveLinkToDownload(arquivo.UniqueId)
          .then(data => {
            window.open(data, "_blank");
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },
    adicionaDocumentoManual() {
      if (!this.documentosManuaisParaSolicitar)
        this.documentosManuaisParaSolicitar = [];

      this.documentosManuaisParaSolicitar.push({ Name: "" });
    },

    removeDocumentoManual(index) {
      this.documentosManuaisParaSolicitar.splice(index, 1);
    },

    mostrarListaDeDocumentos() {
      this.popupListaDocumentos = true;
      this.obterTipoDeDocumentos();
    },
    documentoJaFoiSolicitado(nomeDocumento) {
      const result = this.documentos.some(x => x.Name == nomeDocumento);
      return result == false;
    },
    solicitarDocumentos() {
      const totalSelecionados = (this.documentosParaSolicitar || []).length;
      if (totalSelecionados.length == 0) {
        this.$onpoint.errorModal("Selecione ao menos um documento");
        return;
      }

      const lista = this.documentosPadrao
        .filter(x => this.documentosParaSolicitar.includes(x.Id))
        .map(x => x.Name);

      this.$onpoint.loading(() => {
        return kanbanProvider
          .kanbanProposalRequestDocument({
            RequestDocuments: lista,
            ProposalUniqueId: this.proposta.UniqueId
          })
          .then(data => {
            this.documentosParaSolicitar = [];
            this.data.RequestDocuments = data.RequestDocuments;
            this.popupListaDocumentos = false;
            this.$onpoint.successModal("Documentos adicionados com sucesso.");
            //location.reload();
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },
    solicitarDocumentosManuais() {
      const lista = [];
      for (
        let x = 0;
        x < (this.documentosManuaisParaSolicitar || []).length;
        x++
      ) {
        if (
          this.documentosManuaisParaSolicitar[x].Name &&
          this.documentosManuaisParaSolicitar[x].Name.length > 0
        )
          lista.push(this.documentosManuaisParaSolicitar[x].Name);
      }

      if ((lista || []).length == 0) {
        this.$onpoint.errorModal("Adicione ao menos um documento.");
        return;
      }

      this.$onpoint.loading(() => {
        return kanbanProvider
          .kanbanProposalRequestDocument({
            RequestDocuments: lista,
            ProposalUniqueId: this.proposta.UniqueId
          })
          .then(data => {
            this.documentosManuaisParaSolicitar = [];

            this.data.RequestDocuments = data.RequestDocuments;
            this.popupListaDocumentos = false;
            this.$onpoint.successModal("Documentos adicionados com sucesso.");
            //location.reload();
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    alterarStatusDocumento(documento, status) {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .kanbanProposalChangeStatusRequestDocument({
            RequestDocumentUniqueId: documento.UniqueId,
            ProposalUniqueId: this.proposta.UniqueId,
            StatusId: status
          })
          .then(data => {
            this.data.RequestDocuments = data.RequestDocuments;
            this.$onpoint.successModal("Operação realizada com sucesso!");
            //location.reload();
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    deletarDocumento(documento) {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .kanbanProposalDeleteRequestDocument({
            RequestDocumentUniqueId: documento.UniqueId,
            ProposalUniqueId: this.proposta.UniqueId
          })
          .then(data => {
            this.data.RequestDocuments = data.RequestDocuments;
            this.$onpoint.successModal("Operação realizada com sucesso!");
            //location.reload();
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },
    async enviarParaCorretor(obs) {
      await this.$onpoint.loading(async () => {
        return await kanbanProvider
          .kanbanProposalSendToBroker({
            Observation: obs || this.observation,
            ProposalUniqueId: this.proposta.UniqueId
          })
          .then(async data => {
            this.$onpoint.successModal("Operação realizada com sucesso!");

            let docs = {
              ProposalUniqueId: this.proposta.UniqueId
            };

            await kanbanProvider.KanbanSendEmailRequestDocumentToBroker(docs);

            this.$emit("refreshPipe");
            //location.reload()
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    enviarObservacao(obs) {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .followUpSendObservation({
            Observation: obs || this.observation,
            ProposalUniqueId: this.proposta.UniqueId
          })
          .then(data => {
            this.$onpoint.successModal("Operação realizada com sucesso!");
            this.$emit("refreshPipe");
            //location.reload()
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    async atualizarPrioridade() {
      let data = {
        PipeId: this.priorityDataClickedCard.PipeId,
        CardId: this.priorityDataClickedCard.CardId,
        PipeCardPriority : this.priorityCard.Id
      }

      await this.$onpoint.loading(async () => {
        return await kanbanProvider.updatePriorityKanbanCard(data)
          .then(response => {
            this.$onpoint.successModal("Operação realizada com sucesso!");
          })
          .catch(error => {
            this.$onpoint.errorModal(error);
          });
      })
    },
  },
  async mounted() {
    this.loadGetCompareChanges();
    this.Newe = compareCompanyUtils("companyName", "newe");

    if(this.ownerCard){
      this.selectedOwnerCard = this.ownerCard;
    }

    if (this.priorityDataClickedCard && this.priorityDataClickedCard.Id) {
      this.selectedPriorityId = this.priorityDataClickedCard.Id;
    }
  }
};
</script>

<style lang="scss">
.popup-lista-documentos {
  z-index: 52900 !important;
}

.popup-download-arquivos {
  z-index: 52900 !important;
}

.popup-editar-proposta {
  z-index: 52900 !important;
}
</style>
