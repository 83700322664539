var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("Card", {
        staticClass: "card",
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("div", { staticClass: "title" }, [
                  _c("p", [_vm._v("Informações do segurado")]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "subtitle",
            fn: function () {
              return [
                _c("div", { staticClass: "subtitle" }, [
                  _c("p", [_vm._v("Informe dos dados do segurado")]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      [
                        _c("InputSearchSelect", {
                          staticClass: "w-2/3",
                          attrs: {
                            placeholder: "Selecionar",
                            label:
                              "Digite o CNPJ ou Razão Social para buscar um segurado.",
                            required: true,
                            icon: "onpoint-caret-down",
                            infoData: _vm.dadosBuscaSegurado,
                            isLoading: _vm.isLoadingSearchSegurado,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "infoData",
                              fn: function () {
                                return _vm._l(
                                  _vm.dadosBuscaSegurado,
                                  function (item, i) {
                                    return _c("div", { key: i }, [
                                      _c(
                                        "div",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.validarSegurado(
                                                item.DocumentNumber,
                                                _vm.formData.cnpjTomador
                                              )
                                            },
                                          },
                                        },
                                        [
                                          i < 3
                                            ? _c("Card", {
                                                staticClass: "item-searched",
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "title",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "title",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    item.Name
                                                                  ) +
                                                                  "\n                      "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                    {
                                                      key: "content",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "informations",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    item.DocumentNumber
                                                                  ) +
                                                                  "\n                      "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ])
                                  }
                                )
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.cnpjRazaoSocial,
                            callback: function ($$v) {
                              _vm.cnpjRazaoSocial = $$v
                            },
                            expression: "cnpjRazaoSocial",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.selectedSegurado
                      ? _c(
                          "div",
                          [
                            _c("InformacoesSegurado", {
                              staticClass: "mt-3",
                              attrs: { segurado: _vm.selectedSegurado },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectedSegurado && !_vm.selectedSegurado.FantasyName
                      ? _c(
                          "div",
                          [
                            _c("IncluirNomeFantasia", {
                              staticClass: "mt-3",
                              attrs: { segurado: _vm.selectedSegurado },
                              on: { newSocialName: _vm.searchFantasyName },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectedSegurado && !_vm.selectedSegurado.Locations[1]
                      ? _c(
                          "div",
                          [
                            _c("IncluirEnderecoAdicional", {
                              staticClass: "mt-3",
                              attrs: { segurado: _vm.selectedSegurado },
                              on: { novoEndereco: _vm.renderNewEndereco },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.seguradoFantasyNames
                      ? _c(
                          "div",
                          _vm._l(_vm.seguradoFantasyNames, function (item, i) {
                            return _c("CardInfoWithDeleteButton", {
                              key: i,
                              staticClass: "mt-3",
                              attrs: {
                                title: "Nome fantasia",
                                text: item.Name,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removeFantasyName(item)
                                },
                              },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                    _vm.selectedSegurado && _vm.selectedSegurado.Locations[1]
                      ? _c(
                          "div",
                          _vm._l(
                            _vm.selectedSegurado.Locations,
                            function (location, i) {
                              return _c(
                                "div",
                                { key: i },
                                [
                                  !location.IsMain
                                    ? _c("CardInfoWithDeleteButton", {
                                        staticClass: "mt-3",
                                        attrs: {
                                          title: "Endereço adicional",
                                          text: location.Address,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeEndereco(
                                              location.LocationId
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _c("Divider", { staticClass: "divider" }),
                    _c("div", [_c("Beneficiario")], 1),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "flex justify-end" },
                  [
                    _c("Button", {
                      staticClass: "p-button-rounded cancel-button",
                      attrs: { label: "Voltar" },
                      on: {
                        click: function ($event) {
                          return _vm.prevPage()
                        },
                      },
                    }),
                    _c("Button", {
                      staticClass: "p-button-rounded",
                      attrs: { label: "Próximo", disabled: !_vm.canToNextPage },
                      on: {
                        click: function ($event) {
                          return _vm.nextPage()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }