const environment = {
  production: process.env.NODE_ENV === "production",
  baseURL: process.env.BASE_URL,
  apiUrl: process.env.VUE_APP_API_URL,
  hub_Url: process.env.VUE_APP_HUB_URL,
  application_insights: process.env.VUE_APP_APPLICATION_INSIGHTS_KEY,
  localStorageKeys: {
    demoCompanyKey: "lsCompanyKey",
    applicationConfig: "lsApplicationConfig"
  },
  getUrlParam(name) {
    const results = new RegExp(`[\?&]${name}=([^&#]*)`).exec(
      window.location.href
    );
    if (results == null) {
      return null;
    } else {
      return results[1] || 0;
    }
  },
  getApplicationConfig: () => {
    const data = localStorage.getItem(
      environment.localStorageKeys.applicationConfig
    );
    return JSON.parse(data);
  },
  setApplicationConfig: data => {
    localStorage.setItem(
      environment.localStorageKeys.applicationConfig,
      JSON.stringify(data)
    );
  }
};

export default environment;
