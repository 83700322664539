var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          attrs: { title: _vm.titleModalUpdate, active: _vm.popupEditData },
          on: {
            "update:active": function ($event) {
              _vm.popupEditData = $event
            },
          },
        },
        [
          _vm.typeUpdate == "address"
            ? _c("form-endereco", {
                attrs: {
                  dadosEndereco: _vm.novoEndereco,
                  showCancelButton: true,
                  showOkButton: true,
                  textCancelButton: "Cancelar",
                },
                on: { cancel: _vm.closeEditModal, save: _vm.salvarEndereco },
              })
            : _vm._e(),
          _vm.typeUpdate == "name"
            ? _c(
                "div",
                [
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        { attrs: { "vs-w": "12" } },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: { label: "Razão social", size: "large" },
                            model: {
                              value: _vm.nomeEdicao,
                              callback: function ($$v) {
                                _vm.nomeEdicao = $$v
                              },
                              expression: "nomeEdicao",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vs-row",
                    { staticClass: "mt-5" },
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "end",
                            "vs-align": "center",
                          },
                        },
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: { disabled: !_vm.nomeEdicao },
                              on: {
                                click: function ($event) {
                                  return _vm.updateRazaoSocial()
                                },
                              },
                            },
                            [_vm._v("\n            Salvar\n          ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: { title: _vm.titleModalUpdate, active: _vm.popupNomeSocial },
          on: {
            "update:active": function ($event) {
              _vm.popupNomeSocial = $event
            },
          },
        },
        [
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                { attrs: { "vs-w": "12" } },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      label:
                        "Novo " + _vm.apolice.Insured.Document.length > 11
                          ? "Nome Fantasia"
                          : "Nome Social",
                      size: "large",
                    },
                    model: {
                      value: _vm.newNomeSocial,
                      callback: function ($$v) {
                        _vm.newNomeSocial = $$v
                      },
                      expression: "newNomeSocial",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vs-row",
            { staticClass: "mt-5" },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "end",
                    "vs-align": "center",
                  },
                },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { disabled: !_vm.newNomeSocial },
                      on: {
                        click: function ($event) {
                          return _vm.updateNomeSocial()
                        },
                      },
                    },
                    [_vm._v("\n          Salvar\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "vs-row",
        { attrs: { "vs-type": "flex" } },
        [
          _c(
            "vs-col",
            {
              staticClass: "container-btn-changes",
              attrs: { "vs-lg": "6", "vs-sm": "12" },
            },
            [
              _c("p", { staticClass: "font-bold pb-3 w-full" }, [
                _vm._v("Nº Apólice"),
              ]),
              _c(
                "p",
                {
                  staticClass:
                    "font-bold w-full text-sm text-grey-700 word-wrap",
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.apolice.PolicyNumber || "-") +
                      "\n      "
                  ),
                ]
              ),
            ]
          ),
          _vm._t("default"),
        ],
        2
      ),
      _c(
        "vs-row",
        { staticClass: "colum-persons" },
        [
          _c(
            "vs-col",
            { staticClass: "tomador", attrs: { "vs-lg": "6", "vs-sm": "12" } },
            [
              _c("p", { staticClass: "font-bold pb-3 w-full" }, [
                _vm._v("\n        Tomador\n      "),
              ]),
              _c(
                "p",
                { staticClass: "font-bold w-full text-sm text-grey-700" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.policyHolderName || "-") +
                      "\n        "
                  ),
                  _vm.changeTypesIds.includes(4)
                    ? _c("i", {
                        staticClass:
                          "onpoint-pencil-simple-line icon-font float-right text-lg cursor-pointer text-primary-orig",
                        attrs: { role: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.editarRazaoSocial(false)
                          },
                        },
                      })
                    : _vm._e(),
                ]
              ),
              _c("p", { staticClass: "w-full text-xs" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$utils.maskDocument(
                        _vm.apolice.PolicyHolder.Document
                      ) || "-"
                    ) +
                    "\n      "
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "pt-8 pb-5",
                  attrs: { "vs-align": "flex-end" },
                  on: {
                    click: function ($event) {
                      _vm.changeTypesIds.includes(5)
                        ? _vm.editarEndereco(false)
                        : null
                    },
                  },
                },
                [
                  _c("p", { staticClass: "font-bold w-full" }, [
                    _vm._v("\n          Endereço do Tomador\n          "),
                    _vm.changeTypesIds.includes(5)
                      ? _c("i", {
                          staticClass:
                            "onpoint-pencil-simple-line icon-font text-primary-orig float-right text-lg cursor-pointer",
                        })
                      : _vm._e(),
                  ]),
                  _c("p", { staticClass: "w-full text-sm text-grey-700" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.policyHolderAddress || "-") +
                        "\n        "
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "vs-col",
            { staticClass: "segurado", attrs: { "vs-lg": "6", "vs-sm": "12" } },
            [
              _c("p", { staticClass: "font-bold pb-3 w-full" }, [
                _vm._v("Segurado"),
              ]),
              _c(
                "p",
                { staticClass: "font-bold w-full text-sm text-grey-700" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.insuredName || "-") +
                      "\n\n        "
                  ),
                  _vm.changeTypesIds.includes(6)
                    ? _c("i", {
                        staticClass:
                          "onpoint-pencil-simple-line icon-font text-primary-orig float-right text-lg cursor-pointer",
                        attrs: { role: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.editarRazaoSocial(true)
                          },
                        },
                      })
                    : _vm._e(),
                ]
              ),
              _c("p", { staticClass: "w-full text-xs" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$utils.maskDocument(_vm.apolice.Insured.Document) ||
                        "-"
                    ) +
                    "\n      "
                ),
              ]),
              _vm.enableChangeNomeSocial
                ? _c("div", [
                    _vm.NomeSocial
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "font-bold pb-3 mt-2 w-full nome-social",
                          },
                          [
                            _c("b", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.apolice.Insured.Document.length > 11
                                      ? "Nome Fantasia"
                                      : "Nome Social "
                                  ) +
                                  ":\n            "
                              ),
                              _c("strong", [_vm._v(_vm._s(_vm.NomeSocial))]),
                            ]),
                            _c("i", {
                              staticClass:
                                "onpoint-pencil-simple-line icon-font text-primary-orig float-right text-lg cursor-pointer",
                              attrs: { role: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.editarNomeSocial(true)
                                },
                              },
                            }),
                          ]
                        )
                      : _c(
                          "p",
                          {
                            staticClass:
                              "font-bold pb-3 mt-2 w-full nome-social",
                          },
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass: "btn-view-changes",
                                attrs: { color: "primary", type: "border" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editarNomeSocial(false)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            Adicionar Nome Social\n          "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  class: "mb-0  pb-5 " + _vm.NomeSocial ? " pt-2" : " pt-8",
                  on: {
                    click: function ($event) {
                      _vm.changeTypesIds.includes(7)
                        ? _vm.editarEndereco(true)
                        : null
                    },
                  },
                },
                [
                  _c("p", { staticClass: "font-bold w-full" }, [
                    _vm._v("\n          Endereço do Segurado  \n          "),
                    _vm.changeTypesIds.includes(7)
                      ? _c("i", {
                          staticClass:
                            "onpoint-pencil-simple-line icon-font text-primary-orig float-right text-lg cursor-pointer",
                        })
                      : _vm._e(),
                  ]),
                  _c("p", { staticClass: "w-full text-sm text-grey-700" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.insuredAddress || "-") +
                        "\n        "
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }