var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.dadoFinanceiro.InsurancePremium != null
        ? [
            _c("h4", { staticClass: "mb-5" }, [_vm._v("Prêmio e comissão")]),
            _c(
              "vs-row",
              [
                _c(
                  "vs-col",
                  { attrs: { "vs-type": "flex", "vs-justify": "end" } },
                  [
                    _c("dados-financeiros-visualizar-alteracoes", {
                      attrs: {
                        proposalUniqueId: _vm.proposta.UniqueId,
                        currentValues: _vm.propostaFinancialData,
                        showDesfazer: !(
                          _vm.proposta.BlockChangesInProposal && _vm.blockInputs
                        ),
                      },
                      on: { reseteChanges: _vm.reseteChanges },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "vs-row",
              [
                _c(
                  "vs-col",
                  { staticClass: "w-4/12" },
                  [
                    _c("p", [
                      _vm.propostaFinancialData.IsPremiumFixed
                        ? _c("b", [_vm._v("Valor do prêmio")])
                        : _c("b", [
                            _vm._v(
                              "Valor do prêmio (Minímo R$ " +
                                _vm._s(_vm.proposta.MinInsurancePremium) +
                                ")"
                            ),
                          ]),
                    ]),
                    _c("currency-input", {
                      staticClass:
                        "w-full vs-inputx vs-input--input large hasValue",
                      attrs: { disabled: "true", size: "large" },
                      model: {
                        value: _vm.dadoFinanceiro.InsurancePremium,
                        callback: function ($$v) {
                          _vm.$set(_vm.dadoFinanceiro, "InsurancePremium", $$v)
                        },
                        expression: "dadoFinanceiro.InsurancePremium",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "vs-col",
                  { staticClass: "w-1/6" },
                  [
                    _c(
                      "p",
                      { staticClass: "taxa-aplicada flex" },
                      [
                        _c("b", [_vm._v("Taxa aplicada(%)")]),
                        _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text: "Taxa de risco aplicada sobre o calculo do prêmio.",
                              position: "right",
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons pl-2" }, [
                              _vm._v("info"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("vs-input", {
                      directives: [
                        {
                          name: "currency",
                          rawName: "v-currency",
                          value: {
                            currency: { prefix: "" },
                            locale: "pt",
                            precision: 6,
                          },
                          expression:
                            "{\n            currency: { prefix: '' },\n            locale: 'pt',\n            precision: 6\n          }",
                        },
                      ],
                      staticClass: "w-full insurance",
                      attrs: {
                        disabled:
                          _vm.propostaFinancialData.IsPremiumFixed ||
                          _vm.proposta.BlockChangesInProposal ||
                          _vm.blockInputs ||
                          (_vm.proposta.ProposalTypeId == 2 &&
                            !_vm.perfilHelpers.isInsurance()),
                        size: "large",
                      },
                      on: {
                        keyup: _vm.alteracaoDeValor,
                        blur: _vm.atualizaDadosFinanceirosPelaTaxa,
                      },
                      model: {
                        value: _vm.dadoFinanceiro.Tax,
                        callback: function ($$v) {
                          _vm.$set(_vm.dadoFinanceiro, "Tax", $$v)
                        },
                        expression: "dadoFinanceiro.Tax",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "vs-col",
                  { staticClass: "w-4/12" },
                  [
                    _c("p", [_c("b", [_vm._v("Valor da comissão")])]),
                    _c("currency-input", {
                      staticClass:
                        "w-full vs-inputx vs-input--input large hasValue",
                      attrs: {
                        disabled: "true",
                        size: "large",
                        value: _vm.dadoFinanceiro.ComissionValue,
                      },
                    }),
                  ],
                  1
                ),
                _vm.deveExibirCodQuadroDistribuicaoMapfreEmissao
                  ? _c(
                      "vs-col",
                      { staticClass: "w-1/6" },
                      [
                        _c("p", [
                          _c("b", [
                            _vm._v("Código de quadro de distribuição "),
                          ]),
                        ]),
                        _c("vs-input", {
                          staticClass: "w-full insurance",
                          attrs: { disabled: "", size: "large" },
                          model: {
                            value: _vm.codigoQuadroDistribuicaoMapfreEmissao,
                            callback: function ($$v) {
                              _vm.codigoQuadroDistribuicaoMapfreEmissao = $$v
                            },
                            expression: "codigoQuadroDistribuicaoMapfreEmissao",
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "vs-col",
                      { staticClass: "w-1/6" },
                      [
                        _c("p", [_c("b", [_vm._v("% de comissão")])]),
                        _c("vs-input", {
                          directives: [
                            {
                              name: "currency",
                              rawName: "v-currency",
                              value: {
                                currency: { prefix: "" },
                                locale: "pt",
                              },
                              expression:
                                "{\n            currency: { prefix: '' },\n            locale: 'pt'\n          }",
                            },
                          ],
                          staticClass: "w-full insurance",
                          attrs: {
                            disabled:
                              _vm.proposta.BlockChangesInProposal ||
                              _vm.blockInputs ||
                              !_vm.perfilHelpers.isInsurance(),
                            size: "large",
                          },
                          on: {
                            keyup: _vm.alteracaoDeValor,
                            blur: _vm.atualizaDadosFinanceirosPelaComissao,
                          },
                          model: {
                            value: _vm.dadoFinanceiro.ComissionPercentage,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dadoFinanceiro,
                                "ComissionPercentage",
                                $$v
                              )
                            },
                            expression: "dadoFinanceiro.ComissionPercentage",
                          },
                        }),
                      ],
                      1
                    ),
                _vm.mostraCorretagem
                  ? _c(
                      "vs-col",
                      { attrs: { "vs-w": "12" } },
                      [
                        _c(
                          "vs-checkbox",
                          {
                            staticClass: "mt-8",
                            model: {
                              value: _vm.openCocorretagem,
                              callback: function ($$v) {
                                _vm.openCocorretagem = $$v
                              },
                              expression: "openCocorretagem",
                            },
                          },
                          [
                            _vm._v(
                              "\n          Adicionar cocorretagem\n        "
                            ),
                          ]
                        ),
                        _c(
                          "transition",
                          { attrs: { name: "fade" } },
                          [
                            _vm.openCocorretagem
                              ? _c("cocorretagem")
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.mostrarCocorretagemMapfre
                  ? _c(
                      "vs-col",
                      { attrs: { "vs-w": "12" } },
                      [
                        _c(
                          "vs-checkbox",
                          {
                            staticClass: "mt-8",
                            model: {
                              value: _vm.hasCobrokers,
                              callback: function ($$v) {
                                _vm.hasCobrokers = $$v
                              },
                              expression: "hasCobrokers",
                            },
                          },
                          [_vm._v("\n          Possui cocorretagem\n        ")]
                        ),
                        _c(
                          "transition",
                          { attrs: { name: "fade" } },
                          [
                            _vm.hasCobrokers
                              ? _c("CocorretagemMapfre")
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "text-right" },
              [
                _vm.StringObservations.CanToggleManualReimbursementPremium ==
                "true"
                  ? _c(
                      "vs-button",
                      {
                        staticClass: "mr-2",
                        attrs: { color: "primary" },
                        on: { click: _vm.abrirPopupMotivoRestituicao },
                      },
                      [
                        _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                          _vm._v("\n          Restituição Total\n        "),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.StringObservations.CanToggleZeroOutReimbursementPremium ==
                "true"
                  ? _c(
                      "vs-button",
                      {
                        staticClass: "mr-2",
                        attrs: { color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.zerarPremio(2)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                          _vm._v(
                            "\n          Zerar Prêmio e Comissão\n        "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.compareChanges.RequestInsurancePremiumReview
              ? _c(
                  "div",
                  [
                    _c("span", { staticStyle: { "margin-bottom": "10px" } }, [
                      _vm._v("Motivo da Revisão do Prêmio"),
                    ]),
                    _c("el-input", {
                      staticStyle: { color: "#3a3e47!important" },
                      attrs: {
                        disabled: "",
                        type: "textarea",
                        rows: 2,
                        placeholder: "Please input",
                      },
                      model: {
                        value:
                          _vm.compareChanges
                            .JustificationInsurancePremiumReview,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.compareChanges,
                            "JustificationInsurancePremiumReview",
                            $$v
                          )
                        },
                        expression:
                          "compareChanges.JustificationInsurancePremiumReview",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("hr", { staticClass: "my-12 opacity-25" }),
          ]
        : _vm._e(),
      _vm.StringObservations.CanToggleZeroOutReimbursementPremium != "true"
        ? _c(
            "div",
            [
              _c("h4", { staticClass: "mb-5" }, [_vm._v("Forma de pagamento")]),
              _c(
                "vs-row",
                [
                  _c(
                    "vs-col",
                    { staticClass: "w-1/4" },
                    [
                      _c("p", [_c("b", [_vm._v("Nº de Parcelas")])]),
                      _c(
                        "v-select",
                        {
                          staticClass: "w-full insurance",
                          attrs: {
                            label: "Description",
                            "show-no-options": false,
                            options: _vm.opcoesParcelamento,
                            placeholder: "Selecione um opção",
                            dir: _vm.$vs.rtl ? "rtl" : "ltr",
                            searchable: false,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.setOpcaoDeParcelamento(
                                _vm.selectedOpcaoDeParcelamento
                              )
                            },
                          },
                          model: {
                            value: _vm.selectedOpcaoDeParcelamento,
                            callback: function ($$v) {
                              _vm.selectedOpcaoDeParcelamento = $$v
                            },
                            expression: "selectedOpcaoDeParcelamento",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v("Não há opções de parcelamento")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vs-col",
                    { staticClass: "w-1/4" },
                    [
                      _c("p", [
                        _c("b", [
                          _vm._v("Adicional de fracionamento na parcela (R$)"),
                        ]),
                      ]),
                      _c("currency-input", {
                        staticClass:
                          "w-full vs-inputx vs-input--input large hasValue",
                        attrs: {
                          size: "large",
                          disabled: "true",
                          value:
                            (_vm.selectedOpcaoDeParcelamento || {})
                              .InterestValuePerInstallment || 0,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-col",
                    { staticClass: "w-1/4" },
                    [
                      _c("p", [_c("b", [_vm._v("1ª parcela")])]),
                      _c("currency-input", {
                        staticClass:
                          "w-full vs-inputx vs-input--input large hasValue",
                        attrs: {
                          disabled: "true",
                          size: "large",
                          value:
                            (_vm.selectedOpcaoDeParcelamento || {}).Value || 0,
                        },
                      }),
                      (_vm.opcoesParcelamento || []).length > 1
                        ? _c(
                            "small",
                            {
                              staticClass: "text-primary cursor-pointer",
                              on: {
                                click: function ($event) {
                                  _vm.popupParcelas = true
                                },
                              },
                            },
                            [_vm._v("Exibir outras parcelas")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "vs-col",
                    { staticClass: "w-1/4" },
                    [
                      _c("p", [_c("b", [_vm._v("Dias")])]),
                      _c(
                        "v-select",
                        {
                          staticClass: "w-full insurance",
                          attrs: {
                            disabled: _vm.blockInputs,
                            "show-no-options": false,
                            options: _vm.opcoesDiasParaPagamento,
                            placeholder: "Selecione um opção",
                            dir: _vm.$vs.rtl ? "rtl" : "ltr",
                            searchable: false,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.setOpcaoDiasParaPagamento(
                                _vm.selectedDiasParaPagamento
                              )
                            },
                          },
                          model: {
                            value: _vm.selectedDiasParaPagamento,
                            callback: function ($$v) {
                              _vm.selectedDiasParaPagamento = $$v
                            },
                            expression: "selectedDiasParaPagamento",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v("Não há opções de dias pagamento")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.proposta.PolicyHolder.BankAccounts.length > 0
        ? _c(
            "div",
            [
              _c("h4", { staticClass: "mb-5" }, [
                _vm._v("Dados Bancários do Beneficiário:"),
              ]),
              _c("p", { staticClass: "text-sm font-semibold" }),
              _c("br"),
              _vm._l(
                _vm.proposta.PolicyHolder.BankAccounts.slice(0, 1),
                function (dados, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "vx-row",
                      staticStyle: {
                        "padding-bottom": "5px",
                        "margin-bottom": "10px",
                      },
                    },
                    [
                      _c("div", { staticClass: "vx-col w-1/3" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Tipo de conta"),
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(_vm._s(dados.BankAccountType.Name)),
                        ]),
                      ]),
                      _c("div", { staticClass: "vx-col w-1/3" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Agencia"),
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(dados.Branch) +
                              "\n          "
                          ),
                          dados.DigitBranch
                            ? _c("strong", [
                                _vm._v("( " + _vm._s(dados.DigitBranch) + " )"),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "vx-col w-1/3" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Conta"),
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(dados.Number) +
                              "\n          "
                          ),
                          dados.DigitNumber
                            ? _c("strong", [
                                _vm._v("( " + _vm._s(dados.DigitNumber) + " )"),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "vx-col w-2/3" }, [
                        _c("br"),
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Banco"),
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(_vm._s(dados.Bank.Name)),
                        ]),
                      ]),
                    ]
                  )
                }
              ),
            ],
            2
          )
        : _vm._e(),
      _c(
        "vs-popup",
        {
          attrs: { active: _vm.popupParcelas, title: "" },
          on: {
            "update:active": function ($event) {
              _vm.popupParcelas = $event
            },
          },
        },
        [
          _c("h3", { staticClass: "text-primary ml-4" }, [_vm._v("Parcelas")]),
          _vm.selectedOpcaoDeParcelamento &&
          _vm.selectedOpcaoDeParcelamento.Installments
            ? _c(
                "vs-list",
                { staticClass: "p-4" },
                _vm._l(
                  _vm.selectedOpcaoDeParcelamento.Installments,
                  function (parcela) {
                    return _c("vs-list-item", {
                      key: parcela.Id,
                      attrs: {
                        title: `${
                          parcela.Number
                        }ª de ${parcela.Value.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })} (${_vm.periodoParcela(parcela.PeriodType)})`,
                      },
                    })
                  }
                ),
                1
              )
            : _c("p", { staticClass: "pt-3 pl-3" }, [
                _vm._v("Selecione uma opção de parcelamento."),
              ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "refund-reason-popup",
          attrs: { width: "36%", visible: _vm.popupMotivoRestituicao },
          on: {
            "update:visible": function ($event) {
              _vm.popupMotivoRestituicao = $event
            },
          },
        },
        [
          _c("div", { staticClass: "refund-reason-popup-content" }, [
            _c("div", { staticClass: "refund-reason-popup-content-header" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/icons/ccg-icons/edit-pencil-ccg.svg"),
                  alt: "Ícone de edição",
                  width: "24",
                  height: "24",
                },
              }),
              _c("h3", [_vm._v("\n          Restituição total\n        ")]),
            ]),
            _c("div", { staticClass: "refund-reason-popup-content-body" }, [
              _c(
                "div",
                { staticClass: "refund-reason-popup-content-body-input" },
                [
                  _c("label", { attrs: { for: "cpf-cnpj" } }, [
                    _vm._v("Motivo"),
                  ]),
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 8,
                      placeholder:
                        "Por favor, descreva qual o motivo para a restituição total...",
                    },
                    model: {
                      value: _vm.motivoRestituicao,
                      callback: function ($$v) {
                        _vm.motivoRestituicao = $$v
                      },
                      expression: "motivoRestituicao",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer refund-reason-popup-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "refund-reason-popup-footer-btn",
                  attrs: {
                    type: "primary",
                    disabled: _vm.motivoRestituicao.length < 10,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.restituirPremio(1)
                    },
                  },
                },
                [_vm._v("Restituição Total")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "refund-reason-popup-footer-btn",
                  on: { click: _vm.fecharPopupMotivoRestituicao },
                },
                [_vm._v("Cancelar")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }