var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.apoliceOriginalEndosso
    ? _c(
        "div",
        [
          _c("Steps", {
            staticClass: "mt-5 mb-5 steps",
            attrs: { model: _vm.steps },
          }),
          _vm.apoliceOriginalEndosso.TipoEndosso
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "mt-5 mb-5" },
                    [
                      _c("VisualizarDetalhesApolice", {
                        attrs: {
                          apoliceOriginalEndosso: _vm.apoliceOriginalEndosso,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "keep-alive",
                    [
                      _c("router-view", {
                        attrs: { formData: _vm.formObject },
                        on: {
                          prevPage: function ($event) {
                            return _vm.prevPage($event)
                          },
                          nextPage: function ($event) {
                            return _vm.nextPage($event)
                          },
                          complete: _vm.complete,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("div", [_vm._v("\n    Você não possue dados\n  ")]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }