var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Accordion",
    {
      staticClass: "detalhes-apolice",
      attrs: { multiple: true, activeIndex: _vm.activeIndex },
    },
    [
      _c(
        "AccordionTab",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "detalhes-apolice-header",
                      on: { click: _vm.expandirComponentePrincipal },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "detalhes-apolice-header-primeiro-bloco",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "detalhes-apolice-header-primeiro-bloco-numero",
                            },
                            [
                              _c("p", [_vm._v("Nº Apólice")]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.apoliceOriginalEndosso.NumeroApolice
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "detalhes-apolice-header-primeiro-bloco-tipo",
                            },
                            [
                              _c("p", [_vm._v("Tipo de endosso")]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.apoliceOriginalEndosso.TipoEndosso)
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "detalhes-apolice-header-segundo-bloco",
                        },
                        [
                          !_vm.componentePrincipalAtivo
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "detalhes-apolice-header-segundo-bloco-content",
                                },
                                [
                                  _c("p", [
                                    _vm._v("Visualizar detalhes da apólice"),
                                  ]),
                                  _c("i", {
                                    staticClass: "onpoint-caret-down icon-font",
                                  }),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "detalhes-apolice-header-segundo-bloco-content",
                                },
                                [
                                  _c("p", [
                                    _vm._v("Ocultar detalhes da apólice"),
                                  ]),
                                  _c("i", {
                                    staticClass: "onpoint-caret-up icon-font",
                                  }),
                                ]
                              ),
                        ]
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "detalhes-apolice-content" },
            [
              _c(
                "div",
                { staticClass: "detalhes-apolice-content-primeira-linha" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "detalhes-apolice-content-primeira-linha-tomador",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "detalhes-apolice-content-primeira-linha-tomador-dados",
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "detalhes-apolice-content-primeira-linha-tomador-titulo",
                            },
                            [_vm._v("\n              Tomador\n            ")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "detalhes-apolice-content-primeira-linha-tomador-conteudo",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.apoliceOriginalEndosso.Tomador.Nome
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "detalhes-apolice-content-primeira-linha-tomador-conteudo",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.apoliceOriginalEndosso.Tomador.CpfCnpj
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "detalhes-apolice-content-primeira-linha-tomador-endereco",
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "detalhes-apolice-content-primeira-linha-tomador-titulo",
                            },
                            [
                              _vm._v(
                                "\n              Endereço do Tomador\n            "
                              ),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "detalhes-apolice-content-primeira-linha-tomador-conteudo",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.apoliceOriginalEndosso.Tomador.Endereco
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "detalhes-apolice-content-primeira-linha-segurado",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "detalhes-apolice-content-primeira-linha-segurado-dados",
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "detalhes-apolice-content-primeira-linha-segurado-titulo",
                            },
                            [_vm._v("\n              Segurado\n            ")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "detalhes-apolice-content-primeira-linha-segurado-conteudo",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.apoliceOriginalEndosso.Segurado.Nome
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "detalhes-apolice-content-primeira-linha-segurado-conteudo",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.apoliceOriginalEndosso.Segurado.CpfCnpj
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "detalhes-apolice-content-primeira-linha-segurado-endereco",
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "detalhes-apolice-content-primeira-linha-segurado-titulo",
                            },
                            [
                              _vm._v(
                                "\n              Endereço do Segurado\n            "
                              ),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "detalhes-apolice-content-primeira-linha-segurado-conteudo",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.apoliceOriginalEndosso.Segurado.Endereco
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "detalhes-apolice-content-segunda-linha" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "detalhes-apolice-content-segunda-linha-dados",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "detalhes-apolice-content-segunda-linha-titulo",
                        },
                        [
                          _vm._v(
                            "\n            Modalidade Pricipal\n          "
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "detalhes-apolice-content-segunda-linha-conteudo",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.apoliceOriginalEndosso.DadosProposta
                                  .NomeModalidade
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "detalhes-apolice-content-segunda-linha-dados",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "detalhes-apolice-content-segunda-linha-titulo",
                        },
                        [_vm._v("\n            IS principal\n          ")]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "detalhes-apolice-content-segunda-linha-conteudo",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.apoliceOriginalEndosso.DadosProposta
                                  .ImportanciaSegurada
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "detalhes-apolice-content-segunda-linha-dados",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "detalhes-apolice-content-segunda-linha-titulo",
                        },
                        [_vm._v("\n            Vigência Principal\n          ")]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "detalhes-apolice-content-segunda-linha-conteudo",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.apoliceOriginalEndosso.DadosProposta
                                  .InicioVigencia
                              ) +
                              " à\n            " +
                              _vm._s(
                                _vm.apoliceOriginalEndosso.DadosProposta
                                  .FimVigencia
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "detalhes-apolice-content-segunda-linha-dados",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "detalhes-apolice-content-segunda-linha-titulo",
                        },
                        [_vm._v("\n            Taxa aplicada (%)\n          ")]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "detalhes-apolice-content-segunda-linha-conteudo",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.apoliceOriginalEndosso.DadosProposta.Taxa
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "Accordion",
                {
                  staticClass: "detalhes-apolice-content-terceira-linha",
                  attrs: { activeIndex: _vm.activeIndexObjeto },
                },
                [
                  _c(
                    "AccordionTab",
                    {
                      staticClass:
                        "detalhes-apolice-content-terceira-linha-objeto",
                      scopedSlots: _vm._u([
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "detalhes-apolice-content-terceira-linha-objeto-header",
                                  on: { click: _vm.expandirComponenteObjeto },
                                },
                                [
                                  _c("div", [
                                    _c("i", {
                                      staticClass:
                                        "onpoint-file-text icon-font-big",
                                    }),
                                    _c("span", [_vm._v("Objeto da apólice")]),
                                  ]),
                                  !_vm.componenteObjetoAtivo
                                    ? _c("i", {
                                        staticClass:
                                          "onpoint-caret-down icon-font",
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "onpoint-caret-up icon-font",
                                      }),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "detalhes-apolice-content-terceira-linha-objeto-conteudo",
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.objetoApoliceFormatado),
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }