var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      [
        _c(
          "div",
          { staticClass: "flex gap-4" },
          _vm._l(_vm.camposObjeto, function (tag, index) {
            return _c("OnpointInput", {
              key: index,
              staticClass: "mt-8 w-1/2",
              attrs: { label: tag.Label, required: true },
              on: {
                input: ($event) => {
                  _vm.setObject({ [tag.Name]: $event })
                  tag.Value = $event
                  _vm.formatarTextoObjeto()
                },
              },
            })
          }),
          1
        ),
        _c(
          "div",
          { staticClass: "mt-8" },
          [
            _c(
              "Accordion",
              { staticClass: "accordion" },
              [
                _c("AccordionTab", { attrs: { header: "Objeto da apólice" } }, [
                  _c("div", {
                    staticClass: "p-5",
                    domProps: { innerHTML: _vm._s(_vm.textoObjetoHtml) },
                  }),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c("Divider", { staticClass: "divider" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "title" }, [_vm._v("Dados do objeto")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }