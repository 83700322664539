<template>
  <div class="container">
    <label class="label"
      >{{ label ? label : null }}
      <i class="required" v-if="required">*</i></label
    >   
    <Dropdown
      @input="sendValue"
      :options="items"
      :optionLabel="optionLabel"
      :placeholder="
        optionLabelSelected
          ? optionLabelSelected.toString()
          : placeholder
          ? placeholder.toString()
          : null
      "
      :disabled="disabled"      
      showClear
    />    
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
export default {
  props: {
    required: Boolean,
    items: Array,
    label: String|| Number,
    placeholder: String || Number,
    optionLabel: String || Number,
    disabled: Boolean,
  },
  components: {
    Dropdown,
    Button
  },
  data() {
    return {
      optionLabelSelected: null,
    };
  }, 
  watch:{
    items(value){      
      this.optionLabelSelected=null
    }
  },
  methods: {
    sendValue(event) {      
      this.optionLabelSelected = event[this.optionLabel] || event;
      this.$emit("input", event);
    },    
  },
};
</script>

<style lang="scss" scoped>

.container {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
}
.label {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(55, 65, 81, 1);
  margin-bottom: 9px;
}
.required {
  color: rgba(180, 23, 58, 1);
}

.p-dropdown {
  border-radius: 8px;
  border: solid 1px rgba(209, 213, 219, 1);
  background-color: white;
}
.p-disabled {
  border-radius: 8px;
  border: solid 1px rgba(209, 213, 219, 1);
  background-color: #E5E7EB
}
</style>

