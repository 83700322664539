import instance from "@/axios";
import endossoProvider from "@/providers/endosso-provider";

export default {
  namespaced: true,
  state: {
    apoliceOriginalEndosso: {},
    endosso: {},
  },
  mutations: {
    updateApoliceOriginalEndosso(state, data){
      state.apoliceOriginalEndosso = data;
    },

    updateEndosso(state, data){
      state.endosso = data;
    }
  },
  actions: {
    async getEndorsementType({ commit }) {
      return await instance
        .get("/api/Endorsement/GetEndorsementType")
        .then(response => response.data.Response);
    },
  },
  getters: {
    apoliceOriginalEndosso(state) {
      return state.apoliceOriginalEndosso || {};
    },

    endosso(state) {
      return state.endosso || {};
    },
  }
};
