<template>
  <Accordion
    :multiple="true"
    :activeIndex="activeIndex"
    class="detalhes-apolice"
  >
    <AccordionTab>
      <template #header>
        <div
          class="detalhes-apolice-header"
          @click="expandirComponentePrincipal"
        >
          <div class="detalhes-apolice-header-primeiro-bloco">
            <div class="detalhes-apolice-header-primeiro-bloco-numero">
              <p>Nº Apólice</p>
              <p>{{ apoliceOriginalEndosso.NumeroApolice }}</p>
            </div>
            <div class="detalhes-apolice-header-primeiro-bloco-tipo">
              <p>Tipo de endosso</p>
              <p>{{ apoliceOriginalEndosso.TipoEndosso }}</p>
            </div>
          </div>
          <div class="detalhes-apolice-header-segundo-bloco">
            <div
              v-if="!componentePrincipalAtivo"
              class="detalhes-apolice-header-segundo-bloco-content"
            >
              <p>Visualizar detalhes da apólice</p>
              <i class="onpoint-caret-down icon-font"></i>
            </div>
            <div v-else class="detalhes-apolice-header-segundo-bloco-content">
              <p>Ocultar detalhes da apólice</p>
              <i class="onpoint-caret-up icon-font"></i>
            </div>
          </div>
        </div>
      </template>
      <div class="detalhes-apolice-content">
        <div class="detalhes-apolice-content-primeira-linha">
          <div class="detalhes-apolice-content-primeira-linha-tomador">
            <div class="detalhes-apolice-content-primeira-linha-tomador-dados">
              <p class="detalhes-apolice-content-primeira-linha-tomador-titulo">
                Tomador
              </p>
              <p
                class="detalhes-apolice-content-primeira-linha-tomador-conteudo"
              >
                {{ apoliceOriginalEndosso.Tomador.Nome }}
              </p>
              <p
                class="detalhes-apolice-content-primeira-linha-tomador-conteudo"
              >
                {{ apoliceOriginalEndosso.Tomador.CpfCnpj }}
              </p>
            </div>
            <div
              class="detalhes-apolice-content-primeira-linha-tomador-endereco"
            >
              <p class="detalhes-apolice-content-primeira-linha-tomador-titulo">
                Endereço do Tomador
              </p>
              <p
                class="detalhes-apolice-content-primeira-linha-tomador-conteudo"
              >
                {{ apoliceOriginalEndosso.Tomador.Endereco }}
              </p>
            </div>
          </div>
          <div class="detalhes-apolice-content-primeira-linha-segurado">
            <div class="detalhes-apolice-content-primeira-linha-segurado-dados">
              <p
                class="detalhes-apolice-content-primeira-linha-segurado-titulo"
              >
                Segurado
              </p>
              <p
                class="detalhes-apolice-content-primeira-linha-segurado-conteudo"
              >
                {{ apoliceOriginalEndosso.Segurado.Nome }}
              </p>
              <p
                class="detalhes-apolice-content-primeira-linha-segurado-conteudo"
              >
                {{ apoliceOriginalEndosso.Segurado.CpfCnpj }}
              </p>
            </div>
            <div
              class="detalhes-apolice-content-primeira-linha-segurado-endereco"
            >
              <p
                class="detalhes-apolice-content-primeira-linha-segurado-titulo"
              >
                Endereço do Segurado
              </p>
              <p
                class="detalhes-apolice-content-primeira-linha-segurado-conteudo"
              >
                {{ apoliceOriginalEndosso.Segurado.Endereco }}
              </p>
            </div>
          </div>
        </div>
        <div class="detalhes-apolice-content-segunda-linha">
          <div class="detalhes-apolice-content-segunda-linha-dados">
            <p class="detalhes-apolice-content-segunda-linha-titulo">
              Modalidade Pricipal
            </p>
            <p class="detalhes-apolice-content-segunda-linha-conteudo">
              {{ apoliceOriginalEndosso.DadosProposta.NomeModalidade }}
            </p>
          </div>
          <div class="detalhes-apolice-content-segunda-linha-dados">
            <p class="detalhes-apolice-content-segunda-linha-titulo">
              IS principal
            </p>
            <p class="detalhes-apolice-content-segunda-linha-conteudo">
              {{ apoliceOriginalEndosso.DadosProposta.ImportanciaSegurada }}
            </p>
          </div>
          <div class="detalhes-apolice-content-segunda-linha-dados">
            <p class="detalhes-apolice-content-segunda-linha-titulo">
              Vigência Principal
            </p>
            <p class="detalhes-apolice-content-segunda-linha-conteudo">
              {{ apoliceOriginalEndosso.DadosProposta.InicioVigencia }} à
              {{ apoliceOriginalEndosso.DadosProposta.FimVigencia }}
            </p>
          </div>
          <div class="detalhes-apolice-content-segunda-linha-dados">
            <p class="detalhes-apolice-content-segunda-linha-titulo">
              Taxa aplicada (%)
            </p>
            <p class="detalhes-apolice-content-segunda-linha-conteudo">
              {{ apoliceOriginalEndosso.DadosProposta.Taxa }}
            </p>
          </div>
        </div>

        <Accordion
          :activeIndex="activeIndexObjeto"
          class="detalhes-apolice-content-terceira-linha"
        >
          <AccordionTab class="detalhes-apolice-content-terceira-linha-objeto">
            <template #header>
              <div
                class="detalhes-apolice-content-terceira-linha-objeto-header"
                @click="expandirComponenteObjeto"
              >
                <div>
                  <i class="onpoint-file-text icon-font-big"></i>
                  <span>Objeto da apólice</span>
                </div>
                <i
                  v-if="!componenteObjetoAtivo"
                  class="onpoint-caret-down icon-font"
                ></i>
                <i v-else class="onpoint-caret-up icon-font"></i>
              </div>
            </template>
            <div
              class="detalhes-apolice-content-terceira-linha-objeto-conteudo"
            >
              <span v-html="objetoApoliceFormatado"></span>
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    </AccordionTab>
  </Accordion>
</template>

<script>
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";

export default {
  name: "visualizar-detalhes-apolice",
  components: {
    Accordion,
    AccordionTab,
  },
  props: {
    apoliceOriginalEndosso: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
      activeIndexObjeto: 1,
      componentePrincipalAtivo: false,
      componenteObjetoAtivo: false,
    };
  },
  computed: {
    objetoApoliceFormatado() {
      return this.apoliceOriginalEndosso.ObjetoApolice.Value.replace(/font-family:\s*'Calibri'/g,
        "font-family: 'Montserrat'")
    }
  },
  methods: {
    expandirComponentePrincipal() {
      this.componentePrincipalAtivo = !this.componentePrincipalAtivo;
      this.deveAplicarClasseBorder();
    },

    expandirComponenteObjeto() {
      this.componenteObjetoAtivo = !this.componenteObjetoAtivo;
      this.deveAplicarClasseBorder();
    },

    deveAplicarClasseBorder() {
      const accordionLinks = document.querySelectorAll(
        ".p-accordion-header-link"
      );

      const activeLinkPrincipal = accordionLinks[this.activeIndex];
      const activeLinkObjeto = accordionLinks[this.activeIndexObjeto];

      if (activeLinkPrincipal) {
        if (!this.componentePrincipalAtivo) {
          activeLinkPrincipal.classList.add("detalhes-apolice-custom-border");
        } else {
          activeLinkPrincipal.classList.remove(
            "detalhes-apolice-custom-border"
          );
        }
      }

      if (activeLinkObjeto) {
        if (!this.componenteObjetoAtivo) {
          activeLinkObjeto.classList.add("detalhes-apolice-custom-border");
        } else {
          activeLinkObjeto.classList.remove("detalhes-apolice-custom-border");
        }
      }
    },
  },
  mounted() {
    this.deveAplicarClasseBorder();
  },
};
</script>

<style lang="scss"></style>

<style lang="scss" scoped>
.detalhes-apolice {
  &-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-primeiro-bloco {
      display: flex;

      &-numero {
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding: 12px 40px 12px 0;

        & p:first-of-type {
          font-size: 14px;
          font-weight: 700;
        }

        & p:last-of-type {
          font-size: 16px;
          font-weight: 400;
        }
      }

      &-tipo {
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding: 12px 24px;

        & p:first-of-type {
          font-size: 14px;
          font-weight: 700;
        }

        & p:last-of-type {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    &-segundo-bloco {
      margin-right: 12px;

      & p {
        font-weight: 14px;
        font-weight: 500;
      }

      &-content {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-primeira-linha {
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;
      width: 100%;
      gap: 18px;

      &-tomador,
      &-segurado {
        width: 50%;
        padding: 20px 24px;
        background-color: #f3f4f6;
        border-radius: 12px;

        &-dados,
        &-endereco {
          display: flex;
          flex-direction: column;
          gap: 6px;
        }

        &-dados {
          margin-bottom: 24px;
        }

        &-titulo {
          font-size: 14px;
          font-weight: 700;
        }

        &-conteudo {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    &-segunda-linha {
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;
      padding: 20px 72px 20px 24px;
      background-color: #f3f4f6;
      border-radius: 12px;
      align-items: center;
      justify-content: space-between;

      &-dados {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      &-titulo {
        font-size: 14px;
        font-weight: 700;
      }

      &-conteudo {
        font-size: 16px;
        font-weight: 400;
      }
    }

    &-terceira-linha {
      display: flex;
      flex-direction: row;
      margin-bottom: 12px;
      width: 100%;

      &-objeto {
        width: 100%;

        .p-accordion-header-link {
          justify-content: left !important;
        }

        &-header {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        &-conteudo {
          padding: 8px 12px 15px 12px;
        }
      }
    }
  }
}

.icon-font::before {
  font-size: 16px !important;
  position: relative;
  padding: 1px;
}

.icon-font-big::before {
  font-size: 18px !important;
  position: relative;
  padding: 1px;
  top: 2px;
}
</style>
