var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "grid grid-cols-2 gap-6" },
        [
          _c("OnpointSelect", {
            staticClass: "mt-8",
            attrs: {
              label: "Tipo de modalidade complementar",
              placeholder: "Selecione",
              items: _vm.modalidadesComplementarList,
              optionLabel: "Name",
            },
            model: {
              value: _vm.selectedModalidadeComplementar,
              callback: function ($$v) {
                _vm.selectedModalidadeComplementar = $$v
              },
              expression: "selectedModalidadeComplementar",
            },
          }),
        ],
        1
      ),
      _vm.selectedModalidadeComplementar
        ? _c(
            "div",
            { staticClass: "flex gap-4" },
            [
              _c("OnpointInput", {
                staticClass: "mt-8 w-1/4",
                attrs: {
                  label: "Valor de importancia segurada (IS)",
                  required: true,
                  currency: true,
                },
                on: {
                  input: function ($event) {
                    return _vm.formatarTextoObjeto()
                  },
                  change: function ($event) {
                    return _vm.calculoDataFimVigenciaComplementar()
                  },
                },
                model: {
                  value: _vm.importanciaSeguradaComplementar,
                  callback: function ($$v) {
                    _vm.importanciaSeguradaComplementar = $$v
                  },
                  expression: "importanciaSeguradaComplementar",
                },
              }),
              _c("OnpointInput", {
                staticClass: "mt-8 w-1/4",
                attrs: {
                  label: "Data de inicio da vigencia",
                  required: true,
                  calendar: true,
                  calendarDisabled: true,
                },
                on: {
                  input: function ($event) {
                    return _vm.formatarTextoObjeto()
                  },
                },
                model: {
                  value: _vm.dataDeInicioVigenciaComplementar,
                  callback: function ($$v) {
                    _vm.dataDeInicioVigenciaComplementar = $$v
                  },
                  expression: "dataDeInicioVigenciaComplementar",
                },
              }),
              _c("OnpointInput", {
                staticClass: "mt-8 w-1/4",
                attrs: { label: "Prazo em dias", required: true },
                on: {
                  input: function ($event) {
                    return _vm.formatarTextoObjeto()
                  },
                  change: function ($event) {
                    return _vm.calculoDataFimVigenciaComplementar()
                  },
                },
                model: {
                  value: _vm.prazoDeVigenciaComplementar,
                  callback: function ($$v) {
                    _vm.prazoDeVigenciaComplementar = $$v
                  },
                  expression: "prazoDeVigenciaComplementar",
                },
              }),
              _c("OnpointInput", {
                staticClass: "mt-8 w-1/4",
                attrs: {
                  label: "Data de final da vigencia",
                  required: true,
                  calendar: true,
                  calendarDisabled: true,
                },
                on: {
                  input: function ($event) {
                    return _vm.formatarTextoObjeto()
                  },
                },
                model: {
                  value: _vm.dataFimVigenciaComplementar,
                  callback: function ($$v) {
                    _vm.dataFimVigenciaComplementar = $$v
                  },
                  expression: "dataFimVigenciaComplementar",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "mt-8" },
        [
          _c(
            "div",
            { staticClass: "flex gap-4" },
            [
              _vm._l(_vm.camposObjetoComplementar, function (tag, index) {
                return [
                  tag.Type != "Automatic"
                    ? _c("OnpointInput", {
                        key: index,
                        staticClass: "w-1/4",
                        attrs: { label: tag.Label, required: true },
                        on: {
                          input: ($event) => {
                            _vm.setObject({ [tag.Name]: $event })
                            tag.Value = $event
                            _vm.formatarTextoObjeto()
                          },
                        },
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _c(
            "Accordion",
            { staticClass: "accordion mt-8" },
            [
              _c(
                "AccordionTab",
                {
                  attrs: {
                    header: "Visualizar objeto da modalidade complementar",
                  },
                },
                [
                  _c("div", {
                    staticClass: "p-5",
                    domProps: { innerHTML: _vm._s(_vm.textoObjetoHtml) },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Divider", { staticClass: "divider" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "title" }, [
        _vm._v("Dados da modalidade complementar"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }