var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("label", { staticClass: "label" }, [
        _vm._v(_vm._s(_vm.label ? _vm.label : null) + "\n    "),
        _vm.required
          ? _c("i", { staticClass: "required" }, [_vm._v("*")])
          : _vm._e(),
      ]),
      _c("Dropdown", {
        attrs: {
          options: _vm.items,
          optionLabel: _vm.optionLabel,
          placeholder: _vm.optionLabelSelected
            ? _vm.optionLabelSelected.toString()
            : _vm.placeholder
            ? _vm.placeholder.toString()
            : null,
          disabled: _vm.disabled,
          showClear: "",
        },
        on: { input: _vm.sendValue },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }