var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "flex gap-4" },
      [
        _c("OnpointInputCurrency", {
          staticClass: "mt-8 w-1/4",
          attrs: {
            label: "Valor de importancia segurada (IS)",
            required: true,
          },
          on: {
            change: function ($event) {
              return _vm.calculoDataFimVigenciaPrincipal()
            },
          },
          model: {
            value: _vm.importanciaSegurada,
            callback: function ($$v) {
              _vm.importanciaSegurada = $$v
            },
            expression: "importanciaSegurada",
          },
        }),
        _c("OnpointInput", {
          staticClass: "mt-8 w-1/4",
          attrs: {
            label: "Data de inicio da vigencia",
            required: true,
            calendar: true,
          },
          model: {
            value: _vm.dataDeInicioVigenciaPrincipal,
            callback: function ($$v) {
              _vm.dataDeInicioVigenciaPrincipal = $$v
            },
            expression: "dataDeInicioVigenciaPrincipal",
          },
        }),
        _c("OnpointInput", {
          staticClass: "mt-8 w-1/4",
          attrs: { label: "Prazo em dias", required: true },
          on: {
            change: function ($event) {
              return _vm.calculoDataFimVigenciaPrincipal()
            },
          },
          model: {
            value: _vm.prazoDeVigencia,
            callback: function ($$v) {
              _vm.prazoDeVigencia = $$v
            },
            expression: "prazoDeVigencia",
          },
        }),
        _c("OnpointInput", {
          staticClass: "mt-8 w-1/4",
          attrs: {
            label: "Data de final da vigencia",
            required: true,
            calendar: true,
            calendarDisabled: true,
          },
          model: {
            value: _vm.dataFimVigenciaPrincipal,
            callback: function ($$v) {
              _vm.dataFimVigenciaPrincipal = $$v
            },
            expression: "dataFimVigenciaPrincipal",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "title" }, [_vm._v("Dados da proposta")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }