var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-4" },
    [
      _c(
        "div",
        { staticClass: "new-history" },
        [
          _c("h2", { staticClass: "font-bold" }, [
            _vm._v("Histórico de consultas"),
          ]),
          _vm.historico.length > 0
            ? [
                _c(
                  "vs-table",
                  {
                    attrs: {
                      data: _vm.historico,
                      hoverFlat: true,
                      noDataText: "Nenhuma histórico encontrado",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ data }) {
                            return _vm._l(data, function (item, indextr) {
                              return _c(
                                "vs-tr",
                                { key: indextr, attrs: { data: item } },
                                [
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-left",
                                      attrs: {
                                        data: item.BureauDadosLog.TipoConsulta,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            item.BureauDadosLog.TipoConsulta
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-left",
                                      attrs: {
                                        data: item.BureauDadosLog.DataConsulta,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm._f("moment")(
                                              item.BureauDadosLog.DataConsulta,
                                              "DD/MM/YYYY HH:mm"
                                            )
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-left",
                                      attrs: {
                                        data: item.BureauDadosLog.Bureau,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.BureauDadosLog.Bureau) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-left",
                                      attrs: {
                                        data: item.BureauDadosLog.Mensagem,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.BureauDadosLog.Mensagem) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    { staticClass: "text-right" },
                                    [
                                      _c(
                                        "vs-dropdown",
                                        {
                                          staticClass: "btn-action-apolice",
                                          attrs: {
                                            "vs-custom-content": "",
                                            "vs-trigger-click": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              attrs: {
                                                color: "dark",
                                                type: "flat",
                                                title: "Ações",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "onpoint-dots-three-vertical icon-dots-three-vertical",
                                                attrs: { role: "button" },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "vs-dropdown-menu",
                                            {
                                              staticClass:
                                                "btn-action-apolice-options",
                                            },
                                            [
                                              _c("vs-dropdown-item", [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-base text-nowrap",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openModalJson(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Visualizar JSON"),
                                                    ]),
                                                  ]
                                                ),
                                              ]),
                                              _c(
                                                "vs-dropdown-item",
                                                { attrs: { divider: "" } },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-base text-nowrap",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openModalOriginalData(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Dados originais"
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            })
                          },
                        },
                      ],
                      null,
                      false,
                      2845897639
                    ),
                  },
                  [
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", [_vm._v("Onde foi consultado?")]),
                        _c("vs-th", { staticClass: "text-left" }, [
                          _vm._v("Data da consulta"),
                        ]),
                        _c("vs-th", { staticClass: "text-left" }, [
                          _vm._v("Bureau"),
                        ]),
                        _c("vs-th", { staticClass: "text-left" }, [
                          _vm._v("Mensagem"),
                        ]),
                        _c("vs-th"),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _vm.numberOfPages > 1
                  ? _c("vs-pagination", {
                      staticClass: "mt-5",
                      attrs: { total: _vm.numberOfPages },
                      model: {
                        value: _vm.currentPage,
                        callback: function ($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage",
                      },
                    })
                  : _vm._e(),
              ]
            : [_vm._m(0)],
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            id: "listar-endosso-modal",
            title: " ",
            active: _vm.showRequestJSON,
          },
          on: {
            "update:active": function ($event) {
              _vm.showRequestJSON = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full text-right" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "button-primary",
                    on: {
                      click: function ($event) {
                        return _vm.downloadJson()
                      },
                    },
                  },
                  [_vm._v("\n          Download JSON\n        ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col pt-5 w-full mt-2" },
              [
                _c("tree-view", {
                  staticClass: "historico-consulta-segurado-visualizar-json",
                  attrs: {
                    id: "element-to-print",
                    data: _vm.parsedRequestJson,
                    options: { maxDepth: 3, rootObjectKey: "JSON" },
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: { title: " ", active: _vm.showOriginalData },
          on: {
            "update:active": function ($event) {
              _vm.showOriginalData = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full text-right" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "button-primary",
                    on: {
                      click: function ($event) {
                        return _vm.downloadOriginalData()
                      },
                    },
                  },
                  [_vm._v("\n          Download dados\n        ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col pt-5 w-full mt-2" },
              [
                _c("tree-view", {
                  staticClass: "historico-consulta-tomador-visualizar-json",
                  attrs: {
                    id: "element-to-print-original-data",
                    data: _vm.parsedOriginalData,
                    options: { maxDepth: 3, rootObjectKey: "Dados Originais" },
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "flex flex-col w-full md:w-1/2 mx-auto items-center mt-32",
      },
      [
        _c("p", [
          _c("span", { staticClass: "material-icons text-6xl" }, [
            _vm._v("description"),
          ]),
        ]),
        _c("p", { staticClass: "text-center" }, [
          _vm._v("\n          Nenhum registro encontrado!\n        "),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }