<template>
  <div
    class="btn-list-actions"
    :class="{ hide: ocultarAcoes }"
    @mouseover="deveExibirMenu"
    @mouseleave="deveOcultarMenu"
  >
    <button class="list-actions">
      <i class="el-icon-s-unfold" v-if="!exibirMenu"></i>
      <i class="el-icon-s-fold" v-else></i>
      Ações
      <i class="el-icon-caret-bottom"></i>
    </button>

    <ul v-if="exibirMenu" class="menu-open">
      <div
        class="menu-open-item"
        v-if="
          deveExibirEditar(parte.TipoParteName) && parte.TipoParteStatusId == 1
        "
        @click="editarDados"
      >
        <li class="menu-open-item-title">
          <img
            src="@/assets/images/icons/ccg-icons/edit-pencil-ccg.svg"
            alt="Ícone de edição de dados"
            width="24"
            height="24"
          />
          Edição de dados
        </li>
      </div>

      <div
        v-if="
          exibirCadastroConjuge &&
            !parte.Conjuge &&
            parte.TipoParteStatusId == 1 && conjugeHabilitado
        "
        class="menu-open-item"
        @click="cadastrarConjuge"
      >
        <li class="menu-open-item-title">
          <img
            src="@/assets/images/icons/ccg-icons/spouse-icon-ccg.svg"
            alt="Ícone de edição de dados"
            width="24"
            height="24"
          />
          Cadastrar cônjuge
        </li>
      </div>

      <div
        class="menu-open-item"
        v-if="podeReenviarEmailIndividual && parte.TipoParteStatusId == 2 && !isMapfre"
        @click="reenviarEmail"
      >
        <li class="menu-open-item-title">
          <img
            src="@/assets/images/icons/ccg-icons/forward-to-inbox-ccg.svg"
            alt="Ícone de reenviar de email"
            width="24"
            height="24"
          />
          Reenviar e-mail
        </li>
      </div>

      <div
        class="menu-open-item"
        v-if="
          podeEnviarLinkAssinaturaIndividual &&
            parte.LinkAssinatura &&
            parte.TipoParteStatusId != 3
        "
        @click="copiarLinkAssinatura()"
      >
        <li class="menu-open-item-title">
          <img
            src="@/assets/images/icons/ccg-icons/link-icon-ccg.svg"
            alt="Ícone de link individual de assinatura"
            width="24"
            height="24"
          />
          Link individual de assinatura
        </li>
      </div>

      <div
        class="menu-open-item"
        v-if="
          deveExibirExluir(parte.TipoParteName) && parte.TipoParteStatusId == 1
        "
        @click="excluirRepresentante"
      >
        <li class="menu-open-item-title">
          <img
            src="@/assets/images/icons/ccg-icons/trash-ccg.svg"
            alt="Ícone de excluir representante"
            width="24"
            height="24"
          />
          {{
            parte.TipoParteName === "Representante Legal"
              ? "Excluir representante"
              : parte.TipoParteName === "Conjuge"
              ? "Excluir cônjuge"
              : parte.TipoParteName === "Testemunha"
              ? "Excluir testemunha"
              : "Excluir fiador"
          }}
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
import PerfilHelpers from "@/helpers/PerfilHelpers";
import compareCompanyUtils from "@/utils/compareCompanyUtils.js";

export default {
  name: "lista-acoes",
  props: {
    parte: {
      type: Object,
      required: true
    },
    permissoes: {
      type: Array,
      required: true
    },
    contratoEspecificacoes: {
      type: Object,
      required: true
    },
    conjugeHabilitado: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      profileHelpers: PerfilHelpers,
      exibirMenu: false,
      isMapfre: false
    };
  },
  computed: {
    podeEditarRepresentante() {
      return this.permissoes.includes("PodeEditarRepresentante");
    },

    podeEditarFiadorPF() {
      return this.permissoes.includes("PodeEditarFiadorPessoaFisica");
    },

    podeEditarFiadorPJ() {
      return this.permissoes.includes("PodeEditarFiadorPessoaJuridica");
    },

    exibirCadastroConjuge() {
      return this.parte.TipoParteName === "Fiador Pessoa Física" ? true : false;
    },

    podeReenviarEmailIndividual() {
      return this.permissoes.includes("PodeReenviarEmailIndividual");
    },

    podeEnviarLinkAssinaturaIndividual() {
      return this.permissoes.includes("PodeEnviarLinkAssinaturaIndividual");
    },

    podeExcluirRepresentante() {
      return this.permissoes.includes("PodeExcluirRepresentante");
    },

    podeExcluirFiadorPessoaFisica() {
      return this.permissoes.includes("PodeExcluirFiadorPessoaFisica");
    },

    podeExcluirFiadorPessoaJuridica() {
      return this.permissoes.includes("PodeExcluirFiadorPessoaJuridica");
    },

    ocultarAcoes: {
      get() {
        if (
          this.contratoEspecificacoes.StatusId == 3
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
  },
  methods: {
    deveExibirMenu() {
      if (this.contratoEspecificacoes.StatusId == 3) return;
      this.exibirMenu = true;
    },

    deveOcultarMenu() {
      this.exibirMenu = false;
    },

    deveExibirExluir(podeExcluir) {
      switch (podeExcluir) {
        case "Representante Legal":
          return this.podeExcluirRepresentante;
        case "Fiador Pessoa Física":
          return this.podeExcluirFiadorPessoaFisica;
        case "Fiador Pessoa Jurídica":
          return this.podeExcluirFiadorPessoaJuridica;
        default:
          return true;
      }
    },

    deveExibirEditar(podeEditar) {
      switch (podeEditar) {
        case "Representante Legal":
          return this.podeEditarRepresentante;
        case "Fiador Pessoa Física":
          return this.podeEditarFiadorPF;
        case "Fiador Pessoa Jurídica":
          return this.podeEditarFiadorPJ;
        default:
          return true;
      }
    },

    cadastrarConjuge() {
      this.$emit("cadastrarConjugeSelecionado", {
        mensagem: "Cadastrar conjuge selecionada"
      });
    },

    editarDados() {
      this.$emit("edicaoDadosSelecionado", {
        mensagem: "Edição dados selecionada"
      });
    },

    reenviarEmail() {
      this.$emit("reenviarEmailSelecionado", {
        mensagem: "Reenviar email selecionado"
      });
    },

    copiarLinkAssinatura() {
      this.$emit("copiarLinkAssinaturaSelecionado", {
        mensagem: "Copiar link assinatura selecionado"
      });
    },

    excluirRepresentante() {
      this.$emit("excluirRepresentanteSelecionado", {
        mensagem: "Excluir representante selecionado"
      });
    }
  },
  mounted() {
    this.isMapfre = compareCompanyUtils("companyName", "mapfre");
  },
};
</script>

<style lang="scss" scoped>
.hide {
  visibility: hidden;
}

.btn-list-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.list-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  width: 140px;
  height: 56px !important;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  border: none;
  outline: none;
  border-radius: 8px !important;
  background: rgba(var(--vs-primary), 1) !important;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.list-actions:hover {
  background: rgba(var(--vs-primary), 1) !important;
}

.list-actions:hover,
.menu-open:hover {
  display: flex;
}

.menu-open {
  display: none;
  display: block !important;
  position: absolute;
  right: 0;
  top: 52px;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(var(--vs-primary), 1) !important;
  border-radius: 8px;
  z-index: 1000;
  padding: 16px;
  box-shadow: 0 50px 50px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  &-item {
    width: 284px;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;

    &:hover {
      font-weight: 700;
      background-color: rgba(var(--vs-primary), 0.08) !important;
    }

    &:last-child {
      margin-bottom: 0;
    }

    & img {
      margin: 0 12px 0 20px;
    }

    &-title {
      display: flex;
      font-size: 14px;
      line-height: 20px;
      color: #2c2c2c;
    }
  }
}
</style>
