var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h3", [_vm._v("Dados bancários do Tomador")]),
    _c(
      "div",
      { staticClass: "flex gap-4" },
      [
        _c("OnpointInput", {
          staticClass: "mt-8 w-1/2",
          attrs: {
            label: "Data de inicio da vigencia",
            required: true,
            calendar: true,
          },
          model: {
            value: _vm.teste,
            callback: function ($$v) {
              _vm.teste = $$v
            },
            expression: "teste",
          },
        }),
        _c("OnpointSelect", {
          staticClass: "mt-8 w-1/2",
          attrs: {
            label: "Modalidade principal",
            placeholder: "Selecione",
            required: true,
            items: [0, 1, 2],
            optionLabel: "Name",
            disabled: false,
          },
          model: {
            value: _vm.teste,
            callback: function ($$v) {
              _vm.teste = $$v
            },
            expression: "teste",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "flex gap-4" },
      [
        _c("OnpointInput", {
          staticClass: "mt-8 w-1/4",
          attrs: {
            label: "Data de inicio da vigencia",
            required: true,
            calendar: true,
          },
          model: {
            value: _vm.teste,
            callback: function ($$v) {
              _vm.teste = $$v
            },
            expression: "teste",
          },
        }),
        _c("OnpointInput", {
          staticClass: "mt-8 w-1/4",
          attrs: {
            label: "Data de inicio da vigencia",
            required: true,
            calendar: true,
          },
          model: {
            value: _vm.teste,
            callback: function ($$v) {
              _vm.teste = $$v
            },
            expression: "teste",
          },
        }),
        _c("OnpointInput", {
          staticClass: "mt-8 w-1/4",
          attrs: { label: "Prazo em dias", required: true },
          model: {
            value: _vm.teste,
            callback: function ($$v) {
              _vm.teste = $$v
            },
            expression: "teste",
          },
        }),
        _c("OnpointInput", {
          staticClass: "mt-8 w-1/4",
          attrs: {
            label: "Data de final da vigencia",
            required: true,
            calendar: true,
            calendarDisabled: true,
          },
          model: {
            value: _vm.teste,
            callback: function ($$v) {
              _vm.teste = $$v
            },
            expression: "teste",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }