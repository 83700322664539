var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "credentials-asd-modal-error" },
    [
      _c(
        "el-dialog",
        {
          staticStyle: { padding: "0px" },
          attrs: {
            title: "Atenção!",
            visible: _vm.loginError,
            width: "50%",
            center: "",
            "show-close": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.loginError = $event
            },
          },
        },
        [
          _c(
            "vs-row",
            {
              staticStyle: { padding: "20px" },
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
              },
            },
            [
              _c("p", { staticClass: "error-message" }, [
                _vm._v("Houve um erro ao fazer login!"),
              ]),
              _vm.loginErrorMessage
                ? _c("p", { staticClass: "error-message mt-5" }, [
                    _vm._v(_vm._s(_vm.loginErrorMessage)),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.redirectLogin()
                    },
                  },
                },
                [_vm._v("Tentar novamente")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }