var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "steps-content" },
    [
      _c(
        "section",
        [
          _c("Card", {
            staticClass: "card",
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function () {
                  return [
                    _c("div", { staticClass: "title" }, [
                      _c("p", [_vm._v("Informações do tomador")]),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "subtitle",
                fn: function () {
                  return [
                    _c("div", { staticClass: "subtitle" }, [
                      _c("p", [_vm._v("Informe dos dados do tomador")]),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "content",
                fn: function () {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "div",
                          [
                            _c("InputSearchSelect", {
                              staticClass: "w-2/3",
                              attrs: {
                                placeholder: "Selecionar",
                                label:
                                  "Digite o CNPJ ou Razão Social para buscar um tomador.",
                                required: true,
                                icon: "onpoint-caret-down",
                                infoData: _vm.dadosBuscaTomador,
                                isLoading: _vm.isLoadingSearchTomador,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "infoData",
                                  fn: function () {
                                    return _vm._l(
                                      _vm.dadosBuscaTomador,
                                      function (item, i) {
                                        return _c("div", { key: i }, [
                                          _c(
                                            "div",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.validarTomador(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              i < 3
                                                ? _c("Card", {
                                                    staticClass:
                                                      "item-searched",
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "title",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "title",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        item.Name
                                                                      ) +
                                                                      "\n                        "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                        {
                                                          key: "content",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "informations",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        item.Cnpj
                                                                      ) +
                                                                      "\n                        "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "informations",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        item.Description
                                                                      ) +
                                                                      "                        \n                        "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ])
                                      }
                                    )
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.cnpjRazaoSocial,
                                callback: function ($$v) {
                                  _vm.cnpjRazaoSocial = $$v
                                },
                                expression: "cnpjRazaoSocial",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _vm.dadosTomador.IsNominatedToOtherBroker
                              ? _c("AlertCard", {
                                  staticClass: "mt-5",
                                  attrs: {
                                    title:
                                      "Esse tomador está nomeado para outra corretora.",
                                    text: "Para prosseguir com uma proposta, anexe uma carta de nomeação para submeter ao processo de nomeação.",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _vm.dadosTomador.HasPendingNomeationProcess
                              ? _c("AlertCard", {
                                  staticClass: "mt-5",
                                  attrs: {
                                    title: "Solicitação em análise.",
                                    text: `Você já possui uma solicitação de nomeação via carta para esse tomador. Data da solicitação:${_vm.formatarData(
                                      _vm.dadosTomador
                                        .PolicyHolderNomeationRequests[0]
                                        .RequestDate
                                    )}`,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.selectedTomador
                          ? _c(
                              "div",
                              { staticClass: "mt-5" },
                              [
                                _c("InformacoesTomadorCard", {
                                  attrs: { tomador: _vm.selectedTomador },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.selectedTomador && _vm.filiais[0]
                          ? _c(
                              "div",
                              { staticClass: "mt-5" },
                              [
                                !_vm.alertOtherBrokerOrHasPendingNomeation
                                  ? _c("FilialList", {
                                      attrs: { filiais: _vm.filiais },
                                      on: {
                                        deleteFilial: function ($event) {
                                          return _vm.excluirFilial($event)
                                        },
                                      },
                                      model: {
                                        value: _vm.selectedFilial,
                                        callback: function ($$v) {
                                          _vm.selectedFilial = $$v
                                        },
                                        expression: "selectedFilial",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showFilialButton
                          ? _c(
                              "div",
                              { staticClass: "mt-5" },
                              [
                                _c("Card", {
                                  staticClass: "filial-dialog-card-button",
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "content",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "content" },
                                              [
                                                _c("Button", {
                                                  staticClass:
                                                    "p-button-rounded p-button-sm white-button-center",
                                                  attrs: {
                                                    label: "Adicionar filial",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.filialDialogVisible =
                                                        !_vm.filialDialogVisible
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3625146723
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("Divider", { staticClass: "divider" }),
                        _c("div", { staticClass: "limits-and-fees-data" }, [
                          _c("div", { staticClass: "title" }, [
                            _c("p", [_vm._v("Limites e taxas")]),
                          ]),
                          _c("div", { staticClass: "subtitle" }, [
                            _c("p", [_vm._v("Dados informativos")]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "awaiting-limits" },
                            [
                              _vm.showtableLimits
                                ? _c("DataTableLimits", {
                                    attrs: {
                                      data:
                                        (_vm.limitesETaxas || {})
                                          .LimitAndRates || [],
                                      maxApprovedLimit:
                                        _vm.limitesETaxas.MaxApprovedLimit,
                                      maxAvailableLimit:
                                        _vm.limitesETaxas.MaxAvailableLimit,
                                    },
                                  })
                                : _vm._e(),
                              _vm.alertOtherBrokerOrHasPendingNomeation
                                ? _c("Card", {
                                    staticClass: "card",
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "title",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "title" },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      "Tomador nomeado para outra corretora"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "content",
                                          fn: function () {
                                            return undefined
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      549728490
                                    ),
                                  })
                                : _vm._e(),
                              !_vm.showtableLimits &&
                              !_vm.alertOtherBrokerOrHasPendingNomeation
                                ? _c("Card", {
                                    staticClass: "card",
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "title",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "title" },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      "Aguardando informações"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "subtitle",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "subtitle" },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      "Preencha o campo de CNPJ para visualizar as taxas"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "content",
                                          fn: function () {
                                            return undefined
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2156176256
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mt-5 mb-8" },
                          [
                            _vm.canRequestLimitReview
                              ? _c("Card", {
                                  staticClass: "card-revision-limit",
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "content",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "content" },
                                              [
                                                _c("Button", {
                                                  staticClass:
                                                    "p-button-rounded p-button-sm white-button-center",
                                                  attrs: {
                                                    label:
                                                      "Solicitar revisão de limite",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.solicitarLimiteVisible =
                                                        !_vm.solicitarLimiteVisible
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2165255810
                                  ),
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.showtableLimits
                          ? _c(
                              "div",
                              [
                                _c("Card", {
                                  staticClass: "adicionalInfo",
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "content",
                                        fn: function () {
                                          return [
                                            _c("div", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.informacoesAdicionaisLimites
                                                ),
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    4092747845
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.dadosTomador.CpfCnpj &&
                        _vm.dadosTomador.Id > 0 &&
                        !_vm.dadosTomador.HasPendingNomeationProcess &&
                        !_vm.dadosTomador.IsNominatedToThisBroker
                          ? _c(
                              "div",
                              [
                                _c("Divider", { staticClass: "divider" }),
                                _c("CartaNomeacao", {
                                  attrs: { tomador: _vm.dadosTomador },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex justify-end" },
                      [
                        _c("Button", {
                          staticClass: "p-button-rounded cancel-button",
                          attrs: { label: "Cancelar" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push({ path: "/" })
                            },
                          },
                        }),
                        _c("Button", {
                          staticClass: "p-button-rounded",
                          attrs: {
                            label: "Próximo",
                            disabled: _vm.nextPageDisabled,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.nextPage()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c("FilialDialog", {
        attrs: { visible: _vm.filialDialogVisible },
        on: {
          cancel: function ($event) {
            _vm.filialDialogVisible = false
          },
          confirm: function ($event) {
            return _vm.salvarFilial(_vm.filialCNPJ)
          },
        },
        model: {
          value: _vm.filialCNPJ,
          callback: function ($$v) {
            _vm.filialCNPJ = $$v
          },
          expression: "filialCNPJ",
        },
      }),
      _c("SolicitarLimiteDialog", {
        attrs: {
          visible: _vm.solicitarLimiteVisible,
          detalhamento: _vm.detalhamento,
          tomadorUniqueId: _vm.dadosTomador.UniqueId,
        },
        on: {
          cancel: function ($event) {
            _vm.solicitarLimiteVisible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }