<template>
  <div v-if="apoliceOriginalEndosso">
    <Steps :model="steps" class="mt-5 mb-5 steps"></Steps>
    <div v-if="apoliceOriginalEndosso.TipoEndosso">
      <div class="mt-5 mb-5">
        <VisualizarDetalhesApolice :apoliceOriginalEndosso="apoliceOriginalEndosso" />
      </div>
      <keep-alive>
        <router-view
          :formData="formObject"
          @prevPage="prevPage($event)"
          @nextPage="nextPage($event)"
          @complete="complete"
        />
      </keep-alive>
    </div>
    <div v-else>
      Você não possue dados
    </div>
  </div>
</template>

<script>
import Steps from "primevue/steps";
import VisualizarDetalhesApolice from "@/components/endosso/visualizar-detalhes-apolice/VisualizarDetalhesApolice.vue";

import { mapGetters } from "vuex";

export default {
  name: "endosso-steps",
  components: {
    Steps,
    VisualizarDetalhesApolice,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      formObject: {},
    };
  },
  computed: {
    ...mapGetters("endosso-module-new", ["apoliceOriginalEndosso"]),
  },
  methods: {
    nextPage(event) {
      for (let field in event.formData) {
        this.formObject[field] = event.formData[field];
      }
      this.$router.push(this.steps[event.pageIndex + 1].to);
    },
    prevPage(event) {
      this.$router.push(this.steps[event.pageIndex - 1].to);
    },
    complete() {
      this.$toast.add({
        severity: "success",
        summary: "Order submitted",
        detail:
          "Dear, " +
          this.formObject.firstname +
          " " +
          this.formObject.lastname +
          " your order completed.",
      });
    },
  },
  mouted() {},
};
</script>

<style lang="scss" scoped>
.p-steps {
  margin-top: 20px !important;
}
</style>
