<template>
  <div class="steps-content">
    <section>
      <Card class="card">
        <template #title>
          <div class="title">
            <p>Informações do tomador</p>
          </div>
        </template>
        <template #subtitle>
          <div class="subtitle">
            <p>Informe dos dados do tomador</p>
          </div>
        </template>
        <template #content>
          <div>
            <div>
              <InputSearchSelect
                placeholder="Selecionar"
                label="Digite o CNPJ ou Razão Social para buscar um tomador."
                :required="true"
                icon="onpoint-caret-down"
                v-model="cnpjRazaoSocial"
                :infoData="dadosBuscaTomador"
                :isLoading="isLoadingSearchTomador"   
                class="w-2/3"             
              >
              <template v-slot:infoData>              
                  <div v-for="item,i in dadosBuscaTomador" :key='i'>
                    <div @click="validarTomador(item)">
                      <Card class="item-searched" v-if="i<3"  >
                        <template #title >
                          <div class="title">
                            {{item.Name}}
                          </div>                      
                        </template>
                        <template #content>
                          <div class="informations">
                            {{item.Cnpj}}
                          </div>
                          <div class="informations">
                            {{item.Description}}                        
                          </div>          
                        </template>
                      </Card>    
                    </div>                                
                  </div>              
              </template>
              </InputSearchSelect>
            </div>
            <div>          
              <AlertCard 
              v-if="dadosTomador.IsNominatedToOtherBroker"
              class="mt-5" 
              title="Esse tomador está nomeado para outra corretora." 
              text="Para prosseguir com uma proposta, anexe uma carta de nomeação para submeter ao processo de nomeação."/>
            </div>
            <div>
              <AlertCard
              v-if="dadosTomador.HasPendingNomeationProcess"
                class="mt-5" 
                title="Solicitação em análise."
                :text="`Você já possui uma solicitação de nomeação via carta para esse tomador. Data da solicitação:${formatarData(dadosTomador.PolicyHolderNomeationRequests[0].RequestDate)}`"
              />    
            </div>            
            <div class="mt-5" v-if="selectedTomador">
              <InformacoesTomadorCard :tomador="selectedTomador"/>            
            </div>          
            <div class="mt-5" v-if="selectedTomador && filiais[0] ">           
                <FilialList v-if="!alertOtherBrokerOrHasPendingNomeation" :filiais="filiais" v-model="selectedFilial" @deleteFilial="excluirFilial($event)"></FilialList>          
            </div>
            <div class="mt-5" v-if="showFilialButton">
              <Card class="filial-dialog-card-button"> 
                <template #content>
                  <div class="content">
                    <Button 
                      class="p-button-rounded p-button-sm white-button-center" 
                      label="Adicionar filial"
                      @click="filialDialogVisible=!filialDialogVisible"
                    ></Button>
                  </div>
                </template>  
              </Card>
            </div>
            <Divider class="divider" />
            <div class="limits-and-fees-data">
              <div class="title">
                <p>Limites e taxas</p>
              </div>
              <div class="subtitle">
                <p>Dados informativos</p>
              </div>
              <div class="awaiting-limits">
                <DataTableLimits 
                  :data="(limitesETaxas || {}).LimitAndRates || []" 
                  :maxApprovedLimit="limitesETaxas.MaxApprovedLimit"
                  :maxAvailableLimit="limitesETaxas.MaxAvailableLimit"
                  v-if="showtableLimits"
                />
                <Card class="card" v-if="alertOtherBrokerOrHasPendingNomeation">
                  <template #title>
                    <div class="title">
                      <p>Tomador nomeado para outra corretora</p>
                    </div>
                  </template>                 
                  <template #content>
                  </template>
                </Card>                
                <Card class="card" v-if="!showtableLimits && !alertOtherBrokerOrHasPendingNomeation">
                  <template #title>
                      <div class="title">                        
                        <p>Aguardando informações</p>
                      </div>
                    </template>
                    <template #subtitle>
                      <div class="subtitle">
                        <p>Preencha o campo de CNPJ para visualizar as taxas</p>
                      </div>
                    </template>
                  <template #content>
                  </template>
                </Card>    
              </div>
            </div>
            <div class="mt-5 mb-8" >
              <Card class="card-revision-limit" v-if="canRequestLimitReview"> 
                <template #content>
                  <div class="content">
                    <Button 
                      class="p-button-rounded p-button-sm white-button-center" 
                      label="Solicitar revisão de limite" 
                      @click="solicitarLimiteVisible=!solicitarLimiteVisible"
                    />
                  </div>
                </template>  
              </Card>
            </div>
            <div v-if="showtableLimits">
              <Card class="adicionalInfo">
                <template #content>
                  <div v-html="informacoesAdicionaisLimites">                  
                  </div>
                </template>
              </Card>
            </div>          
            <div 
              v-if="
              dadosTomador.CpfCnpj &&
              dadosTomador.Id > 0 &&
              !dadosTomador.HasPendingNomeationProcess &&
              !dadosTomador.IsNominatedToThisBroker"
            >          
              <Divider class="divider" />
              <CartaNomeacao :tomador="dadosTomador"/>    
            </div>
          </div>
        </template>
        <template #footer>
          <div class="flex justify-end">
            <Button
              label="Cancelar"
              class="p-button-rounded cancel-button"   
              @click="$router.push({path:'/'})"
            />
            <Button
              label="Próximo"
              class="p-button-rounded"                  
              @click="nextPage()"
              :disabled="nextPageDisabled"
            />
          </div>
        </template>
      </Card>     
    </section>   
    <FilialDialog 
      :visible="filialDialogVisible"     
      v-model='filialCNPJ'
      @cancel="filialDialogVisible=false"
      @confirm="salvarFilial(filialCNPJ)"
    /> 
    <SolicitarLimiteDialog 
      :visible="solicitarLimiteVisible" 
      @cancel="solicitarLimiteVisible=false"      
      :detalhamento="detalhamento"
      :tomadorUniqueId="dadosTomador.UniqueId"
    />
   
  </div>
</template>

<script>
import Card from "primevue/card";
import Button from "primevue/button";
import Divider from "primevue/divider";
import InputSearchSelect from "../../../../components/onpoint-input-text-search-select";
import DataTableLimits from './components/DataTableLimits.vue';
import FileUpload from 'primevue/fileupload';
import FilialDialog from './components/FilialDialog.vue';
import FilialList  from './components/FilialList.vue'
import SolicitarLimiteDialog from './components/SolicitarLimiteDialog.vue';
import CartaNomeacao from "./components/CartaNomeacao.vue"
import InformacoesTomadorCard from './components/InformacoesTomadorCard.vue';
import AlertCard from './components/AlertCard.vue';

import { mapGetters, mapMutations, mapActions } from "vuex";
import axiosInstance from "@/axios";
import * as Utils from "@/utils/utils";
import moment from "moment";

export default {
  components: {
    Card,
    Button,
    Divider,
    InputSearchSelect,
    DataTableLimits,
    FileUpload,
    FilialDialog,  
    FilialList,
    SolicitarLimiteDialog,
    CartaNomeacao,
    InformacoesTomadorCard,
    AlertCard   
  },
  data() {
    return {      
      isLoadingSearchTomador:false,
      isLoadingValidationTomador: false,
      cnpjRazaoSocial: null,
      dadosBuscaTomador: [],
      dialogAddEndereco : false,
      isLoadingLimits:false,      
      informacoesAdicionaisLimites: null,
      detalhamento:null,
      selectedTomador :null,
      showtableLimits:false,
      canRequestLimitReview :false, // lembrar de trocar para false antes de subir
      portalConfig : JSON.parse(localStorage.getItem("lsApplicationConfig")),
      filialDialogVisible:false,
      filialCNPJ:null,
      selectedFilial:null,
      solicitarLimiteVisible:false,        
    };
  },
  mounted(){    
    
    
  },
  created(){
    
  },
  watch: {
    cnpjRazaoSocial(value) {
      if (value.length >= 3) {
        return this.buscarTomador(value);
      }
      return this.clearAll();
    },
    proposta: {
      immediate: true,
      handler(val, oldVal) {
        if ((this.proposta || {}).PolicyHolderBranchId > 0) {
          const foundBranch = (
            ((this.proposta || {}).PolicyHolder || {}).Branches || []
          ).find(
            (x) => x.PersonId == (this.proposta || {}).PolicyHolderBranchId
          );
          if (foundBranch && foundBranch.Id > 0)
            this.filialSelecionada[foundBranch.Id] = true;
        }
      },
    },
  },
  computed:{
    ...mapGetters("proposta-module", [
      "proposta",
      "limitesETaxas",
    ]),
    ...mapGetters("tomador-module", [
      "dadosTomador",      
    ]),
    dadosTomador: {
      get() {
        const result = (this.proposta || {}).PolicyHolder || {};
        return result;
      },
    },
    filiais: {
      get() {        
        const tomador = (this.proposta || {}).PolicyHolder || {};
        return tomador.Branches || [];
      },      
    },
    nextPageDisabled(){
      if(
        Object.keys(this.dadosTomador).length === 0 ||
        this.dadosTomador.HasPendingNomeationProcess
      ){        
        return true
      }      
      return false      
    },
    alertOtherBrokerOrHasPendingNomeation(){    
      if(this.dadosTomador.IsNominatedToOtherBroker || this.dadosTomador.HasPendingNomeationProcess){
        return true
      }      
      return false
    },
    showFilialButton(){
      if(!this.selectedTomador || ( this.selectedTomador && this.alertOtherBrokerOrHasPendingNomeation)){
        return false
      }
       return true
    }
  },
  methods: {   
    ...mapActions("proposta-module", [     
      "buscaTomadorPorTexto",     
      "validaTomadorPorCnpj",
      "carregarLimitesETaxas",          
    ]),
    ...mapActions("filial-module", ["deletarFilial","cadastrarFilial"]),
    ...mapMutations("proposta-module", [      
      "updatePropostaFiliaisTomador",
    ]),
    nextPage() {            
      this.$emit("nextPage", { formData: { cnpjTomador:this.selectedTomador.Cnpj,tomadorUniqueId:this.selectedTomador.UniqueId}, pageIndex: 0 });
    },
    formatarData(date) {
      if (date instanceof Date) {
        date = date.setHours(0, 0, 0, 0);
      }
      return moment(date).format("DD/MM/YYYY");
    },
    buscarTomador: _.debounce(async function (query) {
      if (!query || query.length < 3) {
        return this.clearAll();
      }
      this.isLoadingSearchTomador = true;
      this.dadosBuscaTomador = [];
      // this.buscaTomadorPorTexto(Utils.removeSpecialChars(query))
      await this.buscaTomadorPorTexto(query)
        .then((response) => {
          this.isLoadingSearchTomador = false;
          this.dadosBuscaTomador = response;
        })
        .catch((errors) => {
          this.$onpoint.errorModal(errors.response.data.Errors);
        })
        .finally(() => {
          this.isLoadingSearchTomador = false;
        });
    }, 500),
    clearAll() {
      this.dadosBuscaTomador = [];
    },
    async validarTomador(tomador) {        
      if (!tomador.Cnpj) return;

      if (this.selectedTomador && tomador.Cnpj === this.selectedTomador.Cnpj) return;

      this.selectedFilial = null
      this.$appInsights.trackEvent({ name: "inserir-tomador-proposta", properties: { place: "proposta-passo-1", action: "button-click", slug: "inserir-tomador-proposta-passo-1" }});
      await this.$onpoint.loading(async () => {
         
        return await this.validaTomadorPorCnpj(this.$utils.removeSpecialChars(tomador.Cnpj))
          .then(async (response) => {
            this.isLoadingValidationTomador = false;
            if (
              this.dadosTomador &&
              !this.dadosTomador.IsNominatedToOtherBroker &&
              !this.dadosTomador.HasPendingNomeationProcess            
            ) {
              await this.loadingLimites(); 
            } 
            setTimeout(() => {             
              this.cnpjRazaoSocial = "";
              this.dadosBuscaTomador=[]
              this.selectedTomador = tomador
            }, 200);          
          })
          .catch((errors) => {
            this.isLoadingValidationTomador = false;
            this.$onpoint.errorModal(errors.response.data.Errors);
            this.cnpjRazaoSocial = "";
            this.dadosBuscaTomador=[]
          })
          .finally(() => {
            this.isLoadingValidationTomador = false;
            window.scrollTo(0, 0);
            this.addInfoHtml()
            this.visualizarDetalhamento()
          });
      });
    },
    async loadingLimites() {
      this.isLoadingLimits = true

      await this.carregarLimitesETaxas()
        .then(() => {                   
          this.bailLimitData = this.limitesETaxas.BailLimit;
          this.canRequestLimitReview = this.limitesETaxas.CanRequestLimitReview
          this.showtableLimits =true
        })
        .catch((errors) => {          
          this.$onpoint.errorModal(errors.data.Errors);
        });      
    },
    async visualizarDetalhamento() {
      await this.$onpoint.loading(async () => {
        return await axiosInstance
          .get(`api/PolicyHolder/GetRequestLimitParameterDocuments`)
          .then((response) => {
            this.detalhamento = response.data.Response;
          })
          .catch((error) => {
            this.$onpoint.errorModal(error.response.Message);
          });
      });
    },
    addInfoHtml(){
      const htmlStr = this.portalConfig.informacaoAdicionalCriacaoProposta;  
      const defaultInfo = 
        `<h5>Informações adicionais</h5>
        <p>
          * As aprovações acima são indicativas e podem sofrer alterações;
        </p>                  
        <p></p>
        <p></p>` 
      return htmlStr ? this.informacoesAdicionaisLimites = htmlStr : this.informacoesAdicionaisLimites = defaultInfo 
    },

    async salvarFilial(document) {     
      
      await this.$onpoint.loadingModal(
        "Aguarde, as informações estao sendo processadas",
        async () => {          
          const newFilial = {
            cnpj: Utils.removeSpecialChars(document),
            uniqueId: this.selectedTomador.UniqueId
          };
          return await this.cadastrarFilial(newFilial)            
            .catch(error => {
              this.$onpoint.errorModal(error.response.data.Errors);
            })
            .finally(() => {
             this.filialDialogVisible=false,
             this.filialCNPJ=""
            });
        }
      );
    },
    async excluirFilial(event) {     
      const uniqueId= event
      await this.$onpoint.loadingModal("Processando sua solicitação", async () => {
        return await this.deletarFilial(uniqueId)
          .then((response) => {
            this.adicionarFilialActivo = false;
            this.updatePropostaFiliaisTomador(response);
          })
          .catch((error) => {
            this.$onpoint.errorModal(error.response.Message);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 24px;
  border-radius: 16px;
  width: 100%;
}
.title {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.subtitle {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.divider {
  padding: 10px;
}
.awaiting-limits{ 
  margin-block: 20px;
  .card{
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: rgba(249, 250, 251, 1);   
    height: 80px;
    padding: 0px;
    box-shadow: none;
    border: none;
    border-radius: 12px;    
  }
}

.item-searched{ 
  box-shadow: none;
  margin: 2px;  
  .title{
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }
  .informations{
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
  }
  :deep(.p-card-content){
    padding: 0px;
  }
}

.item-searched:hover{
  background-color: rgba(243, 244, 246, 1);
}

.item-searched:active{
  background-color: rgba(var(--vs-primary,1));
  color: white;
}
.filial-dialog-card-button{
  background-color: rgba(249, 250, 251, 1);
  box-shadow: none;
  border: none; 
  border-radius: 12px;   
  :deep(.p-card-content){
    padding: 0px;    
  };   
  .content{
    display: flex;    
    justify-content: center;
  }  
}

.white-button-center{
  color: black;
  background: white;
  border: solid 1px rgba(209, 213, 219, 1);
  height: 32px;
  display: flex;    
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500; 
  line-height: 20px;
}

.card-revision-limit{
  background-color: rgba(249, 250, 251, 1);
  box-shadow: none;
  border: none; 
  border-radius: 12px;   
  :deep(.p-card-content){
    padding: 0px;    
  };   
  .content{
    display: flex;    
    justify-content: center;
  }  
}
.adicionalInfo{
  background: rgba(253, 236, 206, 1);
  h5{
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: red;
  }
  p{
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }   
}
.cancel-button{
  background: white;
  color: black;
  border: solid 1px rgba(209, 213, 219, 1);
  margin-right:5px ;
}
</style>