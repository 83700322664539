<template>
  <section>
    <Card class="card">
      <template #title>
        <h1>Motivos e documentos</h1>
      </template>
      <template #subtitle>
        <p>Teste</p>
      </template>
      <template #content>
        <p>conteúdo do card</p>
      </template>
      <template #footer>
        <div class="flex justify-end">
          <Button
            label="Voltar"
            class="p-button-rounded cancel-button"
            @click="prevPage()"
          />
          <Button
            label="Próximo"
            class="p-button-rounded"
            @click="nextPage()"
          />
        </div>
      </template>
    </Card>
  </section>
</template>

<script>
import Card from "primevue/card";
import Button from "primevue/button";

export default {
  name: "motivo-e-documentos",
  components: {
    Card,
    Button,
  },
  data() {
    return {};
  },
  methods: {
    nextPage() {
      this.$emit("nextPage", {
        formData: {},
        pageIndex: 1,
      });
    },
    prevPage() {
      this.$emit("prevPage", { pageIndex: 1 });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 24px;
  border-radius: 16px;
  width: 100%;
}

.cancel-button {
  background: white;
  color: black;
  border: solid 1px rgba(209, 213, 219, 1);
  margin-right: 5px;
}
</style>
