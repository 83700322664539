var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-8" },
    [
      _vm._m(0),
      _vm._m(1),
      _vm._l(_vm.condicoesParticularesAdicionadas, function (clausula, i) {
        return _c(
          "div",
          { key: i, staticClass: "card mt-8" },
          [
            _c("OnpointSelect", {
              staticClass: "mt-5 w-1/2",
              attrs: {
                label: "Selecione uma condição particular",
                placeholder: "Selecione",
                items: _vm.condicoesParticularesList,
                optionLabel: "Name",
              },
              on: {
                input: function ($event) {
                  return _vm.setInputsParticularClauses($event, i)
                },
              },
            }),
            clausula.Name && clausula.Campos[0]
              ? _c("div", { staticClass: "mt-8" }, [
                  _c("p", { staticClass: "title" }, [
                    _vm._v("Dados do(a) " + _vm._s(clausula.Name)),
                  ]),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "flex gap-5 mt-5" },
              _vm._l(clausula.Campos, function (item, indexCampos) {
                return _c("OnpointInput", {
                  key: indexCampos,
                  staticClass: "mb-5 w-1/3",
                  attrs: { label: item.Label, required: true },
                  on: {
                    input: function ($event) {
                      return _vm.handleFunctionInput(clausula, i)
                    },
                  },
                  model: {
                    value: item.Value,
                    callback: function ($$v) {
                      _vm.$set(item, "Value", $$v)
                    },
                    expression: "item.Value",
                  },
                })
              }),
              1
            ),
            _c("Divider"),
            clausula.ContractualTerm
              ? _c(
                  "Accordion",
                  { staticClass: "accordion mt-8" },
                  [
                    _c(
                      "AccordionTab",
                      {
                        attrs: {
                          header: `Visualizar clausulas das condições ${clausula.Name}`,
                        },
                      },
                      [
                        _c("div", {
                          staticClass: "p-5",
                          domProps: {
                            innerHTML: _vm._s(clausula.HtmlFormatted),
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      }),
      _c(
        "div",
        { staticClass: "mt-5 card flex justify-center" },
        [
          _c(
            "Button",
            {
              staticClass: "white-button",
              on: {
                click: function ($event) {
                  return _vm.addNewClause()
                },
              },
            },
            [_vm._v("Adicionar nova condição particular")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "title" }, [_vm._v("Condições particulares")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "subtitle" }, [
        _vm._v(
          "\n      Adicione as condições particulares abaixo conforme necessário.\n    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }