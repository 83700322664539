<template>
  <section>
    <Card class="card">
      <template #content>
        <div class="card-content-data-cancelamento">
          <h3 class="card-content-data-cancelamento-title">Endosso</h3>
          <p class="card-content-data-cancelamento-subtitle">Informações para cancelamento</p>
          <OnpointInput
            class="w-1/4"
            label="Data de cancelamento"
            v-model="dataCancelamento"
            :required="true"
            :calendar="true"
          />
        </div>
        <Divider class="card-content-divisor" />
        <div class="card-content-dados-bancarios-tomador">
          <DadosBancariosTomador />
        </div>
      </template>
      <template #footer>
        <div class="flex justify-end card-footer">
          <Button
            label="Cancelar"
            class="p-button-rounded cancel-button"
            @click="$router.push({ path: '/' })"
          />
          <Button
            label="Próximo"
            class="p-button-rounded"
            @click="nextPage()"
          />
        </div>
      </template>
    </Card>
  </section>
</template>

<script>
import Card from "primevue/card";
import Button from "primevue/button";
import Divider from "primevue/divider";
import OnpointInput from "@/components/onpoint-input";
import DadosBancariosTomador from "./components/DadosBancariosTomador.vue";

export default {
  name: "informacoes-do-endosso",
  components: {
    Card,
    Button,
    Divider,
    OnpointInput,
    DadosBancariosTomador
  },
  data() {
    return {
      dataCancelamento: null,
    };
  },
  methods: {
    nextPage() {
      this.$emit("nextPage", { formData: {}, pageIndex: 0 });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 24px;
  border-radius: 16px;
  width: 100%;

  :deep(.p-card-body),
  :deep(.p-card-content),
  :deep(.p-card-footer) {
    padding: 0;
  }

  &-content{
    &-data-cancelamento{
      padding-top: 12px;

      &-title{
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: rgba(55, 65, 81, 1);
      }

      &-subtitle{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(75, 85, 99, 1);
        margin-bottom: 36px;
      }
    }

    &-divisor{
      margin: 40px 0;
      padding: 0;
    }
  }

  &-footer{
    margin-top: 64px;
  }
}

.cancel-button {
  background: white;
  color: black;
  border: solid 1px rgba(209, 213, 219, 1);
  margin-right: 5px;
}
</style>
