<template>
  <div class="credentials-asd-modal-error">
    <el-dialog
      title="Atenção!"
      :visible.sync="loginError"
      width="50%"
      center
      style="padding: 0px"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <vs-row
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        style="padding: 20px"
      >
        <p class="error-message">Houve um erro ao fazer login!</p>
        <p v-if="loginErrorMessage" class="error-message mt-5">{{ loginErrorMessage }}</p>
      </vs-row>
      <p slot="footer" class="dialog-footer">
        <el-button type="primary" @click="redirectLogin()"
          >Tentar novamente</el-button
        >
      </p>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "page-credentials-asd",
  data() {
    return {
      loginError: false,
      loginErrorMessage: null
    };
  },
  methods: {
    ...mapActions("auth", ["loginASD"]),

    redirectLogin() {
      this.$router.push({ name: "page-login" });
    },

    async configurarUsuarioASD(token, redirect) {
      this.$vs.loading();
      let destino = redirect ? redirect : "redirect-to-home";

      try {       
        const response = await this.loginASD(token);
        
        this.$router.push({ name: destino });
      } catch (error) {
        this.loginErrorMessage = error.response ? error.response.data.Errors : null;
        this.loginError = true;
      } finally {
        this.$vs.loading.close();
      }
    },
  },
  computed: {},
  created() {
    const token = this.$route.params.token;
    const redirect = this.$route.query.redirect;

    this.configurarUsuarioASD(token, redirect);
  },
  mounted() {
    this.$appInsights.trackPageView({
      name: "page-credentials-asd",
      url: window.location.href,
    });
  },
};
</script>

<style scoped lang="scss">
.credentials-asd-modal-error {
  ::v-deep .el-dialog {
    border-radius: 6px;
    max-width: 530px;
    min-width: 530px;
    padding: 32px;
  }
  ::v-deep .el-dialog__title {
    font-size: 2em;
  }
  ::v-deep .el-dialog__body {
    padding: 0px !important;
    margin: 0px;
  }
  ::v-deep .el-dialog__body > div {
    display: block !important;
  }
  ::v-deep div .el-button--primary {
    background-color: rgba(var(--vs-primary), 1) !important;
  }
  ::v-deep div .error-message {
    text-align: center;
    font-weight: bold;
    color: black;
    font-size: 20px;
  }
}
</style>
