var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _vm.existeCocorretor
        ? _c(
            "div",
            _vm._l(_vm.cocorretores, function (cocorretor, index) {
              return _c("div", { key: index, staticClass: "flex" }, [
                _c("div", { staticClass: "card-component mb-5 p-5" }, [
                  _c("p", { staticClass: "title mb-3" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(index == 0 ? "Corretor lider" : "Corretagem") +
                        "\n          "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex gap-5" },
                    [
                      index === 0 || cocorretor.choosed
                        ? _c("OnpointInput", {
                            staticClass: "w-7/12",
                            attrs: {
                              required: "",
                              label: "Razão social ou CNPJ",
                              disabled: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.updateCnpjCorretor($event, index)
                              },
                            },
                            model: {
                              value: cocorretor.RazaoSocial,
                              callback: function ($$v) {
                                _vm.$set(cocorretor, "RazaoSocial", $$v)
                              },
                              expression: "cocorretor.RazaoSocial",
                            },
                          })
                        : _c("InputSearchSelect", {
                            staticClass: "w-7/12",
                            attrs: {
                              label:
                                "Digite o CNPJ ou Razão Social para buscar um segurado.",
                              required: true,
                              icon: "onpoint-caret-down",
                              infoData: _vm.dadosBuscaCorretor,
                              isLoading: _vm.isLoadingSearchCorretor,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "infoData",
                                  fn: function () {
                                    return _vm._l(
                                      _vm.dadosBuscaCorretor,
                                      function (item, i) {
                                        return _c("div", { key: i }, [
                                          _c(
                                            "div",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.getCorretor(
                                                    item,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              i < 3
                                                ? _c("Card", {
                                                    staticClass:
                                                      "item-searched",
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "title",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "title",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        item.BrokerFantasyName
                                                                      ) +
                                                                      "\n                      "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                        {
                                                          key: "content",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "informations",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        item.BrokerDocument
                                                                      ) +
                                                                      "\n                      "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ])
                                      }
                                    )
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.cnpjRazaoSocial,
                              callback: function ($$v) {
                                _vm.cnpjRazaoSocial = $$v
                              },
                              expression: "cnpjRazaoSocial",
                            },
                          }),
                      _c(
                        "div",
                        { staticClass: "w-1/5" },
                        [
                          _c("OnpointInputCurrency", {
                            attrs: { required: "", label: "Valor da comissão" },
                            on: {
                              change: function ($event) {
                                return _vm.valueIsChanged("value", index)
                              },
                            },
                            model: {
                              value: cocorretor.AmountValue,
                              callback: function ($$v) {
                                _vm.$set(cocorretor, "AmountValue", $$v)
                              },
                              expression: "cocorretor.AmountValue",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("OnpointInput", {
                        staticClass: "w-1/5",
                        attrs: {
                          name: "percentRate",
                          required: "",
                          label: "% de rateio",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.valueIsChanged("rate", index)
                          },
                        },
                        model: {
                          value: cocorretor.Rate,
                          callback: function ($$v) {
                            _vm.$set(cocorretor, "Rate", $$v)
                          },
                          expression: "cocorretor.Rate",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "mb-5 none-trash-button" },
                  [
                    index !== 0
                      ? _c(
                          "Button",
                          {
                            staticClass: "trash-button",
                            on: {
                              click: function ($event) {
                                return _vm.removeCocorretor(index)
                              },
                            },
                          },
                          [_c("i", { staticClass: "onpoint-trash" })]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            }),
            0
          )
        : _vm._e(),
    ]),
    _c("div", [
      _c(
        "div",
        { staticClass: "card-to-button-center mt-2" },
        [
          _c("Button", {
            staticClass: "white-button-center p-button-rounded p-button-sm",
            attrs: {
              label: _vm.cocorretores[0]
                ? "Adicionar outro corretor"
                : "Adicionar co-corretagem",
            },
            on: {
              click: function ($event) {
                return _vm.addCocorretor()
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }