<template>
  <section class="mt-4">
    <div class="new-history">
      <h2 class="font-bold">Histórico de consultas</h2>
      <template v-if="historico.length > 0">
        <vs-table
          :data="historico"
          :hoverFlat="true"
          noDataText="Nenhuma histórico encontrado"
        >
          <template slot="thead">
            <vs-th>Onde foi consultado?</vs-th>
            <vs-th class="text-left">Data da consulta</vs-th>
            <vs-th class="text-left">Bureau</vs-th>
            <vs-th class="text-left">Mensagem</vs-th>
            <vs-th></vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
              <vs-td :data="item.BureauDadosLog.TipoConsulta" class="text-left">
                {{ item.BureauDadosLog.TipoConsulta }}
              </vs-td>

              <vs-td :data="item.BureauDadosLog.DataConsulta" class="text-left">
                {{ item.BureauDadosLog.DataConsulta | moment("DD/MM/YYYY HH:mm") }}
              </vs-td>

              <vs-td :data="item.BureauDadosLog.Bureau" class="text-left">
                {{ item.BureauDadosLog.Bureau }}
              </vs-td>

              <vs-td :data="item.BureauDadosLog.Mensagem" class="text-left">
                {{ item.BureauDadosLog.Mensagem }}
              </vs-td>

              <vs-td class="text-right">
                <vs-dropdown
                  vs-custom-content
                  vs-trigger-click
                  class="btn-action-apolice"
                >
                  <vs-button color="dark" type="flat" title="Ações">
                    <i class="onpoint-dots-three-vertical icon-dots-three-vertical" role="button"></i>
                  </vs-button>
                  <vs-dropdown-menu class="btn-action-apolice-options">
                    <vs-dropdown-item>
                      <p
                        class="text-base text-nowrap"
                        @click="openModalJson(item)"
                      >
                        <span>Visualizar JSON</span>
                      </p>
                    </vs-dropdown-item>
                    <vs-dropdown-item divider>
                      <p
                        class="text-base text-nowrap"
                        @click="openModalOriginalData(item)"
                      >
                        <span>Dados originais</span>
                      </p>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <vs-pagination
          class="mt-5"
          :total="numberOfPages"
          v-model="currentPage"
          v-if="numberOfPages > 1"
        />
      </template>

      <template v-else>
        <div class="flex flex-col w-full md:w-1/2 mx-auto items-center mt-32">
          <i class="onpoint-file-text"/>
          <p class="text-center">
            Nenhum registro encontrado!
          </p>
        </div>
      </template>
    </div>

    <vs-popup
      id="listar-endosso-modal"
      title=" "
      :active.sync="showRequestJSON"
    >
      <div class="vw-row mt-3">
        <div class="vx-col w-full text-right">
          <vs-button @click="downloadJson()" class="button-primary">
            Download JSON
          </vs-button>
        </div>

        <div class="vx-col pt-5 w-full mt-2">
          <tree-view
            id="element-to-print"
            :data="parsedRequestJson"
            :options="{ maxDepth: 3, rootObjectKey: 'JSON' }"
            class="historico-consulta-corretor-visualizar-json"
          ></tree-view>
        </div>
      </div>
    </vs-popup>

    <vs-popup
      title=" "
      :active.sync="showOriginalData"
    >
      <div class="vw-row mt-3">
        <div class="vx-col w-full text-right">
          <vs-button @click="downloadOriginalData()" class="button-primary">
            Download dados
          </vs-button>
        </div>

        <div class="vx-col pt-5 w-full mt-2">
          <tree-view
            id="element-to-print-original-data"
            :data="parsedOriginalData"
            :options="{ maxDepth: 3, rootObjectKey: 'Dados Originais' }"
            class="historico-consulta-tomador-visualizar-json"
          ></tree-view>
        </div>
      </div>
    </vs-popup>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import optionsStaticMixin from "@/mixins/optionsStaticMixin";
import html2pdf from "html2pdf.js";
import axiosInstance from "@/axios";


export default {
  name: "apolices-segurado",
  props: { document: { type: String, require: true }},
  mixins: [optionsStaticMixin],
  mounted() {
    this.listarHitoricoConsultasCorretor();
  },
  data() {
    return {
      historico: [],
      requestJson: null,
      showRequestJSON: false,
      currentPage: 1,
      rowsPerPage: 7,
      numberOfPages: 0,
      seguradoraId: null,
      showOriginalData: false,
      originalData: null
    };
  },
  watch: {
    currentPage(newVal, oldVal) {
      if (newVal != oldVal) this.listarHitoricoConsultasCorretor();
    }
  },
  computed: {
    parsedRequestJson() {
      return this.identifyAndParseData(this.requestJson)
    },

    parsedOriginalData() {
      return this.identifyAndParseData(this.originalData)
    }
  },
  methods: {
    async listarHitoricoConsultasCorretor(){
      await this.$onpoint.loading(async () => {
        return await axiosInstance
          .get(
            `api/Parametros/ObterBureauDadosLog?CpfCnpj=${this.document}&SeguradoraId=${this.seguradoraId}&currentPage=${this.currentPage}&rowsPerPage=${this.rowsPerPage}`
          )
          .then(response => response.data.Response)
          .then(response => {
            this.historico = response.Data;
            this.numberOfPages = response.NumberOfPages;
          });
      });
    },

    openModalJson(content) {
      this.showRequestJSON = true;
      this.requestJson = content.BureauDadosLog.Resultado;
    },

    downloadJson() {
      let element = document.getElementById("element-to-print");
      html2pdf(element);
    },

    downloadOriginalData() {
      let elementOriginalData = document.getElementById("element-to-print-original-data");
      html2pdf(elementOriginalData);
    },

    openModalOriginalData(content) {
      this.showOriginalData = true;
      this.originalData = content.BureauDadosLog.BureauDados.Dados;
    },

    isJSON(str){
      if (!str) return false;
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    },

    isXML(str){
      if (!str) return false;
      try {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(str, "application/xml");
        return !xmlDoc.getElementsByTagName("parsererror").length;
      } catch (e) {
        return false;
      }
    },

    xmlToJson(xml){
      let obj = {};
      if (xml.nodeType === 1) {
        if (xml.attributes.length > 0) {
          obj["@attributes"] = {};
          for (let j = 0; j < xml.attributes.length; j++) {
            const attribute = xml.attributes.item(j);
            obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
          }
        }
      } else if (xml.nodeType === 3) {
        obj = xml.nodeValue;
      }
      if (xml.hasChildNodes()) {
        for (let i = 0; i < xml.childNodes.length; i++) {
          const item = xml.childNodes.item(i);
          const nodeName = item.nodeName;
          if (typeof (obj[nodeName]) === "undefined") {
            obj[nodeName] = this.xmlToJson(item);
          } else {
            if (typeof (obj[nodeName].push) === "undefined") {
              const old = obj[nodeName];
              obj[nodeName] = [];
              obj[nodeName].push(old);
            }
            obj[nodeName].push(this.xmlToJson(item));
          }
        }
      }
      return obj;
    },

    identifyAndParseData(data) {
      if(!data) return {};

      if (this.isJSON(data)){
        return JSON.parse(data);
      } else if (this.isXML(data)){
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(data, "application/xml");
        return this.xmlToJson(xmlDoc)
      }

      return data;
    }
  },
  created(){
    let portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));
    this.seguradoraId = portalConfig.CompanyId;
  }
};
</script>

<style lang="scss" scoped>
.table-list {
  ::v-deep .vs-table--thead {
    tr {
      background: transparent !important;
    }
  }

  ::v-deep .vs-table--td {
    padding: 31px 15px !important;
    margin-bottom: 1em;
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }
}

.text-nowrap {
  white-space: nowrap;
}

.icon-dots-three-vertical::before{
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #afafaf;
}
</style>

<style lang="scss">
.tree-view-item-key-with-chevron {
  color: red;
}

.tree-view-item-key-with-chevron[data-v-efc5bae2]::before {
  content: "\f067" !important;
  font-family: FontAwesome;
}

.tree-view-item-key-with-chevron.opened[data-v-efc5bae2]::before {
  content: "\f068" !important;
  transform: none;
  font-family: FontAwesome;
}

.historico-consulta-corretor-visualizar-json{
  .tree-view-item-leaf{
    white-space: normal !important;

    span{
      word-break: break-all !important;
    }
  }
}
</style>
