<template>
  <div>  
    <Steps :model="steps" class="mt-5 mb-5 steps">      
    </Steps>
    <keep-alive>      
      <router-view :formData="formObject" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="complete" />
    </keep-alive>              
  </div>    
</template>

<script>
import Steps from 'primevue/steps';
export default {
  components: {  
    Steps,
  },
  data(){
    return{
      steps: [
      {
        label: 'Dados Tomador',
        to: '/propostas/nova-proposta-new/dados-tomador'
      },
      {
        label: 'Dados da segurado',
        to: '/propostas/nova-proposta-new/dados-segurado'
      },
      {
        label: 'Dados de risco',
        to: '/propostas/nova-proposta-new/dados-de-risco'
      },
      {
        label: 'Resumo da proposta',
        to: '/propostas/nova-proposta-new/resumo-da-proposta'
      },
      {
        label: 'Dados financeiros',
        to: '/propostas/nova-proposta-new/dados-financeiros'
      }],  
      formObject: {}       
    }
  },
  
  methods:{
    nextPage(event) {
      for (let field in event.formData) {
        this.formObject[field] = event.formData[field];
      }
      this.$router.push(this.steps[event.pageIndex + 1].to);
    },
    prevPage(event) {
      this.$router.push(this.steps[event.pageIndex - 1].to);
    },
    complete() {
      this.$toast.add({severity:'success', summary:'Order submitted', detail: 'Dear, ' + this.formObject.firstname + ' ' + this.formObject.lastname + ' your order completed.'});
    }
  }
}
</script>

<style lang="scss" scoped>
.p-steps{
  margin-top: 20px; 
}
</style>