var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._m(1),
    _c(
      "div",
      { staticClass: "mt-5" },
      _vm._l(_vm.coberturas, function (item, i) {
        return _c("div", { key: i, staticClass: "flex checkbox mt-2" }, [
          _c(
            "div",
            { staticClass: "content-center" },
            [
              _c("Checkbox", {
                staticClass: "mt-2 mr-2",
                attrs: {
                  id: "city1",
                  inputId: "city1",
                  name: "city",
                  value: item,
                },
                model: {
                  value: _vm.coberturasAdicionais,
                  callback: function ($$v) {
                    _vm.coberturasAdicionais = $$v
                  },
                  expression: "coberturasAdicionais",
                },
              }),
            ],
            1
          ),
          _c("div", [
            _c(
              "label",
              { staticClass: "mt-1 ml-2 label", attrs: { for: "city3" } },
              [_vm._v("Cobertura")]
            ),
            _c("p", { staticClass: "description mt-1 ml-2" }, [
              _vm._v(_vm._s(item.Name)),
            ]),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "title" }, [_vm._v("Coberturas adicionais")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "subtitle" }, [
        _vm._v(
          "\n      Selecione as coberturas que deverão estar presentes no objeto desta\n      apólice\n    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }