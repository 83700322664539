<template>
  <vs-row id="endosso-alteracao" class="container">
    <vs-col vs-w="12">
      <vs-alert
        v-if="changed"
        :active="changed"
        color="warning"
        icon="alert"
        class="alert-update"
      >
        <b> Edição iniciada em: {{ startUpdate }}</b>
      </vs-alert>
    </vs-col>
    <section
      class="vx-col md:w-8/12 w-full md:pr-10 update-date"
      style="position:relative"
    >
      <dados-proposal-parts
        :enableChangeNomeSocial="enableChangeNomeSocial"
        v-if="apolice.PolicyUniqueId"
      >
        <vs-col
          vs-lg="6"
          vs-sm="12"
          vs-type="flex"
          vs-justify="end"
          vs-align="end"
          class="container-btn-changes"
          v-show="changed"
        >
          <visualizar-alteracoes
            :objectDiff="objectDiff"
            :changed="changed"
            :showDesfazer="true"
          />
        </vs-col>
      </dados-proposal-parts>

      <!-- Grupo modalidade | Modalidade principal  -->
      <vs-row class="margin-bottom">
        <vs-col vs-lg="4" vs-sm="12">
          <vs-input
            class="w-full"
            size="large"
            label="Grupo modalidades"
            :readonly="true"
            disabled
            v-model="(apolice.Modality || {}).GroupName"
          />
        </vs-col>
        <vs-col vs-lg="4" vs-sm="12">
          <vs-input
            class="w-full"
            size="large"
            label="Modalidade principal"
            :readonly="true"
            disabled
            v-model="(apolice.Modality || {}).Name"
          />
        </vs-col>

        <vs-col vs-lg="4" vs-sm="12">
          <vs-input
            class="w-full"
            size="large"
            label="Taxa aplicada (%)"
            :readonly="true"
            disabled
            v-model="(apolice || {}).RiskRate"
          />
        </vs-col>
      </vs-row>
      <!-- Removido regra de max e min no prazo em dias no PBI165144 -->
      <update-date
        v-if="apolice && apolice.PolicyUniqueId"
        :dataInicio="vigenciaInicioPrincipal"
        :dataFim="vigenciaFimPrincipal"
        :dias="vigenciaDiasPrincipal"
        :maxDias="999999"
        :minDias="0"
        :col="!importanciaSeguradaChanged ? 3 : 4"
        :disabledItens="disabledItensPrincipal"
        :label="labelItens"
        :disabledDates="disabledDatesInicio()"
        :modalidade="'principal'"
        @updateDataInicial="updateDataInicial"
        @updateDataFinal="updateDataFinal"
        @updatePrazo="updatePrazo"
        @updateDateChange="updateDateChange"
      >
        <template>
          <vs-col
            :vs-lg="!importanciaSeguradaChanged ? 3 : 4"
            vs-sm="12"
            class="container-element mb-5"
          >
            <p class="label-content label">Importância segurada</p>

            <ValidationProvider
              name="Importância segurada"
              :rules="
                `required:${true}|minInsurancePremium:${
                  (apolice.Modality || {}).MinInsurancePremium
                },${(apolice.Modality || {}).Name},${percLimit}`
              "
              v-slot="{ errors }"
            >
              <currency-input
                v-model="importanciaSegurada"
                class="w-full vs-inputx vs-input--input large hasValue"
                @change="changeImportanciaSegurada"
              />
              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </vs-col>

          <vs-col
            vs-lg="4"
            vs-sm="12"
            class="mb-5"
            v-if="importanciaSeguradaChanged"
          >
            <vs-input
              class="w-full"
              size="large"
              label="Vigência inicial Apólice"
              :disabled="true"
              :value="$utils.dateToDDMMYYYY(apolice.StartDate)"
            />
          </vs-col>

          <vs-col
            vs-lg="4"
            vs-sm="12"
            class="mb-5"
            v-if="importanciaSeguradaChanged"
          >
            <vs-input
              class="w-full"
              size="large"
              label="Vigência final Apólice"
              :disabled="true"
              :value="$utils.dateToDDMMYYYY(apolice.EndDate)"
            />
          </vs-col>
        </template>
      </update-date>

      <!-- Modalidade complementar -->
      <template v-if="((apolice || {}).ComplementaryModality || {}).Id">
        <vs-row class="mt-8 margin-bottom">
          <vs-col vs-lg="6" vs-sm="12">
            <vs-input
              class="w-full"
              size="large"
              label="Modalidade complementar"
              :readonly="true"
              disabled
              v-model="apolice.ComplementaryModality.Name"
            />
          </vs-col>

          <vs-col vs-lg="4" vs-sm="12">
            <vs-input
              class="w-full"
              size="large"
              label="Taxa aplicada (%)"
              :readonly="true"
              disabled
              v-model="(apolice || {}).RiskRate"
            />
          </vs-col>
        </vs-row>

        <!-- Removido regra de max e min no prazo em dias no PBI165144 -->
        <update-date
          v-if="apolice && apolice.PolicyUniqueId"
          :dataInicio="vigenciaInicioComplementar"
          :dataFim="vigenciaFimComplementar"
          :dias="vigenciaDiasComplementar"
          :maxDias="999999"
          :minDias="0"
          :col="!importanciaSeguradaComplementaryChanged ? 3 : 4"
          :disabledItens="disabledItensComplementar"
          :label="labelItens"
          :modalidade="'complementar'"
          @updateDataInicial="updateDataInicialComplementar"
          @updateDataFinal="updateDataFinalComplementar"
          @updatePrazo="updatePrazoComplementar"
          @updateDateChange="updateDateChange"
        >
          <template>
            <vs-col
              :vs-lg="!importanciaSeguradaComplementaryChanged ? 3 : 4"
              vs-sm="12"
              class="container-element mb-5"
            >
              <p class="label-content label">Importância segurada</p>

              <ValidationProvider
                name="Importância segurada"
                :rules="
                  `required|minInsurancePremium:${
                    (apolice.ComplementaryModality || {}).MinInsurancePremium
                  },${(apolice.ComplementaryModality || {}).Name},${percLimit}`
                "
                v-slot="{ errors }"
              >
                <currency-input
                  v-model="importanciaSeguradaComplementar"
                  class="w-full vs-inputx vs-input--input large hasValue"
                  @change="changeImportanciaSeguradaComplementar"
                />
                <span class="text-danger text-sm" v-show="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </vs-col>

            <vs-col
              vs-lg="4"
              vs-sm="12"
              class="mb-5"
              v-if="importanciaSeguradaComplementaryChanged"
            >
              <vs-input
                class="w-full"
                size="large"
                label="Vigência inicial Apólice"
                :readonly="true"
                disabled
                :value="$utils.dateToDDMMYYYY(apolice.ComplementaryStartDate)"
              />
            </vs-col>

            <vs-col
              vs-lg="4"
              class="mb-5"
              vs-sm="12"
              v-if="importanciaSeguradaComplementaryChanged"
            >
              <vs-input
                class="w-full"
                size="large"
                label="Vigência final Apólice"
                :readonly="true"
                disabled
                :value="$utils.dateToDDMMYYYY(apolice.ComplementaryEndDate)"
              />
            </vs-col>
          </template>
        </update-date>
      </template>

      <!-- Objeto -->
      <contractual-terms
        v-if="endossoObjeto && endossoObjeto.length"
        :defaultTags="defaultTags"
        :tagsSeguradora="tagsSeguradora"
        :isEndosso="true"
        :endossoObjeto="endossoObjeto"
        :disabledTagFieldsEndosso="disabledTagFieldsEndosso"
        @updatePropostaTermos="updatePropostaTermos"
        @textoObjetoCompleto="clonarTextoObjetoCompleto"
        currentTab="1"
      ></contractual-terms>
      <div class="custom-object text-right">
        <vs-button
          v-if="enableChangeObject"
          color="primary"
          class="mr-2"
          :disabled="desableChangeObjectBtn"
          @click="openChangeObjetctModal"
        >
          <span class="font-semibold pr-2 pl-2"> Alterar objeto completo </span>
        </vs-button>
        <vs-popup
          class="popup-aditional-coverage"
          :button-close-hidden="true"
          title=" "
          :active.sync="modalCustomObject"
        >
          <div class="px-6">
            <h2 class="font-bold text-primary">Alterar objeto completo</h2>
            <br />
            <jodit-editor
              :config="joditConfig"
              v-model="cloneTextoObjetoCompleto"
              style="min-height: 500px"
            />
            <div class="text-right">
              <vs-button
                color="grey"
                class="mt-8 mr-3"
                @click="modalCustomObject = false"
                >Cancelar</vs-button
              >
              <el-button type="primary" @click="addCustomObject()"
                >Prosseguir</el-button
              >
            </div>
          </div>
        </vs-popup>
      </div>

      <particular-clauses-select
        v-if="((apolice || {}).Modality || {}).Id"
        v-model="selectedParticularClauses"
        :proposal="{ ...apolice, ...endosso }"
        :modalityId="apolice.Modality.Id"
        :proposalClausesRequired="proposalClausesProposalRequiredValues || []"
        :isEndossoAlteracao="true"
        @proposal-terms-values-change="
          particularClausesProposalTermsValuesChanged
        "
      />
    </section>

    <aside class="vx-col md:w-4/12 w-full md:mt-0 mt-10 md:pt-0 pt-10 md:pl-10">
      <vs-row>
        <vs-col vs-w="12">
          <p class="font-semibold mb-3">Coberturas adicionais</p>

          <template v-if="(apolice.Coverages || []).length > 0">
            <p v-for="cobertura in apolice.Coverages" :key="cobertura.Id">
              <i class="onpoint-check-circle text-primary pr-3 icon-cobertura-adicional"> </i>
              {{ cobertura.Name }}
            </p>
          </template>

          <p v-else>APÓLICE NÃO POSSUI CONTRATAÇÃO DE COBERTURAS ADICONAIS</p>
        </vs-col>
      </vs-row>

      <vs-row class="mt-3">
        <vs-col class="container-element" vs-w="12">
          <p class="label-content label">Prêmio da apólice</p>
          <currency-input
            :value="(apolice || {}).InsurancePremium"
            class="w-full vs-inputx vs-input--input large hasValue"
            disabled
          />
        </vs-col>
      </vs-row>

      <revisao-premio
        :apolice="apolice"
        :loadingPremium="loadingPremium"
        :restituir="restituir"
        :IsBankInformationNeeded="IsBankInformationNeeded"
        @updatePolicyHolderBankInfo="updateEndosso"
      />
    </aside>
  </vs-row>
</template>

<script>
import ContractualTerms from "@/components/contractual-terms/ContractualTerms.vue";
import ParticularClausesSelect from "@/components/propostas/ParticularClausesSelect.vue";
import UpdateDate from "./components/alteracao/UpdateDate.vue";
import DadosProposalParts from "./components/alteracao/DadosProposalParts.vue";
import RevisaoPremio from "./components/RevisaoPremio.vue";
import VisualizarAlteracoes from "./components/VisualizarAlteracoes.vue";
import endossoController from "../endosso/providers/service";
import TagHelpers from "@/helpers/TagHelpers";
import * as Utils from "@/utils/utils";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    ContractualTerms,
    DadosProposalParts,
    ParticularClausesSelect,
    RevisaoPremio,
    UpdateDate,
    VisualizarAlteracoes
  },
  data() {
    return {
      firstStartDate: false,
      modalCustomObject: false,
      enableChangeObject: false,
      joditConfig: { language: "pt_br" },
      isExpired: false,
      premio: 0,
      percLimit: 0,
      restituir: null,
      IsBankInformationNeeded: false,
      loadingPremium: false,
      changed: false,
      startUpdate: null,
      showModalAlteracoes: false,
      tagsSeguradora: [],
      particularClausesProposalTermsValues: null,
      proposalClausesProposalTermsValues: null,
      proposalClausesProposalRequiredValues: null,
      selectedParticularClauses: null,
      endossoObjeto: [],
      importanciaSeguradaComplementar: null,
      importanciaSegurada: null,
      disabledItensPrincipal: {
        dataInicial: true,
        dataFinal: true,
        dias: true
      },
      disabledItensComplementar: {
        dataInicial: true,
        dataFinal: true,
        dias: true
      },
      cloneTextoObjetoCompleto: "",
      disabledTagFieldsEndosso: false,
      desableChangeObjectBtn: false,
      enableChangeNomeSocial: false,
      alterarDatasModalidadePrincipal: false,
      alterarDatasModalidadeComplementar: false

      // vigenciaDiasComplementar: false
    };
  },
  watch: {
    importanciaSeguradaChanged() {
      this.disabledItensPrincipal = {
        dataInicial: false,
        dataFinal: false,
        dias: true
      };
    },

    importanciaSeguradaComplementaryChanged(){
      this.disabledItensComplementar = {
        dataInicial: true,
        dataFinal: false,
        dias: true
      }
    },

    changed(value) {
      if (value)
        this.startUpdate = this.$moment().format("DD/MM/YYYY HH:mm:ss");
    },

    apolice: function(value, old) {
      if (value.EndDate) {
        this.setarDataInicial(value);
      }
      if ((!old || !old.PolicyUniqueId) && value.PolicyUniqueId) {
        this.loadPercentageLimitPremiumRelationInsuredAmount();
        this.setObjeto(value.ContractualTerms.Object);

        this.updateEndosso({
          ContractualTerms: {
            SpecialCondition: null,
            Object: null,
            Endorsement: null,
            ParticularClausule: null
          }
        });

        this.selectedParticularClauses = this.setProposalClauses(false);
        this.proposalClausesProposalRequiredValues = this.setProposalClauses(
          true
        );

        this.importanciaSegurada =
          this.endosso.InsuredAmountValue || this.apolice.InsuredAmountValue;

        this.importanciaSeguradaComplementar =
          this.endosso.ComplementaryInsuredAmountValue ||
          this.apolice.ComplementaryInsuredAmountValue;
      }
    },

    endosso(value) {
      if (this.apolice && this.apolice.PolicyUniqueId)
        this.validChangedObject({
          value: value,
          valueCompare: this.apolice
        })
          .then(result => {
            this.changed = result;

            this.setObjeto(
              (value.ContractualTerms || {}).Object ||
                this.apolice.ContractualTerms.Object
            );

            if (!result) {
              this.showModalAlteracoes = false;

              if (!value.ContractualTerms)
                this.selectedParticularClauses = this.setProposalClauses(
                  true
                ).concat(this.setProposalClauses(false));
            }
          })
          .then(() => {
            let tags = document.getElementById("tags");
            if (tags) {
              let inputs = tags.querySelectorAll("input");
              let inputsObject = {};

              inputs.forEach(input => {
                inputsObject[input.name] = input.value;
              });

              this.desableChangeObjectBtn = this.verificarPropriedadeObjetoSeVazio(
                inputsObject
              );
            }
          });
    }
  },
  computed: {
    ...mapGetters("apolice-module", ["apolice", "changeTypes"]),
    ...mapGetters("endosso-module", [
      "endossoPayload",
      "endosso",
      "objectDiff",
      "seguradoraTags",
      "changedObjectEndorsement"
    ]),

    importanciaSeguradaChanged() {
      if (this.alterarDatasModalidadePrincipal) {
        return true;
      }
      if (this.$store.state["endosso-module"].hasFixedStartDate) {
        return true;
      } else {
        return (
          !!(this.endosso || {}).InsuredAmountValue &&
          (this.apolice || {}).InsuredAmountValue !==
            this.endosso.InsuredAmountValue
        );
      }
    },

    importanciaSeguradaComplementaryChanged() {
      if (this.alterarDatasModalidadeComplementar) {
        return true;
      }
      return (
        !!(this.endosso || {}).ComplementaryInsuredAmountValue &&
        (this.apolice || {}).ComplementaryInsuredAmountValue !==
          this.endosso.ComplementaryInsuredAmountValue
      );
    },

    labelItens() {
      return this.importanciaSeguradaChanged ||
        this.importanciaSeguradaComplementaryChanged
        ? {
            dataInicial: "Início vigência da nova IS",
            dataFinal: "Fim vigência da nova IS",
            dias: "Prazo dias"
          }
        : {
            dataInicial: "Início vigência da apólice",
            dataFinal: "Fim vigência da apólice",
            dias: "Prazo dias"
          };
    },

    vigenciaInicioPrincipal() {
      return this.endosso.StartDate || this.apolice.StartDate;
    },

    vigenciaFimPrincipal() {
      return this.endosso.EndDate || this.apolice.EndDate;
    },

    vigenciaDiasPrincipal() {
      return this.endosso.DeadlineDays || this.apolice.DeadlineDays;
    },

    vigenciaInicioComplementar() {
      return (
        this.endosso.ComplementaryStartDate ||
        this.apolice.ComplementaryStartDate
      );
    },

    vigenciaFimComplementar() {
      return (
        this.endosso.ComplementaryEndDate || this.apolice.ComplementaryEndDate
      );
    },

    vigenciaDiasComplementar() {
      return this.$store.state["endosso-module"].endosso
        .ComplementaryDeadlineDays
        ? this.$store.state["endosso-module"].endosso.ComplementaryDeadlineDays
        : this.apolice.ComplementaryDeadlineDays;
    },

    defaultTags() {
      return TagHelpers.defaultTags(
        {
          ...this.apolice,
          ...this.endosso
        },
        this.seguradoraTags
      );
    }
  },
  methods: {
    ...mapMutations("endosso-module", [
      "updateEndosso",
      "updateDays",
      "updateVigencyStartDate",
      "updateChangedObjectEndorsement"
    ]),
    ...mapActions("endosso-module", [
      "restoreInsurancePremium",
      "GetCalculationProposalDate",
      "getPercentageLimitPremiumRelationInsuredAmount",
      "validChangedObject"
    ]),
    ...mapActions("apolice-module", ["loadEndossoChangeTypes"]),

    updateDateChange(evento) {
      if(evento.modalidade === "principal") this.alterarDatasModalidadePrincipal = true
      if(evento.modalidade === "complementar") this.alterarDatasModalidadeComplementar = true
    },

    async loadPercentageLimitPremiumRelationInsuredAmount() {
      await this.getPercentageLimitPremiumRelationInsuredAmount()
        .then(response => {
          this.percLimit = response;
        })
        .catch(errors => {
          this.$onpoint.errorModal(errors.response.data.Errors);
        });
    },

    setarDataInicial() {
      //Seta data Inicial automática quando apólice está dentro da vigência de acordo com a task #7447
      if (this.$store.state["endosso-module"].endosso.EndorsementTypeId == 2) {
        if (
          this.$moment().format() >=
            this.$store.state["apolice-module"].apolice.StartDate &&
          this.$moment().format() <
            this.$store.state["apolice-module"].apolice.EndDate
        ) {
          this.updateVigencyStartDate(new Date(this.$moment().format()));
          this.isExpired = new Date(this.$moment().format());
        }
      }
    },

    addCustomObject() {
      this.disabledTagFieldsEndosso = true;
      this.modalCustomObject = false;
      this.updateChangedObjectEndorsement(true)
      this.updateEndosso({
        IsCompleteChangeObject: true,
        CompleteObject: this.cloneTextoObjetoCompleto
      });
    },

    validChangesTypes() {     
      this.$onpoint
        .loading(() => {
          return this.loadEndossoChangeTypes(
            this.$route.params.policyUniqueId
              ? this.$route.params.policyUniqueId
              : null
          );
        })
        .then(response => {
          this.changeTypes.data.Response.map(
            types => {
              if (types.PolicyEndorsementChangeTypeDescriptionId == 15) {
                this.enableChangeObject = true;
              }
              if (types.PolicyEndorsementChangeTypeDescriptionId == 16) {
                this.enableChangeNomeSocial = true;
              }
            }
          );
        })
        .catch(errors => {
          this.$onpoint.errorModal(errors.response.data.Errors);
        });
    },

    async loadPercentageLimitPremiumRelationInsuredAmount() {
      await this.getPercentageLimitPremiumRelationInsuredAmount()
        .then(response => {
          this.percLimit = response;
        })
        .catch(errors => {
          this.$onpoint.errorModal(errors.response.data.Errors);
        });
    },

    setObjeto(terms) {
      if (terms && terms.length)
        var proposalTerms = terms.map(term => {
          let tags = eval(term.JSONTag) || [];
          let tagValue = JSON.parse(term.JSONTagValue);

          tags.forEach(tag => {
            tag.Value = tagValue[tag.Name];
          });

          return {
            JSONTag: JSON.stringify(tags.filter(tag => tag.Name != tag.Label)),
            JSONTagValue: tagValue,
            Value: term.Value,
            ContractualTermId: term.ContractualTermId
          };
        });

      if ((this.endossoObjeto || []).length)
        this.endossoObjeto.forEach(term => {
          var termChange = proposalTerms.find(
            x => x.ContractualTermId == term.ContractualTermId
          );

          if (termChange) term.JSONTagValue = termChange.JSONTagValue;
        });
      else this.endossoObjeto = proposalTerms;
    },

    async loadRestoreInsuredPremium(type) {
      this.$vs.loading();

      let data = {
        apolice: this.apolice,
        type: type
      };

      return await this.GetCalculationProposalDate(data)
        .then(async response => {
          this.updateDays(response);
          this.setLoading();
          return await this.restoreInsurancePremium(this.apolice)
            .then(response => {
              this.IsBankInformationNeeded = response.IsBankInformationNeeded;
              this.restituir = response.InsurancePremium <= 0;
              this.updateEndosso({
                RequestInsurancePremiumReview: response.InsurancePremium
              });

              this.loadingPremium = false;
              this.$vs.loading.close();
              this.$forceUpdate();
            })
            .catch(errors => {
              this.loadingPremium = false;
              console.log(errors);
            });
        })
        .catch(errors => {
          this.$vs.loading.close();
          console.log(errors);
        });
    },

    setLoading() {
      this.loadingPremium = true;
    },

    particularClausesProposalTermsValuesChanged(proposalTermsValues) {
      this.updateEndosso({
        ContractualTerms: {
          ...this.endosso.ContractualTerms,
          ParticularClausule: proposalTermsValues
        }
      });
    },

    changeImportanciaSegurada() {
      if (this.isExpired && this.firstStartDate == false) {
        this.updateEndosso({
          StartDate: this.isExpired,
          InsuredAmountValue: this.importanciaSegurada
        });
        this.firstStartDate = true;
      } else {
        this.updateEndosso({
          InsuredAmountValue: this.importanciaSegurada
        });
      }

      this.loadRestoreInsuredPremium();
    },

    updateDataInicial(value) {
      this.updateEndosso({
        StartDate: value
      });
      this.disabledItensPrincipal.dias = true;
      this.disabledItensPrincipal.dataFinal = false;
      setTimeout(() => {
        this.loadRestoreInsuredPremium(1);
      }, 0);
    },

    updateDataFinal(value) {
      this.updateEndosso({
        EndDate: value
      });
      this.disabledItensPrincipal.dias = true;
      this.disabledItensPrincipal.dataInicial = false;

      if (this.apolice.ComplementaryModality)
        this.updateDataInicialComplementar(value);

      setTimeout(() => {
        this.loadRestoreInsuredPremium(3);
      }, 0);
    },

    updatePrazo(value) {
      setTimeout(() => {
        this.loadRestoreInsuredPremium(9);
      }, 0);
    },

    updateDataInicialComplementar(value) {
      this.updateEndosso({
        ComplementaryStartDate: value
      });

      this.loadRestoreInsuredPremium(4);
    },

    updateDataFinalComplementar(value) {
      this.updateEndosso({
        ComplementaryEndDate: value
      });

      this.loadRestoreInsuredPremium(5);
    },

    updatePrazoComplementar(value) {
      this.updateEndosso({
        ComplementaryDeadlineDays: value
      });
    },

    updatePropostaTermos(value) {
      if (!value.initValue) {
        let objeto = [];

        (value.termos || []).forEach(termo => {
          let objetoOriginal = this.apolice.ContractualTerms.Object.find(
            x => x.ContractualTermId == termo.ContractualTermId
          );

          if (objetoOriginal && objetoOriginal.Value != termo.Value) {
            let originalTearm = {
              ...objetoOriginal,
              ...termo
            };

            objeto.push(originalTearm);
          }
        });

        if(!this.changedObjectEndorsement) {
          this.updateEndosso({
            ObjectJSON: value.object,
            ContractualTerms: {
              ...this.endosso.ContractualTerms,
              Object: []
            }
          });
        } else {
          this.updateEndosso({
            ObjectJSON: value.object,
            ContractualTerms: {
              ...this.endosso.ContractualTerms,
              Object: objeto && objeto.length ? objeto : []
            }
          });
        }
      }
    },

    setProposalClauses(required) {
      let isFixedClause = !!required;
      return (
        (this.apolice || {}).ContractualTerms.ParticularClausule.filter(
          item => item.ParticularClause.IsFixedClause == isFixedClause
        ) || []
      ).map(term => {
        let clause = {
          ...term.ParticularClause,
          Id: term.Id,
          ContractualTermId: term.ContractualTermId,
          ContractualTerm: {
            JSONTagValue: term.JSONTagValue,
            JSONTag: term.JSONTag,
            Value: term.Value,
            ContractualTermId: term.ContractualTermId,
            Id: term.ContractualTermId
          }
        };

        return clause;
      });
    },

    disabledDatesInicio() {
      if (this.importanciaSeguradaChanged) {
        let from = this.$utils.addDaysToDate(this.apolice.EndDate, 2);
        return {
          to: new Date(this.apolice.StartDate),
          from: new Date(from)
        };
      }

      return {};
    },

    changeImportanciaSeguradaComplementar() {
      this.updateEndosso({
        ComplementaryInsuredAmountValue: this.importanciaSeguradaComplementar
      });
      this.loadRestoreInsuredPremium();
    },

    clonarTextoObjetoCompleto(value) {
      this.cloneTextoObjetoCompleto = Utils.deepClone(value.objetoCompleto);
    },

    openChangeObjetctModal() {
      this.modalCustomObject = true;
      if (this.$store.state["endosso-module"].endosso.CompleteObject) {
        this.cloneTextoObjetoCompleto = this.$store.state[
          "endosso-module"
        ].endosso.CompleteObject;
      }
    },

    verificarPropriedadeObjetoSeVazio(objeto) {
      for (let propriedade in objeto) {
        if (objeto.hasOwnProperty(propriedade)) {
          if (
            objeto[propriedade] == null ||
            objeto[propriedade] === "" ||
            (Array.isArray(objeto[propriedade]) &&
              objeto[propriedade].length === 0)
          ) {
            return true;
          }
        }
      }
      return false;
    }
  },
  created() {
    this.$appInsights.trackPageView({
      name: "endosso-alteracao",
      url: window.location.href
    });
  },
  mounted() {
    this.validChangesTypes();
    this.updateEndosso({
      EndorsementTypeId: 2,
      PolicyUniqueId: this.$route.params.policyUniqueId
    });
  }
};
</script>

<style lang="scss">
.custom-object {
  margin-top: 30px;
}

.endosso-section {
  position: relative;
}
.margin-bottom {
  margin-bottom: 20px !important;
}

#endosso-alteracao {
  p {
    i {
      color: rgba(var(--vs-warning), 1);
      font-size: 0.8em;
    }
  }

  @media (min-width: 768px) {
    aside {
      border-left: solid 1px rgba(0, 0, 0, 0.2);
      padding-left: 20px;
    }

    section {
      padding-right: 20px;
    }
  }

  @media (max-width: 768px) {
    aside {
      border-top: solid 1px rgba(0, 0, 0, 0.2);
      padding-top: 20px;
    }

    section {
      padding-bottom: 20px;
    }
  }

  .particular-condition {
    ::v-deep .vs__dropdown-toggle {
      height: auto !important;
      min-height: 50px !important;
      padding: 10px !important;
      .vs__selected {
        width: auto !important;
      }
    }
  }

  .alert-update {
    height: auto;

    .vs-alert.con-icon {
      padding: 6px 4px !important;
    }

    .icon-alert {
      padding: 0;
      height: 0;
    }
  }
}
.icon-cobertura-adicional{
  position: relative;
  top: 2px;
  font-size: 16px !important;
}
</style>
