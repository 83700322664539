<template>
  <div class="grid">
    <label class="label"
      >{{ label }}<i class="required" v-if="required"> *</i></label
    >
    <div v-if="!calendar">
      <input
        type="text"
        class="inputStyle"
        @input="formatValue"
        :style="width ? `width:${width}` : 'width:100%'"
        :id="currency ? 'currencyInput' : 'input'"
        :placeholder="currency ? 'R$' : null"
        :value="valueFormatted"
        @change="$emit('change',valueFormatted)"
        :disabled="disabled"
      />      
      <div v-if="alertRequiredItem" class="alertMessage">{{ requiredMessage || "Este campo não pode estar vazío."}}</div>
    </div>
    <div v-if="calendar" class="calendar">
      <Calendar
        @input="formatValue"
        :disabled="calendarDisabled"
        :value="date"
      />
    </div>
  </div>
</template>

<script>
import Calendar from "primevue/calendar";
export default {
  props: {
    label: String,
    required: Boolean,
    disabled: Boolean,
    width: String,
    currency: Boolean,
    calendar: Boolean,
    calendarDisabled: Boolean,
    value: {
      type: [String, Number, Boolean, Date],
      default:null,
    },
    requiredMessage:String
  },
  components: { Calendar },
  data() {
    return {
      date: null,
      valueFormatted: null,
      alertRequiredItem: false,
    };
  },
  created() {    
    if (this.value || this.value == 0) {
      this.formatValue(this.value);
    }
  },
  watch: {
    value(value, oldValue) {    
      if (this.required && (value !== oldValue) && !!!value) {
        this.alertRequiredItem = true;
      }
      if (value || value == 0) {
        this.formatValue(value);
        this.alertRequiredItem = false;
      }
      if (!value && value !=0) {
        this.valueFormatted = null;
        if (this.calendar) {
          this.formatValue(Date.now());
        }
      }
    }    
  },
  methods: {
    formatValue(event) { 
      if (this.calendar) {
        const dataSelecionada = new Date(event);
        const dataFormatada = dataSelecionada.toLocaleDateString("pt-BR");

        this.date = dataFormatada;

        this.$emit("input", dataSelecionada.toJSON());
      } else {
        if(this.value || this.value == 0){          
          this.valueFormatted = this.value
        }
        event.target && (event.target.value || event.target.value === "")
          ? (this.$emit("input", event.target.value),
            (this.valueFormatted = event.target.value))
          : this.$emit("input", this.value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.inputStyle {
  border-radius: 1px;
  height: 38px;
  border: solid 1px rgba(229, 231, 235, 1);
  border-radius: 12px;
  padding-left: 10px;
  color: #3f3f46;
}
.label {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(55, 65, 81, 1);
  margin-bottom: 6px;
}
.required {
  color: rgba(180, 23, 58, 1);
}
.p-calendar {
  width: 100%;
}
.alertMessage{
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: red;
  margin-left: 5px;
  margin-top: 5px;
}
</style>