<template>
  <section class="required-documents">
    <vs-popup
      class="popup-list-documents"
      title=""
      :active.sync="showModalDocuments"
    >
      <div class="mb-8 px-8">
        <span class="badge" v-if="detalhamento.HasPendingDocumentsToUpload">
          <span class="material-icons">contact_support</span>
        </span>
        <vs-tabs color="warning" class="required-documents-tabs pr-1">
          <vs-tab label="Follow Up">
            <div
              class="vx-row"
              v-if="!(detalhamento.Observations || []).length"
            >
              <div class="vx-col w-full mt-2">
                <vs-alert color="warning" class="h-auto">
                  Nenhum follow up cadastrado
                </vs-alert>
              </div>
            </div>

            <vs-row v-else>
              <vs-card
                class="card-follow-up"
                v-for="(mensagem, index) in detalhamento.Observations"
                :key="index"
              >
                <div class="flex items-baseline title-card">
                  <span class="text-primary font-bold mr-2 mb-2">
                    {{ mensagem.CreateByPerson }}
                  </span>

                  <p>
                    escreveu em
                    {{
                      new Date(mensagem.CreateDate) | dateFormat("DD/MM/YYYY")
                    }}
                  </p>
                </div>

                <p>{{ mensagem.Observation }}</p>
              </vs-card>
            </vs-row>
          </vs-tab>

          <vs-tab label="Lista de documentos">
            <vs-alert
              color="primary"
              title="Aviso!"
              active="true"
              style="margin-bottom: 15px"
            >
              Após o upload de todos os documentos obrigatórios clique no botão
              "Enviar para seguradora"
            </vs-alert>

            <p class="mb-5">Documentos marcados com * são obrigatórios</p>
            <vs-list>
              <vs-list-item
                :class="doc.StatusName"
                v-for="(doc, index) in detalhamento.RequestDocuments"
                :key="index"
                :title="doc.Name + (doc.IsRequired ? ' *' : '')"
                :subtitle="
                  doc.StatusDescription === 'Aguardando análise'
                    ? 'Documento Anexado'
                    : doc.StatusDescription
                "
                >{{ doc.Files }}
                <vs-button
                  v-if="doc.StatusDescription !== 'Conforme'"
                  size="large"
                  type="flat"
                  icon="cloud_upload"
                  @click="modalUploadOpen(doc)"
                />
              </vs-list-item>
            </vs-list>
          </vs-tab>

          <div class="mt-4">
            <p class="mb-2">Comentário</p>
            <vs-textarea v-model="observation" placeholder="Ex.: Analisar IR dos sócios fiadores / Analisar balanços dos fiadores, etc." />
            <p class="mb-2">*Lembre de fazer upload dos documentos a serem analisados clicando em “Lista de documentos”</p>
          </div>
          <div class="text-right mt-5 w-full">
            <vs-button
              color="primary"
              type="filled"
              @click="finalizarPendencia()"
              >Enviar para seguradora</vs-button
            >
          </div>
        </vs-tabs>
      </div>
    </vs-popup>

    <vs-popup
      style="z-index: 54000"
      title="Upload de documento"
      class="popup-upload-documento"
      :active.sync="modalUpload"
      v-if="showModalDocuments"
    >
      <div class="px-4">
        <h4>{{ (selectedDocument || {}).Name }}</h4>
      </div>

      <div class="px-4">
        <upload-component v-model="files" @updateDocs="uploadDocument" />
      </div>
    </vs-popup>
  </section>
</template>

<script>
import Upload from "@/components/az-upload/AzUpload.vue";
import axiosInstance from "@/axios";
import { mapGetters, mapActions } from "vuex";
import UploadComponent from "@/components/upload/Upload.vue";

export default {
  name: "required-documents",
  components: {
    UploadComponent
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    detalhamento: {
      type: Object,
      required: true
    },
    propostaUniqueId: {
      type: String,
      required: true
    },
    showFollowUp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modalUpload: false,
      uploadDocumentController: {},
      selectedDocument: {},
      next: false,
      observation: "",
      files: [],
      requestDocuments: []
    };
  },

  computed: {
    showModalDocuments: {
      get() {
        return this.showModal;
      },

      set(value) {
        this.$emit("dismissModal", value);
      }
    }
  },
  methods: {
    ...mapActions("proposta-module", [
      "uploadDocumentoByProposal",
      "validateRequiredProposalDocuments",
      "updateProposalTimelineInfo"
    ]),

    openModalUpload(document) {
      this.selectedDocument = document;
      this.modalUpload = true;
      if (this.uploadDocumentController.files().length)
        this.uploadDocumentController.remove();
      this.uploadDocumentController.reset();
    },

    async finalizarPendencia() {
      if (this.observation.length === 0) {
        this.$onpoint.errorModal("Preencha o campo de comentário.");
        return;
      }
      await this.$onpoint.loadingModal("Estamos processando sua solicitação", async() => {
        return await this.updateProposalTimelineInfo({
          ProposalUniqueId: this.propostaUniqueId,
          Observation: this.observation
        })
          .then(() => {
            this.$emit("closeModal");
            this.popupDetalhamento = false;
            this.$onpoint.successModal("Acompanhe a análise de sua Proposta pela Plataforma!");
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex.response.data.Errors);
          });
      });
    },

    async uploadDocument(files) {
      const request = {
        ProposalUniqueId: this.propostaUniqueId,
        RequestDocuments: [
          {
            RequestDocumentUniqueId: this.selectedDocument.UniqueId,
            ArchivesUniqueId: files.archives.map(x => x.UniqueId)
          }
        ]
      };

      await this.$onpoint.loading(async () => {
        this.modalUpload = false;
        return await this.uploadDocumentoByProposal(request)
          .then(response => {
            this.$onpoint.successModal(
              `${
                files.archives.length > 1
                  ? "Documentos enviados"
                  : "Documento enviado"
              } com sucesso.`
            );

            let docs = [];
            this.detalhamento.RequestDocuments.forEach(doc => {
              if (doc.UniqueId == this.selectedDocument.UniqueId)
                docs.push({
                  ...this.selectedDocument,
                  StatusDescription: "Aguardando análise",
                  StatusName: "Aguardando_Analise"
                });
              else docs.push(doc);
            });

            this.$emit("updateDocs", docs);
            this.selectedDocument = {};
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },

    modalUploadOpen(doc) {      
      this.selectedDocument = doc;
      this.modalUpload = true;
    }
  }
};
</script>

<style lang="scss">
.error-modal,
.success-modal,
.onpoint-loadingmodal {
  z-index: 53003;
}

.popup-upload-documento {
  .con-upload {
    max-height: 400px;
    overflow-y: auto;
  }
}

.popup-list-documents {
  z-index: 52850 !important;

  .vs-popup {
    width: 717px;

    .vs-popup--content {
      padding-top: 0;
      margin-top: 0;
    }
  }

  .line-vs-tabs {
    background: rgba(var(--vs-warning), 1) !important;
  }
  .con-vs-tabs {
    max-height: 600px;
    overflow-y: auto;
    .vs-tabs--content {
      padding: 20px 0 5px 0;
      word-break: break-word;

      .card-follow-up {
        background: #ffffff;
        box-shadow: 0 0;
        border: 1px solid #e4e1e1;
        box-sizing: border-box;
        padding: 16px;
        border-radius: 0;

        .vs-card--content {
          margin: 0;

          .title-card {
            font-size: 16px;

            p {
              color: #8e8e8e;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .vs-list,
  .vs-list .vs-list--item {
    padding: 0 !important;
  }

  .vs-list--item {
    border-bottom: none;

    &.Conforme {
      .vs-list--title {
        width: 70%;
      }

      .vs-list--subtitle {
        border: solid 1px #00c77a;
        color: #00c77a;
      }
    }

    &.Pendente {
      .vs-list--title {
        width: 70%;
      }

      .vs-list--subtitle {
        border: solid 1px #ff9100;
        color: #ff9100;
      }
    }

    &.Aguardando_Analise {
      .vs-list--title {
        width: 70%;
      }

      .vs-list--subtitle {
        border: solid 1px #0096d1;
        color: #0096d1;
      }
    }

    &.Reprovado {
      .vs-list--title {
        width: 70%;
      }

      .vs-list--subtitle {
        border: solid 1px #c40f06;
        color: #c40f06;
      }
    }
  }

  .list-titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .vs-list--subtitle {
      color: white;
      padding: 2px 5px;
      border-radius: 10px;
    }
  }

  .obs {
    width: 80%;
  }

  .badge {
    position: absolute;
    color: #0f6eb4;
    display: grid;
    place-items: center;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    margin-left: 250px;
    margin-top: 5px;

    span {
      font-size: 16px;
    }
  }

  .mensagem {
    border: solid 1px rgba(0, 157, 223, 0.2);
  }
}

.popup-upload-documento {
  z-index: 52851 !important;
}
</style>
