var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("Card", {
        staticClass: "card",
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "card-premio" }, [
                  _c("p", { staticClass: "text-premio" }, [
                    _vm._v("Valor do prêmio estimado"),
                  ]),
                  _vm.premio
                    ? _c("p", { staticClass: "value-premio mt-3" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.premio.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })
                            ) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "premio-alert" }, [
                    _c("i", {
                      staticClass: "onpoint-warning-octagon icon-alert",
                    }),
                    _c("p", { staticClass: "premio-alert-text" }, [
                      _vm._v(
                        "\n            Este valor poderá sofrer alterações durante a avaliação de risco.\n          "
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-8" },
                  [
                    _c(
                      "Accordion",
                      { staticClass: "accordion" },
                      [
                        _c(
                          "AccordionTab",
                          { attrs: { header: "Objeto da proposta completo" } },
                          [
                            _c("div", {
                              domProps: { innerHTML: _vm._s(_vm.fullTerms) },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-8" },
                  [
                    _c(
                      "Accordion",
                      { staticClass: "accordion" },
                      [
                        _c(
                          "AccordionTab",
                          { attrs: { header: "Dados tomador" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "grid grid-rows-2 grid-cols-2 gap-5",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "tab-item-container" },
                                  [
                                    _c("p", { staticClass: "title-data" }, [
                                      _vm._v("Tomador"),
                                    ]),
                                    _c("p", { staticClass: "value-data" }, [
                                      _vm._v(_vm._s(_vm.tomador.Name)),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "tab-item-container" },
                                  [
                                    _c("p", { staticClass: "title-data" }, [
                                      _vm._v("CNPJ"),
                                    ]),
                                    _c("p", { staticClass: "value-data" }, [
                                      _vm._v(_vm._s(_vm.tomador.CpfCnpj)),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-span-2" },
                                  [
                                    _vm._l(
                                      _vm.tomador.Locations,
                                      function (local, i) {
                                        return [
                                          local.IsMain
                                            ? _c(
                                                "div",
                                                {
                                                  key: i,
                                                  staticClass:
                                                    "tab-item-container",
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "title-data",
                                                    },
                                                    [_vm._v("Endereço")]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "value-data",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            local.IsMain
                                                              ? local.Address
                                                              : null
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-8" },
                  [
                    _c(
                      "Accordion",
                      { staticClass: "accordion" },
                      [
                        _c(
                          "AccordionTab",
                          { attrs: { header: "Dados segurado" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "grid grid-rows-2 grid-cols-2 gap-5",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "tab-item-container" },
                                  [
                                    _c("p", { staticClass: "title-data" }, [
                                      _vm._v("Segurado"),
                                    ]),
                                    _c("p", { staticClass: "value-data" }, [
                                      _vm._v(_vm._s(_vm.segurado.Name)),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "tab-item-container" },
                                  [
                                    _c("p", { staticClass: "title-data" }, [
                                      _vm._v("CNPJ"),
                                    ]),
                                    _c("p", { staticClass: "value-data" }, [
                                      _vm._v(_vm._s(_vm.segurado.CpfCnpj)),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-span-2" },
                                  [
                                    _vm._l(
                                      _vm.segurado.Locations,
                                      function (local, i) {
                                        return [
                                          local.IsMain
                                            ? _c(
                                                "div",
                                                {
                                                  key: i,
                                                  staticClass:
                                                    "tab-item-container",
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "title-data",
                                                    },
                                                    [_vm._v("Endereço")]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "value-data",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            local.IsMain
                                                              ? local.Address
                                                              : null
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-8" },
                  [
                    _c(
                      "Accordion",
                      { staticClass: "accordion" },
                      [
                        _c(
                          "AccordionTab",
                          { attrs: { header: "Dados de risco" } },
                          [
                            _c(
                              "div",
                              { staticClass: "grid grid-cols-4 gap-5 mb-5" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "tab-item-container col-span-2",
                                  },
                                  [
                                    _c("p", { staticClass: "title-data" }, [
                                      _vm._v("Grupo Modalidade"),
                                    ]),
                                    _c("p", { staticClass: "value-data" }, [
                                      _vm._v(
                                        _vm._s(_vm.proposta.ModalityGroup.Name)
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "tab-item-container col-span-2",
                                  },
                                  [
                                    _c("p", { staticClass: "title-data" }, [
                                      _vm._v("Modalidade principal"),
                                    ]),
                                    _c("p", { staticClass: "value-data" }, [
                                      _vm._v(
                                        _vm._s(_vm.proposta.Modality.Name)
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "tab-item-container" },
                                  [
                                    _c("p", { staticClass: "title-data" }, [
                                      _vm._v("Taxa aplicada (%)"),
                                    ]),
                                    _c("p", { staticClass: "value-data" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.proposta.ModalityGroup
                                              .RiskRate + " %"
                                          ) +
                                          "\n                "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "tab-item-container" },
                                  [
                                    _c("p", { staticClass: "title-data" }, [
                                      _vm._v("Valor IS"),
                                    ]),
                                    _c("p", { staticClass: "value-data" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.proposta.InsuredAmountValue.toLocaleString(
                                              "pt-br",
                                              {
                                                style: "currency",
                                                currency: "BRL",
                                              }
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "tab-item-container" },
                                  [
                                    _c("p", { staticClass: "title-data" }, [
                                      _vm._v("Inicio / Final da vigencia"),
                                    ]),
                                    _c("p", { staticClass: "value-data" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              new Date(_vm.proposta.StartDate),
                                              "DD/MM/YYYY"
                                            )
                                          ) +
                                          "\n                  -\n                  " +
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              new Date(_vm.proposta.EndDate),
                                              "DD/MM/YYYY"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "tab-item-container" },
                                  [
                                    _c("p", { staticClass: "title-data" }, [
                                      _vm._v("Prazo"),
                                    ]),
                                    _c("p", { staticClass: "value-data" }, [
                                      _vm._v(_vm._s(_vm.proposta.DeadlineDays)),
                                    ]),
                                  ]
                                ),
                                _vm.proposta.ProposalCoverages &&
                                _vm.proposta.ProposalCoverages[0]
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-span-4 tab-item-container",
                                      },
                                      [
                                        _c("p", { staticClass: "title-data" }, [
                                          _vm._v("Coberturas adicionais"),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "flex" },
                                          [
                                            _vm._l(
                                              _vm.proposta.ProposalCoverages,
                                              function (cobertura, i) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      key: i,
                                                      staticClass: "flex",
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "value-data",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                cobertura.Name
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                      i <
                                                      _vm.proposta
                                                        .ProposalCoverages
                                                        .length -
                                                        1
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "divider-margin-value-data",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        /\n                      "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm.proposta.ComplementaryModality
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "title-divider mb-5" },
                                    [
                                      _vm._v(
                                        "\n                Dados modalidade complementar\n              "
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "flex gap-5" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "tab-item-container w-1/4",
                                      },
                                      [
                                        _c("p", { staticClass: "title-data" }, [
                                          _vm._v("Taxa aplicada (%)"),
                                        ]),
                                        _c("p", { staticClass: "value-data" }, [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.proposta
                                                  .ComplementaryModality
                                                  .ModalityTaxValue + " %"
                                              ) +
                                              "\n                  "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "tab-item-container w-1/4",
                                      },
                                      [
                                        _c("p", { staticClass: "title-data" }, [
                                          _vm._v("Valor IS"),
                                        ]),
                                        _c("p", { staticClass: "value-data" }, [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                Number(
                                                  _vm.proposta
                                                    .ComplementaryInsuredAmountValue
                                                ).toLocaleString("pt-br", {
                                                  style: "currency",
                                                  currency: "BRL",
                                                })
                                              ) +
                                              "\n                  "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "tab-item-container w-1/4",
                                      },
                                      [
                                        _c("p", { staticClass: "title-data" }, [
                                          _vm._v("Inicio / Final da vigencia"),
                                        ]),
                                        _c("p", { staticClass: "value-data" }, [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm._f("dateFormat")(
                                                  new Date(
                                                    _vm.proposta.ComplementaryStartDate
                                                  ),
                                                  "DD/MM/YYYY"
                                                )
                                              ) +
                                              "\n                    -\n                    " +
                                              _vm._s(
                                                _vm._f("dateFormat")(
                                                  new Date(
                                                    _vm.proposta.ComplementaryEndDate
                                                  ),
                                                  "DD/MM/YYYY"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "tab-item-container w-1/4",
                                      },
                                      [
                                        _c("p", { staticClass: "title-data" }, [
                                          _vm._v("Prazo"),
                                        ]),
                                        _c("p", { staticClass: "value-data" }, [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.proposta
                                                  .ComplementaryDeadlineDays
                                              ) +
                                              "\n                  "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.particularClauses &&
                            Object.keys(_vm.particularClauses[0])[0]
                              ? _c(
                                  "div",
                                  { staticClass: "mb-0" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "title-divider mt-5 mb-5",
                                      },
                                      [
                                        _vm._v(
                                          "\n                Dados clausulas particulares\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._l(
                                      _vm.particularClauses,
                                      function (clausula, i) {
                                        return _c("div", { key: i }, [
                                          _c(
                                            "div",
                                            {
                                              class:
                                                clausula.Campos &&
                                                clausula.Campos.length > 3
                                                  ? "grid grid-cols-3 gap-5"
                                                  : "flex gap-5 mb-5",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  class:
                                                    clausula.Campos &&
                                                    clausula.Campos.length > 3
                                                      ? "tab-item-container"
                                                      : "tab-item-container w-1/3",
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "title-data",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "condições particulares"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "value-data",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(clausula.Name)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._l(
                                                clausula.Campos,
                                                function (campo, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      class:
                                                        clausula.Campos &&
                                                        clausula.Campos.length >
                                                          3 &&
                                                        index ===
                                                          clausula.Campos
                                                            .length -
                                                            1
                                                          ? "tab-item-container col-span-3 mb-0"
                                                          : clausula.Campos &&
                                                            clausula.Campos
                                                              .length > 3 &&
                                                            index !==
                                                              clausula.Campos
                                                                .length -
                                                                1
                                                          ? "tab-item-container"
                                                          : "tab-item-container w-1/3",
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "title-data",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                campo.Label
                                                                  ? campo.Label
                                                                  : null
                                                              ) +
                                                              "\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "value-data",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                campo.Value
                                                                  ? campo.Value
                                                                  : null
                                                              ) +
                                                              "\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ])
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "flex justify-end" },
                  [
                    _c("Button", {
                      staticClass: "p-button-rounded cancel-button",
                      attrs: { label: "Cancelar" },
                      on: {
                        click: function ($event) {
                          return _vm.cancelCreateProposal()
                        },
                      },
                    }),
                    _c("Button", {
                      staticClass: "p-button-rounded back-button",
                      attrs: { label: "Voltar" },
                      on: {
                        click: function ($event) {
                          return _vm.prevPage()
                        },
                      },
                    }),
                    _c("Button", {
                      staticClass: "p-button-rounded",
                      attrs: { label: "Enviar para a seguradora" },
                      on: {
                        click: function ($event) {
                          return _vm.salvarProposta()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("poposal-step-rules-modal", {
        attrs: {
          showModal: _vm.enviarParaSeguradoraModal,
          stepList: _vm.getSteps,
          showErrorButton: _vm.showTimelineErrorButton,
          showSuccessButton:
            !_vm.showTimelineErrorButton &&
            !_vm.showRequiredDocumentsButton &&
            !!_vm.proposta.UniqueId,
          showPendingButton: _vm.showRequiredDocumentsButton,
          showLeaveButton: !(_vm.showTimelineButton && _vm.proposta.UniqueId),
          showButtons: _vm.showActionButtons,
          showExeption: _vm.showExeptionButton,
          labelModal: "Proposta",
          labelErrorButton: "Seguir para timeline",
          labelSuccessButton: "Seguir para emissão",
        },
        on: {
          onClickSuccessButton: () => _vm.nextPage(),
          onClickErrorButton: () => _vm.goToTimeline(),
          onClickCloseButton: () => _vm.goToDashboard(),
          onClickLeaveButton: () => _vm.closeModal(),
          onClickPendingButton: () => _vm.sendDocuments(),
          setActionModal: _vm.enviarParaSeguradoraModal,
        },
      }),
      ((_vm.detalhamento || {}).RequestDocuments || []).length
        ? [
            _c("required-documents", {
              attrs: {
                proposal: _vm.proposta,
                detalhamento: _vm.detalhamento,
                propostaUniqueId: _vm.proposta.UniqueId,
                showModal: _vm.sendDocumentsDialog,
                showFollowUp: true,
              },
              on: {
                closeModal: _vm.closeModalDocuments,
                dismissModal: _vm.closeModalDocuments,
                updateDocs: _vm.updateDocs,
              },
            }),
          ]
        : _vm._e(),
      _c(
        "vs-popup",
        {
          staticClass: "modalCancelation",
          attrs: {
            active: _vm.showModalCancelationProposal,
            title: "",
            "button-close-hidden": true,
          },
          on: {
            "update:active": function ($event) {
              _vm.showModalCancelationProposal = $event
            },
          },
        },
        [
          _c("div", [
            _c("h3", [_vm._v("O valor da proposta excede o limite aprovado.")]),
            _c("br"),
            _c("h6", [
              _vm._v(
                "\n        Deseja revisar limite deste Tomador para continuar com a proposta?\n      "
              ),
            ]),
          ]),
          _c(
            "div",
            [
              _c(
                "vs-button",
                {
                  staticClass: "mt-8 px-20 button-default",
                  attrs: { color: "primary", type: "filled" },
                  on: {
                    click: function ($event) {
                      return _vm.continuarProposta()
                    },
                  },
                },
                [_vm._v("\n        Continuar com a proposta\n      ")]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "mt-8 ml-3",
                  staticStyle: {
                    "background-color": "#cb2020 !important",
                    float: "inline-end",
                  },
                  attrs: { type: "filled" },
                  on: {
                    click: function ($event) {
                      return _vm.cancelarProposta()
                    },
                  },
                },
                [_vm._v("\n        Cancelar proposta\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }