export const ResponsiblePersonType = Object.freeze({
  Administrator: 1,
  FinancialOfficer: 2
});

export const ProposalTimelineSituationGroup = Object.freeze({
  Cotacao: 1,
  Proposta: 2,
  Emissao: 3
});

export const ProposalTimelineSituation = Object.freeze({
  Cotacao: 1,
  Cadastro: 2,
  Credito: 3,
  Subscricao: 4,
  Resseguro: 5,
  Emissao: 6
});

export const ProposalTimelineStatus = Object.freeze({
  Aprovado: 1,
  Seguradora: 2,
  Corretor: 3,
  Recusado: 4,
  Cancelado: 5
});

export const ProposalStatus = Object.freeze({
  Cotacao: 1,
  EmAnalise: 2,
  AguardandoEmissao: 3,
  AguardandoPagamento: 4,
  Emitida: 5,
  AguardandoCorretor: 6,
  Recusada: 98,
  Cancelada: 99
});

export const PolicyStatus = Object.freeze({
  AguardandoEmissao: 1,
  Emitida: 2,
  Cancelada: 3,
  Vencida: 4,
  Rejeitada: 5
});

export const ContractualTermType = Object.freeze({
  CondicoesGerais: 1,
  CondicoesEspeciais: 2,
  Objeto: 3,
  CoberturasAdicionais: 4,
  ObjetoCobertura: 5
});

export const HistoryProperty = Object.freeze({
  Number: "Número",
  OriginalDocument: "OriginalDocument",
  ArchivePolicyId: "ArchivePolicyId",
  IssueDate: "IssueDate",
  PolicyStatusId: "Status"
});

export const CCGStatus = Object.freeze({
  Criado: 1,
  AguardandoAssinatura: 2,
  AguardandoAnaliseSeguradora: 3,
  Assinado: 4,
  AlteracaoReprovada: 5,
  AguardandoCorretor: 6
});