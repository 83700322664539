var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("Card", {
        staticClass: "card",
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("div", { staticClass: "title" }, [
                  _c("p", [_vm._v("Dados Financeiros")]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "subtitle",
            fn: function () {
              return [
                _c("div", { staticClass: "subtitle" }, [
                  _c("p", [_vm._v("Informe a forma de pagamento da proposta")]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _vm.proposta
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "flex gap-5" },
                        [
                          _c("OnpointInputCurrency", {
                            staticClass: "mb-5 w-1/4",
                            attrs: { label: "Valor do prêmio", disabled: true },
                            model: {
                              value: _vm.proposta.InsurancePremium,
                              callback: function ($$v) {
                                _vm.$set(_vm.proposta, "InsurancePremium", $$v)
                              },
                              expression: "proposta.InsurancePremium",
                            },
                          }),
                          _c("OnpointInput", {
                            staticClass: "mb-5 w-1/4",
                            attrs: {
                              label: "Taxa aplicada (%)",
                              disabled: true,
                            },
                            model: {
                              value: _vm.proposta.RiskRate,
                              callback: function ($$v) {
                                _vm.$set(_vm.proposta, "RiskRate", $$v)
                              },
                              expression: "proposta.RiskRate",
                            },
                          }),
                          _c("OnpointInputCurrency", {
                            staticClass: "mb-5 w-1/4",
                            attrs: {
                              label: "Valor da comissão",
                              disabled: true,
                            },
                            model: {
                              value: _vm.proposta.ComissionValue,
                              callback: function ($$v) {
                                _vm.$set(_vm.proposta, "ComissionValue", $$v)
                              },
                              expression: "proposta.ComissionValue",
                            },
                          }),
                          _c("OnpointInput", {
                            staticClass: "mb-5 w-1/4",
                            attrs: { label: "% Comissão", disabled: true },
                            model: {
                              value: _vm.proposta.ComissionPercentage,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.proposta,
                                  "ComissionPercentage",
                                  $$v
                                )
                              },
                              expression: "proposta.ComissionPercentage",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-5" },
                        [_c("Cocorretagem")],
                        1
                      ),
                      _vm.propostaFinancialData.InstallmentResult
                        ? _c("div", [
                            _c("p", { staticClass: "mb-5 title" }, [
                              _vm._v("Forma de pagamento"),
                            ]),
                            _c("div", { staticClass: "flex gap-5" }, [
                              _c(
                                "div",
                                { staticClass: "mb-5 w-1/4" },
                                [
                                  _c("OnpointSelect", {
                                    attrs: {
                                      label: "Número de parcelamento",
                                      placeholder: "Selecione",
                                      required: true,
                                      items:
                                        _vm.propostaFinancialData
                                          .InstallmentResult.InstallmentOptions,
                                      optionLabel: "Description",
                                    },
                                    model: {
                                      value: _vm.dataInstallment,
                                      callback: function ($$v) {
                                        _vm.dataInstallment = $$v
                                      },
                                      expression: "dataInstallment",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mb-5 w-1/4" },
                                [
                                  _c("OnpointInput", {
                                    attrs: {
                                      label: "Vencimento da 1ª parcela",
                                      required: true,
                                      disabled: true,
                                      calendar: true,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mb-5 w-1/4" },
                                [
                                  _c("OnpointSelect", {
                                    attrs: {
                                      label: "Dia de vencimento das parcelas",
                                      required: true,
                                      items:
                                        _vm.propostaFinancialData
                                          .InstallmentResult
                                          .PossibleGracePeriodsInDays,
                                      placeholder: "Selecione",
                                    },
                                    model: {
                                      value: _vm.dayToPayInstallment,
                                      callback: function ($$v) {
                                        _vm.dayToPayInstallment = $$v
                                      },
                                      expression: "dayToPayInstallment",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mb-5 w-1/4" },
                                [
                                  _vm.dataInstallment
                                    ? _c("OnpointInputCurrency", {
                                        attrs: {
                                          label:
                                            "Fracionamento na parcela (R$)",
                                          value:
                                            _vm.dataInstallment
                                              .InterestValuePerInstallment,
                                          disabled: true,
                                        },
                                      })
                                    : _c("OnpointInput", {
                                        attrs: {
                                          label:
                                            "Fracionamento na parcela (R$)",
                                          disabled: true,
                                        },
                                      }),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "flex justify-end" },
                  [
                    _c("Button", {
                      staticClass: "p-button-rounded cancel-button",
                      attrs: { label: "Cancelar" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({ name: "propostas" })
                        },
                      },
                    }),
                    _c("Button", {
                      staticClass: "p-button-rounded default-button",
                      attrs: { label: "Salvar proposta" },
                      on: {
                        click: function ($event) {
                          _vm.enviarEventoAppInsights("salvar-proposta"),
                            _vm.salvarProposta()
                        },
                      },
                    }),
                    _c("Button", {
                      staticClass: "p-button-rounded default-button",
                      attrs: { label: "Imprimir minuta" },
                      on: {
                        click: function ($event) {
                          return _vm.imprimirMinuta()
                        },
                      },
                    }),
                    _c("Button", {
                      staticClass: "p-button-rounded",
                      attrs: { label: "Emitir minuta", disabled: !_vm.canEmit },
                      on: {
                        click: function ($event) {
                          return _vm.emitirApolice()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "Dialog",
        {
          staticClass: "termos-aceite-modal",
          attrs: {
            title: "",
            visible: _vm.assinarNovoTermoDeAceiteProposta,
            containerStyle: { width: "70vw" },
          },
          on: {
            "update:visible": function ($event) {
              _vm.assinarNovoTermoDeAceiteProposta = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vw-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("h4", [_vm._v("Termo e declaração")]),
              _c("small", [
                _c("b", [
                  _vm._v(
                    "Leia os termos para a solicitação de emissão da apólice"
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c(
              "div",
              {
                staticClass: "vx-col w-full",
                staticStyle: { "background-color": "'#f2f2f2'" },
              },
              [
                _c("span", {
                  staticClass: "text-justify",
                  domProps: { innerHTML: _vm._s(_vm.TermoAceite) },
                }),
              ]
            ),
          ]),
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.aceitouTermosDeAceiteProposta,
                    expression: "aceitouTermosDeAceiteProposta",
                  },
                ],
                staticClass: "mt-8 mb-4 mx-0 alingCheckbox",
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.aceitouTermosDeAceiteProposta)
                    ? _vm._i(_vm.aceitouTermosDeAceiteProposta, null) > -1
                    : _vm.aceitouTermosDeAceiteProposta,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.aceitouTermosDeAceiteProposta,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          (_vm.aceitouTermosDeAceiteProposta = $$a.concat([
                            $$v,
                          ]))
                      } else {
                        $$i > -1 &&
                          (_vm.aceitouTermosDeAceiteProposta = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.aceitouTermosDeAceiteProposta = $$c
                    }
                  },
                },
              }),
              _vm._v(
                "\n        Declaro que li e estou ciente das condições acima.\n      "
              ),
            ]),
          ]),
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full text-right" },
              [
                _c(
                  "Button",
                  {
                    staticClass: "button-default mr-5",
                    on: {
                      click: function ($event) {
                        return _vm.cancelarEmissao()
                      },
                    },
                  },
                  [_vm._v("\n          Cancelar\n        ")]
                ),
                _c(
                  "Button",
                  {
                    staticClass: "button-primary",
                    attrs: { disabled: !_vm.aceitouTermosDeAceiteProposta },
                    on: {
                      click: function ($event) {
                        return _vm.validaDataVigencia()
                      },
                    },
                  },
                  [_vm._v("\n          Emitir\n        ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "Dialog",
        {
          staticClass: "validacao-data-vigente-modal",
          attrs: { title: "", visible: _vm.editaDataVigente },
          on: {
            "update:visible": function ($event) {
              _vm.editaDataVigente = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vw-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("h4", [_vm._v("Aviso")]),
              _c("small", [
                _c("b", [
                  _vm._v(
                    "A data de vigência da proposta não pode ser retroativa."
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c(
              "div",
              {
                staticClass: "vx-col w-full",
                staticStyle: { "background-color": "'#f2f2f2'" },
              },
              [
                _c("span", [
                  _vm._v(" " + _vm._s(_vm.mensagemRetornoData) + "."),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("span", [_vm._v("Deseja alterar?")]),
            ]),
          ]),
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full text-right" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "button-default mr-5",
                    on: {
                      click: function ($event) {
                        return _vm.cancelarEdicaoDatas()
                      },
                    },
                  },
                  [_vm._v("\n          Cancelar\n        ")]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "button-primary",
                    on: {
                      click: function ($event) {
                        return _vm.confirmarEmissao(true)
                      },
                    },
                  },
                  [_vm._v("\n          Confirma\n        ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "Dialog",
        {
          staticClass: "modal-explicativo-emissao-mapfre",
          attrs: { title: "", visible: _vm.explicativoEmissaoMapfre },
          on: {
            "update:visible": function ($event) {
              _vm.explicativoEmissaoMapfre = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vw-row" }, [
            _c(
              "div",
              {
                staticClass:
                  "vx-col w-full text-center modal-explicativo-emissao-mapfre-content",
              },
              [
                _c("h4", [_vm._v("Atenção")]),
                _c("p", [
                  _vm._v(
                    "\n          Sua solicitação está sendo processada. Caso a Apólice/Boleto(s)\n          ainda não esteja(m) disponível(eis) para download na próxima tela,\n          vá para a área de consulta de propostas ou apólices e verifique se\n          está(ão) disponível(eis). Você também receberá no seu e-mail a\n          confirmação da emissão da apólice.\n        "
                  ),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full text-center" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "button-primary",
                    on: {
                      click: function ($event) {
                        return _vm.prosseguirEmissaoMapfre()
                      },
                    },
                  },
                  [_vm._v("\n          Prosseguir\n        ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }