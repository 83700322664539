var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grid" }, [
    _c("label", { staticClass: "label" }, [
      _vm._v(_vm._s(_vm.label)),
      _vm.required
        ? _c("i", { staticClass: "required" }, [_vm._v(" *")])
        : _vm._e(),
    ]),
    _c("input", {
      staticClass: "inputStyle",
      attrs: { type: "text", placeholder: "R$", disabled: _vm.disabled },
      domProps: { value: _vm.valueFormatted },
      on: {
        input: _vm.formatValue,
        change: function ($event) {
          return _vm.$emit("change")
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }